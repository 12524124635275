import { Inject, OnDestroy, OnInit, ViewChild, Component } from '@angular/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { TrapState, UpdateLinerForm, trapStateDefault } from '../trap.state';
import { Store, select } from '@ngrx/store';
import _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/services/application.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { Trap } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { PATCH_TRAP } from '../trap.actions';

@Component({
  selector: 'app-updateliner-modal',
  templateUrl: './update-liner-modal.component.html',
  styleUrls: ['./update-liner-modal.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class UpdateLinerModalComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) public form!: NgForm;

  public trapState$: Observable<TrapState>;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public state: TrapState = trapStateDefault;
  public selectedDate: string = '';
  public selection: Array<Trap>;

  constructor(
    private store: Store<any>,
    public trans: TranslateTypes,
    private dialogRef: MatDialogRef<UpdateLinerModalComponent>,
    public applicationService: ApplicationService,

    @Inject(MAT_DIALOG_DATA) public data: { selectedItems: any[]; items: Array<Trap> }
  ) {
    this.trapState$ = this.store.pipe(select('trap'));
    this.selection = this.data.selectedItems;
  }

  ngOnInit(): void {
    this.subscribe = this.trapState$.subscribe((state: TrapState) => {
      this.state = state;

      if (state && state.closeModal) {
        this.closeModal();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.state.closeModal = false;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public dateChanged(event: MatDatepickerInputEvent<Date>): void {
    if (event.value) {
      let selectedDate = event.value;
      this.selectedDate = moment(selectedDate).toISOString();
    }
  }
  public updateLinerExchange(): void {
    let actualDate: string = this.selectedDate;
    const customerId = this.applicationService.getCustomerId();
    const farmId = this.applicationService.getFarmId();
    const dispatchPromises = this.selection.map((trap) => {
      const trapId = trap.id;
      const dateList = [actualDate];
      const changeInput = {
        linerExchange: dateList,
      };
      const InputForm: UpdateLinerForm = {
        customerId: customerId,
        farmId: farmId,
        trapId: trapId,
        trapData: changeInput,
      };
      return new Promise<void>(() => {
        this.store.dispatch(PATCH_TRAP({ trapForm: InputForm }));
      });
    });
    Promise.all(dispatchPromises).then(() => {
      this.closeModal();
    });
  }
}
