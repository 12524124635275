<!-- Loading -->
<app-loading [isOpen]="state.loadingRemove || state.loading" [excitedTime]="'1s'"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="hideModalList()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span class="title">{{ trans.title.harvests | label }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <div [hidden]="dataSource.data.length <= 0" class="modal-body">
    <mat-card-content>
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortDisableClear
        multiTemplateDataRows
        matSortActive="name"
        matSortDirection="asc"
      >
        <!-- Select -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="material-checkbox-th">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
              [color]="'primary'"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
              [disabled]="row.id === harvestSelected"
              [color]="'primary'"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- Items table -->
        <div
          *ngFor="let column of applicationService.filterColumnByPermission(state.displayedColumns)"
        >
          <ng-container
            matColumnDef="{{ column }}"
            *ngIf="column !== 'select' && column !== 'actions' && column === 'name'"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ trans.label[column] | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <a
                class="material-table-item-link"
                (click)="expandedElement = expandedElement === element ? null : element"
                >{{ element[column] }}</a
              >
            </td>
          </ng-container>
          <ng-container
            matColumnDef="{{ column }}"
            *ngIf="column !== 'select' && column !== 'actions' && column !== 'name'"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ trans.label[column] | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
          </ng-container>
        </div>
        <!-- View item -->
        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="
              applicationService.filterColumnByPermission(state.displayedColumns).length
            "
          >
            <div
              class="material-element-detail"
              [@detailExpand]="expandedElement === element ? 'expanded' : 'collapsed'"
              [style.height]="expandedElement === element ? 'unset' : '0 !important'"
            >
              <div
                fxLayout="column"
                fxLayout.gt-xs="row wrap"
                fxLayout.gt-md="row wrap"
                class="reset-padding-15"
              >
                <div
                  fxFlex="100%"
                  fxFlex.gt-xs="0 1 50%"
                  fxFlex.gt-lg="0 1 16.65%"
                  *ngFor="let column of state?.displayedForms"
                >
                  <div>
                    <label> {{ trans.label[column] | translate }}:</label>
                    <p>{{ element[column] ? element[column] : '-' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <!-- Items actions -->
        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="material-table-column-actions">
            <button
              mat-icon-button
              color="warn"
              matTooltipClass="material-actions-tooltip"
              [matTooltip]="trans.button.remove | translate"
              [disabled]="element.id === harvestSelected"
              (click)="removeItems([{ id: element.id }])"
            >
              <i class="las la-trash"></i>
            </button>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="
            applicationService.filterColumnByPermission(state.displayedColumns);
            sticky: true
          "
        ></tr>
        <tr
          mat-row
          *matRowDef="
            let row;
            columns: applicationService.filterColumnByPermission(state.displayedColumns)
          "
          class="material-row-height"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="material-detail-row"
        ></tr>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <mat-paginator [pageSizeOptions]="state.pageSizeOptions" showFirstLastButtons>
      </mat-paginator>
    </mat-card-actions>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="hideModalList()">
    {{ trans.button.close | translate }}
  </button>
  <div *ngIf="dataSource.data.length > 0">
    <button
      *ngIf="selection.hasValue() && applicationService.hasEditPermission(operation.remove)"
      type="button"
      mat-button
      color="warn"
      (click)="removeItems(selection.selected)"
      class="margin-right"
    >
      {{ trans.button.remove | translate }}
      ({{ selection.selected.length >= 100 ? '99+' : selection.selected.length }}
      {{ trans.button.selected | translate }})
    </button>
    <button
      *ngIf="applicationService.hasEditPermission(operation.remove)"
      type="button"
      mat-button
      color="primary"
      (click)="openDialog(false, null)"
    >
      {{ trans.button.createHarvest | label }}
    </button>
  </div>
</mat-dialog-actions>
