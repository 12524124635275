import { createAction, props } from '@ngrx/store';
import { Field } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_FIELDS = createAction('[Field Page] GET_FIELDS');
export const GET_FIELDS_SUCCESS = createAction(
  '[Field Page] GET_FIELDS_SUCCESS',
  props<{ fields: Array<Field> }>()
);
export const GET_FIELDS_FAIL = createAction(
  '[Field Page] GET_FIELDS_FAIL',
  props<{ error: any }>()
);

/**
 * Remover
 */
export const REMOVE_FIELD = createAction(
  '[Field Page] REMOVE_FIELD',
  props<{ ids: Array<string> }>()
);
export const REMOVE_FIELD_SUCCESS = createAction('[Field Page] REMOVE_FIELD_SUCCESS');
export const REMOVE_FIELD_FAIL = createAction(
  '[Field Page] REMOVE_FIELD_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const DESTROY_COMPONENT = createAction('[Field Page] DESTROY_COMPONENT');
