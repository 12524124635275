import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { ApplicationService } from '../../services/application.service';
import { GenericFirestoreService } from '../../services/generic-firestore.service';
import { GenericHttpService } from '../../services/generic-http.service';
import { of } from 'rxjs';
import * as gmTabsAction from './gm-tabs.actions';
import { MadChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Mad';
import { AccumulatedChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/AccumulatedChart';
import {
  ClimateChart,
  Field,
  Glebe,
  SoilChart,
  Trap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { CollectionReference } from '@angular/fire/compat/firestore';
@Injectable()
export class GmTabsEffects {
  constructor(
    private actions$: Actions,
    private applicationService: ApplicationService,
    private genericFirestoreService: GenericFirestoreService,
    private genericHttpServiceService: GenericHttpService
  ) {}

  /**
   * Este método chama um serviço para obter o monitoramento geral.
   */
  public getGm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_GM),
      switchMap(() =>
        this.genericFirestoreService
          .getById<GmConfiguration>(
            `${this.applicationService.getHarvestRefDatabase()}/generalMonitoringConfigurations/${this.applicationService.getUserId()}`
          )
          .pipe(
            map((gm: GmConfiguration) => gmTabsAction.GET_GM_SUCCESS({ gm })),
            catchError((error) => of(gmTabsAction.GET_GM_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para listar talhões.
   */
  public getFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_FIELDS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Field>(`${this.applicationService.getHarvestRefDatabase()}/fields`)
          .pipe(
            map((fields: Array<Field>) => gmTabsAction.GET_FIELDS_SUCCESS({ fields })),
            catchError((error) => of(gmTabsAction.GET_FIELDS_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para listar glebas.
   */
  public getGlebes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_GLEBES),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Glebe>(`${this.applicationService.getHarvestRefDatabase()}/glebes`)
          .pipe(
            map((glebes: Array<Glebe>) => gmTabsAction.GET_GLEBES_SUCCESS({ glebes })),
            catchError((error) => of(gmTabsAction.GET_GLEBES_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para listar glebas.
   */
  public getTraps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_TRAPS),
      switchMap((action) =>
        this.genericFirestoreService
          .getAll<Trap>(`${this.applicationService.getFarmRefDatabase()}/traps`, {
            queryFn: (ref: CollectionReference) => ref.where('field.id', '==', action.fieldId),
          })
          .pipe(
            map((traps: Array<Trap>) => gmTabsAction.GET_TRAPS_SUCCESS({ traps })),
            catchError((error) => of(gmTabsAction.GET_TRAPS_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para status de missões por período.
   */
  public getMadChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_MAD_CHART),
      switchMap((action) =>
        this.genericHttpServiceService
          .getAll<MadChart>(
            `chart?type=mad&customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&harvestId=${this.applicationService.getHarvestId()}&glebeName=${
              action.glebeName
            }&startDate=${action.startDate.toDate()}&endDate=${action.endDate.toDate()}`
          )
          .toPromise()
          .then((madChart: Array<MadChart>) => {
            return gmTabsAction.GET_MAD_CHART_SUCCESS({ madChart });
          })
          .catch((error) => gmTabsAction.GET_MAD_CHART_FAIL(error))
      )
    )
  );

  /**
   * Este método chama um serviço para status de missões por período.
   */
  public getClimateChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_CLIMATE_CHART),
      switchMap((action) =>
        this.genericHttpServiceService
          .getAll<ClimateChart>(
            `chart?type=climate&customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&harvestId=${this.applicationService.getHarvestId()}&startDate=${
              action.startDate
            }&endDate=${action.endDate}&ticketId=${action.ticketId}`
          )
          .toPromise()
          .then((climateChart: Array<ClimateChart>) => {
            return gmTabsAction.GET_CLIMATE_CHART_SUCCESS({ climateChart });
          })
          .catch((error) => gmTabsAction.GET_CLIMATE_CHART_FAIL(error))
      )
    )
  );

  /**
   * Este método chama um serviço para status de missões por período.
   */
  public getSoilChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_SOIL_CHART),
      switchMap((action) =>
        this.genericHttpServiceService
          .getAll<SoilChart>(
            `chart?type=soil&customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&harvestId=${this.applicationService.getHarvestId()}&startDate=${
              action.startDate
            }&endDate=${action.endDate}&ticketId=${action.ticketId}`
          )
          .toPromise()
          .then((soilChart: Array<SoilChart>) => {
            return gmTabsAction.GET_SOIL_CHART_SUCCESS({ soilChart });
          })
          .catch((error) => gmTabsAction.GET_SOIL_CHART_FAIL(error))
      )
    )
  );

  /**
   * Este método chama um serviço para capturas diárias por ocorrências.
   */
  public getReportEvolutionChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_EVOLUTION_CHART),
      switchMap((action) =>
        this.genericHttpServiceService
          .getAll<any>(
            `chart?type=reportEvolution&customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&startDate=${action.startDate.toDate()}&endDate=${action.endDate.toDate()}&fieldId=${
              action.fieldId
            }&occurrenceId=${
              action.occurrenceId
            }&harvestId=${this.applicationService.getHarvestId()}`
          )
          .toPromise()
          .then((evolutionChart: any) =>
            gmTabsAction.GET_EVOLUTION_CHART_SUCCESS({ evolutionChart })
          )
          .catch((error) => gmTabsAction.GET_EVOLUTION_CHART_FAIL(error))
      )
    )
  );

  /**
   * Este método chama um serviço para contagem acumuda por ocorrências.
   */
  public getReportAccumulatedChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_ACCUMULATED_CHART),
      switchMap((action) =>
        this.genericHttpServiceService
          .getById<AccumulatedChart>(
            `chart?type=reportAccumulated&customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&startDate=${action.startDate.toDate()}&endDate=${action.endDate.toDate()}&fieldId=${
              action.fieldId
            }&occurrenceId=${
              action.occurrenceId
            }&harvestId=${this.applicationService.getHarvestId()}`
          )
          .toPromise()
          .then((accumulatedChart: AccumulatedChart) =>
            gmTabsAction.GET_ACCUMULATED_CHART_SUCCESS({ accumulatedChart })
          )
          .catch((error) => gmTabsAction.GET_ACCUMULATED_CHART_FAIL(error))
      )
    )
  );

  /**
   * Este método chama um serviço para evolução de infestação por ocorrências.
   */
  public getInfestationEvolutionChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_INFESTATION_EVOLUTION_CHART),
      switchMap((action) =>
        this.genericHttpServiceService
          .getAll<any>(
            `chart?type=infestationEvolution&customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&fieldId=${
              action.fieldId
            }&occurrenceId=${
              action.occurrenceId
            }&harvestId=${this.applicationService.getHarvestId()}&startDate=${action.startDate.toDate()}&endDate=${action.endDate.toDate()}`
          )
          .toPromise()
          .then((infestationEvolutionChart: any) =>
            gmTabsAction.GET_INFESTATION_EVOLUTION_CHART_SUCCESS({ infestationEvolutionChart })
          )
          .catch((error) => gmTabsAction.GET_INFESTATION_EVOLUTION_CHART_FAIL(error))
      )
    )
  );

  /**
   * Este método chama um serviço para evolução de infestação por ocorrências.
   */
  public getmovingAverageChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_MOVING_AVERAGE_CHART),
      switchMap((action) =>
        this.genericHttpServiceService
          .getAll<any>(
            `chart?type=movingAverage&customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&fieldId=${
              action.fieldId
            }&occurrenceId=${
              action.occurrenceId
            }&harvestId=${this.applicationService.getHarvestId()}&period=${
              action.period
            }&startDate=${action.startDate.toDate()}&endDate=${action.endDate.toDate()}`
          )
          .toPromise()
          .then((movingAverageChart: any) =>
            gmTabsAction.GET_MOVING_AVERAGE_CHART_SUCCESS({ movingAverageChart })
          )
          .catch((error) => gmTabsAction.GET_MOVING_AVERAGE_CHART_FAIL(error))
      )
    )
  );

  /**
   * Este método chama um serviço para total entre talhões.
   */
  public getTotalBetweenFieldsChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_TOTAL_BETWEEN_FIELDS_CHART),
      switchMap((action) => {
        return this.genericHttpServiceService
          .getAll(
            `chart?type=totalBetweenFields&customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&harvestId=${this.applicationService.getHarvestId()}&fieldIds=${
              action.fieldIds
            }&occurrenceId=${
              action.occurrenceId
            }&startDate=${action.startDate.toDate()}&endDate=${action.endDate.toDate()}
            `
          )
          .toPromise()
          .then((totalBetweenFieldsChart: any) => {
            return gmTabsAction.GET_TOTAL_BETWEEN_FIELDS_CHART_SUCCESS({ totalBetweenFieldsChart });
          })
          .catch((error) => gmTabsAction.GET_TOTAL_BETWEEN_FIELDS_CHART_FAIL(error));
      })
    )
  );

  /**
   * Este método chama um serviço para ocorrências que excederam o nível de controle.
   */
  public getLevelExceededChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmTabsAction.GET_LEVEL_EXCEEDED_CHART),
      switchMap((action) =>
        this.genericHttpServiceService
          .getAll<any>(
            `chart?type=controlLevelExceeded&customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&harvestId=${this.applicationService.getHarvestId()}&startDate=${action.startDate.toDate()}&endDate=${action.endDate.toDate()}&fieldId=${
              action.fieldId
            }`
          )
          .toPromise()
          .then((levelExceededChart: any) =>
            gmTabsAction.GET_LEVEL_EXCEEDED_CHART_SUCCESS({
              levelExceededChart,
            })
          )
          .catch((error) => gmTabsAction.GET_LEVEL_EXCEEDED_CHART_FAIL(error))
      )
    )
  );
}
