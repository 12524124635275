import { createAction, props } from '@ngrx/store';
import { KmzPolygon } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Cadastrar
 */
export const CREATE_KMZ_POLYGON = createAction(
  '[Modal Import Kmz Form Page] CREATE_KMZ_POLYGON',
  props<{ kmzPolygons: Array<KmzPolygon> }>()
);
export const CREATE_KMZ_POLYGON_SUCCESS = createAction(
  '[Modal Import Kmz Form Page] CREATE_KMZ_POLYGON_SUCCESS'
);
export const CREATE_KMZ_POLYGON_FAIL = createAction(
  '[Modal Import Kmz Form Page] CREATE_KMZ_POLYGON_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const PARSE_KMZ = createAction(
  '[Modal Import Kmz Form Page] PARSE_KMZ',
  props<{ kmzFile: any }>()
);
export const PARSE_KMZ_SUCCESS = createAction(
  '[Modal Import Kmz Form Page] PARSE_KMZ_SUCCESS',
  props<{ polygons: Array<KmzPolygon> }>()
);
export const PARSE_KMZ_FAIL = createAction(
  '[Modal Import Kmz Form Page] PARSE_KMZ_FAIL',
  props<{ error: any }>()
);

export const HIDE_MODAL = createAction('[Modal Import Kmz Form Page] HIDE_MODAL');
