<!-- Loading -->
<app-loading [isOpen]="state.loadingModal || state.loading"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="onCloseModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span class="title">{{ trans.button.importKmz | translate }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <form class="custom-form-field" [formGroup]="firstFormGroup">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <!-- kmzFile -->
      <mat-form-field fxFlex="100%" class="material-file-cursor-pointer">
        <ngx-mat-file-input
          matInput
          type="file"
          id="kmzFile"
          name="kmzFile"
          formControlName="kmzFile"
          [accept]="'.kmz'"
          placeholder="{{ trans.placeholder.kmzFile | translate }}"
          (ngModelChange)="onChangeKmzFile()"
        ></ngx-mat-file-input>
        <mat-icon fontSet="material-icons-outlined" matSuffix style="color: #757575"
          >folder</mat-icon
        >
        <mat-error>
          {{ getInputMessageError('kmzFile', firstFormGroup.get('kmzFile')?.errors) | translate }}
        </mat-error>
      </mat-form-field>
      <div fxFlex="100%" style="padding: 0px 5px">
        <mat-label class="material-text-two-lines"
          >Selecione alguns {{ trans.label.fieldIds | label }} no mapa para definir sua
          {{ trans.label.glebe | label }} e outras informações importantes:</mat-label
        >
        <app-google-maps-import-kmz
          #mapsImportKmz
          [height]="'400px'"
          [setOnPolygon]="state.polygons"
        ></app-google-maps-import-kmz>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="onCloseModal()">{{ trans.button.close | translate }}</button>
  <div>
    <button
      mat-button
      *ngIf="mapsImportKmz.getPolygonsDefinedAndSelected().length >= 1"
      color="primary"
      (click)="mapsImportKmz.resetPolygons()"
    >
      {{ trans.button.reset | translate }}
    </button>
    <button
      mat-button
      *ngIf="mapsImportKmz.getPolygonsSelected().length >= 1"
      color="primary"
      (click)="openDialog()"
    >
      {{ trans.button.defineGlebe | label }}
    </button>
    <button
      mat-button
      color="primary"
      [disabled]="mapsImportKmz.getPolygonsDefined().length < 1"
      (click)="onFormClick()"
    >
      {{ trans.button.save | translate }}
    </button>
  </div>
</mat-dialog-actions>
