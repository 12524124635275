import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  animations: [
    trigger('openClose', [
      state('open', style({})),
      state(
        'closed',
        style({
          display: 'none',
        })
      ),
      transition('open => closed', [animate('{{time}}')]),
      transition('closed => open', [animate('0s')]),
    ]),
  ],
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() public isOpen = 0;
  @Input() public excitedTime = '1s';

  constructor() {}
}
