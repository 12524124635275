import { Injectable } from '@angular/core';

@Injectable()
export class CalculateService {
  constructor() {}

  calculateLastCount(registrationNumbers: Array<number>): number {
    const highestValue =
      registrationNumbers.length <= 0 ? 0 : registrationNumbers.reduce((a, b) => Math.max(a, b));
    return highestValue > 0 ? highestValue : 0;
  }
}
