import {
  Field,
  TrapReport,
  Glebe,
  Occurrence,
  Trap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const trapReportStateDefault = {
  trapReports: [],
  fields: [],
  glebes: [],
  traps: [],
  occurrences: [],
  loading: 0,
  loadingRemove: 0,
  displayedColumns: [
    'select',
    'name',
    'alias',
    'statusName',
    'type',
    'format',
    'period',
    'actions',
  ],
  displayedForms: {
    INTERACTIVE: ['glebeIds', 'fieldIds', 'occurrenceIds', 'infestationLevel'],
    GEOREFERENCED: ['glebeIds', 'fieldIds', 'occurrenceIds', 'infestationLevel'],
    MAD: ['glebeIds'],
    XLS: ['glebeIds', 'fieldIds', 'occurrenceIds'],
  },
  pageSizeOptions: [15],
} as TrapReportState;

export interface TrapReportState {
  trapReports: Array<TrapReport>;
  fields: Array<Field>;
  glebes: Array<Glebe>;
  traps: Array<Trap>;
  occurrences: Array<Occurrence>;
  loading: number;
  loadingRemove: number;
  displayedColumns: string[];
  displayedForms: { [key: string]: Array<string> };
  pageSizeOptions: number[];
}

export const trapReportColumns = [
  'date',
  'farm',
  'trap',
  'ticketId',
  'latlong',
  'glebe',
  'field',
  'crop',
  'pheromone',
  'occurrence',
  'count',
];
