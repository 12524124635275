import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
// import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Regex, regex } from 'src/app/constants/regex';
import {
  CREATE_HARVEST,
  GET_HARVESTS,
  HIDE_MODAL,
} from 'src/app/view/harvest/harvest-form/harvest-form.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { MATERIAL_DATE_YEAR } from '../../../constants/mask';
import { HarvestFormState, harvestFormStateDefault } from './harvest-form.state';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import * as Moment from 'moment';
import * as _ from 'lodash';
import { HarvestPeriod, Status } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Component({
  selector: 'app-harvest-form',
  templateUrl: './harvest-form.component.html',
  styleUrls: ['./harvest-form.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATE_YEAR },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class HarvestFormComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) public form!: NgForm;

  public cols!: Observable<number | undefined>;
  public harvestFormState$: Observable<HarvestFormState>;
  public state: HarvestFormState = harvestFormStateDefault;
  public subscribe!: Subscription;
  public regex: Regex = regex;
  public moment: any = Moment;
  public harvestStatus: any = Status;

  constructor(
    private store: Store<any>,
    private dialogRef: MatDialogRef<HarvestFormComponent>,
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.harvestFormState$ = this.store.pipe(select('harvestFormState'));
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    this.store.dispatch(GET_HARVESTS());

    /**
     * Escuta as alterações de estado do redux
     */
    this.subscribe = this.harvestFormState$.subscribe((state: HarvestFormState) => {
      this.state = state;
      if (state.closeModal) {
        this.closeModal();
      }
    });
  }

  /**
   * Este método é executado quando o componente é destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  public onNameInputChange(event: any): void {
    this.state.harvestEdit.name = event;
  }

  /**
   * Este método cadastra ou edita
   */
  public create(): void {
    let harvest = _.cloneDeep(this.state.harvestEdit);
    const parameters = {
      customerId: this.applicationService.getCustomerId(),
      farmId: this.applicationService.getFarmId(),
    };
    const harvestObject = {
      farmId: parameters.farmId,
      customerId: parameters.customerId,
      harvestData: {
        name: harvest.name,
        currentYear: harvest.currentYear.toDate().toISOString(),
        harvestPeriod: harvest.harvestPeriod,
        status: harvest.status,
      },
    };
    if (!this.data.edit) {
      this.store.dispatch(
        CREATE_HARVEST({
          harvest: { ...harvestObject },
        })
      );
    }
  }

  /**
   * Este método limpa o redux e o formulário, além de fechar a modal
   */
  public closeModal(): void {
    this.form.reset();
    this.store.dispatch(HIDE_MODAL());
    this.dialogRef.close();
  }

  /**
   * Este método seta o ano e fecha o datepicker
   */
  public chosenYearHandler(normalizedYear: any, datepicker: MatDatepicker<any>): void {
    this.state.harvestEdit.harvestPeriod = HarvestPeriod.firstHarvest;
    this.state.harvestEdit.name = '';
    this.state.harvestEdit.currentYear = normalizedYear;
    datepicker.close();
  }
}
