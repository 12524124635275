<!-- route-optimization.component.html -->
<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayout.sm="column"
  fxLayout.md="row"
  fxLayout.lg="row"
  fxLayoutAlign="start stretch"
  class="main-container"
>
  <!-- Sidebar -->
  <div fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="30%" fxFlex.lg="30%" class="sidebar">
    <h2>Otimização de Rotas</h2>

    <!-- Feedback de Carregamento para Clientes -->
    <div *ngIf="loadingCustomers$ | async as loadingCustomers" class="loading-container">
      <mat-spinner diameter="30"></mat-spinner>
      <span>Carregando clientes...</span>
    </div>

    <!-- Seleção de Cliente -->
    <!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
    <div *ngIf="!(loadingCustomers$ | async)">
      <div>
        <label>Selecionar Cliente:</label>
        <select
          (change)="handleClientChange()"
          [(ngModel)]="selectedClientId"
          class="select-client"
        >
          <option value="">-- Selecione o Cliente --</option>
          <option *ngFor="let client of clients$ | async" [value]="client.id">
            {{ client.name }}
          </option>
        </select>
      </div>

      <!-- Feedback de Carregamento para Fazendas -->
      <div *ngIf="loadingFarms$ | async as loadgingFarms" class="loading-container">
        <mat-spinner diameter="30"></mat-spinner>
        <span>Carregando fazendas...</span>
      </div>

      <!-- Seleção de Fazendas -->
      <!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
      <div *ngIf="!(loadingFarms$ | async) && farms.length > 0">
        <label>Selecionar Fazendas do Cliente Selecionado:</label>
        <select (change)="handleFarmSelection()" [(ngModel)]="selectedFarmId" class="select-farm">
          <option value="">-- Selecione a Fazenda --</option>
          <option *ngFor="let farm of farms$ | async" [value]="farm.id">
            {{ farm.name }}
          </option>
        </select>
      </div>
    </div>

    <!-- Campos de Endereço de Partida -->
    <div>
      <label>Endereço de Partida:</label>
      <input
        type="text"
        [(ngModel)]="startAddress"
        placeholder="Insira seu endereço de partida"
        class="address-input"
      />
      <button (click)="geocodeStartAddress()">Definir Partida</button>
    </div>

    <!-- Checkbox para o mesmo endereço de retorno -->
    <div class="same-as-start-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="sameAsStart"
          (change)="onSameAsStartChange()"
          [disabled]="!startCoordinates"
        />
        O mesmo endereço de retorno
      </label>
    </div>

    <!-- Campos de Endereço de Retorno (condicional) -->
    <div *ngIf="!sameAsStart">
      <label>Endereço de Retorno:</label>
      <input
        type="text"
        [(ngModel)]="endAddress"
        placeholder="Insira seu endereço de retorno (Opcional)"
        class="address-input"
      />
      <button (click)="geocodeEndAddress()">Definir Retorno</button>
    </div>

    <!-- Fazendas Selecionadas -->
    <div *ngIf="selectedFarms.length > 0">
      <h3>Fazendas Selecionadas:</h3>
      <ul class="selected-farms-list">
        <li *ngFor="let farm of selectedFarms" class="selected-farm-item">
          <button
            class="remove-farm-button"
            (click)="removeFarm(farm)"
            aria-label="Remover Fazenda"
          >
            x
          </button>
          <span class="farm-name">
            {{ farm.name }} (Cliente: {{ getClientNameForFarm(farm.id) }})
          </span>
        </li>
      </ul>
      <button (click)="calculateRoute()" class="calculate-route-button">Calcular Rota</button>
    </div>

    <!-- Seção de Seleção de Rotas -->
    <div *ngIf="routeReport.length > 0" class="route-selection">
      <h3>Selecione as Rotas a Exibir:</h3>
      <ul class="route-selection-list">
        <li *ngFor="let route of routeReport; let idx = index">
          <label>
            <input type="checkbox" [(ngModel)]="route.selected" (change)="updateFilteredData()" />
            {{ route.begin }} para {{ route.end }}
          </label>
        </li>
      </ul>
    </div>

    <!-- Relatório da Rota -->
    <div
      *ngIf="filteredRouteReport.length > 0 && filteredDurations.length > 0"
      class="route-report"
    >
      <h3>Relatório da Rota:</h3>
      <ul>
        <li *ngFor="let route of filteredRouteReport; let idx = index">
          Rota {{ idx + 1 }}: {{ route.begin }} para {{ route.end }}
          - Duração:
          {{ formatDuration(filteredDurations[idx] || 0) }}
        </li>
      </ul>
      <h4>Tempo Total: {{ formatDuration(totalDuration || 0) }}</h4>
    </div>
  </div>

  <!-- Mapa -->
  <div fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="70%" fxFlex.lg="70%" class="map-container">
    <app-basic-map
      [height]="'100%'"
      [width]="'100%'"
      [latitude]="mapCenter.lat"
      [longitude]="mapCenter.lng"
      [zoom]="mapZoom"
      [markers]="markers"
      [routeSegments]="filteredRouteSegments"
      [durations]="filteredDurations"
    ></app-basic-map>
  </div>
</div>

<!-- Feedback de Carregamento Geral (Opcional) -->
<div *ngIf="loading$ | async" class="global-loading-overlay">
  <mat-spinner diameter="50"></mat-spinner>
  <span>Processando...</span>
</div>
