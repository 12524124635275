<app-loading [isOpen]="state.loadingModal || state.loadingProgressBar"></app-loading>

<button mat-icon-button aria-label="Close Modal" (click)="closeModal()" tabindex="-1">
  <i class="las la-times"></i>
</button>
<div mat-dialog-title>
  <h2 align="center">{{ trans.title.registerLinerExchange | translate }}</h2>
</div>

<div mat-dialog-content align="center">
  <mat-form-field>
    <mat-label>{{ trans.label.linerDate | translate }}</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      id="updateLiner"
      name="updateLiner"
      required
      (dateChange)="dateChanged($event)"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<div mat-dialog-actions align="center">
  <button mat-button color="primary" (click)="updateLinerExchange()">
    {{ trans.button.save | translate }}
  </button>
</div>
