import { Injectable } from '@angular/core';
import { TypedAction } from '@ngrx/store/src/models';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';

@Injectable()
export class EffectsServices {
  constructor(protected toastrService: ToastrService) {}

  handleError(errorMessage: string, action: any) {
    this.toastrService.error(errorMessage);
    return of(action);
  }

  handleSuccess(successMessage: string, action: TypedAction<string>): TypedAction<any> {
    this.toastrService.success(successMessage);
    return action;
  }
}
