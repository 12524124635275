import { createAction, props } from '@ngrx/store';
import { Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_HARVESTS = createAction('[Harvest Page] GET_HARVESTS');
export const GET_HARVESTS_SUCCESS = createAction(
  '[Harvest Page] GET_HARVESTS_SUCCESS',
  props<{ harvests: Array<Harvest> }>()
);
export const GET_HARVESTS_FAIL = createAction(
  '[Harvest Page] GET_HARVESTS_FAIL',
  props<{ error: any }>()
);

/**
 * Remover
 */
export const REMOVE_HARVEST = createAction(
  '[Harvest Page] REMOVE_HARVEST',
  props<{ ids: Array<string> }>()
);
export const REMOVE_HARVEST_SUCCESS = createAction('[Harvest Page] REMOVE_HARVEST_SUCCESS');
export const REMOVE_HARVEST_FAIL = createAction(
  '[Harvest Page] REMOVE_HARVEST_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const DESTROY_COMPONENT = createAction('[Harvest Page] DESTROY_COMPONENT');
