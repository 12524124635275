import { Validators } from '@angular/forms';
import { regex } from 'src/app/constants/regex';
import { CustomValidators } from '../../utils/custom-validators/custom-validators';
import { WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { HarvestPeriod } from '@tarvos-ag/tarvos-firestore-models/src/enums';

export const customerStateDefault = {
  user: null,
  supportUsers: [],
  loading: false,
  termsOfUseUrl: null,
  privacyPolicyUrl: null,
} as CustomerState;

export interface CustomerState {
  supportUsers: Array<WebUser>;
  loading: boolean;
  termsOfUseUrl: string | null;
  privacyPolicyUrl: string | null;
}

export const customer = {
  data: {
    customerName: '',
    contractedPeriod: '',
  },
  usersIds: [],
  farm: {
    name: '',
    coordinates: {
      lat: 0,
      lng: 0,
    },
  },
  harvest: {
    name: '',
    currentYear: '',
    harvestPeriod: HarvestPeriod.firstHarvest,
    status: '',
  },
} as CustomerData;
export interface CustomerData {
  data: {
    customerName: string;
    contractedPeriod: string;
  };
  usersIds: Array<string>;
  farm?: {
    name: string;
    coordinates: {
      lat: number;
      lng: number;
    };
  };
  harvest: {
    name: string;
    currentYear: string;
    harvestPeriod: HarvestPeriod;
    status: string;
  };
}
export interface User {
  customerId: string;
  customerName: string;
  usersIds: Array<string>;
  farmId?: string;
  harvestId?: string;
}

export const user = {
  customerId: '',
  customerName: '',
  usersIds: [],
} as User;

export const firstFormGroup = {
  customerName: [
    null,
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(60),
      CustomValidators.whitespaceValidator,
    ],
  ],
  enableFarm: [true],
  supportIds: [null, [Validators.required]],
  contractedPeriod: [null, [Validators.required]],
} as any;
export const secondFormGroup = {
  farmName: [
    null,
    [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(60),
      CustomValidators.whitespaceValidator,
    ],
  ],
  harvestName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(60)]],
  lat: [
    null,
    [Validators.required, Validators.pattern(regex.lat), CustomValidators.whitespaceValidator],
  ],
  lng: [
    null,
    [Validators.required, Validators.pattern(regex.lng), CustomValidators.whitespaceValidator],
  ],
  currentYear: [null, [Validators.required]],
  harvestPeriod: [null, [Validators.required]],
} as any;
