import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { GenericDialogComponent } from '../components/generic-dialog/generic-dialog.component';
import {
  GET_CUSTOMERS,
  GET_CUSTOMER_FARMS,
  GET_SUPPORT_USERS,
  POST_UPDATE_USERS_CUSTOMERS,
} from './update-user.action';
import { UpdateUserState, updateUserStateDefault } from './update-user.state';
import { TranslateTypes } from 'src/app/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent implements OnInit {
  public state: UpdateUserState = updateUserStateDefault;
  public updateUserState$: Observable<UpdateUserState>;

  public subscribe!: Subscription;

  constructor(
    private store: Store<any>,
    public dialog: MatDialog,
    public trans: TranslateTypes,
    private translateService: TranslateService
  ) {
    this.updateUserState$ = this.store.pipe(select('updateUserState'));
  }

  openDialog(): void {
    this.dialog.open(GenericDialogComponent, {
      data: {
        title: this.translateService.instant(this.trans.title.updateUserCustomersModal),
        text: this.getUsersNames(),
        rejectionText: 'Não',
        confirmText: 'Sim',
        fn: () =>
          this.onSubmit(
            this.getUsersIds(),
            this.state.selectedCustomerId,
            this.state.selectedCustomerName,
            this.state.selectedFarms
          ),
      },
    });
  }

  getUsersNames(): string {
    let res = '';
    for (let i = 0; i < this.state.users.length; i++) {
      res += this.state.users[i].name;
      if (i < this.state.users.length - 1) {
        res += ', ';
      }
    }
    return res;
  }

  ngOnInit(): void {
    this.subscribe = this.updateUserState$.subscribe((updateUserState: UpdateUserState) => {
      this.state = updateUserState;
      if (this.state.closeModal) {
        this.dialog.closeAll();
      }
    });

    this.store.dispatch(GET_CUSTOMERS());
    this.store.dispatch(GET_SUPPORT_USERS());
  }

  getUsersIds() {
    return this.state.users.map((user) => user.id);
  }

  onCustomerSelectionChange(event: any) {
    this.state.selectedCustomerName = event.value.name;
    this.state.selectedCustomerId = event.value.id;
    this.store.dispatch(GET_CUSTOMER_FARMS({ customerId: event.value.id }));
  }

  onSubmit(usersIds: string[], customerId: string, customerName: string, farmsIds: string[]) {
    this.store.dispatch(
      POST_UPDATE_USERS_CUSTOMERS({ usersIds, customerId, customerName, farmsIds })
    );
  }
}
