import { Pipe, PipeTransform } from '@angular/core';
import { TranslateTypes } from '../services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { PipeService, genericObject } from '../services/pipe.service';

@Pipe({
  name: 'label',
})
export class LabelPipe implements PipeTransform {
  constructor(
    public trans: TranslateTypes,
    private translateService: TranslateService,
    private pipeService: PipeService
  ) {}
  public transform(input: genericObject<string>): string {
    const labelObject = this.pipeService.validInput(input);
    const labelCategory = this.pipeService.getLabelCategory(labelObject.type);
    const namingPatter = this.pipeService.getNamingPattern();
    const typeLabel = this.pipeService.checkTypeOfLabel({
      type: labelCategory,
      config: namingPatter,
    });
    const nomenclature = this.pipeService.getNomenclature({
      typeLabel,
      labelObject,
    });

    return this.translateService.instant(nomenclature);
  }
}
