<button
  mat-flat-button
  color="primary"
  class="material-button-open-menu"
  [matMenuTriggerFor]="menuFarmAndCrop"
>
  <p class="material-title">
    {{ getFarmName() }}
  </p>
  <p class="material-subtitle">{{ getHarvestName() }}</p>
  <mat-icon>keyboard_arrow_down</mat-icon>
</button>

<mat-menu #menuFarmAndCrop="matMenu">
  <div fxLayout="column" fxLayoutAlign="center none" class="material-item-container-buttons">
    <button type="button" mat-flat-button color="primary" (click)="openModalFarm()">
      {{ farmTitle }}
    </button>
    <button type="button" mat-flat-button color="primary" (click)="openModalHarvest()">
      {{ harvestTitle }}
    </button>
  </div>
  <span *ngFor="let farm of farms">
    <button
      mat-menu-item
      [matMenuTriggerFor]="menu"
      [ngClass]="user.lastSelectedFarmId === farm.id ? 'material-item-select' : ''"
    >
      {{ farm.name }}
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        color="primary"
        *ngFor="let harvest of getHarvestsByFarm(farm.id)"
        [ngClass]="user.lastSelectedHarvestId === harvest.id ? 'material-item-select' : ''"
        (click)="menuSelectionChange(farm.id, harvest.id)"
      >
        {{ harvest.name }} - {{ trans.enums[harvest.status] | translate }}
      </button>
    </mat-menu>
  </span>
</mat-menu>
