import { createAction, props } from '@ngrx/store';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import { Field } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import {
  CreateFieldForm,
  UpdateFieldForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';
/**
 * Obter
 */
export const GET_FIELDS = createAction('[Field Form Page] GET_FIELDS');
export const GET_FIELDS_SUCCESS = createAction(
  '[Field Form Page] GET_FIELDS_SUCCESS',
  props<{ fields: Array<Field> }>()
);
export const GET_FIELDS_FAIL = createAction(
  '[Field Form Page] GET_FIELDS_FAIL',
  props<{ error: any }>()
);

export const GET_GLEBES = createAction('[Field Form Page] GET_GLEBES');
export const GET_GLEBES_SUCCESS = createAction(
  '[Field Form Page] GET_GLEBES_SUCCESS',
  props<{ glebes: Array<Glebe> }>()
);
export const GET_GLEBES_FAIL = createAction(
  '[Field Form Page] GET_GLEBES_FAIL',
  props<{ error: any }>()
);

export const GET_SYSTEM = createAction('[Field Form Page] GET_SYSTEM');
export const GET_SYSTEM_SUCCESS = createAction(
  '[Field Form Page] GET_SYSTEM_SUCCESS',
  props<{ system: OccurrenceSettings }>()
);
export const GET_SYSTEM_FAIL = createAction(
  '[Field Form Page] GET_SYSTEM_FAIL',
  props<{ error: any }>()
);

export const GET_HIGHEST_NUMBER_REGISTERED = createAction(
  '[Field Form Page] GET_HIGHEST_NUMBER_REGISTERED'
);
export const GET_HIGHEST_NUMBER_REGISTERED_SUCCESS = createAction(
  '[Field Form Page] GET_HIGHEST_NUMBER_REGISTERED_SUCCESS',
  props<{ lastCount: number }>()
);
export const GET_HIGHEST_NUMBER_REGISTERED_FAIL = createAction(
  '[Field Form Page] GET_HIGHEST_NUMBER_REGISTERED_FAIL',
  props<{ error: any }>()
);

export const GET_HAS_TRAP_LINKED_FIELD = createAction(
  '[Field Form Page] GET_HAS_TRAP_LINKED_FIELD',
  props<{ id: string }>()
);
export const GET_HAS_TRAP_LINKED_FIELD_SUCCESS = createAction(
  '[Field Form Page] GET_HAS_TRAP_LINKED_FIELD_SUCCESS',
  props<{ hasTrapLinkedField: boolean }>()
);
export const GET_HAS_TRAP_LINKED_FIELD_FAIL = createAction(
  '[Field Form Page] GET_HAS_TRAP_LINKED_FIELD_FAIL',
  props<{ error: any }>()
);

/**
 * Cadastrar
 */
export const CREATE_FIELD = createAction(
  '[Modal Field Page] CREATE_FIELD',
  props<{ field: CreateFieldForm }>()
);
export const CREATE_FIELD_SUCCESS = createAction('[Modal Field Page] CREATE_FIELD_SUCCESS');
export const CREATE_FIELD_FAIL = createAction(
  '[Modal Field Page] CREATE_FIELD_FAIL',
  props<{ error: any }>()
);

/**
 * Atualizar
 */
export const UPDATE_FIELD = createAction(
  '[Modal Field Page] UPDATE_FIELD',
  props<{ field: UpdateFieldForm }>()
);
export const UPDATE_FIELD_SUCCESS = createAction('[Modal Field Page] UPDATE_FIELD_SUCCESS');
export const UPDATE_FIELD_FAIL = createAction(
  '[Modal Field Page] UPDATE_FIELD_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_MODAL = createAction('[Modal Field Page] HIDE_MODAL');
