import * as _ from 'lodash';
import { Action, createReducer, on } from '@ngrx/store';
import {
  ImportTrapFormState,
  importTrapFormStateDefault,
} from 'src/app/view/trap/import-trap-form/import-trap-form.state';
import * as importTrapFormAction from './import-trap-form.actions';

export const importTrapFormReducer = createReducer(
  _.cloneDeep(importTrapFormStateDefault),

  /**
   * OBTER
   */
  on(importTrapFormAction.GET_HARVESTS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(importTrapFormAction.GET_HARVESTS_SUCCESS, (state, action) => ({
    ...state,
    harvests: action.harvests,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(importTrapFormAction.GET_HARVESTS_FAIL, (state) => ({
    ...state,
    harvests: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(importTrapFormAction.GET_TRAP, (state) => ({ ...state, loading: state.loading + 1 })),
  on(importTrapFormAction.GET_TRAP_SUCCESS, (state, action) => ({
    ...state,
    traps: action.traps,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(importTrapFormAction.GET_TRAP_FAIL, (state) => ({
    ...state,
    traps: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * CADASTRAR
   */
  on(importTrapFormAction.CREATE_IMPORT_TRAP, (state) => ({
    ...state,
    loadingModal: state.loadingModal + 1,
  })),
  on(importTrapFormAction.CREATE_IMPORT_TRAP_SUCCESS, (state) => ({ ...state, closeModal: true })),
  on(importTrapFormAction.CREATE_IMPORT_TRAP_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * OUTROS
   */
  on(importTrapFormAction.HIDE_MODAL, (state) => ({
    ..._.cloneDeep(importTrapFormStateDefault),
  }))
);

export function reducer(
  state: ImportTrapFormState | undefined,
  action: Action
): ImportTrapFormState {
  return importTrapFormReducer(state, action);
}
