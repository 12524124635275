import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Operation } from 'src/app/enums/Operation';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-empty-states-box',
  templateUrl: './empty-states-box.component.html',
  styleUrls: ['./empty-states-box.component.scss'],
})
export class EmptyStatesBoxComponent {
  @Input() public height = '100%';
  @Input() public iconSize = 'auto';
  @Input() public messageSize = 'auto';
  @Input() public messagePadding = '15px';
  @Input() public icon: string | null = null;
  @Input() public message = '';
  @Input() public buttonOne = '';
  @Input() public buttonTwo = '';
  @Input() public buttonThree = '';
  @Input() public checkHarvestCompleted = false;
  public operation = Operation;

  @Output() public buttonOneClick: EventEmitter<any> = new EventEmitter<any>(true);
  @Output() public buttonTwoClick: EventEmitter<any> = new EventEmitter<any>(true);
  @Output() public buttonThreeClick: EventEmitter<any> = new EventEmitter<any>(true);

  constructor(public applicationService: ApplicationService) {}
}
