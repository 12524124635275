import { Action, createReducer, on } from '@ngrx/store';
import * as FarmsActions from './farms-route-optimization.actions';

import {
  FarmsRouteOptimizationState,
  initialFarmsRouteOptimizationState,
} from './farms-route-optimization.state';

export const farmsReducer = createReducer(
  initialFarmsRouteOptimizationState,
  on(FarmsActions.GET_FARMS_ROUTE_OPTIMIZATION, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(FarmsActions.GET_FARMS_ROUTE_OPTIMIZATION_SUCCESS, (state, { farms }) => ({
    ...state,
    farms,
    loading: false,
    error: null,
  })),
  on(FarmsActions.GET_FARMS_ROUTE_OPTIMIZATION_FAIL, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);

export function reducer(
  state: FarmsRouteOptimizationState | undefined,
  action: Action
): FarmsRouteOptimizationState {
  return farmsReducer(state, action);
}
