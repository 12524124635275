<div fxLayout="column" fxLayoutAlign="center center" class="material-weather-forecast-container">
  <div *ngIf="weatherForecast">
    <!-- Dia / min / max -->
    <div fxLayout="column" fxLayoutAlign="center center" class="material-day-container">
      <span>{{ weatherForecast.day | uppercase }}</span>
      <img [src]="weatherForecast.icon" />
      <span>{{ weatherForecast.description | titlecase }}</span>
      <span>{{ weatherForecast.tempMaxMin }}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="material-info-container">
      <i
        class="las la-temperature-high material-icon"
        matTooltipClass="material-weather-forecast-tooltip"
        [matTooltip]="weatherForecast.feelsLike"
      ></i>
      <i
        class="las las la-wind material-icon"
        matTooltipClass="material-weather-forecast-tooltip"
        [matTooltip]="weatherForecast.windSpeed"
      ></i>
      <i
        class="las la-clock material-icon"
        matTooltipClass="material-weather-forecast-tooltip"
        [matTooltip]="weatherForecast.pressure"
      ></i>
      <i
        class="las la-tint material-icon"
        matTooltipClass="material-weather-forecast-tooltip"
        [matTooltip]="weatherForecast.humidity"
      ></i>
      <i
        class="las la-ruler material-icon"
        matTooltipClass="material-weather-forecast-tooltip"
        [matTooltip]="weatherForecast.rain"
      ></i>
    </div>
  </div>
  <div *ngIf="!weatherForecast">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</div>
