import { createAction, props } from '@ngrx/store';
import { Customer } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter Clients
 */
export const GET_CUSTUMERS_ROUTE_OPTIMIZATION = createAction('[Clients] Get Clients');
export const GET_CUSTOMERS_ROUTE_OPTMIZIATION_SUCCESS = createAction(
  '[Clients] Get Customers Route Optimization Success',
  props<{ customers: Customer[] }>()
);
export const GET_CUSTOMERS_ROUTE_OPTIMIZATION_FAIL = createAction(
  '[Clients] Get Customers Route Optimization Fail',
  props<{ error: any }>()
);
