import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  CREATE_CUSTOMER,
  GET_PRIVACY_POLICY_URL,
  GET_SUPPORT_USERS,
  GET_TERMS_OF_USE_URL,
} from 'src/app/view/customer/create-customer/create-customer.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import {
  CustomerState,
  customerStateDefault,
  firstFormGroup,
  secondFormGroup,
  customer,
} from './create-customer.state';
import { BaseForm } from '../../utils/base-form/base-form';
import _ from 'lodash';
import { HarvestPeriod, Status } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Component({
  selector: 'app-create-customer',
  templateUrl: './/create-customer.component.html',
  styleUrls: ['../../login/login.component.scss', './create-customer.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class CreateCustomerComponent extends BaseForm implements OnInit, OnDestroy {
  public customerState$: Observable<CustomerState>;
  public subscribe!: Subscription;
  public state: CustomerState = customerStateDefault;

  constructor(
    private store: Store<any>,
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    public formBuilder: FormBuilder
  ) {
    super(store, null, formBuilder, trans);
    this.customerState$ = this.store.pipe(select('createCustomer'));
  }

  public ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));
    this.secondFormGroup = this.formBuilder.group(_.cloneDeep(secondFormGroup));

    this.subscribe = this.customerState$.subscribe((state: CustomerState) => {
      this.state = state;
    });

    this.store.dispatch(GET_TERMS_OF_USE_URL());
    this.store.dispatch(GET_PRIVACY_POLICY_URL());
    this.store.dispatch(GET_SUPPORT_USERS());
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Método onde retorna uma ação para cadastrar usuário
   */
  public createCustomer(): void {
    if (this.firstFormGroup.invalid) {
      this.checkValidateForms(this.firstFormGroup);
      this.checkValidateForms(this.secondFormGroup);
    } else {
      customer.data = {
        customerName: this.firstFormGroup.get('customerName')?.value,
        contractedPeriod: new Date(
          this.firstFormGroup.get('contractedPeriod')?.value
        ).toISOString(),
      };
      customer.usersIds = this.firstFormGroup.get('supportIds')?.value;
      if (this.firstFormGroup.get('enableFarm')?.value === true) {
        customer.farm = {
          name: this.secondFormGroup.get('farmName')?.value,
          coordinates: {
            lat: parseFloat(this.secondFormGroup.get('lat')?.value),
            lng: parseFloat(this.secondFormGroup.get('lng')?.value),
          },
        };
        customer.harvest = {
          name: '1º Safra',
          currentYear: this.secondFormGroup.get('currentYear')?.value,
          harvestPeriod: HarvestPeriod.firstHarvest,
          status: Status.created,
        };
      }
      const action = CREATE_CUSTOMER({ customer: customer });
      this.onSubmit(action);
    }
  }

  /**
   * Este método seta o ano e fecha o datepicker
   */
  public chosenYearHandler(normalizedYear: any, datepicker: MatDatepicker<any>): void {
    this.secondFormGroup.get('harvestName')?.setValue(null);
    this.secondFormGroup.get('currentYear')?.setValue(normalizedYear);
    datepicker.close();
  }
}
