import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { UPDATE_GM } from 'src/app/view/gm/gm.actions';
import { TranslateTypes } from 'src/app/services/translation.service';
import { GmState, gmStateDefault } from '../gm.state';
import Moment from 'moment';
import { Field, Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-gm-filter',
  templateUrl: './gm-filter.component.html',
  styleUrls: ['./gm-filter.component.scss'],
})
export class GmFilterComponent implements OnInit, OnDestroy {
  public gmState$: Observable<GmState>;
  public state: GmState = gmStateDefault;
  public cols!: Observable<number | undefined>;
  public subscribe!: Subscription;
  public moment: any = Moment;

  constructor(
    private store: Store<any>,
    private dialogRef: MatDialogRef<GmFilterComponent>,
    public trans: TranslateTypes,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.gmState$ = this.store.pipe(select('gm'));
  }

  public ngOnInit(): void {
    this.subscribe = this.gmState$.subscribe((state: GmState) => {
      this.state = state;
    });
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método limpa o redux e o formulário, além de fechar a modal
   */
  public closeModal(): void {
    this.dialogRef.close();
  }

  /**
   * Este método retorna uma lista de field quando selecionar uma gleba;
   */
  public getFieldsByGlebe(glebeId?: string): Array<Field> {
    if (glebeId) {
      return this.state.fields.filter((field: Field) => field.glebe.id === glebeId);
    }
    return this.state.fields;
  }

  /**
   * Este método retorna uma lista de gleba ou a gleba selecionada;
   */
  public getGlebesByIds(): Array<Glebe> {
    const glebes = this.getGlebesByCrop();
    const glebeIds = this.state.gmConfigEdit.glebeIds;

    if (glebeIds?.length > 0) {
      return glebes.filter(
        (glebe: Glebe) => glebeIds.filter((id: string) => id === glebe.id).length > 0
      );
    }

    return glebes;
  }

  public selectAllFields(): void {
    const fieldIds = this.getFieldIdsByGlebe();
    this.state.gmConfigEdit.fieldIds = fieldIds;
  }
  public deselectAllFields(): void {
    this.state.gmConfigEdit.fieldIds = [];
  }
  public getFieldIdsByGlebe(): Array<string> {
    return this.state.fields
      .filter((field) => {
        return this.getGlebesByIds()
          .map((glebe) => glebe.id)
          .includes(field.glebe.id);
      })
      .map((field) => field.id);
  }

  public selectAllGlebes(): void {
    const glebeIds = this.state.glebes.map((glebe) => glebe.id);
    this.state.gmConfigEdit.glebeIds = glebeIds;
  }
  public deselectAllGlebes(): void {
    this.state.gmConfigEdit.glebeIds = [];
  }

  /**
   * Este método recebe um evento ao selecionar a gleba
   * @param glebeIds lista de ids de gleba
   */
  public onChangeGlebe(): void {
    const { glebeIds, fieldIds } = this.state.gmConfigEdit;
    if (glebeIds?.length > 0 && fieldIds?.length > 0) {
      const fields = this.state.fields.filter(
        (field: Field) => glebeIds.filter((id: string) => id === field.glebe.id).length > 0
      );
      this.state.gmConfigEdit.fieldIds = fieldIds.filter(
        (id: string) => fields.filter((field: Field) => field.id === id).length > 0
      );
    }
  }

  /**
   * Este método aplica os filtros
   */
  public apply(): void {
    this.store.dispatch(UPDATE_GM({ gm: this.state.gmConfigEdit }));
    this.dialogRef.close(true);
  }

  /**
   * Este método apaga todos os filtros aplicados
   */
  public clearFilter(): void {
    this.state.gmConfigEdit.glebeIds = [];
    this.state.gmConfigEdit.fieldIds = [];
    this.state.gmConfigEdit.hideCollectionPoint = false;
    this.state.gmConfigEdit.showFieldWithHighControlLevel = false;

    this.apply();
  }

  /**
   * Este método retorna uma lista de gleba filtrado por cultura;
   */
  getGlebesByCrop(): Array<Glebe> {
    return this.state.glebes;
  }
}
