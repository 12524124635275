<div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
  <!-- MAD -->

  <div
    fxFlex="100%"
    fxFlex.gt-xs="0 1 50%"
    *ngIf="['CAPITATA', 'ANASTREPHA'].includes(gmConfiguration.trapOccurrenceId)"
  >
    <mat-card class="material-card-container mat-elevation-z8" matRipple (click)="madChartDialog()">
      <i class="las la-chart-bar material-card-icon"></i>
      <mat-card-header>
        <mat-card-title>
          {{ trans.title.mad | translate }}
          <button
            class="custom-button-help"
            mat-mini-fab
            color="primary"
            matTooltipClass="custom-tooltip-help"
            [matTooltip]="trans.text.helpMadChart['TRAP'] | translate"
          >
            ?
          </button>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>

  <!-- Ocorrências que excederam o nível de controle -->
  <div fxFlex="100%" fxFlex.gt-xs="0 1 50%">
    <mat-card
      class="material-card-container mat-elevation-z8"
      matRipple
      (click)="controlLevelExceededChartDialog()"
    >
      <i class="las la-chart-bar material-card-icon"></i>
      <mat-card-header>
        <mat-card-title>
          {{ trans.title.controlLevelExceeded | translate }}
          <button
            class="custom-button-help"
            mat-mini-fab
            color="primary"
            matTooltipClass="custom-tooltip-help"
            [matTooltip]="trans.text.helpLevelExceededChart['TRAP'] | translate"
          >
            ?
          </button>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>

  <!-- Contagem acumulada -->
  <div fxFlex="100%" fxFlex.gt-xs="0 1 50%">
    <mat-card
      class="material-card-container mat-elevation-z8"
      matRipple
      (click)="accumulatedChartDialog()"
    >
      <i class="las la-chart-line material-card-icon"></i>
      <mat-card-header>
        <mat-card-title>
          {{ trans.title.accumulatedCount | translate }}
          <button
            class="custom-button-help"
            mat-mini-fab
            color="primary"
            matTooltipClass="custom-tooltip-help"
            [matTooltip]="trans.text.helpAccumulatedChart['TRAP'] | translate"
          >
            ?
          </button>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>

  <!-- Capturas diárias -->
  <div fxFlex="100%" fxFlex.gt-xs="0 1 50%">
    <mat-card
      class="material-card-container mat-elevation-z8"
      matRipple
      (click)="evolutionChartDialog()"
    >
      <i class="las la-chart-line material-card-icon"></i>
      <mat-card-header>
        <mat-card-title>
          {{ trans.title.dailyCaptures | translate }}
          <button
            class="custom-button-help"
            mat-mini-fab
            color="primary"
            matTooltipClass="custom-tooltip-help"
            [matTooltip]="trans.text.helpEvolutionChart['TRAP'] | translate"
          >
            ?
          </button>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>

  <!-- Total entre talhões -->
  <div fxFlex="100%" fxFlex.gt-xs="0 1 50%">
    <mat-card
      class="material-card-container mat-elevation-z8"
      matRipple
      (click)="totalBetweenFieldsChartsDialog()"
    >
      <i class="las la-chart-line material-card-icon"></i>
      <mat-card-header>
        <mat-card-title>
          {{ trans.title.totalBetweenFields | label }}
          <button
            class="custom-button-help"
            mat-mini-fab
            color="primary"
            matTooltipClass="custom-tooltip-help"
            [matTooltip]="trans.text.helpTotalBetweenChart['TRAP'] | label"
          >
            ?
          </button>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>

  <!-- Média Móvel -->
  <div fxFlex="100%" fxFlex.gt-xs="0 1 50%">
    <mat-card
      class="material-card-container mat-elevation-z8"
      matRipple
      (click)="movingAverageChartsDialog()"
    >
      <i class="las la-chart-line material-card-icon"></i>
      <mat-card-header>
        <mat-card-title>
          {{ trans.title.movingAverage | translate }}
          <button
            class="custom-button-help"
            mat-mini-fab
            color="primary"
            matTooltipClass="custom-tooltip-help"
            [matTooltip]="trans.text.helpMovingAverageChart['TRAP'] | translate"
          >
            ?
          </button>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>

  <!-- Dados Climáticos -->
  <div fxFlex="100%" fxFlex.gt-xs="0 1 50%">
    <mat-card
      class="material-card-container mat-elevation-z8"
      matRipple
      (click)="climateChartsDialog()"
    >
      <i class="las la-chart-line material-card-icon"></i>
      <mat-card-header>
        <mat-card-title>
          {{ trans.title.climate | translate }}
          <button
            class="custom-button-help"
            mat-mini-fab
            color="primary"
            matTooltipClass="custom-tooltip-help"
            [matTooltip]="trans.text.helpClimateChart['TRAP'] | translate"
          >
            ?
          </button>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>

  <!-- Umidade do Solo -->
  <div fxFlex="100%" fxFlex.gt-xs="0 1 50%">
    <mat-card
      class="material-card-container mat-elevation-z8"
      matRipple
      (click)="soilChartsDialog()"
    >
      <i class="las la-chart-line material-card-icon"></i>
      <mat-card-header>
        <mat-card-title>
          {{ trans.title.soil | translate }}
          <button
            class="custom-button-help"
            mat-mini-fab
            color="primary"
            matTooltipClass="custom-tooltip-help"
            [matTooltip]="trans.text.helpSoilChart['TRAP'] | translate"
          >
            ?
          </button>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>
</div>
