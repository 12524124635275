import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import * as reapFormAction from './reap-form.actions';
import { ReapFormState, reapFormStateDefault } from './reap-form.state';

export const reapFormReducer = createReducer(
  _.cloneDeep(reapFormStateDefault),

  /**
   * Obter
   */
  on(reapFormAction.GET_TRAP_LINKED_TO_FIELD, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(reapFormAction.GET_TRAP_LINKED_TO_FIELD_SUCCESS, (state, action) => ({
    ...state,
    trapLinkedToField: action.trapLinkedToField,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(reapFormAction.GET_TRAP_LINKED_TO_FIELD_FAIL, (state) => ({
    ...state,
    hasTrapLinkedField: false,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(reapFormAction.GET_REPORT_LINKED_TO_FIELD, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(reapFormAction.GET_REPORT_LINKED_TO_FIELD_SUCCESS, (state, action) => ({
    ...state,
    reportLinkedToField: action.reportLinkedToField,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(reapFormAction.GET_REPORT_LINKED_TO_FIELD_FAIL, (state) => ({
    ...state,
    hasReportLinkedField: false,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * UPDATE
   */
  on(reapFormAction.UPDATE_HARVEST_FIELD, (state) => ({
    ...state,
    loadingModal: state.loadingModal + 1,
  })),
  on(reapFormAction.UPDATE_HARVEST_FIELD_SUCCESS, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
    closeModal: true,
  })),
  on(reapFormAction.UPDATE_HARVEST_FIELD_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * OTHER
   */
  on(reapFormAction.HIDE_MODAL, (_state) => ({
    ..._.cloneDeep(reapFormStateDefault),
  }))
);

export function reducer(state: ReapFormState | undefined, action: Action): ReapFormState {
  return reapFormReducer(state, action);
}
