<button mat-icon-button (click)="openPanelByIndex(0)">
  <ng-container *ngIf="user?.imageUrl; else elseTemplate">
    <img class="material-image-icon-circle" [src]="user?.imageUrl" alt="image User" />
  </ng-container>
  <ng-template #elseTemplate>
    <i class="las la-user"></i>
  </ng-template>
</button>

<ng-template cdk-portal>
  <mat-card class="material-user-card">
    <mat-card-header fxLayout="column" fxLayoutAlign="center center">
      <div mat-card-avatar>
        <ng-container *ngIf="user?.imageUrl; else elseTemplate">
          <img
            class="material-image-icon-circle"
            [src]="user ? user.imageUrl : ''"
            alt="image User"
          />
        </ng-container>
        <ng-template #elseTemplate>
          <i class="las la-user"></i>
        </ng-template>
      </div>
      <div>
        <mat-card-title>{{ user?.name }}</mat-card-title>
      </div>
      <div>
        <mat-card-subtitle>{{
          trans.enums[user != null ? user.role : ''] | translate
        }}</mat-card-subtitle>
      </div>
      <!-- <mat-slide-toggle
        style="margin-top: 15px"
        id="dark"
        name="dark"
        [color]="'primary'"
        [checked]="user?.isDarkTheme"
        (click)="onSelectTheme()"
      >
        <mat-label>Modo escuro (Beta)</mat-label>
      </mat-slide-toggle> -->
    </mat-card-header>
    <mat-card-content> </mat-card-content>
    <mat-card-actions fxLayout="column" fxLayoutAlign="center stretch" style="margin-left: 0px">
      <button mat-raised-button color="primary" (click)="editAccount()">
        {{ trans.button.editProfile | translate }}
      </button>
    </mat-card-actions>
  </mat-card>
</ng-template>
