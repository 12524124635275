import * as _ from 'lodash';
import * as moment from 'moment';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, Portal } from '@angular/cdk/portal';
import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CLEAN_NOTIFICATIONS, UPDATE_GM } from 'src/app/view/header/header.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { Router } from '@angular/router';
import { Notification } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  @ViewChildren(CdkPortal) public templatePortals?: QueryList<Portal<any>>;
  @Input() public notifications: Array<Notification> = [];

  public overlayRef!: OverlayRef;

  constructor(
    private store: Store<any>,
    private overlay: Overlay,
    private translateService: TranslateService,
    // private media: MediaMatcher,
    private router: Router,
    public applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método configura e abre um panel pelo index indicado
   * @param index Indica qual panel
   */
  public openPanelByIndex(index: number): void {
    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'material-backdrop-class',
      positionStrategy: this.overlay.position().global().centerHorizontally(),
    });

    const overlayRef = this.overlay.create(config);
    // const mobileQuery = this.media.matchMedia('(max-width: 599px)');

    overlayRef.updatePositionStrategy(this.overlay.position().global().right('15px').top('50px'));
    overlayRef.attach(this.templatePortals?.toArray()[index]);
    overlayRef.backdropClick().subscribe(() => overlayRef.detach());

    this.overlayRef = overlayRef;
  }

  /**
   * Redireciona para a tela de monitoramento geral
   * @param notification notificações
   */
  public viewNotification(notification: Notification): void {
    if (notification.reportType === 'traps') {
      this.router.navigateByUrl('home/trapReport');
    } else {
      this.store.dispatch(UPDATE_GM({ notification: _.cloneDeep(notification) }));
      this.overlayRef.detach();
    }

    this.overlayRef.dispose();
  }

  /**
   * Este Método formata a data para os padrões br|en
   * @param param parâmetros
   */
  public formatDateToString(param: any): string {
    if (param['%0date'] && typeof param['%0date'] === 'object') {
      param['%0date'] = moment(param['%0date'].toDate()).format(
        this.translateService.instant(this.trans.text.date)
      );
    } else if (param['%1date'] && typeof param['%1date'] === 'object') {
      param['%1date'] = moment(param['%1date'].toDate()).format(
        this.translateService.instant(this.trans.text.date)
      );
    }

    return param;
  }

  /**
   * Este método limpa uma ou mais notificações
   */
  public clearNotification(notificationIds: Array<string> | null): void {
    if (notificationIds == null) {
      notificationIds = this.notifications.map((x) => x.id);
    }
    this.store.dispatch(CLEAN_NOTIFICATIONS({ notificationIds }));
  }
}
