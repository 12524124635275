<!-- Loading -->
<app-loading [isOpen]="state.loadingModal || state.loadingProgressBar"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="closeModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span *ngIf="data.edit" class="title">{{ trans.title.editTrap | translate }}</span>
  <span *ngIf="!data.edit" class="title">{{ trans.title.createTrap | translate }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <form class="custom-form-field" #form="ngForm">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="name"
          name="name"
          [(ngModel)]="state.trapForm.name"
          #name="ngModel"
          disabled="true"
          placeholder="{{ trans.placeholder.name | translate }}"
          minlength="2"
          maxlength="64"
          required
        />
        <mat-error *ngIf="name.invalid && (name.dirty || name.touched)">
          <mat-error *ngIf="name.errors?.required">
            {{ trans.errors?.required.name | translate }}
          </mat-error>
          <mat-error *ngIf="name.errors?.minlength">
            {{ trans.errors?.minlength.name | translate }}
          </mat-error>
          <mat-error *ngIf="name.errors?.pattern && !name.errors?.minlength">
            {{ trans.errors?.minlength.name | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="alias"
          name="alias"
          [(ngModel)]="state.trapForm.alias"
          #alias="ngModel"
          placeholder="{{ trans.placeholder.alias | translate }}"
          minlength="2"
          maxlength="64"
          appWhiteSpaceValidator
          [pattern]="regex.text"
        />
        <mat-error *ngIf="alias.invalid">
          <mat-error *ngIf="alias.errors?.whitespaceValidator" class="control-label">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label>{{ trans.label.device | translate }}</mat-label>
        <mat-select
          #materialSelect
          id="device"
          name="deviceId"
          #device="ngModel"
          [(ngModel)]="state.trapForm.deviceId"
          [disabled]="data.edit"
          (selectionChange)="deviceSelection($event.value)"
          required
        >
          <mat-option *ngFor="let device of state.devices" [value]="device.id">
            {{ device.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="device.invalid && (device.dirty || device.touched)">
          <mat-error *ngIf="device.errors?.required">
            {{ trans.errors?.required.device | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label>{{ trans.label.ticketId | translate }}</mat-label>
        <mat-select
          #materialSelect
          id="ticketId"
          name="ticketId"
          #ticketId="ngModel"
          (selectionChange)="selectionTicketId($event.value)"
          [(ngModel)]="state.trapForm.ticketId"
          [disabled]="data.edit || !state.trapForm.deviceId"
          required
        >
          <mat-option
            class="material-select-option-message"
            *ngIf="state.manufacturedTraps.length <= 0"
            [disabled]="true"
          >
            {{ trans.text.noRegisteredManufacturedTraps | translate }}</mat-option
          >
          <mat-option
            *ngFor="let manufacturedTrap of getManufacturedTrapsByDevice()"
            [value]="manufacturedTrap.ticketId"
            [disabled]="manufacturedTrap.hasRegisteredTrap"
          >
            {{ manufacturedTrap.ticketId
            }}<span *ngIf="!data.edit && manufacturedTrap.hasRegisteredTrap">{{
              trans.text.ticketIdInUse | translate
            }}</span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="ticketId.invalid && (ticketId.dirty || ticketId.touched)">
          <mat-error *ngIf="ticketId.errors?.required">
            {{ trans.errors?.required.ticketId | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label>{{ trans.label.glebe | label }}</mat-label>
        <mat-select
          #materialSelect
          [(ngModel)]="state.trapForm.glebeId"
          id="glebe"
          name="glebeId"
          #glebe="ngModel"
          (selectionChange)="glebeSelection($event.value)"
          [disabled]="false"
        >
          <mat-option [value]="null"> {{ trans.text.clean | translate }} </mat-option>
          <mat-option
            *ngFor="let glebe of state.glebes"
            [value]="glebe.id"
            [disabled]="glebe.harvested"
          >
            {{ glebe.name }}
            <span *ngIf="glebe.alias && glebe.alias !== ''">( {{ glebe.alias }} )</span>
            <span
              style="float: right"
              matTooltipClass="material-tooltip-class"
              [matTooltip]="trans.text.harvestedDate | translate"
              [matTooltipPosition]="'left'"
              *ngIf="glebe.harvested"
            >
              {{ moment(glebe.finalHarvestDate.toDate()).format(trans.text.date | translate) }}
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label>{{ trans.label.field | label }}</mat-label>
        <mat-select
          #materialSelect
          id="field"
          id="field"
          name="field"
          #field="ngModel"
          [(ngModel)]="state.trapForm.fieldId"
          (selectionChange)="fieldSelection($event.value)"
          required
        >
          <mat-optgroup *ngFor="let item of filterGlebe(); let i = index" [label]="item.name">
            <mat-option
              *ngFor="let field of getFieldsByGlebe(item.id)"
              [value]="field.id"
              [disabled]="field.harvested"
            >
              {{ field.name }}
              <span *ngIf="field.alias && field.alias !== ''">( {{ field.alias }} )</span>
              <span
                style="float: right"
                matTooltipClass="material-tooltip-class"
                [matTooltip]="trans.text.harvestedDate | translate"
                [matTooltipPosition]="'left'"
                *ngIf="field.harvested"
              >
                {{ moment(field.harvestDate.toDate()).format(trans.text.date | translate) }}
              </span>
            </mat-option>
          </mat-optgroup>
        </mat-select>
        <mat-error *ngIf="field.invalid && (field.dirty || field.touched)">
          <mat-error *ngIf="field.errors?.required">
            {{ trans.errors?.required.field | label }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="lat"
          name="lat"
          [disabled]="!state.trapEdit.field"
          [(ngModel)]="state.trapForm.marker.lat"
          (ngModelChange)="changeCoordinates($event, lat)"
          #lat="ngModel"
          placeholder="{{ trans.placeholder.lat | translate }}"
          minlength="2"
          appWhiteSpaceValidator
          [pattern]="regex.lat"
          required
        />
        <mat-error *ngIf="lat.invalid && (lat.dirty || lat.touched)">
          <mat-error *ngIf="lat.errors?.required">
            {{ trans.errors?.required.lat | translate }}
          </mat-error>
          <mat-error *ngIf="lat.errors?.pattern && !lat.errors?.whitespaceValidator">
            {{ trans.errors?.pattern.lat | translate }}
          </mat-error>
          <mat-error *ngIf="lat.errors?.whitespaceValidator">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="lng"
          name="lng"
          [(ngModel)]="state.trapForm.marker.lng"
          [disabled]="!state.trapEdit.field"
          #lng="ngModel"
          (ngModelChange)="changeCoordinates($event, lng)"
          placeholder="{{ trans.placeholder.lng | translate }}"
          appWhiteSpaceValidator
          [pattern]="regex.lng"
          required
        />
        <mat-error *ngIf="lng.invalid && (lng.dirty || lng.touched)">
          <mat-error *ngIf="lng.errors?.required">
            {{ trans.errors?.required.lng | translate }}
          </mat-error>
          <mat-error *ngIf="lng.errors?.pattern && !lng.errors?.whitespaceValidator">
            {{ trans.errors?.pattern.lng | translate }}
          </mat-error>
          <mat-error *ngIf="lng.errors?.whitespaceValidator">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label>{{ trans.placeholder.pheromone | translate }}</mat-label>
        <mat-select
          #materialSelect
          id="pheromone"
          name="pheromone"
          #pheromone="ngModel"
          [(ngModel)]="state.trapForm.pheromone"
          [disabled]="!state.trapForm.deviceId"
          required
        >
          <mat-option *ngFor="let pheromone of pheromones" [value]="pheromone">
            {{ pheromone }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="pheromone.invalid && (pheromone.dirty || pheromone.touched)">
          <mat-error *ngIf="pheromone.errors?.required">
            {{ trans.errors?.required.pheromone | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label
          >{{ trans.label.trapType | translate }} ({{
            trans.label.optional | translate
          }})</mat-label
        >
        <input
          matInput
          type="text"
          id="trapType"
          name="trapType"
          [(ngModel)]="state.trapForm.trapType"
          #trapType="ngModel"
          placeholder="{{ trans.placeholder.trapType | translate }}"
          minlength="2"
          maxlength="30"
          [pattern]="regex.text"
        />
        <mat-error *ngIf="trapType.invalid && (trapType.dirty || trapType.touched)">
          <mat-error *ngIf="trapType.errors?.minlength" class="control-label">
            {{ trans.errors?.minlength.trapType | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              trapType.errors?.pattern &&
              !trapType.errors?.minlength &&
              !trapType.errors?.whitespaceValidator
            "
            class="control-label"
          >
            {{ trans.errors?.minlength.city | translate }}
          </mat-error>
          <mat-error *ngIf="trapType.errors?.whitespaceValidator" class="control-label">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label
          >{{ trans.label.trapCode | translate }} ({{
            trans.label.optional | translate
          }})</mat-label
        >
        <input
          matInput
          type="text"
          id="trapCode"
          name="trapCode"
          [(ngModel)]="state.trapForm.trapCode"
          #trapCode="ngModel"
          placeholder="{{ trans.placeholder.trapCode | translate }}"
          minlength="2"
          maxlength="30"
          appWhiteSpaceValidator
          [pattern]="regex.text"
        />
        <mat-error *ngIf="trapCode.invalid && (trapCode.dirty || trapCode.touched)">
          <mat-error *ngIf="trapCode.errors?.minlength" class="control-label">
            {{ trans.errors?.minlength.trapCode | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              trapCode.errors?.pattern &&
              !trapCode.errors?.minlength &&
              !trapCode.errors?.whitespaceValidator
            "
            class="control-label"
          >
            {{ trans.errors?.minlength.city | translate }}
          </mat-error>
          <mat-error *ngIf="trapCode.errors?.whitespaceValidator" class="control-label">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label
          >{{ trans.label.officialTrap | translate }} ({{
            trans.label.optional | translate
          }})</mat-label
        >
        <input
          matInput
          type="text"
          id="officialTrap"
          name="officialTrap"
          [(ngModel)]="state.trapForm.officialTrap"
          #officialTrap="ngModel"
          placeholder="{{ trans.placeholder.officialTrap | translate }}"
          minlength="2"
          maxlength="30"
          appWhiteSpaceValidator
          [pattern]="regex.text"
        />
        <mat-error *ngIf="officialTrap.invalid && (officialTrap.dirty || officialTrap.touched)">
          <mat-error *ngIf="officialTrap.errors?.minlength" class="control-label">
            {{ trans.errors?.minlength.officialTrap | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              officialTrap.errors?.pattern &&
              !officialTrap.errors?.minlength &&
              !officialTrap.errors?.whitespaceValidator
            "
            class="control-label"
          >
            {{ trans.errors?.minlength.city | translate }}
          </mat-error>
          <mat-error *ngIf="officialTrap.errors?.whitespaceValidator" class="control-label">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <div fxFlex="100%" class="map-container">
        <mat-label>{{ trans.label.drawOrDragPointToTrap | translate }}:</mat-label>
        <app-google-maps-draw-marker
          #drawMarker
          [height]="'280px'"
          [setInputPolygons]="state.trapEdit.field?.polygon"
          [iconUrl]="'trap_light.svg'"
          (eventDrawEnd)="onDrawEndMarker($event)"
          [setInputMarkers]="[state.trapForm.marker]"
          [multiple]="false"
          [markerClickable]="true"
          [markerDraggable]="true"
          [enableDrawing]="true"
        >
        </app-google-maps-draw-marker>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="closeModal()">
    {{ trans.button.close | translate }}
  </button>
  <button mat-button color="primary" [disabled]="outOfArea || form.invalid" (click)="create()">
    {{ trans.button.save | translate }}
  </button>
</mat-dialog-actions>
