import { createAction, props } from '@ngrx/store';
import { LevelExceededChart } from '../../interfaces/LevelExceededChart';
import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { MovingAverageChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/MovingAverageChart';
import { AccumulatedChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/AccumulatedChart';
import { TimestampType } from 'src/app/interfaces/Types';
import {
  EvolutionChart,
  Field,
  Glebe,
  InfestationEvolutionChart,
  SoilChart,
  TotalBetweenFieldsChart,
  Trap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { MadChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Mad';
import { ClimateChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/ClimateChart';

/**
 * Obter
 */
export const GET_FIELDS = createAction('[Gm Tabs Page] GET_FIELDS');
export const GET_FIELDS_SUCCESS = createAction(
  '[Gm Tabs Page] GET_FIELDS_SUCCESS',
  props<{ fields: Array<Field> }>()
);
export const GET_FIELDS_FAIL = createAction(
  '[Gm Tabs Page] GET_FIELDS_FAIL',
  props<{ error: any }>()
);

export const GET_GLEBES = createAction('[Gm Tabs Page] GET_GLEBES');
export const GET_GLEBES_SUCCESS = createAction(
  '[Gm Tabs Page] GET_GLEBES_SUCCESS',
  props<{ glebes: Array<Glebe> }>()
);
export const GET_GLEBES_FAIL = createAction(
  '[Gm Tabs Page] GET_GLEBES_FAIL',
  props<{ error: any }>()
);

export const GET_TRAPS = createAction('[Gm Tabs Page] GET_TRAPS', props<{ fieldId: string }>());
export const GET_TRAPS_SUCCESS = createAction(
  '[Gm Tabs Page] GET_TRAPS_SUCCESS',
  props<{ traps: Array<Trap> }>()
);
export const GET_TRAPS_FAIL = createAction(
  '[Gm Tabs Page] GET_TRAPS_FAIL',
  props<{ error: any }>()
);

export const GET_GM = createAction('[Gm Tabs Page] GET_GM');
export const GET_GM_SUCCESS = createAction(
  '[Gm Tabs Page] GET_GM_SUCCESS',
  props<{ gm: GmConfiguration }>()
);
export const GET_GM_FAIL = createAction('[Gm Tabs Page] GET_GM_FAIL', props<{ error: any }>());

export const GET_MAD_CHART = createAction(
  '[Gm Tabs Page] GET_MAD_CHART',
  props<{ startDate: TimestampType; endDate: TimestampType; glebeName: string }>()
);
export const GET_MAD_CHART_SUCCESS = createAction(
  '[Gm Tabs Page] GET_MAD_CHART_SUCCESS',
  props<{ madChart: Array<MadChart> }>()
);
export const GET_MAD_CHART_FAIL = createAction(
  '[Gm Tabs Page] GET_MAD_CHART_FAIL',
  props<{ error: any }>()
);

export const GET_CLIMATE_CHART = createAction(
  '[Gm Tabs Page] GET_CLIMATE_CHART',
  props<{ startDate: Date; endDate: Date; ticketId: string }>()
);
export const GET_CLIMATE_CHART_SUCCESS = createAction(
  '[Gm Tabs Page] GET_CLIMATE_CHART_SUCCESS',
  props<{ climateChart: Array<ClimateChart> }>()
);
export const GET_CLIMATE_CHART_FAIL = createAction(
  '[Gm Tabs Page] GET_CLIMATE_CHART_FAIL',
  props<{ error: any }>()
);

export const GET_SOIL_CHART = createAction(
  '[Gm Tabs Page] GET_SOIL_CHART',
  props<{ startDate: Date; endDate: Date; ticketId: string }>()
);
export const GET_SOIL_CHART_SUCCESS = createAction(
  '[Gm Tabs Page] GET_SOIL_CHART_SUCCESS',
  props<{ soilChart: Array<SoilChart> }>()
);
export const GET_SOIL_CHART_FAIL = createAction(
  '[Gm Tabs Page] GET_SOIL_CHART_FAIL',
  props<{ error: any }>()
);

export const GET_ACCUMULATED_CHART = createAction(
  '[Gm Tabs Page] GET_ACCUMULATED_CHART',
  props<{
    startDate: TimestampType;
    endDate: TimestampType;
    fieldId: string;
    occurrenceId: string;
    cropId: string;
  }>()
);
export const GET_ACCUMULATED_CHART_SUCCESS = createAction(
  '[Gm Tabs Page] GET_ACCUMULATED_CHART_SUCCESS',
  props<{ accumulatedChart: AccumulatedChart }>()
);
export const GET_ACCUMULATED_CHART_FAIL = createAction(
  '[Gm Tabs Page] GET_ACCUMULATED_CHART_FAIL',
  props<{ error: any }>()
);

export const GET_EVOLUTION_CHART = createAction(
  '[Gm Tabs Page] GET_EVOLUTION_CHART',
  props<{
    startDate: TimestampType;
    endDate: TimestampType;
    fieldId: string;
    occurrenceId: string;
    cropId: string;
  }>()
);
export const GET_EVOLUTION_CHART_SUCCESS = createAction(
  '[Gm Tabs Page] GET_EVOLUTION_CHART_SUCCESS',
  props<{ evolutionChart: EvolutionChart }>()
);
export const GET_EVOLUTION_CHART_FAIL = createAction(
  '[Gm Tabs Page] GET_EVOLUTION_CHART_FAIL',
  props<{ error: any }>()
);

export const GET_INFESTATION_EVOLUTION_CHART = createAction(
  '[Gm Tabs Page] GET_INFESTATION_EVOLUTION_CHART',
  props<{
    fieldId: string;
    occurrenceId: string;
    cropId: string;
    startDate: TimestampType;
    endDate: TimestampType;
  }>()
);
export const GET_INFESTATION_EVOLUTION_CHART_SUCCESS = createAction(
  '[Gm Tabs Page] GET_INFESTATION_EVOLUTION_CHART_SUCCESS',
  props<{ infestationEvolutionChart: InfestationEvolutionChart }>()
);
export const GET_INFESTATION_EVOLUTION_CHART_FAIL = createAction(
  '[Gm Tabs Page] GET_INFESTATION_EVOLUTION_CHART_FAIL',
  props<{ error: any }>()
);

export const GET_MOVING_AVERAGE_CHART = createAction(
  '[Gm Tabs Page] GET_MOVING_AVERAGE_CHART',
  props<{
    fieldId: string;
    occurrenceId: string;
    cropId: string;
    period: number;
    startDate: TimestampType;
    endDate: TimestampType;
  }>()
);
export const GET_MOVING_AVERAGE_CHART_SUCCESS = createAction(
  '[Gm Tabs Page] GET_MOVING_AVERAGE_CHART_SUCCESS',
  props<{ movingAverageChart: MovingAverageChart }>()
);
export const GET_MOVING_AVERAGE_CHART_FAIL = createAction(
  '[Gm Tabs Page] GET_MOVING_AVERAGE_CHART_FAIL',
  props<{ error: any }>()
);

export const GET_TOTAL_BETWEEN_FIELDS_CHART = createAction(
  '[Gm Tabs Page] GET_TOTAL_BETWEEN_FIELDS_CHART',
  props<{
    fieldIds: Array<string>;
    occurrenceId: string;
    startDate: TimestampType;
    endDate: TimestampType;
    cropId: string;
  }>()
);
export const GET_TOTAL_BETWEEN_FIELDS_CHART_SUCCESS = createAction(
  '[Gm Tabs Page] GET_TOTAL_BETWEEN_FIELDS_CHART_SUCCESS',
  props<{ totalBetweenFieldsChart: TotalBetweenFieldsChart }>()
);
export const GET_TOTAL_BETWEEN_FIELDS_CHART_FAIL = createAction(
  '[Gm Tabs Page] GET_TOTAL_BETWEEN_FIELDS_CHART_FAIL',
  props<{ error: any }>()
);

export const GET_LEVEL_EXCEEDED_CHART = createAction(
  '[Gm Tabs Page] GET_LEVEL_EXCEEDED_CHART',
  props<{ startDate: TimestampType; endDate: TimestampType; fieldId: string; cropId: string }>()
);
export const GET_LEVEL_EXCEEDED_CHART_SUCCESS = createAction(
  '[Gm Tabs Page] GET_LEVEL_EXCEEDED_CHART_SUCCESS',
  props<{ levelExceededChart: Array<LevelExceededChart> }>()
);
export const GET_LEVEL_EXCEEDED_CHART_FAIL = createAction(
  '[Gm Tabs Page] GET_LEVEL_EXCEEDED_CHART_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_GM_TABS = createAction('[Gm Tabs Page] HIDE_GM_TABS');
