import { XYChart } from '@amcharts/amcharts4/.internal/charts/types/XYChart';
import { Component, Input, NgZone, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseGraph } from '../../../utils/base-graph/base-graph';
import { LineSeries } from '@amcharts/amcharts4/charts';
import { ApplicationService } from 'src/app/services/application.service';
import { InfestationEvolutionChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { UnitOfMeasureOfOccurrence } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Component({
  selector: 'app-infestation-evolution-chart',
  templateUrl: './infestation-evolution-chart.component.html',
  styleUrls: ['./infestation-evolution-chart.component.scss'],
})
export class InfestationEvolutionChartComponent extends BaseGraph implements OnDestroy {
  @Input() public set data(data: InfestationEvolutionChart) {
    this.infestationEvolutionChart = data;
    this.initChart();
  }

  public infestationEvolutionChart!: InfestationEvolutionChart;

  private chart!: XYChart;

  constructor(
    public anotherTranslateService: TranslateService,
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    public zone: NgZone
  ) {
    super(anotherTranslateService, applicationService);
  }

  public ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  public initChart(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }

      // Criar gráfico
      this.createXYChart('infestationevolution', this.am4charts.XYChart);

      // Criar DateAxis no eixo x
      this.createBasicXYChartDateAxis();

      // Criar ValueAxis no eixo y
      const valueAxis = this.createBasicXYChartValueAxis();

      valueAxis.renderer.labels.template.adapter.add(
        'text',
        (text) =>
          text +
          `${
            this.infestationEvolutionChart?.unit === UnitOfMeasureOfOccurrence.percentage ? '%' : ''
          }`
      );
      valueAxis.min = 0;

      // Habilitar scrollbar
      this.addBasicXYChartScrollbarX();

      // Adicionar legenda
      this.addBasicXYChartLegend();

      if (this.infestationEvolutionChart) {
        // Nível de controle '#e91e63',
        if (this.infestationEvolutionChart.controlLevels?.length > 0) {
          this.infestationEvolutionChart.controlLevels[0].name = this.translateService.instant(
            this.trans.label.controlLevel
          );
          this.infestationEvolutionChart.controlLevels[1].name = this.translateService.instant(
            this.trans.label.controlLevel
          );

          const lineSeries = this.createTrendLine(
            this.infestationEvolutionChart.controlLevels,
            false,
            true,
            '#e91e63',
            null,
            this.translateService.instant(this.trans.label.controlLevel)
          );
          lineSeries.strokeDasharray = '3,3';
        }

        // Total #000000
        if (this.infestationEvolutionChart.infestations?.length > 0) {
          this.createTrendLine(
            this.infestationEvolutionChart.infestations,
            false,
            true,
            '#000000',
            null,
            this.translateService.instant(this.trans.label.infestation)
          );
        }
      }

      this.chart = this.xYChart;
    });
  }

  /**
   * Este método gera as linhas do gráfico
   * @param data conteúdo do gráfico
   * @param randomColor gerar cor automático
   */
  public createTrendLine(
    data: any,
    randomColor: boolean,
    hasCircleBullet: boolean,
    color: string | null,
    fillOpacity: number | null,
    name: string
  ): LineSeries {
    const trend = this.xYChart.series.push(new this.am4charts.LineSeries());

    trend.tooltipText = `[bold font-size: 13px]{name}: {valueY} ${
      this.infestationEvolutionChart &&
      this.infestationEvolutionChart.unit !== UnitOfMeasureOfOccurrence.und
        ? this.translateService.instant(this.trans.enums[this.infestationEvolutionChart.unit])
        : ''
    }`;

    trend.dataFields.valueY = 'value';
    trend.dataFields.dateX = 'date';
    trend.name = name;
    trend.strokeWidth = 2;
    trend.fillOpacity = fillOpacity != null ? fillOpacity : 0;
    trend.propertyFields.strokeOpacity = 'notHadStickyExchange';

    if (!randomColor) {
      if (color) {
        trend.stroke = trend.fill = this.am4core.color(color.toString());
      } else if (this.xYChart.series.values.length > 1) {
        const lastColor =
          this.xYChart.series.values[this.xYChart.series.values.length - 1].stroke.toString();
        trend.stroke = trend.fill = this.am4core.color(lastColor);
      }
    }

    if (hasCircleBullet) {
      const bullet = trend.bullets.push(new this.am4charts.CircleBullet());
      bullet.strokeWidth = 0.5;
      // bullet.stroke = this.am4core.color('#fff');
      bullet.circle.fill = trend.stroke;

      const hoverState = bullet.states.create('hover');
      hoverState.properties.scale = 1.7;
    }

    trend.data = data;

    return trend;
  }
}
