import { Injectable } from '@angular/core';
import { ApplicationService } from './application.service';
import { GenericFireStorageService } from './generic-firestorage.service';
import { GenericHttpService } from './generic-http.service';
import { TranslateTypes } from './translation.service';
import { KmzPolygon } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Injectable()
export class ImportKmzFormService {
  private url!: string;
  private path!: string;
  private kmzFile!: any;

  constructor(
    private applicationService: ApplicationService,
    private genericFireStorageService: GenericFireStorageService,
    private genericHttpService: GenericHttpService,
    public trans: TranslateTypes
  ) {}

  parseKmz(kmzFile: any): Promise<any> {
    this.path = `customers/${this.applicationService.getCustomerId()}/kmz/${this.applicationService
      .getServerDate()
      .toDate()}.kmz`;
    return new Promise(async (resolve, reject) => {
      try {
        this.kmzFile = kmzFile.files[0];
        const file = await this.genericFireStorageService.upload(this.path, this.kmzFile);
        this.url = await file.ref.getDownloadURL();
        const polygons = await this.genericHttpService
          .create('kmzParse', { url: this.url, path: this.path })
          .toPromise();
        resolve(polygons);
      } catch (error) {
        return reject(error);
      }
    });
  }

  public createKmzPolygon(kmzPolygons: Array<KmzPolygon>): Promise<KmzPolygon> {
    return this.genericHttpService
      .create<KmzPolygon>('createKmzPolygons', {
        customerId: this.applicationService.getCustomerId(),
        farmId: this.applicationService.getFarmId(),
        harvestId: this.applicationService.getHarvestId(),
        kmzPolygons,
        url: this.url,
        path: this.path,
        name: this.kmzFile.name,
      })
      .toPromise();
  }
}
