import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';

export const COOKIE_CONFIG: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain,
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#ecb842',
      text: '#222222',
      link: '#ffffff',
    },
    button: {
      background: '#00394e',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  type: 'info',
  layout: 'my-custom-layout',
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
    <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="https://tarvos.ag/terms_of_use/" target="_blank"><strong>{{cookiePolicyLink}}</strong></a>, 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="https://tarvos.ag/privacy_policy/" target="_blank"><strong>{{privacyPolicyLink}}</strong></a>{{andOur}} 
    <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="https://tarvos.ag/terms_of_use/" target="_blank"><strong>{{termsServiceLink}}</strong></a>
    </span>
    `,
  },
};
