import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { UploadMetadata } from '@angular/fire/compat/storage/interfaces';

@Injectable({
  providedIn: 'root',
})
export class GenericFireStorageService {
  constructor(private fS: AngularFireStorage) {}

  public getUrl<T>(path: string): Promise<any> {
    return this.fS.ref(path).getDownloadURL().toPromise();
  }

  public upload<T>(path: string, data: any, metadata?: UploadMetadata): AngularFireUploadTask {
    return this.fS.upload(path, data, metadata);
  }
}
