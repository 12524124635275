import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import {
  GlebeFormState,
  glebeFormStateDefault,
} from 'src/app/view/glebe/glebe-form/glebe-form.state';
import * as glebeFormAction from './glebe-form.actions';

export const glebeReducer = createReducer(
  _.cloneDeep(glebeFormStateDefault),

  /**
   * OBTER
   */
  on(glebeFormAction.GET_HIGHEST_NUMBER_REGISTERED, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(glebeFormAction.GET_HIGHEST_NUMBER_REGISTERED_SUCCESS, (state, action) => ({
    ...state,
    lastCount: action.lastCount,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(glebeFormAction.GET_HIGHEST_NUMBER_REGISTERED_FAIL, (state) => ({
    ...state,
    lastCount: 0,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(glebeFormAction.GET_CROPS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(glebeFormAction.GET_CROPS_SUCCESS, (state, action) => ({
    ...state,
    crops: action.crops,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(glebeFormAction.GET_CROPS_FAIL, (state) => ({
    ...state,
    crops: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(glebeFormAction.GET_HAS_FIELD_LINKED_GLEBE, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(glebeFormAction.GET_HAS_FIELD_LINKED_GLEBE_SUCCESS, (state, action) => ({
    ...state,
    hasFieldLinkedGlebe: action.hasFieldLinkedGlebe,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(glebeFormAction.GET_HAS_FIELD_LINKED_GLEBE_FAIL, (state) => ({
    ...state,
    hasFieldLinkedGlebe: false,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(glebeFormAction.GET_SYSTEM, (state) => ({ ...state, loading: state.loading + 1 })),
  on(glebeFormAction.GET_SYSTEM_SUCCESS, (state, action) => ({
    ...state,
    system: action.system,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(glebeFormAction.GET_SYSTEM_FAIL, (state) => ({
    ...state,
    system: {} as OccurrenceSettings,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * CADASTRAR
   */
  on(glebeFormAction.CREATE_GLEBE, (state) => ({ ...state, loadingModal: state.loadingModal + 1 })),
  on(glebeFormAction.CREATE_GLEBE_SUCCESS, (state) => ({ ...state, closeModal: true })),
  on(glebeFormAction.CREATE_GLEBE_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * ATUALIZAR
   */
  on(glebeFormAction.UPDATE_GLEBE, (state) => ({ ...state, loadingModal: state.loadingModal + 1 })),
  on(glebeFormAction.UPDATE_GLEBE_SUCCESS, (state) => ({ ...state, closeModal: true })),
  on(glebeFormAction.UPDATE_GLEBE_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * OUTROS
   */
  on(glebeFormAction.HIDE_MODAL, (state) => ({
    ..._.cloneDeep(glebeFormStateDefault),
  }))
);

export function reducer(state: GlebeFormState | undefined, action: Action): GlebeFormState {
  return glebeReducer(state, action);
}
