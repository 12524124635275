import { createAction, props } from '@ngrx/store';
import { NameAndId } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const GET_CUSTOMERS = createAction('[Update User Page] GET_CUSTOMERS');
export const GET_CUSTOMERS_SUCCESS = createAction(
  '[Update User Page] GET_CUSTOMERS_SUCCESS',
  props<{ customers: Array<NameAndId> }>()
);
export const GET_CUSTOMERS_FAIL = createAction(
  '[Update User Page] GET_CUSTOMERS_FAIL',
  props<{ error: any }>()
);

export const GET_CUSTOMER_FARMS = createAction(
  '[Update User Page] GET_CUSTOMERS_FARMS',
  props<{ customerId: string }>()
);
export const GET_CUSTOMERS_FARMS_SUCCESS = createAction(
  '[Update User Page] GET_CUSTOMERS_FARMS_SUCCESS',
  props<{ farms: Array<NameAndId> }>()
);
export const GET_CUSTOMERS_FARMS_FAIL = createAction(
  '[Update User Page] GET_CUSTOMERS_FARMS_FAIL',
  props<{ error: any }>()
);

export const GET_SUPPORT_USERS = createAction('[Update User Page] GET_SUPPORT_USERS');
export const GET_SUPPORT_USERS_SUCCESS = createAction(
  '[Update User Page] GET_SUPPORT_USERS_SUCCESS',
  props<{ users: Array<NameAndId> }>()
);
export const GET_SUPPORT_USERS_FAIL = createAction(
  '[Update User Page] GET_SUPPORT_USERS_FAIL',
  props<{ error: any }>()
);
export const POST_UPDATE_USERS_CUSTOMERS = createAction(
  '[Update User Page] POST_UPDATE_USERS_CUSTOMERS',
  props<{
    usersIds: Array<string>;
    customerId: string;
    customerName: string;
    farmsIds: Array<string>;
  }>()
);
export const UPDATE_USERS_CUSTOMERS_SUCCESS = createAction(
  '[Update User Page] UPDATE_USERS_CUSTOMERS_SUCCESS'
);
export const POST_UPDATE_USERS_CUSTOMERS_FAIL = createAction(
  '[Update User Page] POST_UPDATE_USERS_CUSTOMERS_FAIL'
);
