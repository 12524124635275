/**
 * NOTE - Comentários e Refatoração de código 19/05/2021
 */

import { Injectable } from '@angular/core';
import { CollectionReference } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UpdateUsersCustomers } from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/user';
import { WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ApplicationService } from 'src/app/services/application.service';
import { GenericFirestoreService } from 'src/app/services/generic-firestore.service';
import { TARVOS } from 'src/environments/environment';
import { AccountService } from '../../../services/account.service';
import { MyToastrService } from '../../../services/toastr.service';
import { TranslateTypes } from '../../../services/translation.service';
import * as customerAction from './create-customer.actions';
import { user } from './create-customer.state';

@Injectable()
export class CreateCustomerEffects {
  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService,
    private toastrService: MyToastrService,
    public trans: TranslateTypes,
    private router: Router
  ) {}

  /**
   * Este método chama um serviço para obter os termos de uso.
   */
  public getTermsOfUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerAction.GET_TERMS_OF_USE_URL),
      switchMap(() =>
        of(customerAction.GET_TERMS_OF_USE_URL_SUCCESS({ termsOfUseUrl: TARVOS.termsOfUse }))
      )
    )
  );

  /**
   * Este método chama um serviço para obter as politicas de privacidade.
   */
  public getPrivacyPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerAction.GET_PRIVACY_POLICY_URL),
      switchMap(() =>
        of(
          customerAction.GET_PRIVACY_POLICY_URL_SUCCESS({ privacyPolicyUrl: TARVOS.privacyPolicy })
        )
      )
    )
  );

  /**
   * Este método chama um serviço para obter os usuários de suporte
   */
  public getSupportUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerAction.GET_SUPPORT_USERS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<WebUser>(`${this.applicationService.getUserRefDatabase()}`, {
            queryFn: (ref: CollectionReference) => ref.where('support', '==', true),
          })
          .pipe(
            map((supportUsers: Array<WebUser>) =>
              customerAction.GET_SUPPORT_USERS_SUCCESS({ supportUsers })
            ),
            catchError((error) => of(customerAction.GET_SUPPORT_USERS_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para cadastrar usuários.
   */
  public createAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customerAction.CREATE_CUSTOMER),
      mergeMap((action) =>
        this.accountService
          .createCustomer(action.customer.data)
          .then((customer) => {
            if (action.customer.farm) {
              this.accountService
                .createFarm({
                  farmData: action.customer.farm,
                  customerId: customer.id,
                })
                .then((farm) => {
                  this.accountService
                    .createHarvest({
                      harvestData: action.customer.harvest,
                      customerId: customer.id,
                      farmId: farm.id,
                    })
                    .then((harvest) => {
                      let userThatWillHaveCustomersAndFarmsUpdated: UpdateUsersCustomers = {
                        ...user,
                        farms: [{ farmId: farm.id, harvestId: harvest.id }],
                        customerId: customer.id,
                        customerName: action.customer.data.customerName,
                        usersIds: action.customer.usersIds,
                      };
                      Promise.resolve(
                        this.accountService.updateSupportUser(
                          userThatWillHaveCustomersAndFarmsUpdated
                        )
                      );
                      this.router.navigate(['/home/gm']);
                    });
                });
            }
            this.toastrService.success(this.trans.services.create.customerSuccess);
            return customerAction.CREATE_CUSTOMER_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return customerAction.CREATE_CUSTOMER_FAIL(error);
          })
      )
    )
  );
}
