import { Action, createReducer, on } from '@ngrx/store';
import * as RoutesActions from './route-route-optimization.actions';
import { initialRoutesState, RoutesState } from './route-route-optimizations.state';

export const routesReducer = createReducer(
  initialRoutesState,
  on(RoutesActions.CALCULATE_ROUTE, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(RoutesActions.CALCULATE_ROUTE_SUCCESS, (state, { route }) => ({
    ...state,
    route,
    loading: false,
    error: null,
  })),
  on(RoutesActions.CALCULATE_ROUTE_FAIL, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
export function reducer(state: RoutesState | undefined, action: Action): RoutesState {
  return routesReducer(state, action);
}
