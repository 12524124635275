import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  QueryFn,
} from '@angular/fire/compat/firestore';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

export interface Config {
  queryFn?: QueryFn | undefined;
  fieldPath?: string;
  directionStr?: 'desc' | 'asc';
}

const DefaultConfig = {
  queryFn: undefined,
  fieldPath: 'name',
  directionStr: 'asc',
} as Config;

@Injectable({
  providedIn: 'root',
})
export class GenericFirestoreService {
  constructor(private db: AngularFirestore) {}

  public col<T>(
    ref: CollectionPredicate<T>,
    queryFn?: QueryFn | undefined
  ): AngularFirestoreCollection<T> {
    return typeof ref === 'string'
      ? this.db.collection<T>(ref, queryFn ? queryFn : undefined)
      : ref;
  }
  public async colPromise<T>(
    ref: CollectionPredicate<T>,
    queryFn?: QueryFn | undefined
  ): Promise<T> {
    let value = await this.col<T>(ref, queryFn).valueChanges().pipe(first()).toPromise();

    return value[0];
  }

  public doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

  /**
   * Este método consome um serviço para obter dado
   * @param ref Referência do banco
   * @param config ajusta os dados antes de disponibilizar para a aplicação
   */
  public getById<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .valueChanges()
      .pipe(
        map((result: any) => {
          return result.track?.active !== false ? result : null;
        })
      );
  }

  /**
   * Este método consome um serviço para obter uma lista de dados
   * @param ref Referência do banco
   * @param config ajusta os dados antes de disponibilizar para a aplicação
   */
  public getAll<T>(ref: CollectionPredicate<T>, config: Config = DefaultConfig): Observable<T[]> {
    return this.col(ref, config.queryFn)
      .valueChanges()
      .pipe(
        map((result) => {
          if (config.fieldPath) {
            result.sort((a: any, b: any) => {
              return a[config.fieldPath ? config.fieldPath : '']
                .toString()
                .localeCompare(b[config.fieldPath ? config.fieldPath : ''], undefined, {
                  numeric: true,
                });
            });
          }
          if (config.directionStr === 'desc') {
            result.reverse();
          }
          return result.map((data: any) => data).filter((data) => data.track?.active !== false);
        })
      );
  }

  /**
   * Este método consome um serviço para obter uma lista combinada de dados
   * @param combineList lista de combinações
   * @param fieldPath Referência do banco
   * @param directionStr como a lista deve ser ordenado
   */
  public getCombine<T>(
    combineList: Array<{
      ref: CollectionPredicate<T>;
      config?: Config;
    }>,
    fieldPath?: string,
    directionStr?: 'desc' | 'asc'
  ): Observable<T[]> {
    const observables: Array<any> = [];
    combineList.forEach((combine) => observables.push(this.getAll(combine.ref, combine.config)));

    return combineLatest<any[]>(observables)
      .pipe(map((arr) => arr.reduce((acc, cur) => acc.concat(cur))))
      .pipe(
        map((result) => {
          if (fieldPath) {
            result.sort((a: any, b: any) => {
              a[fieldPath].toString().localeCompare(b[fieldPath], undefined, {
                numeric: true,
              });
            });
          }
          const data = result.filter((data: any) => data.track?.active !== false);
          return directionStr === 'desc' ? data.reverse() : data;
        })
      );
  }

  /**
   * Este método consome um serviço para cadastrar um objeto
   * @param ref referência do banco
   * @param data objeto
   * @param config ajusta os dados antes de cadastrar
   */
  public create<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    if (!data.id) data.id = this.db.createId();
    return this.doc(`${ref}/${data.id}`).set(data);
  }

  /**
   * Este método consome um serviço para cadastrar uma lista de objetos
   * @param ref referência do banco
   * @param data lista de objetos
   * @param config ajusta os dados antes de cadastrar
   */
  public batchCreate<T>(ref: DocPredicate<T>, data: Array<any>): Promise<void> {
    const batch = this.db.firestore.batch();
    data.forEach((element: any) => {
      element.id = this.db.createId();
      batch.set(this.doc(`${ref}/${element.id}`).ref, element);
    });
    return batch.commit();
  }

  /**
   * Este método consome um serviço para atualizar um objeto
   * @param ref referência do banco
   * @param data objeto
   * @param config ajusta os dados antes de atualizar
   */
  public update<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    return this.doc(`${ref}/${data.id}`).update(data);
  }

  /**
   * Este método consome um serviço para atualizar uma lista de objetos
   * @param ref referência do banco
   * @param data lista de objetos
   */
  public batchUpdate<T>(ref: DocPredicate<T>, data: Array<any>): Promise<void> {
    const batch = this.db.firestore.batch();
    data.forEach((element: any) => batch.update(this.doc(`${ref}/${element.id}`).ref, element));
    return batch.commit();
  }

  /**
   * Este método consome um serviço para deletar um objeto
   * @param ref referência do banco + id do objeto
   */
  public remove<T>(ref: DocPredicate<T>): Promise<void> {
    return this.doc(ref).delete();
  }

  /**
   * Este método consome um serviço para cadastrar uma lista de objetos
   * @param ref referência do banco
   * @param data lista de objetos
   * @param config ajusta os dados antes de cadastrar
   */
  public batchRemove<T>(ref: DocPredicate<T>, data: Array<any>): Promise<void> {
    const batch = this.db.firestore.batch();
    data.forEach((element: any) => batch.delete(this.doc(`${ref}/${element}`).ref));
    return batch.commit();
  }
}
