import { ImportKmz } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const importKmzStateDefault = {
  kmz: [],
  loading: 0,
  loadingModal: 0,
  displayedColumns: ['name', 'importDate', 'actions'],
  pageSizeOptions: [15],
} as ImportKmzState;

export interface ImportKmzState {
  kmz: Array<ImportKmz>;
  loading: number;
  loadingModal: number;
  displayedColumns: Array<string>;
  pageSizeOptions: number[];
}
