import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateCustomerForm,
  CreateFarmForm,
  CreateHarvestForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';
import { UpdateUsersCustomers } from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/user';
import { environment } from 'src/environments/environment';
import { TranslateTypes } from './translation.service';

@Injectable()
export class AccountService {
  constructor(private http: HttpClient, public trans: TranslateTypes) {}

  public createCustomer(customer: CreateCustomerForm): Promise<any> {
    return this.http.post(`${environment.api}/createCustomer`, customer).toPromise();
  }

  public createFarm({ farmData, customerId }: CreateFarmForm): Promise<any> {
    return this.http.post(`${environment.api}/createFarm`, { farmData, customerId }).toPromise();
  }

  public createHarvest({ harvestData, customerId, farmId }: CreateHarvestForm): Promise<any> {
    return this.http
      .post(`${environment.api}/createHarvest`, { harvestData, customerId, farmId })
      .toPromise();
  }

  public updateSupportUser(user: UpdateUsersCustomers): Promise<any> {
    return this.http.patch(`${environment.api}/updateUsersCustomers`, user).toPromise();
  }
}
