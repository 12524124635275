import { createAction, props } from '@ngrx/store';
import { Farm, WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import {
  CreateUserForm,
  UpdateUserForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';

/**
 * Obter
 */
export const GET_USERS = createAction('[User Page] GET_USERS');
export const GET_USERS_SUCCESS = createAction(
  '[User Page] GET_USERS_SUCCESS',
  props<{ users: Array<WebUser> }>()
);
export const GET_USERS_FAIL = createAction('[User Page] GET_USERS_FAIL', props<{ error: any }>());

export const GET_FARMS = createAction('[User Page] GET_FARMS');
export const GET_FARMS_SUCCESS = createAction(
  '[User Page] GET_FARMS_SUCCESS',
  props<{ farms: Array<Farm> }>()
);
export const GET_FARMS_FAIL = createAction('[User Page] GET_FARMS_FAIL', props<{ error: any }>());

/**
 * Cadastrar
 */
export const CREATE_USER = createAction(
  '[Modal User Page] CREATE_USER',
  props<{ user: CreateUserForm }>()
);
export const CREATE_USER_SUCCESS = createAction('[Modal User Page] CREATE_USER_SUCCESS');
export const CREATE_USER_FAIL = createAction(
  '[Modal User Page] CREATE_USER_FAIL',
  props<{ error: any }>()
);

/**
 * Atualizar
 */
export const LOAD_USER_UPDATE = createAction(
  '[Modal User Page] LOAD_USER_UPDATE',
  props<{ user: WebUser }>()
);
export const UPDATE_USER = createAction(
  '[Modal User Page] UPDATE_USER',
  props<{ user: UpdateUserForm }>()
);

export const UPDATE_USER_SUCCESS = createAction('[Modal User Page] UPDATE_USER_SUCCESS');
export const UPDATE_USER_FAIL = createAction(
  '[Modal User Page] UPDATE_USER_FAIL',
  props<{ error: any }>()
);

/**
 * Remover
 */
export const REMOVE_USER = createAction('[User Page] REMOVE_USER', props<{ ids: Array<string> }>());
export const REMOVE_USER_SUCCESS = createAction('[User Page] REMOVE_USER_SUCCESS');
export const REMOVE_USER_FAIL = createAction(
  '[User Page] REMOVE_USER_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_MODAL = createAction('[Modal User Page] HIDE_MODAL');
