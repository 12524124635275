import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, FormControl } from '@angular/forms';

@Directive({
  selector: '[appWhiteSpaceValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: WhiteSpaceValidatorDirective,
      multi: true,
    },
  ],
})
export class WhiteSpaceValidatorDirective implements Validator {
  public validate(control: FormControl): { whitespaceValidator: true } | null {
    if (control.value && control.value.startsWith(' ')) {
      return { whitespaceValidator: true };
    }
    if (control.value && control.value.endsWith(' ')) {
      return { whitespaceValidator: true };
    }
    return null;
  }
}
