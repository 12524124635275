import { Action, createReducer, on } from '@ngrx/store';
import {
  ImportKmzFormState,
  importKmzFormStateDefault,
} from 'src/app/view/import-kmz/import-kmz-form/import-kmz-form.state';
import * as importKmzFormAction from './import-kmz-form.actions';
import * as _ from 'lodash';

export const importKmzFormReducer = createReducer(
  _.cloneDeep(importKmzFormStateDefault),

  /**
   * GET
   */

  /**
   * CREATE
   */
  on(importKmzFormAction.CREATE_KMZ_POLYGON, (state) => ({
    ...state,
    loadingModal: state.loadingModal + 1,
  })),
  on(importKmzFormAction.CREATE_KMZ_POLYGON_SUCCESS, (state) => ({
    ...state,
    closeModal: true,
    progressPercentageKmz: null,
  })),
  on(importKmzFormAction.CREATE_KMZ_POLYGON_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * OTHER
   */
  on(importKmzFormAction.PARSE_KMZ, (state) => ({
    ...state,
    loadingModal: state.loadingModal + 1,
  })),
  on(importKmzFormAction.PARSE_KMZ_SUCCESS, (state, action) => ({
    ...state,
    polygons: action.polygons,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),
  on(importKmzFormAction.PARSE_KMZ_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  on(importKmzFormAction.HIDE_MODAL, (state) => ({
    ..._.cloneDeep(importKmzFormStateDefault),
  }))
);

export function reducer(state: ImportKmzFormState | undefined, action: Action): ImportKmzFormState {
  return importKmzFormReducer(state, action);
}
