export const regex = {
  text: '^[A-Za-z0-9À-ú- _]*$',
  email: '^([A-Za-z0-9._-ú-]{2,})(@[A-Za-z0-9_-]{2,})(.[A-Za-z]{2,6})+$',
  password: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$',
  lat: '^[+|-]?(90.0{6}|[0-8]?[0-9][.][0-9]{6})$',
  lng: '^[+|-]?(180.0{6}|1[0-7][0-9][.][0-9]{6}|[0-9]?[0-9][.][0-9]{6})$',
  unitsOfMeasurement: '^0*(([,.]?)?|[0-9]*?[0-9]*([,.][0-9]*[0-9]*?)?)$',
  percentage: '^0*(100([,.]00?)?|[0-9]?[0-9]([,.][0-9][0-9]?)?)$',
  unit: '^[0-9]+$',
} as Regex;

export interface Regex {
  text: string;
  email: string;
  password: string;
  lat: string;
  lng: string;
  unitsOfMeasurement: string;
  percentage: string;
  unit: string;
}
