import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as systemAction from './system-settings.actions';
import { ApplicationService } from '../../../services/application.service';
import { GenericFirestoreService } from '../../../services/generic-firestore.service';
import { MyToastrService } from '../../../services/toastr.service';
import { TranslateTypes } from '../../../services/translation.service';
import { SystemSettings } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Injectable()
export class SystemSettingsEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço para obter as configurações do sistema.
   */
  public getSystemSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(systemAction.GET_SYSTEM_SETTINGS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<SystemSettings>(
            `${this.applicationService.getHarvestRefDatabase()}/systemSettings`
          )
          .pipe(
            map((systemSettings: Array<SystemSettings>) =>
              systemAction.GET_SYSTEM_SETTINGS_SUCCESS({ systemSettings: systemSettings[0] })
            ),
            catchError((error) => of(systemAction.GET_SYSTEM_SETTINGS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para atualizar configurações do sistema.
   */
  public updateSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemAction.UPDATE_SYSTEM_SETTINGS),
      mergeMap((action) => {
        return this.genericFirestoreService
          .update<SystemSettings>(
            `${this.applicationService.getHarvestRefDatabase()}/systemSettings`,
            action.systemSettings
          )
          .then(() => {
            this.toastrService.success(this.trans.services.update.systemSuccess);
            return systemAction.UPDATE_SYSTEM_SETTINGS_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return systemAction.UPDATE_SYSTEM_SETTINGS_FAIL(error);
          });
      })
    )
  );
}
