export const services = {
  create: {
    // Sucesso //
    glebeSuccess: {
      default: 'A gleba foi cadastrada com sucesso.',
      productionUnit: 'A Unidade de Produção foi cadastrada com sucesso.',
      productiveUnit: 'A Unidade Produtiva foi cadastrada com sucesso.',
      type: 'glebe',
    },
    customerSuccess: 'Cliente cadastrado com sucesso.',
    trapReportSuccess: 'O relatório de armadilhas {{%0}} foi cadastrado com sucesso.',
    harvestSuccess: {
      default: 'A safra foi cadastrada com sucesso.',
      year: 'O ano foi cadastrada com sucesso.',
      cycle: 'O ciclo foi cadastrada com sucesso.',
      type: 'harvest',
    },
    fieldSuccess: {
      default: 'O talhão foi cadastrado com sucesso.',
      lot: 'O lote foi cadastrado com sucesso.',
      type: 'field',
    },
    userSuccess:
      'O usuário {{%0}} foi cadastrado com sucesso. Um e-mail será enviado ao usuário para definir uma senha.',
    trapSuccess: 'A armadilha {{%0}} foi cadastrado com sucesso.',
    requestConcludeSuccess: 'A solicitação de {{%0}} foi concluída com sucesso.',
    farmSuccess: {
      default: 'A fazenda foi cadastrada com sucesso.',
      productiveUnit: 'A Unidade Produtiva foi cadastrada com sucesso.',
      type: 'farm',
    },
    kmzImportSuccess: 'O kmz foi importado com sucesso.',
    importGlebeSuccess: {
      default: 'A gleba foi importada com sucesso.',
      productionUnit: 'A Unidade de Produção foi importada com sucesso.',
      productiveUnit: 'A Unidade Produtiva foi importada com sucesso.',
      type: 'glebe',
    },
    importFieldSuccess: {
      default: 'O talhão foi importado com sucesso.',
      lot: 'O lote foi importado com sucesso.',
      type: 'field',
    },
    importTrapSuccess: 'A Armadilha foi importado com sucesso.',

    // Error //
    glebeError: {
      default: 'Erro ao cadastrar a gleba {{%0}}.',
      productionUnit: 'Erro ao cadastrar a Unidade de Produção {{%0}}.',
      productiveUnit: 'Erro ao cadastrar a Unidade Produtiva {{%0}}.',
      type: 'glebe',
    },
    harvestError: {
      default: 'Erro ao cadastrar a safra {{%0}}.',
      year: 'Erro ao cadastrar o ano {{%0}}.',
      cycle: 'Erro ao cadastrar o ciclo {{%0}}.',
      type: 'harvest',
    },
    fieldError: {
      default: 'Erro ao cadastrar o talhão {{%0}}.',
      lot: 'Erro ao cadastrar o lote {{%0}}.',
      type: 'field',
    },
    userError: 'Erro ao cadastrar o usuário {{%0}}.',
    trapError: 'Erro ao cadastrar armadilha {{%0}}.',
    farmError: {
      default: 'Erro ao cadastrar fazenda {{%0}}.',
      productiveUnit: 'Erro ao cadastrar Unidade Produtiva {{%0}}.',
      type: 'farm',
    },
    requestError: 'Erro ao cadastrar solicitação {{%0}}.',
    requestConcludeError: 'Erro ao concluir a solicitação de {{%0}}.',
    trapReportError: 'Erro ao cadastrar relatório de armadilhas {{%0}}.',

    kmzImportError: 'Erro ao importar kmz.',
    kmzInvalidFormatError: 'O arquivo não é um arquivo kmz.',
    userDifferentPasswordError: 'As senhas não são iguais.',
    importGlebeError: {
      default: 'Erro ao importar gleba para safra.',
      productionUnit: 'Erro ao importar Unidade de Produção para safra.',
      productiveUnit: 'Erro ao importar Unidade Produtiva para safra.',
      type: 'glebe',
    },
    importFieldError: {
      default: 'Erro ao importar o talhão {{%0}}.',
      lot: 'Erro ao importar o lote {{%0}}.',
      type: 'field',
    },
    importTrapError: 'Erro ao importar armadilhas para safra.',

    // Existe //
    trapReportExist: 'Já existe um relatório deste tipo em andamento',
  },

  update: {
    // Sucesso //
    glebeSuccess: {
      default: 'A gleba foi atualizada com sucesso.',
      productionUnit: 'A Unidade de Produção foi atualizada com sucesso.',
      productiveUnit: 'A Unidade Produtiva foi atualizada com sucesso.',
      type: 'glebe',
    },
    harvestSuccess: {
      default: 'A safra {{%0}} foi atualizada com sucesso.',
      year: 'O ano {{%0}} foi atualizada com sucesso.',
      cycle: 'O ciclo {{%0}} foi atualizada com sucesso.',
      type: 'harvest',
    },
    fieldSuccess: {
      default: 'O talhão foi atualizado com sucesso.',
      lot: 'O lote foi atualizado com sucesso.',
      type: 'field',
    },
    usersSuccess: 'Os usuários foram atualizados com sucesso.',
    userSuccess: 'O usuário {{%0}} foi atualizado com sucesso.',
    systemSuccess: 'A configuração do sistema foi atualizado com sucesso.',
    trapSuccess: 'A armadilha foi atualizada com sucesso.',
    farmSuccess: {
      default: 'A fazenda foi atualizada com sucesso.',
      productiveUnit: 'A unidade produtiva foi atualizada com sucesso.',
      type: 'farm',
    },
    headerSuccess: 'O usuário {{%0}} foi atualizado com sucesso.',
    reapSuccess: {
      default: 'O talhão foi colhido com sucesso.',
      lot: 'O lote foi colhido com sucesso.',
      type: 'field',
    },
    recoverPasswordSuccess: 'Enviamos um link para alterar sua senha em seu e-mail.',
    trapReportSuccess: 'O relatório de armadilha {{%0}} foi atualizado com sucesso.',

    // Error //
    userError: 'Erro ao atualizar a usuário {{%0}}.',
    glebeError: {
      default: 'Erro ao atualizar a gleba.',
      productionUnit: 'Erro ao atualizar a Unidade de Produção.',
      productiveUnit: 'Erro ao atualizar a Unidade Produtiva.',
      type: 'glebe',
    },
    harvestError: {
      default: 'Erro ao atualizar a safra {{%0}}.',
      year: 'Erro ao atualizar o ano {{%0}}.',
      cycle: 'Erro ao atualizar o ciclo {{%0}}.',
      type: 'harvest',
    },
    fieldError: {
      default: 'Erro ao atualizar o talhão.',
      lot: 'Erro ao atualizar o lote.',
      type: 'field',
    },
    usersError: 'Erro ao atualizar os usuários.',
    systemError: 'Erro ao atualizar configuração do sistema.',
    trapError: 'Erro ao atualizar armadilha {{%0}}.',
    requestError: 'Erro ao atualizar solicitação {{%0}}.',
    farmError: {
      default: 'Erro ao atualizar fazenda {{%0}}.',
      productiveUnit: 'Erro ao atualizar unidade produtiva {{%0}}.',
      type: 'farm',
    },
    headerError: 'Erro ao atualizar o usuário {{%0}}.',
    reapError: 'Erro ao colher talhão {{%0}}.',
    trapReportError: 'Erro ao atualizar o relatório de armadilha.',
  },

  remove: {
    // Sucesso //
    farmSuccess: {
      default: 'A fazenda foi removida com sucesso.',
      productiveUnit: 'A unidade produtiva foi removida com sucesso.',
      type: 'farm',
    },
    cleanCacheSuccess: {
      default: 'A fazenda foi sincronizada com sucesso.',
      productiveUnit: 'A unidade produtiva foi sincronizada com sucesso.',
      type: 'farm',
    },
    reportSuccess: 'O relatório foi removido com sucesso.',

    // Error //
    headerError: 'Erro ao remover notificações.',
    glebeError: {
      default: 'Erro ao remover a gleba.',
      productionUnit: 'Erro ao remover a Unidade de Produção.',
      productiveUnit: 'Erro ao remover a Unidade Produtiva.',
      type: 'glebe',
    },
    harvestError: {
      default: 'Erro ao remover a safra.',
      year: 'Erro ao remover o ano.',
      cycle: 'Erro ao remover o ciclo.',
      type: 'harvest',
    },
    fieldError: {
      default: 'Erro ao remover o talhão.',
      lot: 'Erro ao remover o lote.',
      type: 'field',
    },
    userError: 'Erro ao remover o usuário.',
    trapError: 'Erro ao remover a armadilha.',
    requestError: 'Erro ao remover a solicitação.',
    farmError: {
      default: 'Erro ao remover a fazenda.',
      productiveUnit: 'Erro ao remover a unidade produtiva.',
      type: 'farm',
    },
    cleanCacheError: {
      default: 'Erro ao sincronizar fazenda.',
      productiveUnit: 'Erro ao sincronizar unidade produtiva.',
      type: 'farm',
    },
    reportError: 'Erro ao remover a relatório.',
  },

  get: {
    gmFieldsData: {
      default: 'Erro ao carregar dados dos talhões.',
      lot: 'Erro ao carregar dados dos lotes.',
      type: 'field',
    },
    checkToken: 'Não podemos alterar sua senha, o token não é valido.',
    getCustomersError: 'Erro ao carregar clientes.',
    supportUsersError: 'Erro ao carregar usuários de suporte.',
    getCustomersFarmsError: 'Erro ao carregar fazendas do cliente.',
  },
};
