import { FormArray, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Regex, regex } from 'src/app/constants/regex';
import { TranslateTypes } from 'src/app/services/translation.service';
import { CustomTableDirective } from '../custom-table/custom-table';
import * as Moment from 'moment';

export abstract class BaseForm extends CustomTableDirective {
  constructor(
    private refStore: Store<any>,
    private refDialogRef: MatDialogRef<any> | null,
    // @ts-ignore
    private refFormBuilder: FormBuilder,
    public trans: TranslateTypes
  ) {
    super();
  }

  public firstFormGroup!: FormGroup;
  public secondFormGroup!: FormGroup;
  public thirdFormGroup!: FormGroup;
  public regex: Regex = regex;
  public enum: any;
  public moment: any = Moment;

  /**
   * Este método dispara uma ação para submeter um formulário
   * @param action ação a ser executada
   */
  public onSubmit(action: any): void {
    this.refStore.dispatch(action);
  }

  /**
   * Este método fecha as modais e executa uma ação
   * @param action ação a ser executada
   */
  public closeModal(
    action: any,
    firstFormReset?: boolean,
    secondFormReset?: boolean,
    thirdFormReset?: boolean
  ): void {
    if (firstFormReset && this.firstFormGroup) {
      this.firstFormGroup.reset();
    }

    if (secondFormReset && this.secondFormGroup) {
      this.secondFormGroup.reset();
    }

    if (thirdFormReset && this.thirdFormGroup) {
      this.thirdFormGroup.reset();
    }
    this.refStore.dispatch(action);
    this.refDialogRef?.close();
  }

  /**
   * Este método retorna uma mensagem de erro para os inputs do formulário
   * @param index índice do input no formulário
   * @param errors objeto com os erros
   */
  public getInputMessageError(index: string, errors: ValidationErrors | null | undefined): any {
    if (errors) {
      if (errors.required) {
        return this.trans.errors?.required[index];
      } else if (errors.minlength) {
        return this.trans.errors?.minlength[index];
      } else if (errors.matDatepickerMin) {
        return this.trans.errors?.matDatepickerMin[index];
      } else if (errors.listIsEmpty) {
        return this.trans.errors?.listIsEmpty[index];
      } else if (errors.whitespaceValidator) {
        return this.trans.errors?.whiteSpace;
      } else if (errors.isNotPercentage) {
        return this.trans.errors?.isNotPercentage[index];
      } else if (errors.isNotUnd) {
        return this.trans.errors?.isNotUnd[index];
      } else if (errors.isNotOccurrenceByMeters) {
        return this.trans.errors?.isNotOccurrenceByMeters[index];
      } else if (errors.pattern) {
        return this.trans.errors?.pattern[index];
      } else if (errors.min) {
        return this.trans.errors?.min[index];
      } else if (errors.max) {
        return this.trans.errors?.max[index];
      } else if (errors.isNotFileKmz) {
        return this.trans.errors?.isNotFileKmz[index];
      }
    }

    return '';
  }

  /**
   * Este método verifica se o formulário e valido analisando cada item do mesmo
   * @param formGroup formulário
   */
  public checkValidateForms(formGroup: FormGroup | FormArray): void {
    Object.keys(formGroup.controls).forEach((campo) => {
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup || controle instanceof FormArray) {
        this.checkValidateForms(controle);
      } else {
        controle?.markAsDirty();
        controle?.markAllAsTouched();
      }
    });
  }

  /**
   * Este método compara dois objetos e retorna um boolean
   * @param optionOne Objeto 1
   * @param optionTwo Objeto 2
   */
  public compareSelectedObject(optionOne: any, optionTwo: any): boolean {
    return optionOne && optionTwo ? optionOne.id === optionTwo.id : false;
  }
}
