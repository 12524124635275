import { Action, createReducer, on } from '@ngrx/store';
import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { gmTabsDefault, GmTabsState } from 'src/app/view/gm-tabs/gm-tabs.state';
import * as _ from 'lodash';
import * as gmTabsAction from './gm-tabs.actions';

export const gmTabsReducer = createReducer(
  _.cloneDeep(gmTabsDefault),

  /**
   * GET
   */

  on(gmTabsAction.GET_GM, (state) => ({ ...state })),
  on(gmTabsAction.GET_GM_SUCCESS, (state, action) => ({
    ...state,
    gm: action.gm,
    initComponent: true,
  })),
  on(gmTabsAction.GET_GM_FAIL, (state) => ({
    ...state,
    gm: {} as GmConfiguration,
    initComponent: false,
  })),

  on(gmTabsAction.GET_FIELDS, (state) => ({ ...state })),
  on(gmTabsAction.GET_FIELDS_SUCCESS, (state, action) => ({ ...state, fields: action.fields })),
  on(gmTabsAction.GET_FIELDS_FAIL, (state) => ({ ...state, fields: [] })),

  on(gmTabsAction.GET_GLEBES, (state) => ({ ...state })),
  on(gmTabsAction.GET_GLEBES_SUCCESS, (state, action) => ({ ...state, glebes: action.glebes })),
  on(gmTabsAction.GET_GLEBES_FAIL, (state) => ({ ...state, glebes: [] })),

  on(gmTabsAction.GET_TRAPS, (state) => ({ ...state })),
  on(gmTabsAction.GET_TRAPS_SUCCESS, (state, action) => ({ ...state, traps: action.traps })),
  on(gmTabsAction.GET_TRAPS_FAIL, (state) => ({ ...state, traps: [] })),

  on(gmTabsAction.GET_MAD_CHART, (state) => ({
    ...state,
    loadingMadChart: state.loadingMadChart + 1,
  })),
  on(gmTabsAction.GET_MAD_CHART_SUCCESS, (state, action) => ({
    ...state,
    madChart: action.madChart,
    updateMadChart: true,
    loadingMadChart:
      state.loadingMadChart > 0 ? state.loadingMadChart - 1 : (state.loadingMadChart = 0),
  })),
  on(gmTabsAction.GET_MAD_CHART_FAIL, (state) => ({
    ...state,
    madChart: [],
    loadingMadChart:
      state.loadingMadChart > 0 ? state.loadingMadChart - 1 : (state.loadingMadChart = 0),
  })),

  on(gmTabsAction.GET_CLIMATE_CHART, (state) => ({
    ...state,
    loadingClimateChart: state.loadingClimateChart + 1,
  })),
  on(gmTabsAction.GET_CLIMATE_CHART_SUCCESS, (state, action) => ({
    ...state,
    climateChart: action.climateChart,
    updateClimateChart: true,
    loadingClimateChart:
      state.loadingClimateChart > 0
        ? state.loadingClimateChart - 1
        : (state.loadingClimateChart = 0),
  })),
  on(gmTabsAction.GET_CLIMATE_CHART_FAIL, (state) => ({
    ...state,
    climateChart: [],
    loadingClimateChart:
      state.loadingClimateChart > 0
        ? state.loadingClimateChart - 1
        : (state.loadingClimateChart = 0),
  })),

  on(gmTabsAction.GET_SOIL_CHART, (state) => ({
    ...state,
    loadingSoilChart: state.loadingSoilChart + 1,
  })),
  on(gmTabsAction.GET_SOIL_CHART_SUCCESS, (state, action) => ({
    ...state,
    soilChart: action.soilChart,
    updateSoilChart: true,
    loadingSoilChart:
      state.loadingSoilChart > 0 ? state.loadingSoilChart - 1 : (state.loadingSoilChart = 0),
  })),
  on(gmTabsAction.GET_SOIL_CHART_FAIL, (state) => ({
    ...state,
    soilChart: [],
    loadingSoilChart:
      state.loadingSoilChart > 0 ? state.loadingSoilChart - 1 : (state.loadingSoilChart = 0),
  })),

  on(gmTabsAction.GET_EVOLUTION_CHART, (state) => ({
    ...state,
    loadingEvolutionChart: state.loadingEvolutionChart + 1,
  })),
  on(gmTabsAction.GET_EVOLUTION_CHART_SUCCESS, (state, action) => ({
    ...state,
    evolutionChart: action.evolutionChart,
    updateEvolutionChart: true,
    loadingEvolutionChart:
      state.loadingEvolutionChart > 0
        ? state.loadingEvolutionChart - 1
        : (state.loadingEvolutionChart = 0),
  })),
  on(gmTabsAction.GET_EVOLUTION_CHART_FAIL, (state) => ({
    ...state,
    evolutionChart: null,
    loadingEvolutionChart:
      state.loadingEvolutionChart > 0
        ? state.loadingEvolutionChart - 1
        : (state.loadingEvolutionChart = 0),
  })),

  on(gmTabsAction.GET_ACCUMULATED_CHART, (state) => ({
    ...state,
    loadingAccumulatedChart: state.loadingAccumulatedChart + 1,
  })),
  on(gmTabsAction.GET_ACCUMULATED_CHART_SUCCESS, (state, action) => ({
    ...state,
    accumulatedChart: action.accumulatedChart,
    updateAccumulatedChart: true,
    loadingAccumulatedChart:
      state.loadingAccumulatedChart > 0
        ? state.loadingAccumulatedChart - 1
        : (state.loadingAccumulatedChart = 0),
  })),
  on(gmTabsAction.GET_ACCUMULATED_CHART_FAIL, (state) => ({
    ...state,
    evolutionChart: null,
    loadingAccumulatedChart:
      state.loadingAccumulatedChart > 0
        ? state.loadingAccumulatedChart - 1
        : (state.loadingAccumulatedChart = 0),
  })),

  on(gmTabsAction.GET_MOVING_AVERAGE_CHART, (state) => ({
    ...state,
    loadingMovingAverageChart: state.loadingMovingAverageChart + 1,
  })),
  on(gmTabsAction.GET_MOVING_AVERAGE_CHART_SUCCESS, (state, action) => ({
    ...state,
    movingAverageChart: action.movingAverageChart,
    updateMovingAverageChart: true,
    loadingMovingAverageChart:
      state.loadingMovingAverageChart > 0
        ? state.loadingMovingAverageChart - 1
        : (state.loadingMovingAverageChart = 0),
  })),
  on(gmTabsAction.GET_MOVING_AVERAGE_CHART_FAIL, (state) => ({
    ...state,
    movingAverageChart: null,
    loadingMovingAverageChart:
      state.loadingMovingAverageChart > 0
        ? state.loadingMovingAverageChart - 1
        : (state.loadingMovingAverageChart = 0),
  })),

  on(gmTabsAction.GET_INFESTATION_EVOLUTION_CHART, (state) => ({
    ...state,
    loadingInfestationEvolutionChart: state.loadingInfestationEvolutionChart + 1,
  })),
  on(gmTabsAction.GET_INFESTATION_EVOLUTION_CHART_SUCCESS, (state, action) => ({
    ...state,
    infestationEvolutionChart: action.infestationEvolutionChart,
    updateInfestationEvolutionChart: true,
    loadingInfestationEvolutionChart:
      state.loadingInfestationEvolutionChart > 0
        ? state.loadingInfestationEvolutionChart - 1
        : (state.loadingInfestationEvolutionChart = 0),
  })),
  on(gmTabsAction.GET_INFESTATION_EVOLUTION_CHART_FAIL, (state) => ({
    ...state,
    infestationEvolutionChart: null,
    loadingInfestationEvolutionChart:
      state.loadingInfestationEvolutionChart > 0
        ? state.loadingInfestationEvolutionChart - 1
        : (state.loadingInfestationEvolutionChart = 0),
  })),

  on(gmTabsAction.GET_TOTAL_BETWEEN_FIELDS_CHART, (state) => ({
    ...state,
    loadingTotalBetweenFieldsChart: state.loadingTotalBetweenFieldsChart + 1,
  })),
  on(gmTabsAction.GET_TOTAL_BETWEEN_FIELDS_CHART_SUCCESS, (state, action) => ({
    ...state,
    totalBetweenFieldsChart: action.totalBetweenFieldsChart,
    updateTotalBetweenFieldsChart: true,
    loadingTotalBetweenFieldsChart:
      state.loadingTotalBetweenFieldsChart > 0
        ? state.loadingTotalBetweenFieldsChart - 1
        : (state.loadingTotalBetweenFieldsChart = 0),
  })),
  on(gmTabsAction.GET_TOTAL_BETWEEN_FIELDS_CHART_FAIL, (state) => ({
    ...state,
    totalBetweenFieldsChart: null,
    loadingTotalBetweenFieldsChart:
      state.loadingTotalBetweenFieldsChart > 0
        ? state.loadingTotalBetweenFieldsChart - 1
        : (state.loadingTotalBetweenFieldsChart = 0),
  })),

  on(gmTabsAction.GET_LEVEL_EXCEEDED_CHART, (state) => ({
    ...state,
    loadingLevelExceededChart: state.loadingLevelExceededChart + 1,
  })),
  on(gmTabsAction.GET_LEVEL_EXCEEDED_CHART_SUCCESS, (state, action) => ({
    ...state,
    levelExceededChart: action.levelExceededChart,
    updateLevelExceededChart: true,
    loadingLevelExceededChart:
      state.loadingLevelExceededChart > 0
        ? state.loadingLevelExceededChart - 1
        : (state.loadingLevelExceededChart = 0),
  })),
  on(gmTabsAction.GET_LEVEL_EXCEEDED_CHART_FAIL, (state) => ({
    ...state,
    levelExceededChart: [],
    loadingLevelExceededChart:
      state.loadingLevelExceededChart > 0
        ? state.loadingLevelExceededChart - 1
        : (state.loadingLevelExceededChart = 0),
  })),

  /**
   * OTHER
   */
  on(gmTabsAction.HIDE_GM_TABS, (state) => ({
    ..._.cloneDeep(gmTabsDefault),
  }))
);

export function reducer(state: GmTabsState | undefined, action: Action): GmTabsState {
  return gmTabsReducer(state, action);
}
