export const button = {
  edit: 'Editar',
  navigateToHomePage: 'Ir para a página inicial',
  remove: 'Remover',
  cantRemove: 'A armadilha com status MONITORANDO não pode ser removida',
  backFarm: {
    default: 'Voltar para Fazenda',
    productiveUnit: 'Voltar para Unidade Produtiva',
    type: 'farm',
  },
  back: 'Voltar',
  selected: 'selecionados',
  available: 'disponíveis',
  register: 'Cadastrar',
  next: 'Próximo',
  send: 'Enviar',
  forgotPassword: 'Esqueceu sua senha?',
  signIn: 'Login',
  createAccount: 'Crie sua Conta',
  trap: 'Armadilha',
  ndvi: 'Ndvi',
  editProfile: 'Editar Perfil',
  logout: 'Sair',
  clearNotifications: 'Limpar Notificações',
  createManager: 'Cadastrar Gestor',
  createUser: 'Cadastrar Usuário',
  listOfFarm: {
    default: 'Lista de Fazendas',
    productiveUnit: 'Lista de Unidades Produtivas',
    type: 'farm',
  },
  listOfHarvest: {
    default: 'Lista de Safras',
    year: 'Lista de Anos',
    cycle: 'Lista de Ciclos',
    type: 'harvest',
  },
  editField: {
    default: 'Editar Talhão',
    lot: 'Editar Lote',
    type: 'field',
  },
  createChart: 'Criar Gráfico',
  createGlebe: {
    default: 'Cadastrar Gleba',
    productionUnit: 'Cadastrar Unidade de Produção',
    productiveUnit: 'Cadastrar Unidade Produtiva',
    type: 'glebe',
  },
  createReport: 'Gerar Relatório',
  editReport: 'Editar relatório',
  editGlebe: {
    default: 'Editar Gleba',
    productionUnit: 'Editar Unidade de Produção',
    productiveUnit: 'Editar Unidade Produtiva',
    type: 'glebe',
  },
  createTrap: 'Cadastrar Armadilha',
  linerChange: 'Troca de Piso',
  exportXls: 'Exportar Excel',
  exportPdf: 'Abrir Relatório',
  print: 'Imprimir',
  filter: 'Filtrar',
  backToLogin: 'Voltar ao login',
  others: 'Outros',
  comment: 'Comentário',
  apply: 'Aplicar',
  clearFilter: 'Limpar Filtro',
  request: 'Solicitação',
  importKmz: 'Importar Kmz',
  kmz: 'KMZ',
  close: 'Fechar',
  save: 'Salvar',
  download: 'Download',
  changePassword: 'Alterar Senha',
  createFarm: {
    default: 'Cadastrar Fazenda',
    productiveUnit: 'Cadastrar Unidade Produtiva',
    type: 'farm',
  },
  createField: {
    default: 'Cadastrar Talhão',
    lot: 'Cadastrar Lote',
    type: 'field',
  },
  createHarvest: {
    default: 'Cadastrar Safra',
    year: 'Cadastrar Ano',
    cycle: 'Cadastrar Ciclo',
    type: 'harvest',
  },
  yes: 'Sim',
  selectAllOccurrences: 'Selecionar Todas as Ocorrências',
  unselectAllOccurrences: 'Desmarcar Todas as Ocorrências',
  reap: 'Colher',
  lastPoints: 'Últimos Apontamentos',
  generatePoints: 'Gerar Pontos Automáticos',
  moreInformation: 'Mais Informações',
  updatePlan: 'Atualizar Plano',
  contractedPlan: 'Plano Contratado',
  hideCollectionPoint: 'Ocultar armadilhas',
  showFieldWithHighControlLevel: {
    default: 'Exibir apenas talhões com infestação alta',
    lot: 'Exibir apenas lotes com infestação alta',
    type: 'field',
  },
  sendEmail: 'Enviar email',
  toSchedule: 'Agendar',
  cancelSchedule: 'Cancelar Programação',
  importGlebe: {
    default: 'Importar Gleba',
    productionUnit: 'Importar Unidade de Produção',
    productiveUnit: 'Importar Unidade Produtiva',
    type: 'glebe',
  },
  importField: {
    default: 'Importar Talhão',
    lot: 'Importar Lote',
    type: 'field',
  },
  importTrap: 'Importar Armadilha',
  defineGlebe: {
    default: 'Definir Gleba',
    productionUnit: 'Definir Unidade de Produção',
    productiveUnit: 'Definir Unidade Produtiva',
    type: 'glebe',
  },
  reset: 'Redefinir',
  pdf: 'Pdf',
  xls: 'Excel',
  ok: 'ok',
};
