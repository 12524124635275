<!-- Loading -->
<app-loading [isOpen]="state.loadingModal || state.loading"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="onCloseModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span *ngIf="data.edit" class="title">{{ trans.button.editGlebe | label }}</span>
  <span *ngIf="!data.edit" class="title">{{ trans.button.createGlebe | label }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <form class="custom-form-field" [formGroup]="firstFormGroup">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <!-- name -->
      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="name"
          formControlName="name"
          [appDisableControl]="true"
          required
        />
        <mat-error>
          {{ getInputMessageError('name', firstFormGroup.get('name')?.errors) | translate }}
        </mat-error>
      </mat-form-field>

      <!-- alias -->
      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="alias"
          formControlName="alias"
          placeholder="{{ trans.placeholder.alias | translate }}"
          minlength="2"
          maxlength="64"
          [pattern]="regex.text"
          cdkFocusInitial
        />
        <mat-error>
          {{ getInputMessageError('alias', firstFormGroup.get('alias')?.errors) | translate }}
        </mat-error>
      </mat-form-field>

      <!-- cropId -->
      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label>{{ trans.label.crop | translate }}</mat-label>
        <mat-select
          #materialSelect
          cdkFocusInitial
          id="cropId"
          [appDisableControl]="state.hasFieldLinkedGlebe"
          formControlName="cropId"
          (selectionChange)="onSelectionChangeCrop($event.value)"
          required
        >
          <mat-option
            class="material-select-option-message"
            *ngIf="state.crops.length <= 0"
            [disabled]="true"
          >
            {{ trans.text.noRegisteredCrops | translate }}</mat-option
          >
          <mat-option *ngFor="let crop of state.crops" [value]="crop.id">
            {{ crop.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ getInputMessageError('crop', firstFormGroup.get('cropId')?.errors) | translate }}
        </mat-error>
      </mat-form-field>

      <!-- cropName -->
      <mat-form-field
        fxFlex="100%"
        fxFlex.gt-xs="0 1 50%"
        [style.display]="this.firstFormGroup.get('cropId')?.value === 'FRUTAS' ? 'block' : 'none'"
      >
        <input
          matInput
          type="text"
          id="cropName"
          formControlName="cropName"
          placeholder="{{ trans.placeholder.cropName | translate }}"
          [pattern]="regex.text"
          [appDisableControl]="false"
          cdkFocusInitial
          required
          minlength="2"
          maxlength="32"
        />
        <mat-error>
          {{ getInputMessageError('cropName', firstFormGroup.get('cropName')?.errors) | translate }}
        </mat-error>
      </mat-form-field>

      <!-- varieties -->
      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="varieties"
          formControlName="varieties"
          placeholder="{{ trans.placeholder.cropVarieties | translate }}"
          [pattern]="regex.text"
          [appDisableControl]="false"
          cdkFocusInitial
        />
        <mat-error>
          {{
            getInputMessageError('varieties', firstFormGroup.get('varieties')?.errors) | translate
          }}
        </mat-error>
      </mat-form-field>

      <!-- sector -->
      <mat-form-field
        fxFlex="100%"
        fxFlex.gt-xs="0 1 50%"
        [style.display]="this.firstFormGroup.get('cropId')?.value === 'FRUTAS' ? 'block' : 'none'"
      >
        <input
          matInput
          type="text"
          id="sector"
          name="sector"
          formControlName="sector"
          placeholder="{{ trans.placeholder.sector | translate }} ({{
            trans.label.optional | translate
          }})"
          minlength="2"
          maxlength="30"
          [pattern]="regex.text"
        />
        <mat-error>
          {{ getInputMessageError('sector', firstFormGroup.get('sector')?.errors) | translate }}
        </mat-error>
      </mat-form-field>

      <!-- allotment -->
      <mat-form-field
        fxFlex="100%"
        fxFlex.gt-xs="0 1 50%"
        [style.display]="this.firstFormGroup.get('cropId')?.value === 'FRUTAS' ? 'block' : 'none'"
      >
        <input
          matInput
          type="text"
          id="allotment"
          name="allotment"
          formControlName="allotment"
          placeholder="{{ trans.placeholder.allotment | translate }} ({{
            trans.label.optional | translate
          }})"
          minlength="2"
          maxlength="30"
          [pattern]="regex.text"
        />
        <mat-error>
          {{
            getInputMessageError('allotment', firstFormGroup.get('allotment')?.errors) | translate
          }}
        </mat-error>
      </mat-form-field>

      <!-- govCode -->
      <mat-form-field
        fxFlex="100%"
        fxFlex.gt-xs="0 1 50%"
        [style.display]="this.firstFormGroup.get('cropId')?.value === 'FRUTAS' ? 'block' : 'none'"
      >
        <input
          matInput
          type="text"
          id="govCode"
          name="govCode"
          formControlName="govCode"
          placeholder="{{ trans.placeholder.govCode | translate }} ({{
            trans.label.optional | translate
          }})"
          minlength="2"
          maxlength="30"
          [pattern]="regex.text"
        />
        <mat-error>
          {{ getInputMessageError('govCode', firstFormGroup.get('govCode')?.errors) | translate }}
        </mat-error>
      </mat-form-field>

      <!-- productiveUnit -->
      <mat-form-field
        fxFlex="100%"
        fxFlex.gt-xs="0 1 50%"
        [style.display]="this.firstFormGroup.get('cropId')?.value === 'FRUTAS' ? 'block' : 'none'"
      >
        <input
          matInput
          type="text"
          id="productiveUnit"
          name="productiveUnit"
          formControlName="productiveUnit"
          placeholder="{{ trans.placeholder.productiveUnit | translate }} ({{
            trans.label.optional | translate
          }})"
          minlength="2"
          maxlength="30"
          [pattern]="regex.text"
        />
        <mat-error>
          {{
            getInputMessageError('productiveUnit', firstFormGroup.get('productiveUnit')?.errors)
              | translate
          }}
        </mat-error>
      </mat-form-field>

      <!-- program -->
      <mat-form-field
        fxFlex="100%"
        fxFlex.gt-xs="0 1 50%"
        [style.display]="this.firstFormGroup.get('cropId')?.value === 'FRUTAS' ? 'block' : 'none'"
      >
        <input
          matInput
          type="text"
          id="program"
          name="program"
          formControlName="program"
          placeholder="{{ trans.placeholder.program | translate }} ({{
            trans.label.optional | translate
          }})"
          minlength="2"
          maxlength="30"
          [pattern]="regex.text"
        />
        <mat-error>
          {{ getInputMessageError('program', firstFormGroup.get('program')?.errors) | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="onCloseModal()">{{ trans.button.close | translate }}</button>
  <div>
    <button mat-button color="primary" (click)="onFormClick()">
      {{ trans.button.save | translate }}
    </button>
  </div>
</mat-dialog-actions>
