import { DecimalPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { MapMonitoring, PointMonitoring } from 'src/app/interfaces/Monitoring';
import { UsefulService } from '../../utils/useful-methods/useful.service';
import { ApplicationService } from 'src/app/services/application.service';
import moment from 'moment';
import {
  AreaType,
  BatteryLevel,
  FillFactorLevel,
  UnitOfMeasureOfOccurrence,
  ValueType,
} from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
})
export class GeneralInfoComponent {
  @Input() mapMonitoringItem!: MapMonitoring;

  public moment = moment;
  public step: string | null = null;
  public batteryLevel = BatteryLevel;
  public fillFactorLevel = FillFactorLevel;
  public areaType = AreaType;

  constructor(
    public trans: TranslateTypes,
    private decimalPipe: DecimalPipe,
    private translateService: TranslateService,
    public useful: UsefulService,
    public applicationService: ApplicationService
  ) {}

  /**
   * Este método retorna um valor formatado de acordo com a unidade de medida
   * @param value valor
   * @param unit unidade de medida
   * @param isControlLevel nível de controle
   */
  getFormattedNumber(value: number | string, unit: string, isControlLevel?: boolean): string {
    if (value != null && unit) {
      if (typeof value === 'string') {
        return `${this.translateService.instant(this.trans.enums['OCCURRENCE_' + value])}`;
      } else if (unit === UnitOfMeasureOfOccurrence.percentage) {
        return `${this.decimalPipe.transform(value, '.2-2')}%`;
      } else if (unit === UnitOfMeasureOfOccurrence.occurrenceByMeters) {
        return `${this.decimalPipe.transform(value, '.2-2')} ${this.translateService.instant(
          this.trans.enums[UnitOfMeasureOfOccurrence.occurrenceByMeters]
        )}`;
      } else if (unit === UnitOfMeasureOfOccurrence.und || unit === ValueType.number) {
        const regex = '.2-2';
        return `${this.decimalPipe.transform(value, regex)} ${this.translateService.instant(
          this.trans.enums[UnitOfMeasureOfOccurrence.und]
        )}`;
      } else if (unit === UnitOfMeasureOfOccurrence.boolean) {
        return `${
          value
            ? isControlLevel
              ? 'Presença'
              : 'Presente'
            : isControlLevel
            ? 'Ausência'
            : 'Ausente'
        }`;
      } else if (unit === UnitOfMeasureOfOccurrence.intensity) {
        return `${
          value == 1
            ? this.translateService.instant(this.trans.enums['LEVEL_HIGH'])
            : this.translateService.instant(this.trans.enums[`OCCURRENCE_${value}`])
        }`;
      } else if (unit === UnitOfMeasureOfOccurrence.notDefined) {
        return `${
          value == 1
            ? this.translateService.instant(this.trans.label.presence)
            : this.translateService.instant(this.trans.label.absence)
        }`;
      }
    }

    return isControlLevel
      ? `${this.translateService.instant(this.trans.text.controlLevelUndefined)}`
      : `${this.translateService.instant(this.trans.text.unmonitored)}`;
  }

  /**
   * Este método abre um painel expansível
   */
  public setStep(name: string): void {
    this.step = name;
  }

  /**
   * Este método retorna uma lista ordenada
   * @param list lista a ser ordenada
   */
  public getSortedList(list: Array<PointMonitoring>): Array<PointMonitoring> {
    return this.useful.orderList<PointMonitoring>(list, 'name');
  }
}
