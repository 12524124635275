import { XYChart } from '@amcharts/amcharts4/.internal/charts/types/XYChart';
import { Component, Input, NgZone, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseGraph } from '../../../utils/base-graph/base-graph';
import { LevelExceededChart } from 'src/app/interfaces/LevelExceededChart';
import { ApplicationService } from 'src/app/services/application.service';
import { UnitOfMeasureOfOccurrence } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Component({
  selector: 'app-level-exceeded-chart',
  templateUrl: './level-exceeded-chart.component.html',
  styleUrls: ['./level-exceeded-chart.component.scss'],
})
export class LevelExceededChartComponent extends BaseGraph implements OnDestroy {
  @Input() public set data(data: Array<LevelExceededChart>) {
    this.levelExceededCharts = data;
    this.initChart();
  }

  public levelExceededCharts!: Array<LevelExceededChart>;

  private chart!: XYChart;

  constructor(
    public anotherTranslateService: TranslateService,
    public trans: TranslateTypes,
    public zone: NgZone,
    public applicationService: ApplicationService
  ) {
    super(anotherTranslateService, applicationService);
  }

  public ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  public initChart(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }

      // criar gráfico
      this.createXYChart('levelExceededChart', this.am4charts.XYChart);

      // criar categoria do eixo x
      const categoryAxis = this.createBasicXYChartCategoryAxis('name', '');
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataItems.template.text = '{name}';
      categoryAxis.renderer.minGridDistance = 130;

      const label = categoryAxis.renderer.labels.template;
      label.truncate = true;
      label.maxWidth = 130;

      // criar value do eixo y
      const valueAxis = this.createBasicXYChartValueAxis();
      valueAxis.renderer.labels.template.adapter.add(
        'text',
        (text) =>
          text +
          `${this.levelExceededCharts[0]?.unit === UnitOfMeasureOfOccurrence.percentage ? '%' : ''}`
      );

      // adicionar scrollbar
      this.addBasicXYChartScrollbarX();
      this.xYChart.scrollbarX.marginTop = 25;

      // adicionar coluna das ocorrências
      const columnSeries1 = this.xYChart.series.push(new this.am4charts.ColumnSeries());
      columnSeries1.dataFields.valueY = 'value';
      columnSeries1.dataFields.categoryX = 'name';
      columnSeries1.tooltipText = `[bold font-size: 17px]{name}: {valueY} ${
        this.levelExceededCharts &&
        this.levelExceededCharts[0].unit !== UnitOfMeasureOfOccurrence.und &&
        this.levelExceededCharts[0].unit !== UnitOfMeasureOfOccurrence.boolean
          ? this.translateService.instant(this.trans.enums[this.levelExceededCharts[0].unit])
          : ''
      }`;
      columnSeries1.columns.template.width = this.am4core.percent(50);

      columnSeries1.columns.template.adapter.add('fill', (fill, target) => {
        if (target.dataItem) {
          target.stroke = this.xYChart.colors.getIndex(target.dataItem.index + 1);
          return this.xYChart.colors.getIndex(target.dataItem.index + 1);
        }
        return this.xYChart.colors.getIndex(0);
      });

      // adicionar coluna com nível de controle
      const columnSeries2 = this.xYChart.series.push(new this.am4charts.ColumnSeries());
      columnSeries2.dataFields.valueY = 'controlLevel';
      columnSeries2.dataFields.categoryX = 'name';

      columnSeries2.tooltipText = `[bold font-size: 17px] ${this.translateService.instant(
        this.trans.label.controlLevel
      )}: {valueY} ${
        this.levelExceededCharts &&
        this.levelExceededCharts[0].unit !== UnitOfMeasureOfOccurrence.und &&
        this.levelExceededCharts[0].unit !== UnitOfMeasureOfOccurrence.boolean
          ? this.translateService.instant(this.trans.enums[this.levelExceededCharts[0].unit])
          : ''
      }`;
      columnSeries2.clustered = false;
      columnSeries2.columns.template.width = this.am4core.percent(35);

      // gerar cores para as colunas do nível de controle
      columnSeries2.columns.template.adapter.add('fill', (fill, target: any) => {
        target.stroke = this.am4core.color('#f44336');
        return this.am4core.color('#f44336');
      });

      const rangeTemplate = categoryAxis.axisRanges.template;
      rangeTemplate.tick.location = 0;
      rangeTemplate.tick.length = 13;
      rangeTemplate.tick.strokeOpacity = 0.6;
      rangeTemplate.grid.strokeOpacity = 0.6;

      const chartData = this.levelExceededCharts;

      this.xYChart.data = chartData;

      // adicionar cursor ao gráfico
      this.xYChart.cursor = new this.am4charts.XYCursor();

      this.chart = this.xYChart;
    });
  }
}
