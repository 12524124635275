import { Component, Input, NgZone, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseGraph } from '../../../utils/base-graph/base-graph';
import { ApplicationService } from 'src/app/services/application.service';
import { MadChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Mad';
import { XYChart } from '@amcharts/amcharts4/charts';
@Component({
  selector: 'app-mad-chart',
  templateUrl: './mad-chart.component.html',
  styleUrls: ['./mad-chart.component.scss'],
})
export class MadChartComponent extends BaseGraph implements OnDestroy {
  @Input() public set data(data: Array<MadChart>) {
    this.madChart = data;
    this.initChart();
  }

  public madChart!: Array<MadChart>;

  private chart!: XYChart;

  constructor(
    public anotherTranslateService: TranslateService,
    public trans: TranslateTypes,
    public zone: NgZone,
    public applicationService: ApplicationService
  ) {
    super(anotherTranslateService, applicationService);
  }

  public ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  public initChart(): void {
    this.zone.runOutsideAngular(async () => {
      if (this.chart) {
        this.chart.dispose();
      }

      const data = this.processData(this.madChart);
      // Call chart
      this.createChart(data);
    });
  }

  public createChart(mad: any): void {
    // create chart
    this.createXYChart('madInfestation', this.am4charts.XYChart);

    //create CategoryAxis on axis x
    const categoryAxis = this.createBasicXYChartCategoryAxis('date', '');
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 130;
    categoryAxis.dataItems.template.text = '{date}';

    const label = categoryAxis.renderer.labels.template;
    label.maxWidth = 130;

    // create ValueAxis on axis y
    this.createBasicXYChartValueAxis();

    // add scrollbar
    this.addBasicXYChartScrollbarX();
    this.xYChart.scrollbarX.marginTop = 25;

    // add colum MAD
    const columnSeries = this.xYChart.series.push(new this.am4charts.ColumnSeries());
    columnSeries.name = 'MAD';
    columnSeries.dataFields.valueY = 'value';
    columnSeries.dataFields.categoryX = 'date';
    columnSeries.tooltipText = `[bold font-size: 15px]{name} em {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/][#fff]{additional}[/]`;
    columnSeries.columns.template.width = this.am4core.percent(50);

    columnSeries.columns.template.adapter.add('fill', (fill, target) => {
      const values = target.dataItem?.values;
      const { controlLevel, damageLevel } = mad[0];
      if (values) {
        if (values?.valueY && values.valueY?.value < controlLevel) {
          target.stroke = this.am4core.color('#0c9926');
          return this.am4core.color('#0c9926');
        } else if (values?.valueY && values.valueY?.value < damageLevel) {
          target.stroke = this.am4core.color('#dba80f');
          return this.am4core.color('#dba80f');
        } else {
          target.stroke = this.am4core.color('#bf170b');
          return this.am4core.color('#bf170b');
        }
      }
      return fill;
    });

    columnSeries.columns.template.propertyFields.fillOpacity = 'fillOpacity';
    columnSeries.columns.template.propertyFields.stroke = 'stroke';
    columnSeries.columns.template.propertyFields.strokeWidth = 'strokeWidth';
    columnSeries.columns.template.propertyFields.strokeDasharray = 'columnDash';
    columnSeries.tooltip!.label.textAlign = 'middle';

    //add line of control level

    let lineSeries1 = this.xYChart.series.push(new this.am4charts.LineSeries());
    lineSeries1.name = this.translateService.instant(this.trans.label['controlLevel']);
    lineSeries1.dataFields.valueY = 'controlLevel';
    lineSeries1.dataFields.categoryX = 'date';

    lineSeries1.stroke = this.am4core.color('#EFA35D');
    lineSeries1.strokeWidth = 2;
    lineSeries1.strokeDasharray = '3,3';
    lineSeries1.tooltip!.label.textAlign = 'middle';
    lineSeries1.tooltipText =
      '[#fff font-size: 15px]{name} em {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/]';

    let bullet1 = lineSeries1.bullets.push(new this.am4charts.Bullet());
    bullet1.fill = this.am4core.color('#EFA35D'); // tooltips grab fill from parent by default
    let circle1 = bullet1.createChild(this.am4core.Circle);
    circle1.radius = 3;
    circle1.strokeWidth = 2;

    //add line of demage level

    let lineSeries2 = this.xYChart.series.push(new this.am4charts.LineSeries());
    lineSeries2.name = this.translateService.instant(this.trans.label['damageLevel']);
    lineSeries2.dataFields.valueY = 'damageLevel';
    lineSeries2.dataFields.categoryX = 'date';

    lineSeries2.stroke = this.am4core.color('#CA3838');
    lineSeries2.strokeWidth = 2;
    lineSeries2.strokeDasharray = '3,3';
    lineSeries2.tooltip!.label.textAlign = 'middle';
    lineSeries2.tooltipText =
      '[#fff font-size: 15px]{name} em {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/]';

    let bullet2 = lineSeries2.bullets.push(new this.am4charts.Bullet());
    bullet2.fill = this.am4core.color('#CA3838'); // tooltips grab fill from parent by default
    let circle2 = bullet2.createChild(this.am4core.Circle);
    circle2.radius = 3;
    circle2.strokeWidth = 2;

    // Add legend
    this.xYChart.legend = new this.am4charts.Legend();
    this.xYChart.legend.position = 'bottom';

    this.xYChart.cursor = new this.am4charts.XYCursor();

    this.xYChart.data = mad;
  }

  public processData(data: any): Array<any> {
    return data.map((value: any) => {
      if (value.inProgress) {
        return {
          ...value,
          strokeWidth: 1,
          columnDash: '5',
          fillOpacity: 0.2,
          additional: ` (${this.translateService.instant(this.trans.text.inProjection)})`,
        };
      }
      return value;
    });
  }
}
