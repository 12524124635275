<div
  [ngStyle]="{ height: height }"
  fxLayout="column"
  fxLayoutAlign="center center"
  class="tm-color-gray"
>
  <div>
    <i class="las" [class]="icon" [ngStyle]="{ fontSize: iconSize }"></i>
  </div>
  <div>
    <p [ngStyle]="{ fontSize: messageSize, padding: messagePadding }">
      {{ message | translate }}
    </p>
  </div>

  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="15px" class="buttons-actions">
    <button
      fxFlex="0 1 100%"
      *ngIf="
        buttonTwo !== '' &&
        applicationService.hasEditPermission(operation.create, checkHarvestCompleted)
      "
      type="button"
      mat-flat-button
      color="primary"
      (click)="buttonTwoClick.emit()"
    >
      {{ buttonTwo | translate }}
    </button>
    <button
      fxFlex="0 1 100%"
      *ngIf="
        buttonOne !== '' &&
        applicationService.hasEditPermission(operation.create, checkHarvestCompleted)
      "
      type="button"
      mat-flat-button
      color="primary"
      (click)="buttonOneClick.emit()"
    >
      {{ buttonOne | translate }}
    </button>
    <button
      fxFlex="0 1 100%"
      *ngIf="
        buttonThree !== '' &&
        applicationService.hasEditPermission(operation.any, checkHarvestCompleted)
      "
      type="button"
      mat-flat-button
      color="primary"
      (click)="buttonThreeClick.emit()"
    >
      {{ buttonThree | translate }}
    </button>
  </div>
</div>
