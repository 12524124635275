import { Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Glebe';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';

export const glebeStateDefault = {
  glebes: [],
  system: {} as OccurrenceSettings,
  loading: 0,
  loadingRemove: 0,
  displayedColumns: ['select', 'name', 'cropName', 'varieties', 'actions'],
  displayedForms: ['name', 'cropName', 'varieties'],
  pageSizeOptions: [15],
} as GlebeState;

export interface GlebeState {
  glebes: Array<Glebe>;
  system: OccurrenceSettings;
  loading: number;
  loadingRemove: number;
  displayedColumns: Array<string>;
  displayedForms: Array<string>;
  pageSizeOptions: number[];
}
