/**
 * NOTE - Comentários e Refatoração de código 19/05/2021
 */

import * as fieldFormAction from './field-form.actions';
import { Injectable } from '@angular/core';
import { CollectionReference } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Field, Glebe, Harvest, Trap } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import { ApplicationService } from '../../../services/application.service';
import { GenericFirestoreService } from '../../../services/generic-firestore.service';
import { GenericHttpService } from '../../../services/generic-http.service';
import { MyToastrService } from '../../../services/toastr.service';
import { TranslateTypes } from '../../../services/translation.service';
import {
  CreateFieldForm,
  UpdateFieldForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { TranslateService } from '@ngx-translate/core';
import { CalculateService } from 'src/app/services/calculate.service';

@Injectable()
export class FieldFormEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService,
    private genericHttpService: GenericHttpService,
    private calculateService: CalculateService,

    public trans: TranslateTypes,
    private labelPipe: LabelPipe,

    private translateService: TranslateService
  ) {}

  /**
   * Este método chama um serviço para listar talhões
   */
  public getFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fieldFormAction.GET_FIELDS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Field>(`${this.applicationService.getHarvestRefDatabase()}/fields`)
          .pipe(
            map((fields: Array<Field>) => fieldFormAction.GET_FIELDS_SUCCESS({ fields })),
            catchError((error) => of(fieldFormAction.GET_FIELDS_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço obter o maior número de registro
   */
  public getLastCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fieldFormAction.GET_HIGHEST_NUMBER_REGISTERED),
      switchMap(() => {
        let lastCount;
        if (!this.applicationService.getFarm()?.collectionSettings?.field?.lastCount) {
          const combineList = this.applicationService.getHarvestList().map((harvest: Harvest) => {
            return {
              ref: `${this.applicationService.getFarmRefDatabase()}/harvests/${harvest.id}/fields`,
              config: {
                queryFn: (ref: CollectionReference) =>
                  ref.orderBy('registrationNumber', 'desc').limit(1),
              },
            };
          });
          return this.genericFirestoreService.getCombine<Field>(combineList).pipe(
            map(
              (fields: Array<Field>) => {
                const registrationNumberMap = fields
                  .filter((field) => field.registrationNumber)
                  .map((field: any) => field.registrationNumber);
                lastCount = this.calculateService.calculateLastCount(registrationNumberMap);

                return fieldFormAction.GET_HIGHEST_NUMBER_REGISTERED_SUCCESS({
                  lastCount,
                });
              },
              catchError((error) => of(fieldFormAction.GET_HIGHEST_NUMBER_REGISTERED_FAIL(error)))
            )
          );
        } else {
          lastCount = this.applicationService.getFarm()?.collectionSettings?.field?.lastCount
            ? this.applicationService.getFarm()!.collectionSettings!.field!.lastCount
            : 0;
          return of(
            fieldFormAction.GET_HIGHEST_NUMBER_REGISTERED_SUCCESS({
              lastCount,
            })
          );
        }
      })
    )
  );

  /**
   * Este método chama um serviço para listar glebas.
   */
  public getGlebes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fieldFormAction.GET_GLEBES),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Glebe>(`${this.applicationService.getHarvestRefDatabase()}/glebes`, {
            queryFn: (ref: CollectionReference) => ref.orderBy('name', 'asc'),
          })
          .pipe(
            map((glebes: Array<Glebe>) => fieldFormAction.GET_GLEBES_SUCCESS({ glebes })),
            catchError((error) => of(fieldFormAction.GET_GLEBES_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para obter as configurações do sistema.
   */
  public getSystem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fieldFormAction.GET_SYSTEM),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<OccurrenceSettings>(
            `${this.applicationService.getHarvestRefDatabase()}/systemSettings`
          )
          .pipe(
            map((systems: Array<OccurrenceSettings>) =>
              fieldFormAction.GET_SYSTEM_SUCCESS({ system: systems[0] })
            ),
            catchError((error) => of(fieldFormAction.GET_SYSTEM_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para verificar se existem armadilha para o talhão.
   */
  public getHasTrapLinkedField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fieldFormAction.GET_HAS_TRAP_LINKED_FIELD),
      switchMap((action) =>
        this.genericFirestoreService
          .getAll<Trap>(`${this.applicationService.getFarmRefDatabase()}/traps`, {
            queryFn: (ref: CollectionReference) =>
              ref
                .where('harvestId', '==', this.applicationService.getHarvestId())
                .where('field.id', '==', action.id),
          })
          .pipe(
            map((trap: Array<Trap>) =>
              fieldFormAction.GET_HAS_TRAP_LINKED_FIELD_SUCCESS({
                hasTrapLinkedField: trap.length ? true : false,
              })
            ),
            catchError((error) => of(fieldFormAction.GET_HAS_TRAP_LINKED_FIELD_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para cadastrar talhões.
   */
  public createField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fieldFormAction.CREATE_FIELD),
      mergeMap((action) => {
        return this.genericHttpService
          .create<CreateFieldForm>('createField', action.field)
          .toPromise()
          .then(() => {
            this.toastrService.success(
              this.labelPipe.transform(this.trans.services.create.fieldSuccess)
            );
            return fieldFormAction.CREATE_FIELD_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              this.labelPipe.transform(
                this.translateService.instant(
                  `${
                    error.code !== undefined
                      ? error.code
                      : error.error.code !== undefined
                      ? error.error.code
                      : error.error.internalCode
                  }`
                )
              )
            );
            return fieldFormAction.CREATE_FIELD_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para atualizar talhões.
   */
  public updateField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fieldFormAction.UPDATE_FIELD),
      mergeMap((action) => {
        return this.genericHttpService
          .update<UpdateFieldForm>('updateField', action.field)
          .toPromise()
          .then(() => {
            this.toastrService.success(
              this.labelPipe.transform(this.trans.services.update.fieldSuccess)
            );
            return fieldFormAction.UPDATE_FIELD_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              this.labelPipe.transform(this.translateService.instant(error.error.internalCode))
            );
            return fieldFormAction.UPDATE_FIELD_FAIL(error);
          });
      })
    )
  );
}
