import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ApplicationService } from './application.service';
import { LabelPipe } from '../pipes/label.pipe';
import { TranslateTypes } from './translation.service';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class GenericReportFilesService {
  constructor(
    private applicationService: ApplicationService,
    private translateService: TranslateService,
    public trans: TranslateTypes,
    private labelPipe: LabelPipe
  ) {}

  public generateExcelFile<T>(report: any, columns: string[]): Promise<any> {
    const data = report.data;
    return new Promise<any>((resolve, reject) => {
      try {
        const worksheet: any = XLSX.utils.json_to_sheet(data, {
          header: [...columns],
        });
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C
          if (!worksheet[address]) {
            continue;
          }
          const value = columns.includes(worksheet[address].v)
            ? ['farm', 'glebe', 'field', 'harvest'].includes(worksheet[address].v)
              ? this.labelPipe.transform(this.trans.label[worksheet[address].v])
              : this.translateService.instant('label.' + worksheet[address].v)
            : worksheet[address].v;

          worksheet[address].v = value;
        }
        const workbook: XLSX.WorkBook = {
          SheetNames: ['data'],
          Sheets: { data: worksheet },
        };
        const excelBuffer: any = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveExcelFile(excelBuffer, report.alias);
        resolve(null);
      } catch (error) {
        reject(error);
      }
    });
  }

  private saveExcelFile(buffer: any, alias?: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    const filename =
      alias ?? 'tarvos_report_' + this.applicationService.getServerDate().toDate().getTime();
    FileSaver.saveAs(data, filename + EXCEL_EXTENSION);
  }
}
