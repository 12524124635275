import { createAction, props } from '@ngrx/store';
import { SystemSettings } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_SYSTEM_SETTINGS = createAction('[System Settings Page] GET_SYSTEM_SETTINGS');
export const GET_SYSTEM_SETTINGS_SUCCESS = createAction(
  '[System Settings Page] GET_SYSTEM_SETTINGS_SUCCESS',
  props<{ systemSettings: SystemSettings }>()
);
export const GET_SYSTEM_SETTINGS_FAIL = createAction(
  '[System Settings Page] GET_SYSTEM_SETTINGS_FAIL',
  props<{ error: any }>()
);

/**
 * Atualizar
 */
export const UPDATE_SYSTEM_SETTINGS = createAction(
  '[System Settings Page] UPDATE_SYSTEM_SETTINGS',
  props<{ systemSettings: SystemSettings }>()
);
export const UPDATE_SYSTEM_SETTINGS_SUCCESS = createAction(
  '[System Settings Page] UPDATE_SYSTEM_SETTINGS_SUCCESS'
);
export const UPDATE_SYSTEM_SETTINGS_FAIL = createAction(
  '[System Settings Page] UPDATE_SYSTEM_SETTINGS_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const DESTROY_COMPONENT = createAction('[System Settings Page] DESTROY_COMPONENT');
