import { Action, createReducer, on } from '@ngrx/store';
import * as CustomersActions from './customers-route-optimization.actions';
import {
  CustomerRouteOptimizationState,
  initialCustomerState,
} from './customers-route-optimization.state';

export const clientsReducer = createReducer(
  initialCustomerState,
  on(CustomersActions.GET_CUSTUMERS_ROUTE_OPTIMIZATION, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CustomersActions.GET_CUSTOMERS_ROUTE_OPTMIZIATION_SUCCESS, (state, { customers }) => ({
    ...state,
    customers,
    loading: false,
    error: null,
  })),
  on(CustomersActions.GET_CUSTOMERS_ROUTE_OPTIMIZATION_FAIL, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
export function reducer(
  state: CustomerRouteOptimizationState | undefined,
  action: Action
): CustomerRouteOptimizationState {
  return clientsReducer(state, action);
}
