import { XYChart } from '@amcharts/amcharts4/.internal/charts/types/XYChart';
import { Component, Input, NgZone, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseGraph } from '../../../utils/base-graph/base-graph';
import { AxisRenderer, LineSeries, ValueAxis } from '@amcharts/amcharts4/charts';
import { SoilChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { ApplicationService } from 'src/app/services/application.service';
import { SoilData } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/SoilChart';

@Component({
  selector: 'app-soil-chart',
  templateUrl: './soil-chart.component.html',
  styleUrls: ['./soil-chart.component.scss'],
})
export class SoilChartComponent extends BaseGraph implements OnDestroy {
  @Input() public set data(data: Array<SoilChart>) {
    this.soilChart = data;
    this.initChart();
  }

  @Input() public set ticketId(ticketId: string) {
    this.ticketId = ticketId;
  }

  public soilChart!: Array<SoilChart>;

  private chart!: XYChart;

  constructor(
    public anotherTranslateService: TranslateService,
    public trans: TranslateTypes,
    public zone: NgZone,
    public applicationService: ApplicationService
  ) {
    super(anotherTranslateService, applicationService);
  }

  public ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  public initChart(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }

      // Criar gráfico
      this.createXYChart('soilChart', this.am4charts.XYChart);

      // Criar DateAxis no eixo x
      this.createDateAxis();

      // Criar ValueAxis no eixo y
      const h0Axis = this.createHumidityAxis('0cm', '#04ff00');
      const h1Axis = this.createHumidityAxis('20cm', '#0087c7');
      const h2Axis = this.createHumidityAxis('40cm', '#5e008e');
      const h3Axis = this.createHumidityAxis('80cm', '#560000');

      const axis = [h0Axis, h1Axis, h2Axis, h3Axis];
      // Habilitar scrollbar
      // this.addBasicXYChartScrollbarX();

      // Adicionar legenda
      this.addLegend();

      // Process Data
      // const processedData = this.processData(mockData);
      const processedData = this.processData(this.soilChart);

      // Create Chart
      this.createChart(processedData, axis);

      this.xYChart.cursor.disabled = true;
    });
  }

  public createDateAxis() {
    const dateAxis = this.createBasicXYChartDateAxis();
    dateAxis.title.text = 'Data';
    dateAxis.title.fontSize = '16px';
    dateAxis.title.fontWeight = 'bold';
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.startLocation = -3;
    dateAxis.endLocation = 12;
    dateAxis.baseInterval = {
      timeUnit: 'hour',
      count: 1,
    };
    dateAxis.gridIntervals.setAll([{ timeUnit: 'day', count: 1 }]);
    return dateAxis;
  }

  public createHumidityAxis(text: string, fillColor: string) {
    const humidityAxis = this.createBasicXYChartValueAxis();
    humidityAxis.min = -10;
    humidityAxis.max = 110;
    humidityAxis.strictMinMax = true;
    // humidityAxis.renderer.labels.template.disabled = true;
    humidityAxis.renderer.opposite = true;
    humidityAxis.title.text = `${text}`;
    humidityAxis.title.rotation = -90;
    humidityAxis.title.fontSize = '16px';
    humidityAxis.title.fontWeight = 'bold';
    humidityAxis.title.fill = this.am4core.color(fillColor);
    humidityAxis.renderer.labels.template.fill = this.am4core.color(fillColor);
    humidityAxis.maxPrecision = 0;

    // humidityAxis.syncWithAxis = temperatureAxis;
    return humidityAxis;
  }

  public addLegend(): void {
    this.xYChart.legend = new this.am4charts.Legend();
    this.xYChart.legend.position = 'top';
    this.xYChart.legend.labels.template.fontSize = 13;
    this.xYChart.legend.labels.template.text = '{name}';
    this.xYChart.legend.maxHeight = 100;
    this.xYChart.legend.useDefaultMarker = true;
    this.xYChart.legend.paddingBottom = 12;

    this.xYChart.legend.itemContainers._template.paddingTop = 0;
    this.xYChart.legend.itemContainers._template.paddingBottom = 0;

    const marker = this.xYChart.legend.markers.template.children.getIndex(0);
    if (marker) {
      marker.width = 15;
      marker.height = 15;
      marker.paddingTop = 2;
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;
      marker.stroke = this.am4core.color('#ccc');
    }
  }

  public createChart(
    data: { [key: string]: Array<SoilData> },
    humidityAxes: Array<ValueAxis<AxisRenderer>>
  ) {
    const timeDelta = 4 * 60 * 60 * 1000; // 4h between values
    const distanceDelta = 20; // 20cm between sensors
    const ticketIds = Object.keys(data);
    for (const ticketId of ticketIds) {
      for (let h = 0; h < 4; h++) {
        const trapData = data[ticketId];
        const humidityData = [];
        const humidityAxis = humidityAxes[h];
        for (let msgCount = 0; msgCount < trapData.length; msgCount++) {
          const msg = trapData[msgCount];
          const timestamp = new Date(msg.timestamp);
          const dateTime = timestamp.getTime();
          for (let i = 1; i >= 0; i--) {
            const date = new Date(dateTime - i * timeDelta);
            humidityData.push({
              date,
              name: `${(h + 1) * distanceDelta}cm`,
              value: msg.soil[h][i],
              unit: '%',
            });
          }
        }
        this.createTrendLine(
          humidityData,
          humidityAxis,
          false,
          true,
          humidityAxis.title.fill!.toString(),
          null
        );
      }
    }
    this.chart = this.xYChart;
  }

  /**
   * Este método gera as linhas do gráfico
   * @param data conteúdo do gráfico
   * @param randomColor gerar cor automático
   */
  public createTrendLine(
    data: any,
    axis: any,
    randomColor: boolean,
    hasCircleBullet: boolean,
    color: string | null,
    fillOpacity: number | null
  ): LineSeries {
    const trend = this.xYChart.series.push(new this.am4charts.LineSeries());
    trend.dataFields.valueY = 'value';
    trend.dataFields.dateX = 'date';
    trend.name = data[0].name;
    trend.strokeWidth = 6;
    trend.fillOpacity = fillOpacity != null ? fillOpacity : 0;

    if (!randomColor) {
      if (color) {
        trend.stroke = trend.fill = this.am4core.color(color.toString());
      } else if (this.xYChart.series.values.length > 1) {
        const lastColor =
          this.xYChart.series.values[this.xYChart.series.values.length - 1].stroke.toString();
        trend.stroke = trend.fill = this.am4core.color(lastColor);
      }
    }

    if (hasCircleBullet) {
      const bullet = trend.bullets.push(new this.am4charts.CircleBullet());
      bullet.strokeWidth = 0.5;
      // bullet.stroke = this.am4core.color('#fff');
      bullet.circle.fill = trend.stroke;

      const hoverState = bullet.states.create('hover');
      hoverState.properties.scale = 1.7;
    }

    trend.yAxis = axis;
    trend.data = data;
    return trend;
  }

  public processData(data: Array<SoilChart>): { [key: string]: Array<SoilData> } {
    return data.reduce((acc: any, cur: SoilChart) => {
      if (acc[cur.ticketId]) acc[cur.ticketId].push(cur.data);
      else acc[cur.ticketId] = [cur.data];
      return acc;
    }, {});
  }
}
