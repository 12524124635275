<div class="material-menu-container tm-background-custom">
  <button
    mat-icon-button
    color="primary"
    autofocus="false"
    class="material-button-close"
    (click)="closeGmTabsEvent.emit()"
  >
    <i class="las la-arrow-right" aria-hidden="true"></i>
  </button>
  <mat-tab-group
    #matTabGroup
    mat-align-tabs="start"
    animationDuration="0"
    class="tm-background-header-custom"
  >
    <!-- Geral -->
    <mat-tab [label]="trans.label.general | translate">
      <app-general-info [mapMonitoringItem]="mapMonitoringItem"></app-general-info>
    </mat-tab>

    <!-- Gráficos -->
    <mat-tab [label]="trans.label.charts | translate">
      <app-list-chart
        [mapMonitoringItem]="mapMonitoringItem"
        [gmConfiguration]="state.gm"
      ></app-list-chart>
    </mat-tab>
  </mat-tab-group>
</div>
