import * as _ from 'lodash';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  CREATE_IMPORT_GLEBE,
  GET_GLEBES,
  GET_HARVESTS,
  HIDE_MODAL,
} from 'src/app/view/glebe/import-glebe-form/import-glebe-form.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseForm } from '../../utils/base-form/base-form';
import {
  firstFormGroup,
  ImportGlebeFormState,
  importGlebeFormStateDefault,
} from './import-glebe-form.state';
import { Glebe, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-import-glebe-form',
  templateUrl: './import-glebe-form.component.html',
  styleUrls: ['./import-glebe-form.component.scss'],
})
export class ImportGlebeFormComponent extends BaseForm implements OnInit, OnDestroy {
  public importGlebeFormState$: Observable<ImportGlebeFormState>;
  public state: ImportGlebeFormState = importGlebeFormStateDefault;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;

  constructor(
    public formBuilder: FormBuilder,
    public store: Store<any>,
    public dialogRef: MatDialogRef<ImportGlebeFormComponent>,
    public applicationService: ApplicationService,
    public dialog: MatDialog,
    public trans: TranslateTypes,
    @Inject(MAT_DIALOG_DATA) public glebesInHarvest: Array<Glebe>
  ) {
    super(store, dialogRef, formBuilder, trans);
    this.importGlebeFormState$ = this.store.pipe(select('importGlebeFormState'));
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    this.store.dispatch(GET_HARVESTS());
    this.store.dispatch(GET_GLEBES());
    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));

    this.subscribe = this.importGlebeFormState$.subscribe(
      (importGlebeFState: ImportGlebeFormState) => {
        this.state = importGlebeFState;

        if (importGlebeFState.closeModal) {
          this.onCloseModal();
        }
      }
    );
  }

  /**
   * Este método executa quando o componentes não é mais ativo
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método fecha uma modal
   */
  public onCloseModal(): void {
    this.closeModal(HIDE_MODAL(), true, false, false);
  }

  /**
   * Este método valida e cadastrar ou atualiza
   */
  public onFormClick(): void {
    if (this.firstFormGroup.invalid) {
      this.checkValidateForms(this.firstFormGroup);
    } else {
      const action = CREATE_IMPORT_GLEBE({ ids: this.firstFormGroup.getRawValue().ids });
      this.onSubmit(action);
    }
  }

  /**
   * Este método retorna uma lista de safra ordenada
   */
  public getHarvests(): Array<Harvest> {
    return this.state.harvests
      .sort((a: any, b: any) =>
        a.currentYear.seconds
          .toString()
          .localeCompare(b.currentYear.seconds.toString(), undefined, { numeric: true })
      )
      .reverse();
  }

  /*
   * Este método retorna uma lista de gleba por safra;
   */
  public getGlebesByHarvest(harvestId: string): Array<Glebe> {
    if (this.state.glebes) {
      if (harvestId) {
        return this.state.glebes.filter(
          (glebe: Glebe) => glebe.harvestId === harvestId && glebe.harvested
        );
      }
    }
    return [];
  }

  /**
   *  Este método verifica se a gleba ja foi importada
   * @param glebeId id da gleba
   */
  public wasImportedGlebe(glebeId: string): boolean {
    return this.glebesInHarvest.filter((glebe: Glebe) => glebe.id === glebeId).length > 0;
  }
}
