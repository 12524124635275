<!-- Loading -->
<app-loading [isOpen]="state.loading ? 1 : 0"></app-loading>
<div fxLayout="column" fxLayoutAlign="start center" style="height: 100vh; overflow-x: auto">
  <div class="material-login-container">
    <img
      *ngIf="applicationService.getIsDarkTheme()"
      src="./../../../assets//images/logo_h_cor2.png"
      alt=""
    />
    <img
      *ngIf="!applicationService.getIsDarkTheme()"
      src="./../../../assets//images/logo_h_cor1.png"
      alt=""
    />
    <h4>{{ trans.title.createNewCustomer | translate }}:</h4>

    <form #form="ngForm" autocomplete="on" [formGroup]="firstFormGroup">
      <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
        <mat-form-field fxFlex="100%" appearance="outline" [color]="'primary'">
          <mat-label>{{ trans.label.customerName | translate }}</mat-label>
          <input
            matInput
            type="text"
            id="customerName"
            name="customerName"
            formControlName="customerName"
            placeholder="{{ trans.placeholder.typeTheCompany | translate }}"
            autofocus="true"
            required
          />
          <mat-error>
            {{
              getInputMessageError('customerName', firstFormGroup.get('customerName')?.errors)
                | translate
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="100%" appearance="outline" [color]="'primary'">
          <mat-label>Selecione um usuário de suporte</mat-label>
          <mat-select
            #materialSelect
            cdkFocusInitial
            id="supportIds"
            name="supportIds"
            formControlName="supportIds"
            multiple
            required
          >
            <mat-option
              class="material-select-option-message"
              *ngIf="state.supportUsers.length <= 0"
              [disabled]="true"
            >
              {{ trans.text.noRegisteredSupportUsers | translate }}</mat-option
            >
            <mat-option *ngFor="let supportUser of state.supportUsers" [value]="supportUser.id">
              {{ supportUser.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{
              getInputMessageError('supportIds', firstFormGroup.get('supportIds')?.errors)
                | translate
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="100%" appearance="outline" [color]="'primary'">
          <mat-label>Período do Contrato</mat-label>
          <input
            matInput
            [matDatepicker]="contractedPeriod"
            [min]="this.applicationService.getServerDate().toDate()"
            id="contractedPeriod"
            formControlName="contractedPeriod"
            required
          />
          <mat-datepicker-toggle matSuffix [for]="contractedPeriod"></mat-datepicker-toggle>
          <mat-datepicker #contractedPeriod></mat-datepicker>
          <mat-error>
            {{
              getInputMessageError(
                'contractedPeriod',
                firstFormGroup.get('contractedPeriod')?.errors
              ) | translate
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <form #form2="ngForm" autocomplete="on" [formGroup]="secondFormGroup">
      <div
        style="margin-top: 0"
        fxLayout="column"
        fxLayout.gt-xs="row wrap"
        fxLayout.gt-md="row wrap"
      >
        <mat-form-field
          [style.display]="firstFormGroup.get('enableFarm')?.value !== true ? 'none' : 'block'"
          fxFlex="100%"
          appearance="outline"
          [color]="'primary'"
        >
          <mat-label>{{ trans.label.farm | label }}</mat-label>
          <input
            matInput
            type="text"
            id="farm"
            name="farm"
            formControlName="farmName"
            placeholder="{{ trans.placeholder.typeTheFarmName | label }}"
          />
          <mat-error>
            {{ getInputMessageError('farm', secondFormGroup.get('farmName')?.errors) | label }}
          </mat-error>
        </mat-form-field>

        <mat-form-field
          fxFlex="100%"
          [style.display]="firstFormGroup.get('enableFarm')?.value !== true ? 'none' : 'block'"
          appearance="outline"
          [color]="'primary'"
        >
          <mat-label>{{ trans.label.lat | translate }}</mat-label>
          <input
            matInput
            type="text"
            id="lat"
            name="lat"
            placeholder="{{ trans.placeholder.typeTheLat | translate }}"
            formControlName="lat"
          />
          <mat-hint>
            <strong>{{ trans.text.latLng | translate }}</strong>
          </mat-hint>
          <mat-error>
            {{ getInputMessageError('lat', secondFormGroup.get('lat')?.errors) | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field
          fxFlex="100%"
          [style.display]="firstFormGroup.get('enableFarm')?.value !== true ? 'none' : 'block'"
          appearance="outline"
          [color]="'primary'"
        >
          <mat-label>{{ trans.label.lng | translate }}</mat-label>
          <input
            matInput
            type="text"
            id="lng"
            name="lng"
            placeholder="{{ trans.placeholder.typeTheLng | translate }}"
            formControlName="lng"
          />
          <mat-hint>
            <strong>{{ trans.text.latLng | translate }}</strong>
          </mat-hint>
          <mat-error>
            {{ getInputMessageError('lng', secondFormGroup.get('lng')?.errors) | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field
          fxFlex="100%"
          [style.display]="firstFormGroup.get('enableFarm')?.value !== true ? 'none' : 'block'"
          appearance="outline"
          [color]="'primary'"
        >
          <mat-label>{{ trans.label.harvestYear | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="startPicker"
            id="currentYear"
            name="currentYear"
            (dateInput)="chosenYearHandler($event.value, startPicker)"
            [min]="moment(applicationService.getServerDate().toDate().getFullYear(), 'YYYY')"
            formControlName="currentYear"
          />
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker
            #startPicker
            startView="multi-year"
            (yearSelected)="chosenYearHandler($event, startPicker)"
            panelClass="material-year-picker"
          >
          </mat-datepicker>
          <mat-error>
            {{
              getInputMessageError('currentYear', secondFormGroup.get('currentYear')?.errors)
                | translate
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <div
      class="material-login-action"
      fxLayout="column"
      fxLayout.gt-xs="row wrap"
      fxLayout.gt-md="row wrap"
    >
      <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
        <div fxFlex="100%" fxLayout="row">
          <button
            style="width: 100%"
            type="button"
            mat-flat-button
            color="primary"
            [disabled]="firstFormGroup.invalid && secondFormGroup.invalid"
            (click)="createCustomer()"
          >
            {{ trans.button.register | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
