import { createAction, props } from '@ngrx/store';
import { Customer, Farm, Point } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter Clientes
 */
export const GET_CUSTOMERS = createAction('[Route Optimization] Get Clients');
export const GET_CUSTOMERS_SUCCESS = createAction(
  '[Route Optimization] Get Clients Success',
  props<{ clients: Customer[] }>()
);
export const GET_CUSTOMERS_FAIL = createAction(
  '[Route Optimization] Get Clients Fail',
  props<{ error: any }>()
);

/**
 * Obter Fazendas
 */
export const GET_FARMS = createAction(
  '[Route Optimization] Get Farms',
  props<{ customerId: string }>()
);
export const GET_FARMS_SUCCESS = createAction(
  '[Route Optimization] Get Farms Success',
  props<{ farms: Farm[] }>()
);
export const GET_FARMS_FAIL = createAction(
  '[Route Optimization] Get Farms Fail',
  props<{ error: any }>()
);

/**
 * Calcular Rota
 */
export const CALCULATE_ROUTE = createAction(
  '[Route Optimization] Calculate Route',
  props<{ startPoint: Point; homePoint: Point; farmsPoints: Point[] }>()
);

export const CALCULATE_ROUTE_SUCCESS = createAction(
  '[Route Optimization] Calculate Route Success',
  props<{
    routeSegments: Array<Array<{ lat: number; lng: number }>>;
    optimizedOrder: Point[];
    durations: number[]; // Durações individuais
  }>()
);

export const CALCULATE_ROUTE_FAIL = createAction(
  '[Route Optimization] Calculate Route Fail',
  props<{ error: any }>()
);
