/**
 * NOTE - Comentários e Refatoração de código 19/05/2021
 */

import * as harvestFarmAction from './harvest-form.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ApplicationService } from '../../../services/application.service';
import { GenericFirestoreService } from '../../../services/generic-firestore.service';
import { GenericHttpService } from '../../../services/generic-http.service';
import { MyToastrService } from '../../../services/toastr.service';
import { TranslateTypes } from '../../../services/translation.service';
import { CreateHarvestForm } from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { TranslateService } from '@ngx-translate/core';
import { Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Injectable()
export class HarvestFormEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFireStoreService: GenericFirestoreService,
    private applicationService: ApplicationService,
    private genericHttpService: GenericHttpService,
    private labelPipe: LabelPipe,
    private translateService: TranslateService,

    public trans: TranslateTypes
  ) {}

  /**
   * Este método retorna uma lista de safras.
   */
  public getHarvests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(harvestFarmAction.GET_HARVESTS),
      switchMap(() =>
        this.genericFireStoreService
          .getAll<Harvest>(`${this.applicationService.getFarmRefDatabase()}/harvests`)
          .pipe(
            map((harvests: Array<Harvest>) => harvestFarmAction.GET_HARVESTS_SUCCESS({ harvests })),
            catchError((error) => of(harvestFarmAction.GET_HARVESTS_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para cadastrar safra.
   */
  public createHarvest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(harvestFarmAction.CREATE_HARVEST),
      mergeMap((action) => {
        return this.genericHttpService
          .create<CreateHarvestForm>('createHarvest', action.harvest)
          .toPromise()
          .then(() => {
            this.toastrService.success(
              this.labelPipe.transform(this.trans.services.create.harvestSuccess)
            );
            return harvestFarmAction.CREATE_HARVEST_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              this.labelPipe.transform(
                this.translateService.instant(
                  `${
                    error.code !== undefined
                      ? error.code
                      : error.error.code !== undefined
                      ? error.error.code
                      : error.error.internalCode
                  }`
                )
              )
            );
            return harvestFarmAction.CREATE_HARVEST_FAIL(error);
          });
      })
    )
  );
}
