import { components } from './pt/components';
import { enums } from './pt/enums';
import { others } from './pt/others';
import { services } from './pt/services';
import { text } from './pt/text';
import { title } from './pt/title';
import { firebase } from './pt/firebase';
import { cookies } from './pt/cookies';

export default {
  text,
  enums,
  title,
  services,
  cookies,
  ...components,
  ...firebase,
  ...others,
} as any;
