<mat-dialog-content
  class="material-dialog-content"
  fxLayout="column"
  fxLayoutAlign="space-around center"
>
  <h2>
    {{ data.title }}
  </h2>
  <p class="material-dialog-text" fxLayout="column" fxLayoutAlign="space-around center">
    {{ data.text }}
  </p>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between stretch">
  <button mat-button [mat-dialog-close]="">
    {{ data.rejectionText }}
  </button>
  <button mat-flat-button (click)="data.fn()" cdkFocusInitial>{{ data.confirmText }}</button>
</mat-dialog-actions>
