<app-progress-bar [isOpen]="state.loadingInfestationEvolutionChart"></app-progress-bar>

<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 mat-dialog-title>
    {{ trans.title.infestationEvolution | translate }}
    {{ state.infestationEvolutionChart ? '(' + state.infestationEvolutionChart?.name + ')' : '' }}
    <button
      class="custom-button-help"
      mat-mini-fab
      color="primary"
      matTooltipClass="custom-tooltip-help"
      [matTooltip]="trans.text.helpInfestationEvolutionChart['TRAP'] | translate"
    >
      ?
    </button>
  </h2>

  <button
    mat-icon-button
    autofocus="false"
    class="custom-button-close"
    (click)="closeModal()"
    tabindex="-1"
  >
    <i class="las la-times"></i>
  </button>
</div>

<mat-dialog-content style="height: 75vh; overflow: hidden">
  <app-infestation-evolution-chart
    *ngIf="
      state.infestationEvolutionChart && state.infestationEvolutionChart.controlLevels.length > 0
    "
    [data]="state.infestationEvolutionChart"
  ></app-infestation-evolution-chart>
  <app-empty-states-box
    *ngIf="
      state.loadingInfestationEvolutionChart <= 0 &&
      state.infestationEvolutionChart &&
      state.infestationEvolutionChart.controlLevels.length <= 0
    "
    [iconSize]="'62px'"
    [messageSize]="'22px'"
    [messagePadding]="'45px 20px'"
    [icon]="'la-chart-line'"
    [message]="trans.text.chartNotFound"
  ></app-empty-states-box>
</mat-dialog-content>
