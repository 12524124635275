import { XYChart } from '@amcharts/amcharts4/.internal/charts/types/XYChart';
import { Component, Input, NgZone, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseGraph } from '../../../utils/base-graph/base-graph';
import { AxisRenderer, LineSeries, ValueAxis } from '@amcharts/amcharts4/charts';
import { ClimateChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { ApplicationService } from 'src/app/services/application.service';
import { ClimateData } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/ClimateChart';

@Component({
  selector: 'app-climate-chart',
  templateUrl: './climate-chart.component.html',
  styleUrls: ['./climate-chart.component.scss'],
})
export class ClimateChartComponent extends BaseGraph implements OnDestroy {
  @Input() public set data(data: Array<ClimateChart>) {
    this.climateChart = data;
    this.initChart();
  }

  @Input() public set ticketId(ticketId: string) {
    this.ticketId = ticketId;
  }

  public climateChart!: Array<ClimateChart>;

  private chart!: XYChart;

  constructor(
    public anotherTranslateService: TranslateService,
    public trans: TranslateTypes,
    public zone: NgZone,
    public applicationService: ApplicationService
  ) {
    super(anotherTranslateService, applicationService);
  }

  public ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  public initChart(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }

      // Criar gráfico
      this.createXYChart('climateChart', this.am4charts.XYChart);

      // Criar DateAxis no eixo x
      this.createDateAxis();

      // Criar ValueAxis no eixo y
      const temperatureAxis = this.createTemperatureAxis();
      const humidityAxis = this.createHumidityAxis();

      // Habilitar scrollbar
      // this.addBasicXYChartScrollbarX();

      // Adicionar legenda
      this.addLegend();

      // Process Data
      // const processedData = this.processData(mockData);
      const processedData = this.processData(this.climateChart);

      // Create Chart
      this.createChart(processedData, temperatureAxis, humidityAxis);

      this.xYChart.cursor.disabled = true;
    });
  }

  public createDateAxis() {
    const dateAxis = this.createBasicXYChartDateAxis();
    dateAxis.title.text = 'Data';
    dateAxis.title.fontSize = '16px';
    dateAxis.title.fontWeight = 'bold';
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.startLocation = -3;
    dateAxis.endLocation = 12;
    dateAxis.baseInterval = {
      timeUnit: 'hour',
      count: 1,
    };
    dateAxis.gridIntervals.setAll([{ timeUnit: 'day', count: 1 }]);
    return dateAxis;
  }

  public createTemperatureAxis() {
    const temperatureAxis = this.createBasicXYChartValueAxis();
    temperatureAxis.min = 0;
    temperatureAxis.max = 50;
    temperatureAxis.strictMinMax = true;
    // temperatureAxis.renderer.labels.template.disabled = true;
    temperatureAxis.title.text = `${this.translateService.instant(
      this.trans.label.temperature
    )} (ºC)`;
    temperatureAxis.title.fontSize = '16px';
    temperatureAxis.title.fontWeight = 'bold';
    temperatureAxis.title.fill = this.am4core.color('#FF0000');
    temperatureAxis.renderer.labels.template.fill = this.am4core.color('#FF0000');
    temperatureAxis.maxPrecision = 0;
    return temperatureAxis;
  }

  public createHumidityAxis() {
    const humidityAxis = this.createBasicXYChartValueAxis();
    humidityAxis.min = 0;
    humidityAxis.max = 100;
    humidityAxis.strictMinMax = true;
    // humidityAxis.renderer.labels.template.disabled = true;
    humidityAxis.renderer.opposite = true;
    humidityAxis.title.text = `${this.translateService.instant(this.trans.label.humidity)} (%)`;
    humidityAxis.title.rotation = -90;
    humidityAxis.title.fontSize = '16px';
    humidityAxis.title.fontWeight = 'bold';
    humidityAxis.title.fill = this.am4core.color('#0000FF');
    humidityAxis.renderer.labels.template.fill = this.am4core.color('#0000FF');
    humidityAxis.maxPrecision = 0;

    // humidityAxis.syncWithAxis = temperatureAxis;
    return humidityAxis;
  }

  public addLegend(): void {
    this.xYChart.legend = new this.am4charts.Legend();
    this.xYChart.legend.position = 'top';
    this.xYChart.legend.labels.template.fontSize = 13;
    this.xYChart.legend.labels.template.text = '{name}';
    this.xYChart.legend.maxHeight = 100;
    this.xYChart.legend.useDefaultMarker = true;
    this.xYChart.legend.paddingBottom = 12;

    this.xYChart.legend.itemContainers._template.paddingTop = 0;
    this.xYChart.legend.itemContainers._template.paddingBottom = 0;

    const marker = this.xYChart.legend.markers.template.children.getIndex(0);
    if (marker) {
      marker.width = 15;
      marker.height = 15;
      marker.paddingTop = 2;
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;
      marker.stroke = this.am4core.color('#ccc');
    }
  }

  public createChart(
    data: { [key: string]: Array<ClimateData> },
    temperatureAxis: ValueAxis<AxisRenderer>,
    humidityAxis: ValueAxis<AxisRenderer>
  ) {
    const timeDelta = 3 * 60 * 60 * 1000; // 3h between values
    const ticketIds = Object.keys(data);
    for (const ticketId of ticketIds) {
      const trapData = data[ticketId];
      const temperatureData = [];
      const humidityData = [];
      for (let msgCount = 0; msgCount < trapData.length; msgCount++) {
        const msg = trapData[msgCount];
        const timestamp = new Date(msg.timestamp);
        const dateTime = timestamp.getTime();
        for (let i = 3; i >= 0; i--) {
          const date = new Date(dateTime - i * timeDelta);
          if (!(msg.temperature[i] == 0 && msg.humidity[i] == 0)) {
            temperatureData.push({
              date,
              name: `temperature`,
              value: msg.temperature[i],
              unit: 'ºC',
            });
            humidityData.push({
              date,
              name: `humidity`,
              value: msg.humidity[i],
              unit: '%',
            });
          }
        }
      }
      this.createTrendLine(temperatureData, temperatureAxis, false, true, '#FF0000', null);
      this.createTrendLine(humidityData, humidityAxis, false, true, '#0000FF', null);
    }
    this.chart = this.xYChart;
  }

  /**
   * Este método gera as linhas do gráfico
   * @param data conteúdo do gráfico
   * @param randomColor gerar cor automático
   */
  public createTrendLine(
    data: any,
    axis: any,
    randomColor: boolean,
    hasCircleBullet: boolean,
    color: string | null,
    fillOpacity: number | null
  ): LineSeries {
    const trend = this.xYChart.series.push(new this.am4charts.LineSeries());
    trend.dataFields.valueY = 'value';
    trend.dataFields.dateX = 'date';
    trend.name = this.translateService.instant(this.trans.label[data[0].name]);
    trend.strokeWidth = 2;
    trend.fillOpacity = fillOpacity != null ? fillOpacity : 0;

    if (!randomColor) {
      if (color) {
        trend.stroke = trend.fill = this.am4core.color(color.toString());
      } else if (this.xYChart.series.values.length > 1) {
        const lastColor =
          this.xYChart.series.values[this.xYChart.series.values.length - 1].stroke.toString();
        trend.stroke = trend.fill = this.am4core.color(lastColor);
      }
    }

    if (hasCircleBullet) {
      const bullet = trend.bullets.push(new this.am4charts.CircleBullet());
      bullet.strokeWidth = 0.5;
      // bullet.stroke = this.am4core.color('#fff');
      bullet.circle.fill = trend.stroke;

      const hoverState = bullet.states.create('hover');
      hoverState.properties.scale = 1.7;
    }

    trend.yAxis = axis;
    trend.data = data;
    return trend;
  }

  public processData(data: Array<ClimateChart>): { [key: string]: Array<ClimateData> } {
    return data.reduce((acc: any, cur: ClimateChart) => {
      if (acc[cur.ticketId]) acc[cur.ticketId].push(cur.data);
      else acc[cur.ticketId] = [cur.data];
      return acc;
    }, {});
  }
}

// const mockData: Array<ClimateChart> = [
//   {
//     ticketId: 'ISL03-MOCK01',
//     data: {
//       timestamp: '2024-09-11T09:00:00-0300',
//       humidity: [80, 60, 40, 50],
//       temperature: [40, 20, 30, 35],
//     },
//   },
//   {
//     ticketId: 'ISL03-MOCK01',
//     data: {
//       timestamp: '2024-09-12T09:00:00-0300',
//       humidity: [90, 80, 60, 50],
//       temperature: [35, 25, 34, 32],
//     },
//   },
//   {
//     ticketId: 'ISL03-MOCK01',
//     data: {
//       timestamp: '2024-09-13T09:00:00-0300',
//       humidity: [40, 30, 40, 30],
//       temperature: [30, 20, 23, 18],
//     },
//   },
//   {
//     ticketId: 'ISL03-MOCK01',
//     data: {
//       timestamp: '2024-09-14T09:00:00-0300',
//       humidity: [95, 75, 55, 50],
//       temperature: [40, 38, 30, 35],
//     },
//   },
//   {
//     ticketId: 'ISL03-MOCK02',
//     data: {
//       timestamp: '2024-09-11T09:00:00-0300',
//       humidity: [80, 60, 40, 50],
//       temperature: [40, 20, 30, 35],
//     },
//   },
//   {
//     ticketId: 'ISL03-MOCK02',
//     data: {
//       timestamp: '2024-09-12T09:00:00-0300',
//       humidity: [20, 10, 5, 8],
//       temperature: [10, 5, 12, 18],
//     },
//   },
// ];
