import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TARVOS } from 'src/environments/environment';
import * as homeAction from './home.actions';

@Injectable()
export class HomeEffects {
  constructor(private actions$: Actions) {}

  /**
   * Este método chama um serviço para pagar os termos de uso
   */
  public getTermsOfUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(homeAction.GET_TERMS_OF_USE_URL),
      switchMap(() => {
        return of(
          homeAction.GET_TERMS_OF_USE_URL_SUCCESS({
            termsOfUseUrl: TARVOS.termsOfUse,
          })
        );
      })
    );
  });

  /**
   * Este método chama um serviço para pagar as politicas de privacidade
   */
  public getPrivacyPolicy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(homeAction.GET_PRIVACY_POLICY_URL),
      switchMap(() => {
        return of(
          homeAction.GET_PRIVACY_POLICY_URL_SUCCESS({
            privacyPolicyUrl: TARVOS.privacyPolicy,
          })
        );
      })
    );
  });
}
