import * as _ from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  CREATE_GLEBE,
  GET_CROPS,
  GET_GLEBES,
  GET_HIGHEST_NUMBER_REGISTERED,
  HIDE_MODAL,
} from 'src/app/view/import-kmz/define-field-form/define-field-form.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { MATERIAL_DATE_MONTH } from 'src/app/constants/mask';
import { regex, Regex } from 'src/app/constants/regex';
import { BaseForm } from '../../utils/base-form/base-form';
import {
  DefineFieldFormState,
  FirstForm,
  FormGroup,
  defineFieldFormStateDefault,
  firstFormGroup,
} from './define-field-form.state';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { HarvestIndex } from '@tarvos-ag/tarvos-firestore-models/src/utils/interfaces';
import { CreateGlebeForm } from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';

@Component({
  selector: 'app-define-field-form',
  templateUrl: './define-field-form.component.html',
  styleUrls: ['./define-field-form.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATE_MONTH },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class DefineFieldFormComponent extends BaseForm implements OnInit, OnDestroy {
  public defineFieldState$: Observable<DefineFieldFormState>;
  public state: DefineFieldFormState = defineFieldFormStateDefault;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public regex: Regex = regex;
  public isCreateGlebe = false;

  constructor(
    public formBuilder: FormBuilder,
    public store: Store<any>,
    public dialogRef: MatDialogRef<DefineFieldFormComponent>,
    public applicationService: ApplicationService,
    public dialog: MatDialog,
    public trans: TranslateTypes
  ) {
    super(store, dialogRef, formBuilder, trans);
    this.defineFieldState$ = this.store.pipe(select('defineFieldFormState'));
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    this.store.dispatch(GET_HIGHEST_NUMBER_REGISTERED());
    this.store.dispatch(GET_GLEBES());
    this.store.dispatch(GET_CROPS());
    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));
    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.subscribe = this.defineFieldState$.subscribe((defineFieldState: DefineFieldFormState) => {
      this.state = defineFieldState;
      if (defineFieldState.closeModal) {
        this.onCloseModal();
      }

      if (defineFieldState.lastCount >= 0) {
        this.generateUniqueName();
      }

      if (defineFieldState.createGlebeSuccess !== null) {
        this.dialogRef?.close(defineFieldState.createGlebeSuccess);
      }
    });
  }

  /**
   * Este método executa quando o componentes não é mais ativo
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método fecha uma modal
   */
  public onCloseModal(): void {
    this.closeModal(HIDE_MODAL(), true, false, true);
  }

  /**
   * Este método valida e cadastrar ou atualiza
   */
  public onFormClick(): void {
    if (this.firstFormGroup.invalid) {
      this.checkValidateForms(this.firstFormGroup);
    } else {
      const form: FirstForm = this.firstFormGroup.getRawValue();
      if (this.isCreateGlebe) {
        const glebeIndex: HarvestIndex = {
          customerId: this.applicationService.getCustomerId(),
          farmId: this.applicationService.getFarmId(),
          harvestId: this.applicationService.getHarvestId(),
        };
        let action;
        const glebeData = this.generatesCreateGlebeForm();
        action = CREATE_GLEBE({
          glebe: {
            ...glebeIndex,
            glebeData,
          },
        });
        this.onSubmit(action);
      } else {
        this.dialogRef?.close(form.selectedGlebe);
      }
    }
  }

  /**
   * Este método define o nome de uma cultura
   * @param cropId Nome da cultura
   */
  public onSelectionChangeCrop(cropId: string): void {
    const crop = this.state.crops.filter((item) => item.id === cropId)[0];
    if (crop.name) {
      this.firstFormGroup.get('glebe')?.get('cropName')?.setValue(crop.name);
    }
  }

  /**
   * Este método gera o nome da gleba
   */
  public generateUniqueName(): void {
    this.firstFormGroup
      .get('glebe')
      ?.get('name')
      ?.setValue(`G${this.state.lastCount + 1}`);
  }

  private generatesCreateGlebeForm(): CreateGlebeForm['glebeData'] {
    const form: FormGroup = this.firstFormGroup.getRawValue().glebe;
    const { id, ...glebeData } = form;
    return { ...glebeData };
  }
}
