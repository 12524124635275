import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisableControl]',
})
export class DisableControlDirective {
  @Input() set appDisableControl(condition: boolean | null | undefined) {
    const action = condition ? 'disable' : 'enable';
    setTimeout(() => {
      if (this.ngControl.control) {
        this.ngControl.control[action]();
      }
    });
  }

  constructor(private ngControl: NgControl) {}
}
