import { ApplicationService } from 'src/app/services/application.service';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { TranslateTypes } from 'src/app/services/translation.service';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { Subscription } from 'rxjs';
declare var require: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnDestroy {
  @Input() public sideNav!: MatSidenav;
  @Input() public termsOfUseUrl!: string;
  @Input() public privacyPolicyUrl!: string;
  public user!: any;
  public hasMad: boolean;
  public version = require('../../../../package.json').version;
  public step = [0, 0];
  public glebeTitle!: string;
  public fieldTitle!: string;
  public subscribe!: Subscription;

  constructor(
    private router: Router,
    public trans: TranslateTypes,
    private labelPipe: LabelPipe,
    public applicationService: ApplicationService
  ) {
    const routerStateSnapshot = this.router.routerState.snapshot;
    this.user = this.applicationService.getUser();
    console.log(this.user.role);
    this.hasMad = this.applicationService.hasMad();

    this.glebeTitle = this.labelPipe.transform(this.trans.label.glebeIds);
    this.fieldTitle = this.labelPipe.transform(this.trans.label.fieldIds);

    this.subscribe = this.applicationService.getUserObservable().subscribe((user) => {
      if (this.user.lastSelectedFarmId != user?.lastSelectedFarmId) {
        this.glebeTitle = this.labelPipe.transform(this.trans.label.glebeIds);
        this.fieldTitle = this.labelPipe.transform(this.trans.label.fieldIds);
      }
      this.user = user;
    });
    /**
     * Quando a aplicação for carregado este switch define qual nível é aberto
     */
    switch (routerStateSnapshot.url) {
      case '/home/user':
      case '/home/trap':
      case '/home/routeOptimization':
      case '/home/trapReport':
        this.step = [3, 0];
        break;
      case '/home/configuration/system':
      case '/home/configuration/occurrence':
        this.step = [4, 0];
        break;
      default:
        this.step = [0, 0];
        break;
    }
  }

  /**
   * Este método abre um dropdown e fecha o outro
   * @param index Posição do array
   * @param value Qual menu esta sendo aberto
   */
  public setStep(index: number, value: number): void {
    if (index > 0) {
      this.step[index - 1] = value;
    }
    this.step[index] = value;
  }
  /**
   * Este método é executado quando o componente é destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }
}
