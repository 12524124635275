import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  GET_REPORT_LINKED_TO_FIELD,
  GET_TRAP_LINKED_TO_FIELD,
  HIDE_MODAL,
} from 'src/app/view/field/reap-form/reap-form.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import {
  firstFormGroup,
  ReapFormState,
  reapFormStateDefault,
} from 'src/app/view/field/reap-form/reap-form.state';
import { BaseForm } from '../../utils/base-form/base-form';
import { UPDATE_HARVEST_FIELD } from './reap-form.actions';
import * as _ from 'lodash';

@Component({
  selector: 'app-reap',
  templateUrl: './reap-form.component.html',
  styleUrls: ['./reap-form.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class ReapFormComponent extends BaseForm implements OnInit, OnDestroy {
  public reapState$: Observable<ReapFormState>;
  public state: ReapFormState = reapFormStateDefault;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ReapFormComponent>,
    public dialog: MatDialog,
    public store: Store<any>,
    public applicationService: ApplicationService,
    public trans: TranslateTypes,

    @Inject(MAT_DIALOG_DATA) public data: { fieldId: string; fieldName: string }
  ) {
    super(store, dialogRef, formBuilder, trans);
    this.reapState$ = this.store.pipe(select('reapFormState'));
  }

  public ngOnInit(): void {
    if (this.data.fieldId) {
      this.store.dispatch(GET_TRAP_LINKED_TO_FIELD({ id: this.data.fieldId }));
      this.store.dispatch(GET_REPORT_LINKED_TO_FIELD({ id: this.data.fieldId }));
    }

    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));
    this.firstFormGroup.patchValue({
      id: this.data.fieldId,
      harvestDate: this.applicationService.getServerDate(),
    });

    this.subscribe = this.reapState$.subscribe((state: ReapFormState) => {
      this.state = state;

      if (state.closeModal) {
        this.onCloseModal();
      }
    });
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  public onCloseModal(): void {
    this.closeModal(HIDE_MODAL());
  }

  public onFormClick(): void {
    const action = UPDATE_HARVEST_FIELD({
      reap: this.firstFormGroup.getRawValue(),
      fieldName: this.data.fieldName,
    });
    this.onSubmit(action);
  }

  public onChangeFallowing(event: MatCheckboxChange): void {
    if (!event.checked) {
      this.firstFormGroup.get('fallowingUntil')?.setValue(null);
    }
  }
}
