import { Action, createReducer, on } from '@ngrx/store';
import { LoginState, loginStateDefault } from 'src/app/view/login/login.state';
import * as loginAction from './login.actions';

export const loginReducer = createReducer(
  loginStateDefault,
  /**
   * login
   */
  on(loginAction.LOGIN, (state) => ({ ...state, loading: state.loading + 1 })),
  on(loginAction.LOGIN_SUCCESS, (state, action) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
    user: action.user,
  })),
  on(loginAction.LOGIN_FAIL, (state) => ({
    ...state,
    user: null,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * Recuperar Senha
   */
  on(loginAction.RECOVER_PASSWORD, (state) => ({ ...state, loading: state.loading + 1 })),
  on(loginAction.RECOVER_PASSWORD_SUCCESS, (state) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(loginAction.RECOVER_PASSWORD_FAIL, (state) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(loginAction.CHECK_TOKEN, (state) => ({ ...state, loading: state.loading + 1 })),
  on(loginAction.CHECK_TOKEN_SUCCESS, (state, action) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
    oobCode: action.oobCode,
    decoded: action.decoded,
  })),
  on(loginAction.CHECK_TOKEN_FAIL, (state) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
    oobCode: '',
  })),

  on(loginAction.UPDATE_PASSWORD, (state) => ({ ...state, loading: state.loading + 1 })),
  on(loginAction.UPDATE_PASSWORD_SUCCESS, (state) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(loginAction.UPDATE_PASSWORD_FAIL, (state) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  }))
);

export function reducer(state: LoginState | undefined, action: Action): LoginState {
  return loginReducer(state, action);
}
