<!-- Loading -->
<app-loading [isOpen]="state.loadingModal ? 1 : 0"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="closeModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span class="title">{{ trans.title.editUser | translate }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <form class="custom-form-field" #form="ngForm">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <div fxFlex="100%">
        <div fxLayout="row" fxLayoutAlign="center center">
          <button
            mat-flat-button
            [matTooltip]="trans.text.changeProfilePhoto | translate"
            [matTooltipPosition]="'right'"
            type="button"
            (click)="changeImage()"
            class="material-button-image"
          >
            <input
              type="file"
              id="userImage"
              name="userImage"
              ngModel
              #userImage="ngModel"
              class="file-input"
              (change)="readerImage($event)"
            />
            <i
              *ngIf="!state?.userEdit?.image?.src && !state?.userEdit?.imageUrl"
              class="las la-user"
            ></i>
            <img
              *ngIf="!state?.userEdit?.image?.src && state?.userEdit?.imageUrl"
              [src]="state?.userEdit?.imageUrl"
              alt="image User"
            />
            <img
              *ngIf="state?.userEdit?.image?.src"
              [src]="state?.userEdit?.image?.src"
              alt="image User"
            />
          </button>
        </div>
      </div>
      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="name"
          name="name"
          [(ngModel)]="state.userEdit.name"
          #name="ngModel"
          placeholder="{{ trans.placeholder.name | translate }}"
          minlength="2"
          maxlength="64"
          appWhiteSpaceValidator
          required
          cdkFocusInitial
          [pattern]="regex.text"
        />
        <mat-error *ngIf="name.invalid && (name.dirty || name.touched)">
          <mat-error *ngIf="name.errors?.required">
            {{ trans.errors?.required.name | translate }}
          </mat-error>
          <mat-error *ngIf="name.errors?.minlength">
            {{ trans.errors?.minlength.name | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              name.errors?.pattern && !name.errors?.minlength && !name.errors?.whitespaceValidator
            "
          >
            {{ trans.errors?.minlength.name | translate }}
          </mat-error>
          <mat-error *ngIf="name.errors?.whitespaceValidator">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="email"
          id="email"
          name="email"
          #email="ngModel"
          [(ngModel)]="state.userEdit.email"
          placeholder="{{ trans.placeholder.typeTheEmail | translate }}"
          minlength="8"
          maxlength="64"
          required
          [disabled]="true"
          [pattern]="regex.email"
        />
        <mat-hint *ngIf="!data.edit"
          ><strong>{{ trans.text.emailExample | translate }}</strong>
        </mat-hint>
        <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
          <mat-error *ngIf="email.errors?.required">
            {{ trans.errors?.required.email | translate }}
          </mat-error>
          <mat-error *ngIf="email.errors?.minlength">
            {{ trans.errors?.minlength.email | translate }}
          </mat-error>
          <mat-error *ngIf="email.errors?.pattern && !email.errors?.minlength">
            {{ trans.errors?.pattern.email | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label>{{ trans.label.ccc | translate }}</mat-label>
        <mat-select
          #materialSelect
          id="ccc"
          name="ccc"
          #ccc="ngModel"
          [(ngModel)]="state.userEdit.ccc"
        >
          <mat-option [value]="null">
            {{ trans.text.clean | translate }}
          </mat-option>
          <mat-option *ngFor="let ccc of getEnumType()" [value]="ccc">
            <span
              *ngIf="ccc === enumCcc.brazil"
              class="material-flag-icon flag-icon flag-icon-br"
            ></span>
            <span
              *ngIf="ccc === enumCcc.paraguay"
              class="material-flag-icon flag-icon flag-icon-py"
            ></span>
            {{ trans.enums[ccc] | translate }} <strong>{{ ccc }}</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          matInput
          id="phone"
          name="phone"
          [mask]="state.userEdit.ccc === enumCcc.brazil ? phoneMask.brazil : phoneMask.paraguay"
          [disabled]="!state.userEdit.ccc"
          [(ngModel)]="state.userEdit.phone"
          #phone="ngModel"
          placeholder="{{ trans.placeholder.phone | translate }}"
          [required]="state.userEdit.ccc !== null"
        />
        <mat-hint *ngIf="state.userEdit.ccc">
          <strong>{{
            state.userEdit.ccc === enumCcc.brazil ? phoneMask.brazil : phoneMask.paraguay
          }}</strong>
        </mat-hint>
        <mat-error *ngIf="phone.invalid && (phone.dirty || phone.touched)">
          <mat-error
            *ngIf="
              state.userEdit.ccc === enumCcc.brazil &&
              !phone.errors?.Mask &&
              !phone.errors?.required
            "
          >
            {{ trans.errors?.mask.phone.brazil | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              state.userEdit.ccc === enumCcc.paraguay &&
              !phone.errors?.Mask &&
              !phone.errors?.required
            "
          >
            {{ trans.errors?.mask.phone.paraguay | translate }}
          </mat-error>
          <mat-error *ngIf="phone.errors?.required">
            {{ trans.errors?.required.phone | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label>{{ trans.label.role | translate }}</mat-label>
        <mat-select
          #materialSelect
          id="role"
          name="role"
          #role="ngModel"
          [(ngModel)]="state.userEdit.role"
          [disabled]="true"
          required
        >
          <div *ngFor="let role of getEnumRole()">
            <mat-option [value]="role">
              {{ trans.enums[role] | translate }}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="closeModal()">
    {{ trans.button.close | translate }}
  </button>
  <div>
    <button *ngIf="state.recoverPassword" mat-button color="primary" (click)="changePassword()">
      {{ trans.button.changePassword | translate }}
    </button>
    <button mat-button color="primary" [disabled]="form.invalid" (click)="update()">
      {{ trans.button.save | translate }}
    </button>
  </div>
</mat-dialog-actions>
