<!-- Loading -->
<app-loading [isOpen]="state.loadingModal || state.loading"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="onCloseModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span class="title">{{ trans.button.importField | label }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <form class="custom-form-field" [formGroup]="firstFormGroup">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <!-- field -->
      <mat-form-field fxFlex="100%">
        <mat-label>{{ trans.label.field | label }}</mat-label>
        <mat-select #materialSelect id="ids" name="ids" formControlName="ids" multiple>
          <mat-option
            class="material-select-option-message"
            *ngIf="getGlebes().length <= 0"
            [disabled]="true"
          >
            {{ trans.text.noImportedGlebe | label }}</mat-option
          >
          <mat-optgroup *ngFor="let item of getGlebes(); let i = index" [label]="item.name">
            <mat-option
              class="material-select-option-message"
              *ngIf="getFieldsByGlebe(item.id).length <= 0"
              [disabled]="true"
            >
              {{ trans.text.noHarvestedField | label }}</mat-option
            >
            <mat-option
              *ngFor="let field of getFieldsByGlebe(item.id)"
              [value]="field.id"
              [disabled]="field.fallowing || wasImportedField(field.id)"
            >
              {{ field.name }}
              <span *ngIf="field.alias && field.alias !== ''">( {{ field.alias }} )</span>

              <span
                *ngIf="field.fallowing"
                style="float: right"
                matTooltipClass="material-tooltip-class"
                [matTooltip]="trans.text.periodFallowing | translate"
                [matTooltipPosition]="'left'"
              >
                {{ moment(field.fallowingUntil).format(trans.text.date | translate) }}
              </span>
              <span
                *ngIf="
                  field.harvestDate &&
                  field.harvested &&
                  !field.fallowing &&
                  !wasImportedField(field.id)
                "
                style="float: right"
                matTooltipClass="material-tooltip-class"
                [matTooltip]="trans.text.harvestedDate | translate"
                [matTooltipPosition]="'left'"
              >
                {{ moment(field.harvestDate.toDate()).format(trans.text.date | translate) }}
              </span>
              <span style="float: right" *ngIf="wasImportedField(field.id)">{{
                trans.text.imported | translate
              }}</span>
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="onCloseModal()">{{ trans.button.close | translate }}</button>
  <div>
    <button mat-button color="primary" (click)="onFormClick()">
      {{ trans.button.save | translate }}
    </button>
  </div>
</mat-dialog-actions>
