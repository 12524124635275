import { GenericObject } from 'src/app/interfaces/GenericObject';

export const context: GenericObject<GenericObject<string>> = {
  FRUTAS: {
    farm: 'productiveUnit',
    glebe: 'productiveUnit',
    field: 'lot',
    harvest: 'year',
  },
  GRAOS: {
    farm: 'default',
    glebe: 'default',
    field: 'default',
    harvest: 'default',
  },
  TOMATE: {
    farm: 'default',
    glebe: 'default',
    field: 'default',
    harvest: 'default',
  },
  SILVICULTURA: {
    farm: 'default',
    glebe: 'productionUnit',
    field: 'default',
    harvest: 'cycle',
  },
};
