import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs/tab-group';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { GET_FIELDS, GET_GM, HIDE_GM_TABS } from 'src/app/view/gm-tabs/gm-tabs.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { MapMonitoring } from 'src/app/interfaces/Monitoring';
import { gmTabsDefault, GmTabsState } from './gm-tabs.state';

@Component({
  selector: 'app-gm-tabs',
  templateUrl: './gm-tabs.component.html',
  styleUrls: ['./gm-tabs.component.scss'],
})
export class GmTabsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() public closeGmTabsEvent: EventEmitter<any> = new EventEmitter<any>(true);
  @ViewChild('matTabGroup', { static: true }) public matTabGroup!: MatTabGroup;

  public mapMonitoringItem!: MapMonitoring;
  public gmTabsState$: Observable<GmTabsState>;
  public state: GmTabsState = gmTabsDefault;
  public subscribe!: Subscription;
  public selectedIndex = 0;

  @Input() public set setOptions(data: { mapMonitoringItem?: MapMonitoring }) {
    if (data.mapMonitoringItem) {
      this.mapMonitoringItem = data.mapMonitoringItem;
    }
  }

  constructor(
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    private store: Store<any>
  ) {
    this.gmTabsState$ = this.store.pipe(select('gmTabs'));
  }

  public ngOnInit(): void {
    this.store.dispatch(GET_GM());
    this.store.dispatch(GET_FIELDS());

    this.subscribe = this.gmTabsState$.subscribe((state: GmTabsState) => {
      this.state = state;
    });
  }

  public ngAfterViewInit(): void {
    this.matTabGroup.selectedIndexChange.subscribe((value: number) => {
      this.selectedIndex = value;
    });
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.store.dispatch(HIDE_GM_TABS());
    this.subscribe.unsubscribe();
  }
}
