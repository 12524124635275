<!-- Loading -->
<app-loading [isOpen]="loading ? 1 : 0" [excitedTime]="'0.2s'"></app-loading>
<agm-map
  #AgmMap
  [latitude]="convertToNumber(coordinates?.lat)"
  [longitude]="convertToNumber(coordinates?.lng)"
  [zoom]="zoom"
  [maxZoom]="20"
  [minZoom]="8"
  [gestureHandling]="'cooperative'"
  [mapTypeId]="mapTypeId"
  [ngStyle]="{ height: height }"
  [scrollwheel]="scrollwheel"
  [disableDoubleClickZoom]="true"
  [mapDraggable]="mapDraggable"
  [disableDefaultUI]="true"
  [backgroundColor]="'transparent'"
>
  <div *ngFor="let map of mapMonitoring">
    <!-- Polygon ( zIndex 1 ) -->

    <div
      *ngIf="
        (showFieldWithHighControlLevel && map.fieldMonitoring.borderColor === 'DANGER') ||
        !showFieldWithHighControlLevel
      "
    >
      <agm-polygon
        [fillOpacity]="0.2"
        [zIndex]="1"
        [fillColor]="'#ffffff'"
        [strokeColor]="
          fieldSelectedId === map.field.id
            ? '#02C0FA'
            : map.fieldMonitoring && map.fieldMonitoring.borderColor
            ? borderColor[map.fieldMonitoring.borderColor]
            : '#fafafa'
        "
        [strokeWeight]="4"
        [geodesic]="true"
        [paths]="map.field.polygon.points"
        (polyMouseOver)="mouseHoverPolygon(true, map.field.id)"
        (polyMouseOut)="mouseHoverPolygon(false)"
        (polyClick)="onFieldSelect(map)"
      >
        <agm-snazzy-info-window
          *ngIf="map.pointMonitoring.length > 0"
          [backgroundColor]="'#44546F'"
          [latitude]="load_polygon_centroid(map.field.polygon.points).lat"
          [longitude]="load_polygon_centroid(map.field.polygon.points).lng"
          [maxWidth]="180"
          [padding]="'0px'"
          [isOpen]="this.fieldEnabled === map.field.id"
          [border]="true"
          [shadow]="true"
          [showCloseButton]="false"
          [closeOnMapClick]="false"
        >
          <ng-template> {{ this.trans.text.monitoringTooltip | translate }} </ng-template>
        </agm-snazzy-info-window></agm-polygon
      >
    </div>

    <!-- Tracking ( zIndex 2 ) -->

    <div *ngIf="map.fieldMonitoring?.tracking">
      <agm-circle
        [latitude]="map.fieldMonitoring.tracking[0].lat"
        [longitude]="map.fieldMonitoring.tracking[0].lng"
        [zIndex]="2"
        [fillOpacity]="0.8"
        [radius]="1"
        [fillColor]="'#2bcade'"
        [strokeColor]="'#2bcade'"
      >
      </agm-circle>
      <agm-circle
        [latitude]="map.fieldMonitoring.tracking[map.fieldMonitoring.tracking.length - 1].lat"
        [fillColor]="'#2bcade'"
        [longitude]="map.fieldMonitoring.tracking[map.fieldMonitoring.tracking.length - 1].lng"
        [zIndex]="4"
        [fillOpacity]="0.8"
        [radius]="1"
      >
      </agm-circle>
      <agm-polyline [zIndex]="3" [strokeColor]="'#2bcade'" [strokeOpacity]="0.8">
        <agm-polyline-point
          *ngFor="let m of map.fieldMonitoring.tracking; let i = index"
          [latitude]="m.lat"
          [longitude]="m.lng"
        >
        </agm-polyline-point>
      </agm-polyline>
    </div>

    <!-- Marker ( zIndex 3 ) -->

    <div
      *ngIf="
        (showFieldWithHighControlLevel && map.fieldMonitoring.borderColor === 'DANGER') ||
        (!showFieldWithHighControlLevel && !hideCollectionPoint)
      "
    >
      <agm-marker
        *ngFor="let point of map.pointMonitoring; let i = index"
        [latitude]="point.lat"
        [longitude]="point.lng"
        [zIndex]="3"
        [iconUrl]="point.icon"
      >
      </agm-marker>
    </div>
  </div>
</agm-map>
