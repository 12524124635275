import { createAction, props } from '@ngrx/store';
import { ImportKmz } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_LIST_KMZ = createAction('[Import Kmz Page] GET_LIST_KMZ');
export const GET_LIST_KMZ_SUCCESS = createAction(
  '[Import Kmz Page] GET_LIST_KMZ_SUCCESS',
  props<{ kmz: Array<ImportKmz> }>()
);
export const GET_LIST_KMZ_FAIL = createAction(
  '[Import Kmz Page] GET_LIST_KMZ_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const DESTROY_COMPONENT = createAction('[Import Kmz Page] DESTROY_COMPONENT');
