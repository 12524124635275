import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import {
  HarvestFormState,
  harvestFormStateDefault,
} from 'src/app/view/harvest/harvest-form/harvest-form.state';
import * as harvestFormAction from './harvest-form.actions';

export const harvestFormReducer = createReducer(
  _.cloneDeep(harvestFormStateDefault),

  /**
   * GET
   */
  on(harvestFormAction.GET_HARVESTS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(harvestFormAction.GET_HARVESTS_SUCCESS, (state, action) => ({
    ...state,
    harvests: action.harvests,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(harvestFormAction.GET_HARVESTS_FAIL, (state) => ({
    ...state,
    harvests: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * CREATE
   */
  on(harvestFormAction.CREATE_HARVEST, (state) => ({
    ...state,
    loadingModal: state.loadingModal + 1,
  })),
  on(harvestFormAction.CREATE_HARVEST_SUCCESS, (state) => ({ ...state, closeModal: true })),
  on(harvestFormAction.CREATE_HARVEST_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * OTHER
   */
  on(harvestFormAction.HIDE_MODAL, (state) => ({ ..._.cloneDeep(harvestFormStateDefault) }))
);

export function reducer(state: HarvestFormState | undefined, action: Action): HarvestFormState {
  return harvestFormReducer(state, action);
}
