import { XYChart } from '@amcharts/amcharts4/.internal/charts/types/XYChart';
import { Component, Input, NgZone, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseGraph } from '../../../utils/base-graph/base-graph';
import { LineSeries } from '@amcharts/amcharts4/charts';
import { ApplicationService } from 'src/app/services/application.service';
import { chartColor } from 'src/app/constants/color';
import { UnitOfMeasureOfOccurrence } from '@tarvos-ag/tarvos-firestore-models/src/enums';
import {
  TotalBetweenFieldsChart,
  Data,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces/TotalBetweenFieldsChart';

@Component({
  selector: 'app-total-between-fields-chart',
  templateUrl: './total-between-fields-chart.component.html',
  styleUrls: ['./total-between-fields-chart.component.scss'],
})
export class TotalBetweenFieldsChartComponent extends BaseGraph implements OnDestroy {
  @Input() public set data(data: TotalBetweenFieldsChart | null) {
    this.totalBetweenFieldsChart = data;
    this.initChart();
  }

  public totalBetweenFieldsChart!: TotalBetweenFieldsChart | null;

  private chart!: XYChart;

  private colors = [
    '#006600',
    '#000066',
    '#999900',
    '#880088',
    '#008888',
    '#888888',
    '#BB2222',
    '#00CC00',
    '#0000CC',
    '#CC6633',
    '#CC00CC',
    '#00CCCC',
    '#CC9933',
    '#CCCCCC',
    '#333333',
    '#339933',
    '#333399',
    '#CCCC33',
    '#AA33AA',
    '#33AAAA',
    '#AAAAAA',
    '#CC6666',
    '#33CC33',
    '#3333CC',
    '#CC9966',
    '#CC66CC',
    '#40DA08',
    '#CCCC66',
    '#F01221',
    '#2222FF',
    '#66CCCC',
    '#FF9999',
    '#661166',
    '#8040DD',
    '#FF6622',
    '#DD22DA',
    '#50A053',
    '#AAAA50',
    '#FA6080',
    '#3672DD',
  ];

  constructor(
    public anotherTranslateService: TranslateService,
    public trans: TranslateTypes,
    public applicationService: ApplicationService,
    public zone: NgZone
  ) {
    super(anotherTranslateService, applicationService);
  }

  public ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  public initChart(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }

      // Criar gráfico
      this.createXYChart('TotalBetweenFieldsChart', this.am4charts.XYChart);

      // Criar DateAxis no eixo x
      this.createBasicXYChartDateAxis();

      // Criar ValueAxis no eixo y
      const valueAxis = this.createBasicXYChartValueAxis();
      valueAxis.maxPrecision = 0;

      if (this.totalBetweenFieldsChart?.unit === UnitOfMeasureOfOccurrence.boolean) {
        valueAxis.min = -2;
        valueAxis.max = 2;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.disabled = true;

        const range1 = this.addBasicXYChartAxisRanges(
          valueAxis,
          0,
          2,
          this.trans.label.presence,
          chartColor.red,
          chartColor.red
        );
        const range2 = this.addBasicXYChartAxisRanges(
          valueAxis,
          0,
          -2,
          this.trans.label.absence,
          chartColor.midGreen,
          chartColor.midGreen
        );

        if (this.applicationService.getIsDarkTheme()) {
          range1.axisFill.fillOpacity = 0.1;
          range2.axisFill.fillOpacity = 0.1;
        } else {
          range1.axisFill.fillOpacity = 0.2;
          range2.axisFill.fillOpacity = 0.2;
        }
      } else if (this.totalBetweenFieldsChart?.unit === UnitOfMeasureOfOccurrence.intensity) {
        valueAxis.min = -4;
        valueAxis.max = 4;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.disabled = true;

        // ausência  baixa media ala

        const range1 = this.addBasicXYChartAxisRanges(
          valueAxis,
          2,
          4,
          this.trans.label.high,
          chartColor.red,
          chartColor.red
        );
        const range2 = this.addBasicXYChartAxisRanges(
          valueAxis,
          0,
          2,
          this.trans.label.average,
          chartColor.yellow,
          chartColor.yellow
        );
        const range3 = this.addBasicXYChartAxisRanges(
          valueAxis,
          0,
          -2,
          this.trans.label.low,
          chartColor.midGreen,
          chartColor.midGreen
        );
        const range4 = this.addBasicXYChartAxisRanges(
          valueAxis,
          -2,
          -4,
          this.trans.label.absence,
          chartColor.lightGrey,
          chartColor.lightGrey
        );

        if (this.applicationService.getIsDarkTheme()) {
          range1.axisFill.fillOpacity = 0.1;
          range2.axisFill.fillOpacity = 0.1;
          range3.axisFill.fillOpacity = 0.1;
          range4.axisFill.fillOpacity = 0.1;
        } else {
          range1.axisFill.fillOpacity = 0.2;
          range2.axisFill.fillOpacity = 0.2;
          range3.axisFill.fillOpacity = 0.2;
          range4.axisFill.fillOpacity = 0.2;
        }
      } else {
        valueAxis.min = 0;
        valueAxis.renderer.labels.template.adapter.add(
          'text',
          (text) =>
            text +
            `${
              this.totalBetweenFieldsChart?.unit === UnitOfMeasureOfOccurrence.percentage ? '%' : ''
            }`
        );
      }

      // Habilitar scrollbar
      this.addBasicXYChartScrollbarX();

      // Adicionar legenda
      this.addBasicXYChartLegend();

      if (this.totalBetweenFieldsChart) {
        // Adiciona pontos ---- 0 -> ausência, 0.1 -> baixo, 1.5 -> médio, 1 -> auto
        if (this.totalBetweenFieldsChart?.data?.length > 0) {
          const data: any = this.totalBetweenFieldsChart.data.map((element: any) => {
            if (this.totalBetweenFieldsChart?.unit === UnitOfMeasureOfOccurrence.intensity) {
              element.map((element1: Data) => {
                element1.value =
                  element1.value === 0
                    ? -3
                    : element1.value === 0.1
                    ? -1
                    : element1.value === 0.5
                    ? 1
                    : element1.value === 1
                    ? 3
                    : element1.value;
              });
            } else if (this.totalBetweenFieldsChart?.unit === UnitOfMeasureOfOccurrence.boolean) {
              element.map((element1: Data) => {
                element1.value =
                  element1.value === 1 ? 1 : element1.value === 0 ? -1 : element1.value;
              });
            }

            return element;
          });

          const sortedData = data.sort((a: any, b: any) =>
            a[0].name.localeCompare(b[0].name, undefined, { numeric: true })
          );

          for (let i = 0; i < sortedData.length; i++)
            this.createTrendLine(sortedData[i], false, true, this.colors[i], null);
          if (sortedData.length > this.colors.length) {
            for (let i = this.colors.length; i < sortedData.length; i++)
              this.createTrendLine(sortedData[i], true, true, null, null);
          }
        }
      }

      this.chart = this.xYChart;
    });
  }

  /**
   * Este método gera as linhas do gráfico
   * @param data conteúdo do gráfico
   * @param randomColor gerar cor automático
   */
  public createTrendLine(
    data: any,
    randomColor: boolean,
    hasCircleBullet: boolean,
    color: string | null,
    fillOpacity: number | null
  ): LineSeries {
    const trend = this.xYChart.series.push(new this.am4charts.LineSeries());

    if (
      this.totalBetweenFieldsChart?.unit === UnitOfMeasureOfOccurrence.boolean ||
      this.totalBetweenFieldsChart?.unit === UnitOfMeasureOfOccurrence.intensity
    ) {
      trend.tooltipText = `[bold font-size: 17px]{name}`;
    } else {
      trend.tooltipText = `[bold font-size: 17px]{name}: {valueY}`;
    }

    trend.dataFields.valueY = 'value';
    trend.dataFields.dateX = 'date';
    trend.name = data[0].name;
    trend.strokeWidth = 3;
    trend.fillOpacity = fillOpacity != null ? fillOpacity : 0;
    trend.propertyFields.strokeOpacity = 'notHadStickyExchange';

    if (!randomColor) {
      if (color) {
        trend.stroke = trend.fill = this.am4core.color(color.toString());
      } else if (this.xYChart.series.values.length > 1) {
        const lastColor =
          this.xYChart.series.values[this.xYChart.series.values.length - 1].stroke.toString();
        trend.stroke = trend.fill = this.am4core.color(lastColor);
      }
    }

    if (hasCircleBullet) {
      const bullet = trend.bullets.push(new this.am4charts.CircleBullet());
      bullet.strokeWidth = 2;
      bullet.stroke = this.am4core.color('#fff');
      bullet.circle.fill = trend.stroke;

      const hoverState = bullet.states.create('hover');
      hoverState.properties.scale = 1.7;
    }

    trend.data = data;

    return trend;
  }
}
