import { FieldValue, Timestamp } from '@firebase/firestore-types';

import { Area } from './Area';
import { Metadata } from './Common';

export enum CustomerStatusType {
    paymentOk = 'PAYMENT_OK',
    paymentExpired = 'PAYMENT_EXPIRED',
}

export interface CustomerStatus {
    active: boolean;
    type: CustomerStatusType;
}

export interface Customer {
    farmsIds?: FieldValue;
    fieldAreaPaid: Area;
    fieldAreaUsed: Area;
    id: string;
    lastCheck: Timestamp;
    logo?: string;
    metadata: Metadata;
    name: string;
    status: CustomerStatus;
    validUntil: Timestamp;
}

export type DbCustomerPk = Pick<Customer, 'name'>;

export type DbCustomerFields = Partial<Omit<Customer, 'id'>>;
