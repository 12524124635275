import { Action, createReducer, on } from '@ngrx/store';
import { WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import * as _ from 'lodash';
import { HeaderState, headerStateDefault } from 'src/app/view/header/header.state';
import * as headerAction from './header.actions';

export const headerReducer = createReducer(
  headerStateDefault,
  /**
   * GET
   */
  on(headerAction.GET_USER, (state) => ({ ...state, loading: true })),
  on(headerAction.GET_USER_SUCCESS, (state, action) => ({ ...state, user: action.user })),
  on(headerAction.GET_USER_FAIL, (state) => ({ ...state, user: {} as WebUser, loading: false })),

  on(headerAction.GET_NOTIFICATION, (state) => ({ ...state, loading: true })),
  on(headerAction.GET_NOTIFICATION_SUCCESS, (state, action) => ({
    ...state,
    loading: false,
    notifications: action.notifications,
  })),
  on(headerAction.GET_NOTIFICATION_FAIL, (state) => ({
    ...state,
    notifications: [],
    loading: false,
  })),

  on(headerAction.GET_FARMS, (state) => ({ ...state })),
  on(headerAction.GET_FARMS_SUCCESS, (state, action) => ({ ...state, farms: action.farms })),
  on(headerAction.GET_FARMS_FAIL, (state) => ({ ...state, farms: [] })),

  on(headerAction.GET_HARVESTS, (state) => ({ ...state })),
  on(headerAction.GET_HARVESTS_SUCCESS, (state, action) => ({
    ...state,
    harvests: action.harvests,
  })),
  on(headerAction.GET_HARVESTS_FAIL, (state) => ({ ...state, harvests: [] })),

  /**
   * UPDATE
   */
  on(headerAction.LOAD_USER_UPDATE, (state, action) => ({
    ...state,
    userEdit: _.cloneDeep(action.user),
  })),
  on(headerAction.UPDATE_USER, (state) => ({ ...state })),
  on(headerAction.UPDATE_USER_SUCCESS, (state) => ({ ...state, closeModal: true })),
  on(headerAction.UPDATE_USER_FAIL, (state) => ({ ...state })),

  on(headerAction.UPDATE_THEME, (state) => ({ ...state })),
  on(headerAction.UPDATE_THEME_SUCCESS, (state) => ({ ...state })),
  on(headerAction.UPDATE_THEME_FAIL, (state) => ({ ...state })),

  on(headerAction.UPDATE_USER_CUSTOMER_SELECTED, (state) => ({ ...state })),
  on(headerAction.UPDATE_USER_CUSTOMER_SELECTED_SUCCESS, (state) => ({ ...state })),
  on(headerAction.UPDATE_USER_CUSTOMER_SELECTED_FAIL, (state) => ({ ...state })),

  on(headerAction.UPDATE_USER_FARM_AND_HARVEST_SELECTED, (state) => ({ ...state })),
  on(headerAction.UPDATE_USER_FARM_AND_HARVEST_SELECTED_SUCCESS, (state) => ({ ...state })),
  on(headerAction.UPDATE_USER_FARM_AND_HARVEST_SELECTED_FAIL, (state) => ({ ...state })),

  on(headerAction.UPDATE_USER_PASSWORD, (state) => ({ ...state, loadingModal: true })),
  on(headerAction.UPDATE_USER_PASSWORD_SUCCESS, (state) => ({
    ...state,
    recoverPassword: false,
    loadingModal: false,
  })),
  on(headerAction.UPDATE_USER_PASSWORD_FAIL, (state) => ({ ...state, loadingModal: false })),

  on(headerAction.UPDATE_GM, (state) => ({ ...state })),
  on(headerAction.UPDATE_GM_SUCCESS, (state) => ({ ...state })),
  on(headerAction.UPDATE_GM_FAIL, (state) => ({ ...state })),

  /**
   * REMOVE
   */
  on(headerAction.CLEAN_NOTIFICATIONS, (state) => ({ ...state, loading: true })),
  on(headerAction.CLEAN_NOTIFICATIONS_SUCCESS, (state) => ({ ...state, loading: false })),
  on(headerAction.CLEAN_NOTIFICATIONS_FAIL, (state) => ({ ...state, loading: false })),

  /**
   * OTHER
   */
  on(headerAction.HIDE_MODAL, (state) => ({
    ...state,
    loadingModal: false,
    closeModal: headerStateDefault.closeModal,
    userEdit: headerStateDefault.userEdit,
    recoverPassword: true,
  }))
);

export function reducer(state: HeaderState | undefined, action: Action): HeaderState {
  return headerReducer(state, action);
}
