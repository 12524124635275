import { Action, createReducer, on } from '@ngrx/store';
import { importKmzStateDefault } from 'src/app/view/import-kmz/import-kmz.state';
import { ImportKmzState } from './import-kmz.state';
import * as importKmzAction from './import-kmz.actions';
import * as _ from 'lodash';

export const importKmzReducer = createReducer(
  _.cloneDeep(importKmzStateDefault),
  /**
   * GET
   */
  on(importKmzAction.GET_LIST_KMZ, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(importKmzAction.GET_LIST_KMZ_SUCCESS, (state, action) => ({
    ...state,
    kmz: action.kmz,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(importKmzAction.GET_LIST_KMZ_FAIL, (state) => ({
    ...state,
    kmz: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * OTHER
   */
  on(importKmzAction.DESTROY_COMPONENT, (state) => ({
    ..._.cloneDeep(importKmzStateDefault),
  }))
);

export function reducer(state: ImportKmzState | undefined, action: Action): ImportKmzState {
  return importKmzReducer(state, action);
}
