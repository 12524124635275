import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RouteOptimizationActions from './route-optimization.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Customer, Farm, Point } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Injectable()
export class RouteOptimizationEffects {
  private BASE_URL = 'https://internotarvos.pro/api/routeOptimization';

  constructor(private actions$: Actions, private http: HttpClient) {}

  /**
   * Obter Clientes
   */
  getClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteOptimizationActions.GET_CUSTOMERS),
      mergeMap(() =>
        this.http.get<{ users: Customer[] }>(`${this.BASE_URL}/clients`).pipe(
          map((response) =>
            RouteOptimizationActions.GET_CUSTOMERS_SUCCESS({ clients: response.users })
          ),
          catchError((error) => of(RouteOptimizationActions.GET_CUSTOMERS_FAIL({ error })))
        )
      )
    )
  );

  /**
   * Obter Fazendas
   */
  getFarms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteOptimizationActions.GET_FARMS),
      mergeMap((action) =>
        this.http.post<Farm[]>(`${this.BASE_URL}/farms`, { customer_id: action.customerId }).pipe(
          map((farms) => RouteOptimizationActions.GET_FARMS_SUCCESS({ farms })),
          catchError((error) => of(RouteOptimizationActions.GET_FARMS_FAIL({ error })))
        )
      )
    )
  );

  /**
   * Calcular Rota
   */
  calculateRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouteOptimizationActions.CALCULATE_ROUTE),
      mergeMap((action) =>
        this.http
          .post<{
            optimized_order: Point[];
            route: Array<Array<{ lat: number; lng: number }>>;
            durations: number[];
          }>(`${this.BASE_URL}/calculate_route`, {
            start_point: action.startPoint,
            home_point: action.homePoint,
            farms_points: action.farmsPoints,
          })
          .pipe(
            map((response) =>
              RouteOptimizationActions.CALCULATE_ROUTE_SUCCESS({
                routeSegments: response.route,
                optimizedOrder: response.optimized_order,
                durations: response.durations,
              })
            ),
            catchError((error) => of(RouteOptimizationActions.CALCULATE_ROUTE_FAIL({ error })))
          )
      )
    )
  );
}
