import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { TARVOS } from './../../../../environments/environment';
import { CustomerStatusType } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Customer';

@Component({
  selector: 'app-blocked-application-modal',
  templateUrl: './blocked-application-modal.component.html',
  styleUrls: ['./blocked-application-modal.component.scss'],
})
export class BlockedApplicationModalComponent {
  public type: any = CustomerStatusType;
  public tarvos: any = TARVOS;

  constructor(
    public loginService: LoginService,
    public trans: TranslateTypes,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  /**
   * Este método finaliza a sessão do usuário
   */
  public logout(): void {
    this.loginService.signOutFirebase();
  }
}
