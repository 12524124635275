<app-loading [isOpen]="this.state.loading"></app-loading>
<div class="page">
  <app-header class="app-header"></app-header>
  <div class="form-container">
    <h3>{{ trans.title.updateUserCustomers | translate }}</h3>
    <mat-form-field>
      <mat-label>{{ trans.label.customerName | translate }}</mat-label>
      <mat-select (selectionChange)="onCustomerSelectionChange($event)">
        <mat-option *ngFor="let customer of state.customers" [value]="customer">
          {{ customer.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ trans.label.farm.default | translate }}</mat-label>
      <mat-select multiple [(value)]="state.selectedFarms">
        <mat-option *ngFor="let farm of state.farms" [value]="farm.id">
          {{ farm.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      mat-flat-button
      [disabled]="!state.selectedCustomerId || state.selectedFarms.length === 0"
      (click)="openDialog()"
      color="primary"
    >
      {{ trans.label.confirm | translate }}
    </button>
  </div>
</div>
