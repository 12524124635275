import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { CHECK_TOKEN, UPDATE_PASSWORD } from 'src/app/view/login/login.actions';
import { TranslateTypes } from 'src/app/services/translation.service';
import { LoginState, loginStateDefault } from '../login/login.state';
import { ApplicationService } from 'src/app/services/application.service';
import { BaseForm } from '../utils/base-form/base-form';
import { regex } from 'src/app/constants/regex';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';

const firstFormGroup = {
  password: [
    null,
    [Validators.required, Validators.pattern(regex.password), Validators.minLength(8)],
  ],
  // confirmPassword: [null, [Validators.required, Validators.pattern(regex.password), Validators.minLength(8)]],
} as any;

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class UpdatePasswordComponent extends BaseForm implements OnInit, OnDestroy {
  public loginState$: Observable<LoginState>;
  public subscribe!: Subscription;
  public state: LoginState = loginStateDefault;
  public hide = true;

  constructor(
    private store: Store<any>,
    public trans: TranslateTypes,
    public applicationService: ApplicationService,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    super(store, null, formBuilder, trans);

    this.loginState$ = this.store.pipe(select('login'));
    this.subscribe = this.loginState$.subscribe((state: LoginState) => {
      this.state = state;
    });

    this.verifyPasswordResetCode();
  }

  /**
   * Este método é executado quando o componente e inicializado
   */
  public ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método dispara uma ação para verificar se o token é valido
   */
  verifyPasswordResetCode() {
    const { token } = this.route.snapshot.queryParams;
    this.store.dispatch(CHECK_TOKEN({ token }));
  }

  /**
   * Método onde envia um email para atualizar a senha
   */
  public updatePassword(): void {
    if (this.firstFormGroup.invalid) {
      this.checkValidateForms(this.firstFormGroup);
    } else {
      const { password } = this.firstFormGroup.getRawValue();
      const { oobCode, decoded } = this.state;
      const action = UPDATE_PASSWORD({ oobCode, decoded, password });
      this.onSubmit(action);
    }
  }
}
