<!-- Loading -->
<app-loading [isOpen]="state.loadingModal || state.loading"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="onCloseModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span class="title">{{ trans.button.importGlebe | label }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <form class="custom-form-field" [formGroup]="firstFormGroup">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <!-- glebe -->
      <mat-form-field fxFlex="100%">
        <mat-label>{{ trans.label.glebe | label }}</mat-label>
        <mat-select #materialSelect id="ids" name="ids" formControlName="ids" multiple>
          <mat-option
            class="material-select-option-message"
            *ngIf="getHarvests().length <= 0"
            [disabled]="true"
          >
            {{ trans.text.noHarvestedGlebe | label }}</mat-option
          >
          <mat-optgroup *ngFor="let item of getHarvests(); let i = index" [label]="item.name">
            <mat-option
              class="material-select-option-message"
              *ngIf="getGlebesByHarvest(item.id).length <= 0"
              [disabled]="true"
            >
              {{ trans.text.noHarvestedGlebe | label }}</mat-option
            >
            <mat-option
              *ngFor="let glebe of getGlebesByHarvest(item.id)"
              [value]="glebe.id"
              [disabled]="wasImportedGlebe(glebe.id)"
            >
              {{ glebe.name }}
              <span *ngIf="glebe.alias && glebe.alias !== ''">( {{ glebe.alias }} )</span>
              <span
                style="float: right"
                matTooltipClass="material-tooltip-class"
                [matTooltip]="trans.text.harvestedDate | translate"
                [matTooltipPosition]="'left'"
                *ngIf="glebe.harvested && !wasImportedGlebe(glebe.id)"
              >
                {{
                  glebe.finalHarvestDate
                    ? moment(glebe.finalHarvestDate.toDate()).format(trans.text.date | translate)
                    : null
                }}
              </span>
              <span style="float: right" *ngIf="wasImportedGlebe(glebe.id)">{{
                trans.text.imported | translate
              }}</span>
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="onCloseModal()">{{ trans.button.close | translate }}</button>
  <div>
    <button mat-button color="primary" (click)="onFormClick()">
      {{ trans.button.save | translate }}
    </button>
  </div>
</mat-dialog-actions>
