import { Customer } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export interface CustomerRouteOptimizationState {
  customers: Customer[];
  loading: boolean;
  error: any;
}

export const initialCustomerState: CustomerRouteOptimizationState = {
  customers: [],
  loading: false,
  error: null,
};
