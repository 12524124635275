import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgcContentOptions, NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { ApplicationService } from './services/application.service';
import { Theme } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class') componentCssClass: any;
  public subscribeDataSharing!: Subscription;

  constructor(
    public translateService: TranslateService,
    public overlayContainer: OverlayContainer,
    public applicationService: ApplicationService,
    private ccService: NgcCookieConsentService
  ) {
    // Define o idioma da aplicação
    translateService.setDefaultLang('pt');
    translateService.use('pt');

    if (this.applicationService.getIsDarkTheme()) {
      this.setApplicationTheme(Theme.dark);
    }

    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === AppComponent.name) {
        this.setApplicationTheme(
          this.applicationService.getIsDarkTheme() ? Theme.dark : Theme.default
        );
      }
    });
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  ngOnInit(): void {
    this.translateService
      .get([
        'cookies.dismiss',
        'cookies.message',
        'cookies.privacyPolicyLink',
        'cookies.cookiePolicyLink',
        'cookies.termsServiceLink',
        'cookies.andOur',
      ])
      .subscribe((data) => {
        const content: NgcContentOptions = {
          dismiss: data['cookies.dismiss'],
          message: data['cookies.message'],
          privacyPolicyLink: data['cookies.privacyPolicyLink'],
          cookiePolicyLink: data['cookies.cookiePolicyLink'],
          termsServiceLink: data['cookies.termsServiceLink'],
          andOur: data['cookies.andOur'],
        };
        this.ccService.getConfig().content = content;
        this.ccService.destroy();
        this.ccService.init(this.ccService.getConfig());
      });
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  ngOnDestroy(): void {
    this.subscribeDataSharing.unsubscribe();
  }

  /**
   * Este método aplica o tema na aplicação
   * @param theme tema
   */
  public setApplicationTheme(theme: string): any {
    if (this.applicationService.getIsDarkTheme()) {
      this.overlayContainer.getContainerElement().classList.add(theme);
      this.componentCssClass = theme;
    } else {
      this.overlayContainer.getContainerElement().classList.remove(Theme.dark);
      this.componentCssClass = null;
    }
  }
}
