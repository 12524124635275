import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { ImportKmz } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { CustomTableDirective } from '../utils/custom-table/custom-table';
import { ImportKmzFormComponent } from './import-kmz-form/import-kmz-form.component';
import { DESTROY_COMPONENT, GET_LIST_KMZ } from './import-kmz.actions';
import { ImportKmzState, importKmzStateDefault } from './import-kmz.state';
import { Router } from '@angular/router';
import { Operation } from 'src/app/enums/Operation';

@Component({
  selector: 'app-import-kmz',
  templateUrl: './import-kmz.component.html',
  styleUrls: ['./import-kmz.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ImportKmzComponent extends CustomTableDirective implements OnInit, OnDestroy {
  public importKmzState$!: Observable<ImportKmzState>;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public state: ImportKmzState = importKmzStateDefault;
  public operation = Operation;

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private translateService: TranslateService,
    public applicationService: ApplicationService,
    private router: Router,

    public trans: TranslateTypes
  ) {
    super();
    this.importKmzState$ = this.store.pipe(select('importKmzState'));
  }

  public ngOnInit(): void {
    this.subscribe = this.importKmzState$.subscribe((importKmzState: ImportKmzState) => {
      this.state = importKmzState;
      if (this.state && this.state.kmz) {
        this.setTableSetting();
      }
    });

    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === ImportKmzComponent.name) {
        this.store.dispatch(GET_LIST_KMZ());
      }
    });

    this.store.dispatch(GET_LIST_KMZ());
  }

  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.subscribeDataSharing.unsubscribe();
    this.store.dispatch(DESTROY_COMPONENT());
  }

  /**
   * Este método abre a modal para cadastrar ou editar
   */
  public openDialog(): void {
    this.dialog.open(ImportKmzFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-800'],
    });
  }

  public openComponentFields(): void {
    this.router.navigate(['/home/field']);
  }
  /**
   * Este método define as configurações iniciais da tabela
   * @param state Estado do componente
   */
  public setTableSetting(): void {
    const kmz = _.cloneDeep(this.state.kmz);
    this.dataSource = new MatTableDataSource<ImportKmz>(
      kmz.map((importKmz: any) => {
        importKmz.importDate = moment(importKmz.importDate).format(
          this.translateService.instant(this.trans.text.date)
        );
        return importKmz;
      })
    );
    this.initTable(this.applicationService.filterColumnByPermission(this.state.displayedColumns));
  }
}
