import { createAction, props } from '@ngrx/store';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import { Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_GLEBES = createAction('[Glebe Page] GET_GLEBES');
export const GET_GLEBES_SUCCESS = createAction(
  '[Glebe Page] GET_GLEBES_SUCCESS',
  props<{ glebes: Array<Glebe> }>()
);
export const GET_GLEBES_FAIL = createAction(
  '[Glebe Page] GET_GLEBES_FAIL',
  props<{ error: any }>()
);

export const GET_SYSTEM = createAction('[Glebe Page] GET_SYSTEM');
export const GET_SYSTEM_SUCCESS = createAction(
  '[Glebe Page] GET_SYSTEM_SUCCESS',
  props<{ system: OccurrenceSettings }>()
);
export const GET_SYSTEM_FAIL = createAction(
  '[Glebe Page] GET_SYSTEM_FAIL',
  props<{ error: any }>()
);

/**
 * Remover
 */
export const REMOVE_GLEBE = createAction(
  '[Glebe Page] REMOVE_GLEBE',
  props<{ ids: Array<string> }>()
);
export const REMOVE_GLEBE_SUCCESS = createAction('[Glebe Page] REMOVE_GLEBE_SUCCESS');
export const REMOVE_GLEBE_FAIL = createAction(
  '[Glebe Page] REMOVE_GLEBE_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const DESTROY_COMPONENT = createAction('[Glebe Page] DESTROY_COMPONENT');
