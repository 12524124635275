import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { MATERIAL_DATE_MONTH } from 'src/app/constants/mask';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseForm } from '../../utils/base-form/base-form';
import {
  FirstForm,
  firstFormGroup,
  GlebeFormState,
  glebeFormStateDefault,
} from './glebe-form.state';
import { FormBuilder } from '@angular/forms';
import {
  CREATE_GLEBE,
  GET_CROPS,
  GET_HAS_FIELD_LINKED_GLEBE,
  GET_HIGHEST_NUMBER_REGISTERED,
  GET_SYSTEM,
  HIDE_MODAL,
  UPDATE_GLEBE,
} from 'src/app/view/glebe/glebe-form/glebe-form.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { ControlLevelType } from 'src/app/enums/ControlLevelType';
import { regex, Regex } from 'src/app/constants/regex';
import * as _ from 'lodash';
import {
  CreateGlebeForm,
  UpdateGlebeForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';
import { Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Glebe';
import { FormService } from 'src/app/services/form.service';
import { HarvestIndex } from '@tarvos-ag/tarvos-firestore-models/src/utils/interfaces';
import { MyToastrService } from 'src/app/services/toastr.service';
import { LabelPipe } from 'src/app/pipes/label.pipe';

@Component({
  selector: 'app-glebe-form',
  templateUrl: './glebe-form.component.html',
  styleUrls: ['./glebe-form.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATE_MONTH },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GlebeFormComponent extends BaseForm implements OnInit, OnDestroy {
  public glebeState$: Observable<GlebeFormState>;
  public state: GlebeFormState = glebeFormStateDefault;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public controlLevelType: any = ControlLevelType;
  public regex: Regex = regex;

  constructor(
    public formBuilder: FormBuilder,
    public store: Store<any>,
    public dialogRef: MatDialogRef<GlebeFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { edit: boolean; glebe: Glebe },
    public applicationService: ApplicationService,
    public dialog: MatDialog,
    public trans: TranslateTypes,
    private labelPipe: LabelPipe,
    private formService: FormService,
    private toastrService: MyToastrService
  ) {
    super(store, dialogRef, formBuilder, trans);
    this.glebeState$ = this.store.pipe(select('glebeFormState'));
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    this.store.dispatch(GET_SYSTEM());
    this.store.dispatch(GET_CROPS());
    this.store.dispatch(GET_HIGHEST_NUMBER_REGISTERED());
    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));

    this.subscribe = this.glebeState$.subscribe((glebeState: GlebeFormState) => {
      this.state = glebeState;
      if (glebeState.closeModal) {
        this.onCloseModal();
      }
      if (glebeState.lastCount >= 0 && !this.data.edit) {
        this.generateUniqueName();
      }
    });

    if (this.data.edit) {
      this.store.dispatch(GET_HAS_FIELD_LINKED_GLEBE({ glebeId: this.data.glebe.id }));
      this.firstFormGroup.patchValue({
        ...this.data.glebe,
      });
    }
  }

  /**
   * Este método executa quando o componentes não é mais ativo
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método fecha uma modal
   */
  public onCloseModal(): void {
    this.closeModal(HIDE_MODAL(), true, false, true);
  }

  /**
   * Este método valida e cadastrar ou atualiza
   */
  public onFormClick(): void {
    if (this.firstFormGroup.invalid) {
      this.checkValidateForms(this.firstFormGroup);
    } else {
      const form: FirstForm = this.firstFormGroup.getRawValue();
      const { id } = form;

      const glebeIndex: HarvestIndex = {
        customerId: this.applicationService.getCustomerId(),
        farmId: this.applicationService.getFarmId(),
        harvestId: this.applicationService.getHarvestId(),
      };

      let action;
      if (!this.data.edit) {
        const glebeData = this.generatesCreateGlebeForm();
        action = CREATE_GLEBE({
          glebe: {
            ...glebeIndex,
            glebeData,
          },
        });
      } else {
        const glebeData = this.generatesUpdateGlebeForm();
        if (Object.values(glebeData).length <= 0) {
          return this.toastrService.error(
            this.labelPipe.transform(this.trans.text.noFieldsChanged)
          );
        }
        action = UPDATE_GLEBE({ glebe: { ...glebeIndex, glebeId: id, glebeData } });
      }
      this.onSubmit(action);
    }
  }

  /**
   * Este método gera o nome da gleba
   */
  public generateUniqueName(): void {
    this.firstFormGroup
      .get('name')
      ?.setValue(`G${String(this.state.lastCount + 1).padStart(2, '0')}`);
  }

  /**
   * Este método define o nome de uma cultura
   * @param cropId Nome da cultura
   */
  public onSelectionChangeCrop(cropId: string): void {
    const crop = this.state.crops.filter((item) => item.id === cropId)[0];
    if (crop.name) {
      this.firstFormGroup.get('cropName')?.setValue(crop.name);
    }
  }

  private generatesCreateGlebeForm(): CreateGlebeForm['glebeData'] {
    const form: FirstForm = this.firstFormGroup.getRawValue();
    const { id, ...glebeData } = form;
    return { ...glebeData };
  }

  private generatesUpdateGlebeForm(): UpdateGlebeForm['glebeData'] {
    const form: FirstForm = this.firstFormGroup.getRawValue();

    let glebeFormBeforeEdit = {
      alias: this.data.glebe.alias,
      cropId: this.data.glebe.cropId,
      cropName: this.data.glebe.cropName,
      varieties: this.data.glebe.varieties,
      sector: this.data.glebe.sector,
      allotment: this.data.glebe.allotment,
      govCode: this.data.glebe.govCode,
      productiveUnit: this.data.glebe.productiveUnit,
      program: this.data.glebe.program,
    };

    const glebeFormAfterEdit: UpdateGlebeForm['glebeData'] = {
      alias: form.alias,
      cropId: form.cropId,
      cropName: form.cropName,
      varieties: form.varieties,
      sector: form.sector,
      allotment: form.allotment,
      govCode: form.govCode,
      productiveUnit: form.productiveUnit,
      program: form.program,
    };

    const glebeDataEdit = this.formService.getDifferences(glebeFormBeforeEdit, glebeFormAfterEdit);
    return { ...glebeDataEdit };
  }
}
