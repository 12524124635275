<agm-map
  #AgmMap
  [latitude]="convertToNumber(coordinates?.lat)"
  [longitude]="convertToNumber(coordinates?.lng)"
  [zoom]="zoom"
  [mapTypeId]="mapTypeId"
  [ngStyle]="{ height: height }"
  [disableDoubleClickZoom]="true"
  [disableDefaultUI]="true"
>
  <agm-polygon
    *ngIf="polygon && polygon.points && agmPolygon"
    [fillColor]="'#ECB842'"
    [strokeColor]="'#ECB842'"
    [paths]="polygon.points"
    [editable]="false"
    [zIndex]="2"
  >
  </agm-polygon>
  <agm-polygon
    *ngFor="let p of polygons"
    [fillColor]="'rgba(255, 255, 255, 1)'"
    [strokeColor]="'rgba(255, 255, 255, 1)'"
    [paths]="p.points"
  >
  </agm-polygon>
</agm-map>
