<h2 mat-dialog-title>
  {{ data.title | translate }}
</h2>

<mat-dialog-content>
  <p>{{ data.message | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions align="space-between">
  <button mat-button [mat-dialog-close]="null">
    {{ trans.button.close | translate }}
  </button>
  <button mat-button color="primary" [mat-dialog-close]="data.itemIds">
    {{ trans.button.yes | translate }}
  </button>
</mat-dialog-actions>
