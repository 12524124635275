import { UpdateTrapForm } from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/trap';
import {
  Device,
  Trap,
  Field,
  Glebe,
  ManufacturedTrap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const trapStateDefault = {
  traps: [],
  fields: [],
  glebes: [],
  devices: [],
  manufacturedTraps: [],
  trapForm: {
    alias: null,
    marker: {
      id: null,
      lat: null,
      lng: null,
    },
  } as TrapForm,
  trapEdit: {} as Trap,
  lastCount: 0,
  loading: 0,
  loadingProgressBar: 0,
  loadingModal: 0,
  loadingRemove: 0,
  setMarker: false,
  closeModal: false,
  displayedColumns: [
    'select',
    'name',
    'ticketId',
    'glebeName',
    'fieldName',
    'fillFactor',
    'batteryLevel',
    'lastUpdate',
    'lastLinerExchange',
    'actions',
  ],
  displayedForms: [
    'name',
    'deviceName',
    'pheromone',
    'ticketId',
    'glebeName',
    'fieldName',
    'batteryLevel',
    'lastUpdate',
    'lastLinerExchange',
  ],
  pageSizeOptions: [15],
} as TrapState;

export interface TrapState {
  traps: Array<Trap>;
  lastCount: number;
  fields: Array<Field>;
  glebes: Array<Glebe>;
  devices: Array<Device>;
  manufacturedTraps: Array<ManufacturedTrap>;
  trapForm: TrapForm;
  setMarker: boolean;
  loading: number;
  loadingProgressBar: number;
  loadingModal: number;
  loadingRemove: number;
  closeModal: boolean;
  trapEdit: Trap;
  displayedColumns: Array<string>;
  displayedForms: Array<string>;
  pageSizeOptions: number[];
}

export interface UpdateLinerForm extends Omit<UpdateTrapForm, 'trapData'> {
  trapData: {
    linerExchange?: Array<string>;
  };
}

export interface TrapForm {
  alias?: string | null;
  deviceId: string;
  fieldId?: string | null;
  glebeId: string;
  installationDate: string | null;
  manufacturedTrapId: string;
  marker: {
    id: string | null;
    lat: number | null;
    lng: number | null;
  };
  name?: string | null;
  officialTrap?: string | null;
  pheromone?: string | null;
  ticketId: string;
  trapCode?: string | null;
  trapType?: string | null;
}
