/**
 * NOTE - Comentários e Refatoração de código 19/05/2021
 */

import * as glebeAction from './glebe.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import { ApplicationService } from '../../services/application.service';
import { GenericFirestoreService } from '../../services/generic-firestore.service';
import { GenericHttpService } from '../../services/generic-http.service';
import { MyToastrService } from '../../services/toastr.service';
import { TranslateTypes } from '../../services/translation.service';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GlebeEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private genericHttpService: GenericHttpService,
    private applicationService: ApplicationService,
    private labelPipe: LabelPipe,
    public trans: TranslateTypes,
    private translateService: TranslateService
  ) {}

  /**
   * Este método chama um serviço para listar gleba.
   */
  public getGlebes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(glebeAction.GET_GLEBES),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Glebe>(`${this.applicationService.getHarvestRefDatabase()}/glebes`)
          .pipe(
            map((glebes: Array<Glebe>) => glebeAction.GET_GLEBES_SUCCESS({ glebes })),
            catchError((error) => of(glebeAction.GET_GLEBES_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para obter as configurações do sistema.
   */
  public getSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(glebeAction.GET_SYSTEM),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<OccurrenceSettings>(
            `${this.applicationService.getHarvestRefDatabase()}/systemSettings`
          )
          .pipe(
            map((systems: Array<OccurrenceSettings>) =>
              glebeAction.GET_SYSTEM_SUCCESS({ system: systems[0] })
            ),
            catchError((error) => of(glebeAction.GET_SYSTEM_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para remover glebas.
   */
  public removeGlebe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(glebeAction.REMOVE_GLEBE),
      mergeMap((action) => {
        return this.genericHttpService
          .remove<Glebe>(
            `removeGlebe?customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&harvestId=${this.applicationService.getHarvestId()}&ids=${
              action.ids
            }`
          )
          .toPromise()
          .then((resp: Array<{ id: string; code: string; status: string }>) => {
            resp.forEach((data: { id: string; code: string; status: string }) => {
              if (data.code.includes('success')) {
                this.toastrService.success(
                  this.labelPipe.transform(this.translateService.instant(data.code))
                );
              } else {
                this.toastrService.error(
                  this.labelPipe.transform(this.translateService.instant(data.code))
                );
              }
            });
            return glebeAction.REMOVE_GLEBE_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return glebeAction.REMOVE_GLEBE_FAIL(error);
          });
      })
    )
  );
}
