import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { MaskApplierService } from 'ngx-mask';
import { Observable, Subscription } from 'rxjs';
import {
  GET_FARMS,
  GET_USERS,
  REMOVE_USER,
  LOAD_USER_UPDATE,
} from 'src/app/view/user/user.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { RemoveModalComponent } from '../components/remove-modal/remove-modal.component';
import { CustomTableDirective } from '../utils/custom-table/custom-table';
import { UserFormComponent } from './user-form/user-form.component';
import { UserState, userStateDefault } from './user-form/user.state';
import { Farm, WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { Operation } from 'src/app/enums/Operation';
import { Role } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserComponent extends CustomTableDirective implements OnInit, OnDestroy {
  @ViewChild('#appRemoveModal', { static: true }) public appRemoveModal!: RemoveModalComponent;
  @ViewChild('#appRemoveMultipleModal', { static: true })
  public appRemoveMultipleModal!: RemoveModalComponent;

  public userState$: Observable<UserState>;
  public subscribeState!: Subscription;
  public subscribeDataSharing!: Subscription;
  public state: UserState = userStateDefault;
  public Role = Role;
  public operation = Operation;

  constructor(
    private store: Store<any>,
    private translateService: TranslateService,
    private maskService: MaskApplierService,
    private dialog: MatDialog,
    public applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {
    super();
    this.userState$ = this.store.pipe(select('user'));
  }

  public ngOnInit(): void {
    this.subscribeState = this.userState$.subscribe((userState: UserState) => {
      this.state = userState;
      if (this.state.users) {
        this.setTableSetting();
      }
    });
    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === UserComponent.name) {
        this.store.dispatch(GET_USERS());
        this.store.dispatch(GET_FARMS());
      }
    });

    this.store.dispatch(GET_USERS());
    this.store.dispatch(GET_FARMS());
  }

  /**
   * Este método abre a modal para cadastrar ou editar
   * @param edit verifica se é uma edição
   * @param user data
   */
  public openDialog(edit: boolean, user: WebUser | null): void {
    if (user) {
      this.store.dispatch(
        LOAD_USER_UPDATE({
          user: this.state.users.filter((x: any) => x.id === user.id)[0],
        })
      );
    }

    this.dialog.open(UserFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-800'],
      data: { edit },
    });
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.state.loading = 0; // Remover quando o redux for separado por lista e formulário
    this.subscribeState.unsubscribe();
    this.subscribeDataSharing.unsubscribe();
  }

  /**
   * Este método abre uma modal de confirmação para remover N itens
   * @param itemIds lista de itemIds
   */
  public removeItems(itemIds: Array<any>): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      disableClose: true,
      width: 'material-dialog-panel-500',
      data: {
        itemIds,
        title: itemIds.length > 1 ? this.trans.title.removeUsers : this.trans.title.removeUser,
        message: itemIds.length > 1 ? this.trans.text.removeAllUser : this.trans.text.removeUser,
      },
    });

    const subs = dialogRef.afterClosed().subscribe((results: any) => {
      if (results) {
        const ids = results.map((result: any) => result.id);
        this.store.dispatch(REMOVE_USER({ ids }));
      }
      subs.unsubscribe();
    });
  }

  /**
   * Este método define as configurações iniciais da tabela
   * @param state Estado do componente
   */
  public setTableSetting(): void {
    const users = _.cloneDeep(this.state.users).filter((user) => user.track?.visible !== false);
    this.dataSource = new MatTableDataSource<WebUser>(
      users.map((user: any) => {
        if (user.ccc && user.phone) {
          user.phoneNumber = `${user.ccc} ${this.maskService.applyMask(
            user.phone,
            '(00) 0 0000-0000'
          )}`;
        }

        user.farmIds = user.farmIds.map((id: string) => {
          return this.state.farms.filter((farm: Farm) => farm.id === id)[0]?.name;
        });

        user.roleName =
          user.role === Role.admin
            ? this.translateService.instant(this.trans.enums.ADMIN)
            : user.role === Role.operator
            ? this.translateService.instant(this.trans.enums.OPERATOR)
            : this.translateService.instant(this.trans.enums.READER);
        return user;
      })
    );
    this.initTable(this.applicationService.filterColumnByPermission(this.state.displayedColumns));
  }

  /**
   * @Override
   * Este método verifica Se o número de elementos selecionados corresponde ao número total de linhas.
   */
  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.filter(
      (x: any) => x.id !== this.applicationService.getUserId()
    ).length;
    const numRows = this.dataSource.filteredData.filter(
      (x: any) => x.id !== this.applicationService.getUserId()
    ).length;
    return numSelected === numRows;
  }

  /**
   * @Override
   * Seleciona todas as linhas se elas não estiverem todas selecionadas; Ou seleciona linha por linha
   */
  public masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          if (row.id !== this.applicationService.getUserId()) {
            this.selection.select(row);
          }
        });
  }
}
