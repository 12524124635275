import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  DESTROY_COMPONENT,
  GET_PRIVACY_POLICY_URL,
  GET_TERMS_OF_USE_URL,
} from 'src/app/view/home/home.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public homeState$: Observable<any>;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public mobileQuery: MediaQueryList;
  public state: any = null;

  constructor(
    private store: Store<any>,
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    private media: MediaMatcher
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.homeState$ = this.store.pipe(select('homeState'));
  }

  /**
   * Este método é executado quando o componente e inicializado
   */
  public ngOnInit(): void {
    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === HomeComponent.name) {
        this.store.dispatch(GET_TERMS_OF_USE_URL());
        this.store.dispatch(GET_PRIVACY_POLICY_URL());
      }
    });

    this.subscribe = this.homeState$.subscribe((state: any) => {
      this.state = state;
    });

    this.store.dispatch(GET_TERMS_OF_USE_URL());
    this.store.dispatch(GET_PRIVACY_POLICY_URL());
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.subscribeDataSharing.unsubscribe();
    this.store.dispatch(DESTROY_COMPONENT());
  }
}
