import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { FieldState, fieldStateDefault } from 'src/app/view/field/field.state';
import * as fieldAction from './field.actions';

export const fieldReducer = createReducer(
  _.cloneDeep(fieldStateDefault),
  /**
   * GET
   */
  on(fieldAction.GET_FIELDS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(fieldAction.GET_FIELDS_SUCCESS, (state, action) => ({
    ...state,
    fields: action.fields,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(fieldAction.GET_FIELDS_FAIL, (state) => ({
    ...state,
    fields: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * REMOVE
   */
  on(fieldAction.REMOVE_FIELD, (state) => ({ ...state, loadingRemove: state.loadingRemove + 1 })),
  on(fieldAction.REMOVE_FIELD_SUCCESS, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),
  on(fieldAction.REMOVE_FIELD_FAIL, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),

  /**
   * OTHER
   */
  on(fieldAction.DESTROY_COMPONENT, (state) => ({
    ..._.cloneDeep(fieldStateDefault),
  }))
);

export function reducer(state: FieldState | undefined, action: Action): FieldState {
  return fieldReducer(state, action);
}
