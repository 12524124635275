import { OnDestroy, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TranslateTypes } from 'src/app/services/translation.service';

@Injectable()
export class CustomPaginator extends MatPaginatorIntl implements OnDestroy {
  public subscribeItemsPerPageLabel: Subscription;
  public subscribeFirstPageLabel: Subscription;
  public subscribeNextPageLabel: Subscription;
  public subscribePreviousPageLabel: Subscription;
  public subscribeLastPageLabel: Subscription;
  public subscribGetRangeLabel: Subscription;

  public itemsPerPageLabel!: string;
  public firstPageLabel!: string;
  public nextPageLabel!: string;
  public previousPageLabel!: string;
  public lastPageLabel!: string;
  public getRangeLabelString!: string;
  public getRangeLabel: any;

  constructor(private translateService: TranslateService, public trans: TranslateTypes) {
    super();

    this.subscribeItemsPerPageLabel = this.translateService
      .get(this.trans.text.itemsPerPage)
      .subscribe((text) => {
        this.itemsPerPageLabel = text;
      });

    this.subscribeFirstPageLabel = this.translateService
      .get(this.trans.text.firstPage)
      .subscribe((text) => {
        this.firstPageLabel = text;
      });

    this.subscribeNextPageLabel = this.translateService
      .get(this.trans.text.nextPage)
      .subscribe((text) => {
        this.nextPageLabel = text;
      });

    this.subscribePreviousPageLabel = this.translateService
      .get(this.trans.text.previousPage)
      .subscribe((text) => {
        this.previousPageLabel = text;
      });

    this.subscribeLastPageLabel = this.translateService
      .get(this.trans.text.lastPage)
      .subscribe((text) => {
        this.lastPageLabel = text;
      });

    this.subscribGetRangeLabel = this.translateService.get(this.trans.text.of).subscribe((text) => {
      this.getRangeLabelString = text;
      this.getRangeLabel = this.dutchRangeLabel;
    });
  }

  public dutchRangeLabel(page: number, pageSize: number, length: number, text: string): any {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.getRangeLabelString} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    const endIndex =
      startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.getRangeLabelString} ${length}`;
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribeItemsPerPageLabel.unsubscribe();
    this.subscribeFirstPageLabel.unsubscribe();
    this.subscribeNextPageLabel.unsubscribe();
    this.subscribePreviousPageLabel.unsubscribe();
    this.subscribeLastPageLabel.unsubscribe();
  }
}
