import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { first } from 'rxjs/operators';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';

interface WeatherForecast {
  day: string;
  icon: string;
  description: string;
  tempMaxMin: string;
  pressure: string;
  humidity: string;
  feelsLike: string;
  windSpeed: string;
  rain: string;
}

@Component({
  selector: 'app-weather-forecast',
  templateUrl: './weather-forecast.component.html',
  styleUrls: ['./weather-forecast.component.scss'],
})
export class WeatherForecastComponent {
  weatherForecast: WeatherForecast | null = null;

  constructor(
    private applicationService: ApplicationService,
    private http: HttpClient,
    private translateService: TranslateService,
    public trans: TranslateTypes
  ) {
    this.initWeatherForecast();
  }

  public async initWeatherForecast(): Promise<void> {
    const lat = this.applicationService.getFarm()?.coordinates.lat;
    const lng = this.applicationService.getFarm()?.coordinates.lng;
    const lang = this.translateService.currentLang === 'pt' ? 'pt_br' : 'en';
    if (lat && lng) {
      const data = await this.getWeatherForecastByLatLng(lat, lng, lang)
        .pipe()
        .pipe(first())
        .toPromise();

      const unit = `${lang === 'pt_br' ? '°C' : '°F'}`;
      const temp = `${data.main.temp.toFixed(1)}${unit}`;

      this.weatherForecast = {
        description: data.weather[0].description,
        day: moment().locale(lang).format('ddd DD'),
        icon: `https://openweathermap.org/img/wn/${data.weather[0].icon}@4x.png`,
        tempMaxMin: `${temp}`,
        pressure: `${this.translateService.instant(
          this.trans.text.pressure
        )}: ${data.main.pressure.toFixed(1)} hPa`,
        humidity: `${this.translateService.instant(
          this.trans.text.humidity
        )}: ${data.main.humidity.toFixed(1)} %`,
        feelsLike: `${this.translateService.instant(
          this.trans.text.feelsLike
        )}: ${data.main.feels_like.toFixed(1)} ${unit}`,
        windSpeed: `${this.translateService.instant(this.trans.text.windSpeed)}: ${
          lang === 'pt_br' ? (data.wind.speed * 3.6).toFixed(1) : data.wind.speed.toFixed(1)
        } ${lang === 'pt_br' ? 'km/h' : 'mph'}`,
        rain: `${this.translateService.instant(this.trans.text.rain)}: ${
          data.rain ? data.rain['1h'].toFixed(1) : '0'
        } mm`,
      } as WeatherForecast;
    }
  }

  private getWeatherForecastByLatLng(lat: number, lng: number, lang: string): Observable<any> {
    const api = 'https://api.openweathermap.org/data/2.5/weather';
    const appId = '9781061a48f6eac54f3cb4f210fe13b5';
    const units = lang === 'pt_br' ? 'metric' : 'imperial';

    const headers = new HttpHeaders().set('X-Skip-Interceptor', '');
    return this.http.get(
      `${api}?appid=${appId}&units=${units}&lat=${lat}&lon=${lng}&lang=${lang}`,
      { headers }
    );
  }
}
