import { Injectable } from '@angular/core';
import { context } from 'src/config/label/labelByCrop';
import { ApplicationService } from './application.service';
import { TranslateTypes } from './translation.service';
import { TranslateService } from '@ngx-translate/core';

export type genericObject<T> = {
  [key: string]: T;
};

@Injectable({
  providedIn: 'root',
})
export class PipeService {
  constructor(
    private applicationService: ApplicationService,
    private trans: TranslateTypes,
    private translateService: TranslateService
  ) {}

  public validInput(input: genericObject<string>): genericObject<string> {
    if (typeof input === 'object') {
      return input;
    }
    return this.labelnotDefined();
  }
  public getLabelCategory(type: string): string {
    const typeInput = type ? type : this.labelnotDefined().type;
    return this.translateService.instant(typeInput);
  }

  public checkTypeOfLabel({ type, config }: { type: string; config: string }): string {
    if (type && config) {
      const labelType = config in context ? context[config][type] : 'default';
      return labelType ? labelType : 'default';
    }
    return 'default';
  }

  public getNamingPattern(): string {
    const systemSettings = this.applicationService.getSystemSettings();
    return systemSettings?.namingPattern ? systemSettings.namingPattern : 'default';
  }

  public labelnotDefined(): genericObject<string> {
    return this.trans.label.notDefined;
  }

  public getNomenclature({
    typeLabel,
    labelObject,
  }: {
    typeLabel: string;
    labelObject: genericObject<string>;
  }): string {
    if (typeLabel in labelObject) {
      return labelObject[typeLabel];
    } else {
      if ('default' in labelObject) {
        return labelObject['default'];
      } else {
        return this.labelnotDefined().default;
      }
    }
  }
}
