import { createAction, props } from '@ngrx/store';
import { UpdatePasswordDecoded } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { UserLogin } from '../../interfaces/UserLogin';

/**
 * Login
 */
export const LOGIN = createAction('[Login Page] LOGIN', props<{ user: UserLogin }>());
export const LOGIN_SUCCESS = createAction(
  '[Login Page] LOGIN_SUCCESS',
  props<{ user: UserLogin }>()
);
export const LOGIN_FAIL = createAction('[Login Page] LOGIN_FAIL', props<{ error: any }>());
export const USER_LOGOUT = createAction('[Login Page] USER_LOGOUT', props<{ user: UserLogin }>());

/**
 * Recuperar senha
 */
export const RECOVER_PASSWORD = createAction(
  '[Recover Password Page] RECOVER_PASSWORD',
  props<{ email: string }>()
);
export const RECOVER_PASSWORD_SUCCESS = createAction(
  '[Recover Password Page] RECOVER_PASSWORD_SUCCESS'
);
export const RECOVER_PASSWORD_FAIL = createAction(
  '[Recover Password Page] RECOVER_PASSWORD_FAIL',
  props<{ error: any }>()
);

export const CHECK_TOKEN = createAction(
  '[Update Password Page] Check token',
  props<{ token: string }>()
);
export const CHECK_TOKEN_SUCCESS = createAction(
  '[Update Password Page] Check token success',
  props<{ oobCode: string; decoded: UpdatePasswordDecoded }>()
);
export const CHECK_TOKEN_FAIL = createAction(
  '[Update Password Page] Check token error',
  props<{ error: any }>()
);

export const UPDATE_PASSWORD = createAction(
  '[Update Password Page] update password',
  props<{ oobCode: string; decoded: UpdatePasswordDecoded; password: string }>()
);
export const UPDATE_PASSWORD_SUCCESS = createAction(
  '[Update Password Page] update password success'
);
export const UPDATE_PASSWORD_FAIL = createAction(
  '[Update Password Page] update password error',
  props<{ error: any }>()
);
