<!-- Botão para abrir as notificações -->
<button mat-icon-button (click)="openPanelByIndex(0)">
  <mat-icon
    style="line-height: 18px"
    [matBadge]="notifications.length >= 100 ? '99+' : notifications.length"
    [matBadgeHidden]="notifications.length <= 0"
    matBadgeColor="accent"
    ><i class="las la-bell"></i
  ></mat-icon>
  <span></span>
</button>

<!-- Panel de notificações -->
<ng-template cdk-portal>
  <mat-card class="material-container-card">
    <!-- texto que informa a quantidade de notificações  -->
    <mat-card-header *ngIf="notifications.length > 0" class="reset-padding-15">
      <p mat-line class="material-text-info">
        {{ trans.text.youHaveNotifications | translate : { '%0': notifications.length } }}
      </p>
    </mat-card-header>
    <mat-divider></mat-divider>
    <!-- Container com os Itens de notificações  -->
    <mat-card-content *ngIf="notifications.length > 0">
      <div *ngFor="let notification of notifications">
        <button mat-button class="material-notification-item">
          <mat-list-item>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                class="material-notification-light"
                *ngIf="notification.type === 'LIGHT'"
              >
                <i class="las la-tasks"></i>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                class="material-notification-info"
                *ngIf="notification.type === 'INFO'"
              >
                <i class="las la-check-circle"></i>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                class="material-notification-success"
                *ngIf="notification.type === 'SUCCESS'"
              >
                <i class="las la-check-circle"></i>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                class="material-notification-warning"
                *ngIf="notification.type === 'WARNING'"
              >
                <i class="las la-exclamation-triangle"></i>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                class="material-notification-danger"
                *ngIf="notification.type === 'DANGER'"
              >
                <i class="las la-exclamation-circle"></i>
              </div>
              <div style="width: 90%; padding-left: 15px">
                <h4 mat-line fxLayout="row" fxLayoutAlign="space-between center">
                  {{ notification.title.code | translate : notification.title.params }}
                  <small
                    ><i class="fa fa-clock-o" style="padding-right: 5px"></i
                    >{{ notification.date }}</small
                  >
                </h4>
                <p mat-line align="left" class="material-text-info tm-color-gray">
                  {{
                    notification.message.code
                      | translate : formatDateToString(notification.message.params)
                  }}
                </p>
              </div>
            </div>
          </mat-list-item>
          <mat-divider></mat-divider>
        </button>
        <button
          mat-icon-button
          autofocus="false"
          class="material-notification-btn-close"
          aria-label="Close"
          (click)="clearNotification([notification.id])"
          tabindex="-1"
        >
          <i aria-hidden="true" class="las la-times"></i>
        </button>
      </div>
    </mat-card-content>
    <!-- Mensagem apresentada quando não há notificações  -->
    <mat-card-content
      *ngIf="notifications.length <= 0"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div class="material-no-result tm-color-gray" fxLayout="column" fxLayoutAlign="center center">
        <i class="las la-bell-slash"></i>
        <p class="material-text-info">
          {{ trans.text.notificationsNotFound | translate }}
        </p>
      </div>
    </mat-card-content>

    <!-- Botão para limpar todas as notificações -->
    <mat-card-actions *ngIf="notifications.length > 0">
      <mat-divider></mat-divider>
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <button mat-button [disabled]="notifications.length <= 0" (click)="clearNotification(null)">
          {{ trans.button.clearNotifications | translate }}
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</ng-template>
