/**
 * NOTE - Comentários e Refatoração de código 19/05/2021
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as harvestAction from './harvest.actions';
import { ApplicationService } from '../../services/application.service';
import { GenericFirestoreService } from '../../services/generic-firestore.service';
import { GenericHttpService } from '../../services/generic-http.service';
import { MyToastrService } from '../../services/toastr.service';
import { TranslateTypes } from '../../services/translation.service';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { TranslateService } from '@ngx-translate/core';
import { Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Injectable()
export class HarvestEffects {
  constructor(
    private actions$: Actions,
    private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService,
    private genericHttpService: GenericHttpService,
    private toastrService: MyToastrService,
    private labelPipe: LabelPipe,

    private translateService: TranslateService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço para listar safras.
   */
  public getHarvests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(harvestAction.GET_HARVESTS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Harvest>(`${this.applicationService.getFarmRefDatabase()}/harvests`)
          .pipe(
            map((harvests: Array<Harvest>) => harvestAction.GET_HARVESTS_SUCCESS({ harvests })),
            catchError((error) => of(harvestAction.GET_HARVESTS_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para remover safras.
   */
  public removeHarvest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(harvestAction.REMOVE_HARVEST),
      mergeMap((action) => {
        return this.genericHttpService
          .remove<Harvest>(
            `removeHarvest?customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&ids=${
              action.ids
            }`
          )
          .toPromise()
          .then((resp: Array<{ id: string; code: string; status: string }>) => {
            resp.forEach((data: { id: string; code: string; status: string }) => {
              if (data.code.includes('success')) {
                this.toastrService.success(
                  this.labelPipe.transform(this.translateService.instant(data.code))
                );
              } else {
                this.toastrService.error(
                  this.labelPipe.transform(this.translateService.instant(data.code))
                );
              }
            });
            return harvestAction.REMOVE_HARVEST_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              this.labelPipe.transform(
                this.translateService.instant(
                  `${
                    error.code !== undefined
                      ? error.code
                      : error.error.code !== undefined
                      ? error.error.code
                      : error.error.internalCode
                  }`
                )
              )
            );
            return harvestAction.REMOVE_HARVEST_FAIL(error);
          });
      })
    )
  );
}
