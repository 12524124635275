import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateTypes } from 'src/app/services/translation.service';
import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { MapMonitoring } from 'src/app/interfaces/Monitoring';
import { EvolutionModalComponent } from '../evolution-modal/evolution-modal.component';
import { MadModalComponent } from '../mad-modal/mad-modal.component';
import { LevelExceededModalComponent } from '../level-exceeded-modal/level-exceeded-modal.component';
import { InfestationEvolutionModalComponent } from '../infestation-evolution-modal/infestation-evolution-modal.component';
import { TotalBetweenFieldsModalComponent } from '../total-between-fields-modal/total-between-fields-modal.component';
import { MovingAverageModalComponent } from '../moving-average-modal/moving-average-modal.component';
import { AccumulatedModalComponent } from '../accumulated-modal/accumulated-modal.component';
import { UnitOfMeasureOfOccurrence } from '@tarvos-ag/tarvos-firestore-models/src/enums';
import { ClimateModalComponent } from '../climate-modal/climate-modal.component';
import { SoilModalComponent } from '../soil-modal/soil-modal.component';

@Component({
  selector: 'app-list-chart',
  templateUrl: './list-chart.component.html',
  styleUrls: ['./list-chart.component.scss'],
})
export class ListChartComponent {
  @Input() mapMonitoringItem!: MapMonitoring;
  @Input() gmConfiguration!: GmConfiguration;

  public unit = UnitOfMeasureOfOccurrence;

  constructor(private dialog: MatDialog, public trans: TranslateTypes) {}

  /**
   * Este método abre a modal para mostrar um gráfico de Incidência MAD.
   */
  public madChartDialog(): void {
    this.dialog.open(MadModalComponent, {
      minWidth: '90vw',
      data: { field: this.mapMonitoringItem.field, gm: this.gmConfiguration },
    });
  }

  /**
   * Este método abre a modal para mostrar um gráfico de Ocorrências que Excederam o Nível de Controle.
   */
  public controlLevelExceededChartDialog(): void {
    this.dialog.open(LevelExceededModalComponent, {
      minWidth: '90vw',
      data: { field: this.mapMonitoringItem.field, gm: this.gmConfiguration },
    });
  }

  /**
   * Este método abre a modal para mostrar um gráfico de capturas acumuladas
   */
  public accumulatedChartDialog(): void {
    this.dialog.open(AccumulatedModalComponent, {
      minWidth: '90vw',
      data: { field: this.mapMonitoringItem.field, gm: this.gmConfiguration },
    });
  }

  /**
   * Este método abre a modal para mostrar um gráfico de capturas diárias
   */
  public evolutionChartDialog(): void {
    this.dialog.open(EvolutionModalComponent, {
      minWidth: '90vw',
      data: { field: this.mapMonitoringItem.field, gm: this.gmConfiguration },
    });
  }

  /**
   * Este método abre a modal para mostrar um gráfico de evolução de infestação
   */
  public infestationEvolutionDialog(): void {
    this.dialog.open(InfestationEvolutionModalComponent, {
      minWidth: '90vw',
      data: { field: this.mapMonitoringItem.field, gm: this.gmConfiguration },
    });
  }

  /**
   * Este método abre a modal para mostrar um gráfico total entre talhões
   */
  public totalBetweenFieldsChartsDialog(): void {
    this.dialog.open(TotalBetweenFieldsModalComponent, {
      minWidth: '90vw',
      data: { field: this.mapMonitoringItem.field, gm: this.gmConfiguration },
    });
  }

  /**
   * Este método abre a modal para mostrar um gráfico média móvel
   */
  public movingAverageChartsDialog(): void {
    this.dialog.open(MovingAverageModalComponent, {
      minWidth: '90vw',
      data: { field: this.mapMonitoringItem.field, gm: this.gmConfiguration },
    });
  }

  /**
   * Este método abre a modal para mostrar um gráfico de Temperatura e Umidade
   */
  public climateChartsDialog(): void {
    this.dialog.open(ClimateModalComponent, {
      minWidth: '90vw',
      data: { field: this.mapMonitoringItem.field, gm: this.gmConfiguration },
    });
  }

  /**
   * Este método abre a modal para mostrar um gráfico de Umidade do Solo
   */
  public soilChartsDialog(): void {
    this.dialog.open(SoilModalComponent, {
      minWidth: '90vw',
      data: { field: this.mapMonitoringItem.field, gm: this.gmConfiguration },
    });
  }
}
