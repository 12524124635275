import * as _ from 'lodash';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  CREATE_IMPORT_FIELD,
  GET_FIELD,
  GET_GLEBES,
  GET_HARVESTS,
  HIDE_MODAL,
} from 'src/app/view/field/import-field-form/import-field-form.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseForm } from '../../utils/base-form/base-form';
import {
  firstFormGroup,
  ImportFieldFormState,
  importFieldFormStateDefault,
} from './import-field-form.state';
import { Field, Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-import-field-form',
  templateUrl: './import-field-form.component.html',
  styleUrls: ['./import-field-form.component.scss'],
})
export class ImportFieldFormComponent extends BaseForm implements OnInit, OnDestroy {
  public importFieldFormState$: Observable<ImportFieldFormState>;
  public state: ImportFieldFormState = importFieldFormStateDefault;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;

  constructor(
    public formBuilder: FormBuilder,
    public store: Store<any>,
    public dialogRef: MatDialogRef<ImportFieldFormComponent>,
    public applicationService: ApplicationService,
    public dialog: MatDialog,
    public trans: TranslateTypes,
    @Inject(MAT_DIALOG_DATA) public fieldsInHarvest: Array<Field>
  ) {
    super(store, dialogRef, formBuilder, trans);
    this.importFieldFormState$ = this.store.pipe(select('importFieldFormState'));
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    this.store.dispatch(GET_HARVESTS());
    this.store.dispatch(GET_GLEBES());
    this.store.dispatch(GET_FIELD());

    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));

    this.subscribe = this.importFieldFormState$.subscribe(
      (importFieldFState: ImportFieldFormState) => {
        this.state = importFieldFState;

        if (importFieldFState.closeModal) {
          this.onCloseModal();
        }
      }
    );
  }

  /**
   * Este método executa quando o componentes não é mais ativo
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método fecha uma modal
   */
  public onCloseModal(): void {
    this.closeModal(HIDE_MODAL(), true, false, false);
  }

  /**
   * Este método valida e cadastrar ou atualiza
   */
  public onFormClick(): void {
    if (this.firstFormGroup.invalid) {
      this.checkValidateForms(this.firstFormGroup);
    } else {
      const action = CREATE_IMPORT_FIELD({ ids: this.firstFormGroup.getRawValue().ids });
      this.onSubmit(action);
    }
  }

  /**
   * Este método retorna uma lista de safra ordenada
   */
  public getGlebes(): Array<Glebe> {
    return this.state.glebes
      .sort((a: any, b: any) =>
        a.name.toString().localeCompare(b.name.toString(), undefined, { numeric: true })
      )
      .reverse();
  }

  /*
   * Este método retorna uma lista de gleba por safra;
   */
  public getFieldsByGlebe(glebeId?: string): Array<Field> {
    if (this.state.fields) {
      if (glebeId) {
        return this.state.fields.filter((field: Field) => field.glebe.id === glebeId);
      }
    }
    return [];
  }

  /**
   *  Este método verifica se o talhão ja foi importada
   * @param fieldId id da gleba
   */
  public wasImportedField(fieldId: string): boolean {
    return this.fieldsInHarvest.filter((field: Field) => field.id === fieldId).length > 0;
  }
}
