import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { GET_CLIMATE_CHART, GET_TRAPS } from 'src/app/view/gm-tabs/gm-tabs.actions';
import { TranslateTypes } from 'src/app/services/translation.service';
import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { gmTabsDefault, GmTabsState } from '../../gm-tabs.state';
import { Field } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';
import { ApplicationService } from 'src/app/services/application.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';

@Component({
  selector: 'app-climate-modal',
  templateUrl: './climate-modal.component.html',
  styleUrls: ['./climate-modal.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class ClimateModalComponent implements OnInit, OnDestroy {
  public reportState$: Observable<GmTabsState>;
  public state: GmTabsState = gmTabsDefault;
  public formGroup!: FormGroup;
  public subscribe!: Subscription;
  public moment: any = moment;
  public startDate: any;
  public endDate: any;
  public gm: any;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ClimateModalComponent>,
    public translateService: TranslateService,
    public trans: TranslateTypes,
    public zone: NgZone,
    private store: Store<any>,
    private formBuilder: FormBuilder,
    public applicationService: ApplicationService,

    @Inject(MAT_DIALOG_DATA) public data: { field: Field; gm: GmConfiguration }
  ) {
    this.formGroup = this.formBuilder.group({
      ticketId: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
    });
    this.reportState$ = this.store.pipe(select('gmTabs'));
    this.gm = data.gm;
  }

  public ngOnInit(): void {
    this.subscribe = this.reportState$.subscribe((state: GmTabsState) => {
      this.state = state;
    });

    const { field } = this.data;
    this.store.dispatch(GET_TRAPS({ fieldId: field.id }));

    this.formGroup.get('startDate')?.setValue(moment(this.gm.startDate.toDate()));
    this.formGroup.get('endDate')?.setValue(moment(this.gm.endDate.toDate()));
  }

  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.state.climateChart = [];
  }

  /**
   * This is the method that close modal
   */
  public closeModal() {
    this.dialogRef.close();
  }

  /**
   * Este método recebe um evento ao selecionar a armadilha
   * @param ticketId id da armadilha
   */
  public createChart(): void {
    const ticketId = this.formGroup.get('ticketId')?.value[0]
      ? this.formGroup.get('ticketId')?.value
      : this.state.traps.map((glebe) => glebe.id);

    const startDate = new Date(this.formGroup.get('startDate')?.value);
    const endDate = new Date(this.formGroup.get('endDate')?.value);

    startDate.setUTCHours(3, 0, 0, 0);
    endDate.setUTCHours(24 + 3, 0, 0, 0);
    this.store.dispatch(
      GET_CLIMATE_CHART({
        startDate: startDate,
        endDate: endDate,
        ticketId: ticketId,
      })
    );
  }
}
