<div fxLayout="column" fxLayoutAlign="space-between none" class="container">
  <mat-accordion class="sidenav">
    <!-- Monitoramento Geral -->
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header
        routerLinkActive="active"
        (click)="sideNav.mode === 'over' ? sideNav.toggle() : null"
        routerLink="/home/gm"
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
      >
        <mat-panel-title>
          <i class="las la-home"></i>
          {{ trans.title.generalMonitoring | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <!-- Usuários -->
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header
        routerLinkActive="active"
        (click)="sideNav.mode === 'over' ? sideNav.toggle() : null"
        routerLink="/home/user"
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
      >
        <mat-panel-title>
          <i class="las la-user"></i>
          {{ trans.title.users | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <!-- Gleba -->
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header
        routerLinkActive="active"
        (click)="sideNav.mode === 'over' ? sideNav.toggle() : null"
        routerLink="/home/glebe"
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
      >
        <mat-panel-title>
          <i class="las la-map"></i>
          {{ glebeTitle }}
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <!-- Talhão -->
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header
        routerLinkActive="active"
        (click)="sideNav.mode === 'over' ? sideNav.toggle() : null"
        routerLink="/home/field"
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
      >
        <mat-panel-title>
          <i class="las la-map-marked-alt"></i>
          {{ fieldTitle }}
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <!-- Equipamentos -->
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header
        routerLinkActive="active"
        (click)="sideNav.mode === 'over' ? sideNav.toggle() : null"
        routerLink="/home/trap"
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
      >
        <mat-panel-title>
          <i class="las la-satellite-dish"></i>
          {{ trans.title.equipments | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <!-- Relatórios -->
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header
        routerLinkActive="active"
        (click)="sideNav.mode === 'over' ? sideNav.toggle() : null"
        routerLink="/home/trapReport"
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
      >
        <mat-panel-title>
          <i class="las la-file-alt"></i>
          {{ trans.title.reports | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <!-- Otimização de Rotas -->
    <mat-expansion-panel *ngIf="user && user.role === 'ADMIN'" hideToggle>
      <mat-expansion-panel-header
        routerLinkActive="active"
        (click)="sideNav.mode === 'over' ? sideNav.toggle() : null"
        routerLink="/home/routeOptimization"
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
      >
        <mat-panel-title>
          <i class="las la-route"></i>
          {{ trans.title.routeOptimization | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <!-- Configuração -->
    <mat-expansion-panel
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      [expanded]="step[0] === 4"
      (opened)="setStep(0, 4)"
    >
      <mat-expansion-panel-header
        class="menu-header"
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
      >
        <mat-panel-title>
          <i class="las la-tasks"></i>
          {{ trans.title.configuration | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-divider></mat-divider>
      <div class="sub-menu" fxLayout="column" fxLayoutAlign="start start">
        <a
          routerLinkActive="active"
          (click)="sideNav.mode === 'over' ? sideNav.toggle() : null"
          routerLink="/home/configuration/system"
          *ngIf="this.hasMad"
        >
          {{ trans.title.mad | translate }}
        </a>

        <a
          routerLinkActive="active"
          (click)="sideNav.mode === 'over' ? sideNav.toggle() : null"
          routerLink="/home/configuration/occurrence"
        >
          {{ trans.title.occurrence | translate }}
        </a>
      </div>
      <mat-divider></mat-divider>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="material-version">
    <p>
      {{ trans.text.tarvosTm | translate }}
      <a target="_blank" [href]="termsOfUseUrl">{{
        trans.text.termsAndPolicy.linkTerm | translate
      }}</a>
      {{ trans.text.and | translate }}
      <a target="_blank" [href]="privacyPolicyUrl"
        >{{ trans.text.termsAndPolicy.linkPolicy | translate }}.</a
      >
      {{ trans.text.allRightsReserved | translate }}
      <br />
      <strong>{{ trans.text.version | translate }}: {{ this.version }}</strong>
    </p>
  </div>
</div>
