import * as _ from 'lodash';
import * as moment from 'moment';
import { AgmMap } from '@agm/core';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IconCustom } from 'src/app/constants/mapIconCustom';
import { IconUrl } from 'src/app/constants/mapIconCustom';
import { ApplicationService } from 'src/app/services/application.service';
import { GenericHttpService } from 'src/app/services/generic-http.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { MyToastrService } from 'src/app/services/toastr.service';
import {
  GeolocationPoint,
  Point,
  Polygon,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import {
  FieldMonitoring,
  MapMonitoring,
  PointMonitoring,
  RequestMonitoring,
} from 'src/app/interfaces/Monitoring';
import { borderColor } from 'src/app/constants/color';
import { TimestampType } from 'src/app/interfaces/Types';
import { Trap } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { BorderColor, IconStatus } from '@tarvos-ag/tarvos-firestore-models/src/enums';

declare const google: any;

@Component({
  selector: 'app-maps-general-monitoring',
  templateUrl: './maps-general-monitoring.component.html',
  styleUrls: ['./maps-general-monitoring.component.scss'],
})
export class MapsGeneralMonitoringComponent implements AfterViewInit {
  @ViewChild('AgmMap', { static: true }) public agmMap!: AgmMap;

  @Output() public mapMonitoringEvent: EventEmitter<MapMonitoring> =
    new EventEmitter<MapMonitoring>(true);
  @Output() public closeGmTabsEvent: EventEmitter<any> = new EventEmitter<any>(true);

  @Input() public height = '500px';
  @Input() public scrollwheel = true;
  @Input() public mapDraggable = true;
  @Input() public componentName: string | null = null;
  @Input() public mapMonitoring: Array<MapMonitoring> = [];
  @Input() public fieldSelectedId: string | null = null;
  @Input() public startDate: TimestampType | null = null;
  @Input() public endDate: TimestampType | null = null;
  @Input() public traps: Array<Trap> = [];
  @Input() public occurrenceSelectedId: string | null = null;
  @Input() public occurrenceSelectedType!: string;
  @Input() public hideCollectionPoint = false;
  @Input() public showFieldWithHighControlLevel = false;

  public readonly mapTypeId: any = 'satellite';

  public collectPoint: string | null = null;
  public map: any = {};
  public groundOverlayList: Array<any> = [];
  public coordinates: GeolocationPoint | null;
  public zoom = 20;
  public p: Polygon | null = null;
  public m: Point | null = null;
  public moment = moment;
  public loading = false;
  public fieldEnabled!: string | null;
  public borderColor = borderColor;
  promises: Array<Promise<Array<RequestMonitoring>>> = [];

  constructor(
    private genericHttpService: GenericHttpService,
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    private toastrService: MyToastrService
  ) {
    const farm = this.applicationService.getFarm();
    this.coordinates = farm ? farm.coordinates : null;
  }

  /**
   * Este método é executado depois que o componente é carregado e pega a instância do mapa
   */
  public ngAfterViewInit(): void {
    this.agmMap.mapReady.subscribe((map) => {
      this.map = map;
      map.setOptions({
        tilt: 0,
        zoom: this.zoom,
        scaleControl: true,
        gestureHandling: 'greedy',
      });

      this.setFitBounds();
    });
  }

  /**
   * Este método da um zoom e centraliza os polígonos na tela
   */
  public setFitBounds(): void {
    setTimeout(() => {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

      if (this.mapMonitoring && this.mapMonitoring.length > 0) {
        this.mapMonitoring.forEach((p: MapMonitoring) => {
          p.field.polygon.points.forEach((latLng) => {
            bounds.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
          });
        });
      } else {
        bounds.extend(new google.maps.LatLng(this.coordinates?.lat, this.coordinates?.lng));
      }

      this.map.fitBounds(bounds);
    }, 500);
  }

  /**
   * Este método aplica o zoom no mapa
   * @param type 'zoon in' ou 'zoon out'
   */
  public setZoom(type: string): void {
    const zoom = this.map.zoom;
    const maxZoom = this.map.maxZoom;
    const minZoom = this.map.minZoom;

    if (zoom >= minZoom && zoom <= maxZoom) {
      if (type === 'zoon in') {
        this.map.setZoom(zoom + 1);
      } else if (type === 'zoon out') {
        this.map.setZoom(zoom - 1);
      }
    }
  }

  /**
   * Este método retorna um evento com o talhão selecionado
   * @param field talhão
   * @param infoWindow popup componente
   */
  public onFieldSelect(mapMonitoring: MapMonitoring): void {
    this.mapMonitoringEvent.emit(mapMonitoring);
  }

  public splitArrayIntoCommaSeparatedStrings(array: Array<string>, parts: number): string[] {
    let result: string[] = [];
    // We do a bit of math here to ensure:
    // 1. The total number of requests is indeed equal to 'parts'
    // 2. The amount of strings in each request is distributed as evenly as possible
    const total = array.length;
    const ceil = Math.ceil(total / parts);
    const floor = Math.floor(total / parts);
    const nFloors = ceil * parts - total;
    const nCeils = parts - nFloors;
    let start = 0;
    for (let i = 0; i < nFloors; i++) {
      let end = start + floor;
      let part = array.slice(start, end);
      result.push(part.join(','));
      start = end;
    }
    for (let i = 0; i < nCeils; i++) {
      let end = start + ceil;
      let part = array.slice(start, end);
      result.push(part.join(','));
      start = end;
    }
    return result;
  }

  public mouseHoverPolygon(show: boolean, fieldId?: string): void {
    if (show && fieldId) {
      this.fieldEnabled = fieldId;
      return;
    }
    this.fieldEnabled = null;
  }
  public getPolygonData(mapMonitoring: Array<MapMonitoring>): void {
    // Optimization: split the requests if there are more than 12 fields
    const nRequests: number = mapMonitoring.length > 12 ? 4 : 1;
    if (this.promises.length <= 0) {
      this.removeHeatmapImage();
      this.loading = true;

      if (mapMonitoring && mapMonitoring.length > 0) {
        if (this.occurrenceSelectedId) {
          const endDate: Date | null = this.endDate?.toDate() || null;
          endDate?.setHours(6, 0, 0, 0);
          let startDate: Date | null = null;
          if (endDate) {
            startDate = new Date(endDate);
          }

          const fieldIds = mapMonitoring.map((map) => map.field.id);
          const fieldIdsSplitted = this.splitArrayIntoCommaSeparatedStrings(fieldIds, nRequests);
          fieldIdsSplitted.forEach((fieldIds) => {
            this.promises.push(
              this.genericHttpService
                .getById<Array<RequestMonitoring>>(
                  `monitoring?customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&harvestId=${this.applicationService.getHarvestId()}&fieldIds=${fieldIds}&occurrenceId=${
                    this.occurrenceSelectedId
                  }&startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}`
                )
                .toPromise()
            );
          });
        }

        Promise.all(this.promises)
          .then((values: Array<Array<RequestMonitoring>>) => {
            for (let group of values) {
              // filter nulls
              group = group.filter((data) => !!data);
              for (const data of group) {
                const index = mapMonitoring.findIndex(
                  (mapMonitoring) => mapMonitoring.field.id === data.field.fieldId
                );

                if (data) {
                  this.setHeatMapImage(mapMonitoring, data, index);
                  this.setMonitoring(mapMonitoring, data, index);
                } else {
                  mapMonitoring[index].fieldMonitoring = {} as FieldMonitoring;
                  mapMonitoring[index].pointMonitoring = [] as Array<PointMonitoring>;
                }
              }
            }

            this.mapMonitoring = this.pointsWithoutCommunication(mapMonitoring);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.mapMonitoring = mapMonitoring;
            this.toastrService.error(this.trans.services.get.gmFieldsData);
          })
          .finally(() => {
            this.promises = [];
            this.setFitBounds();
          });
      }
    }
  }

  /**
   * Este método remove os heatmap do mapa
   */
  public removeHeatmapImage(): void {
    if (this.groundOverlayList.length > 0) {
      this.groundOverlayList.forEach((groundOverlay) => {
        groundOverlay.setMap(null);
      });
      this.groundOverlayList = [];
    }
  }

  /**
   * Este método adiciona o heatmap de cada talhão;
   * @param polygons Lista de polígonos que estão desenhados no mapa
   */
  public setHeatMapImage(
    mapMonitoring: Array<MapMonitoring>,
    data: RequestMonitoring,
    index: number
  ): void {
    if (
      (this.showFieldWithHighControlLevel && data.field.borderColor === BorderColor.danger) ||
      !this.showFieldWithHighControlLevel
    ) {
      mapMonitoring[index].fieldMonitoring = data.field;

      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

      mapMonitoring[index].field.polygon.points.forEach((coordinate) => {
        bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
      });

      if (data.field.heatmapImage) {
        const historicalOverlay: google.maps.GroundOverlay = new google.maps.GroundOverlay(
          `data:image/svg;base64,${data.field.heatmapImage}`,
          bounds
        );
        historicalOverlay.setOpacity(0.8);
        historicalOverlay.setMap(this.map);
        this.groundOverlayList.push(historicalOverlay);
      }
    }
  }

  /**
   * Este método busca e mostra as informações de monitoramento de cada talhão;
   * @param polygons Lista de polígonos que estão desenhados no mapa
   */
  public setMonitoring(
    mapMonitoring: Array<MapMonitoring>,
    data: RequestMonitoring,
    index: number
  ): void {
    if (data.points) {
      mapMonitoring[index].pointMonitoring = data.points.map((route: PointMonitoring) => {
        route.icon = this.getIconSvg(route.iconType);
        return route;
      });
    }
  }

  /**
   * Este método transforma uma string em número
   * @param value valor a ser convertido
   */
  public convertToNumber(value: number | null | undefined): number {
    if (value) {
      return parseFloat(value.toString());
    }
    return 0;
  }

  /**
   * Este método retorna monitoringReports após incluir armadilhas que não comunicaram
   * @param type status do ponto
   */
  public pointsWithoutCommunication(mapMonitoring: Array<MapMonitoring>): Array<MapMonitoring> {
    return mapMonitoring.map((monitoring: MapMonitoring) => {
      if (
        monitoring.pointMonitoring.length <= 0 &&
        this.traps.some((trap) => trap.field?.id === monitoring.field.id)
      ) {
        const points = this.traps
          .filter((trap) => trap.field?.id === monitoring.field.id)
          .map((trap) => {
            return {
              icon: this.getIconSvg('NO_COMMUNICATION'),
              iconType: 'NO_COMMUNICATION',
              values: [{ data: 0, label: 'Contagem', type: 'NUMBER' }],
              name: trap.name,
              alias: trap.alias,
              ticketId: trap.ticketId,
              batteryLevel: trap.batteryLevel,
              fillFactor: trap.fillFactor,
              pheromone: trap.pheromone,
              lastUpdate: trap.lastUpdate ? trap.lastUpdate.toDate() : null,
              device: trap.device.name,
              lat: trap.marker.lat,
              lng: trap.marker.lng,
              fieldId: trap.field!.id,
            };
          }) as Array<any>;
        return {
          ...monitoring,
          fieldMonitoring: { fieldId: monitoring.field.id } as FieldMonitoring,
          pointMonitoring: points,
        };
      }
      return monitoring;
    });
  }

  /**
   * Este método checa e cria um ponto para armadilha que não comunicou
   * @param type status do ponto
   */
  public getIconSvg(type: string): any {
    const iconCustom = _.cloneDeep(IconCustom);

    if (type) {
      iconCustom.url = `./../assets/icons/${IconUrl[this.occurrenceSelectedType][type]}`;
    } else {
      iconCustom.url = `./../assets/icons/${IconUrl['TRAP'][IconStatus.LIGHT]}`;
    }
    return iconCustom;
  }

  public load_polygon_centroid(points: Array<Point>): { lat: number; lng: number } {
    let first = points[0],
      last = points[points.length - 1];

    if (first.lng != last.lng || first.lat != last.lat) points.push(first);
    let area: number = 0,
      lng = 0,
      lat = 0,
      nPts = points.length,
      p1: { lat: number; lng: number },
      p2: { lng: number; lat: number },
      f: number;
    for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
      p1 = points[i];
      p2 = points[j];
      f = (p1.lat - first.lat) * (p2.lng - first.lng) - (p2.lat - first.lat) * (p1.lng - first.lng);
      area += f;

      lng += (p1.lng + p2.lng - 2 * first.lng) * f;
      lat += (p1.lat + p2.lat - 2 * first.lat) * f;
    }
    f = area * 3;
    return { lat: lat / f + first.lat, lng: lng / f + first.lng };
  }
}
