import * as _ from 'lodash';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseForm } from '../../utils/base-form/base-form';
import {
  firstFormGroup,
  ImportTrapFormState,
  importTrapFormStateDefault,
} from './import-trap-form.state';
import {
  CREATE_IMPORT_TRAP,
  GET_HARVESTS,
  GET_TRAP,
  HIDE_MODAL,
} from 'src/app/view/trap/import-trap-form/import-trap-form.actions';
import { Trap, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-import-trap-form',
  templateUrl: './import-trap-form.component.html',
  styleUrls: ['./import-trap-form.component.scss'],
})
export class ImportTrapFormComponent extends BaseForm implements OnInit, OnDestroy {
  public importTrapFormState$: Observable<ImportTrapFormState>;
  public state: ImportTrapFormState = importTrapFormStateDefault;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;

  constructor(
    public formBuilder: FormBuilder,
    public store: Store<any>,
    public dialogRef: MatDialogRef<ImportTrapFormComponent>,
    public applicationService: ApplicationService,
    public dialog: MatDialog,
    public trans: TranslateTypes,
    @Inject(MAT_DIALOG_DATA) public trapsInHarvest: Array<Trap>
  ) {
    super(store, dialogRef, formBuilder, trans);
    this.importTrapFormState$ = this.store.pipe(select('importTrapFormState'));
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    this.store.dispatch(GET_HARVESTS());
    this.store.dispatch(GET_TRAP());
    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));

    this.subscribe = this.importTrapFormState$.subscribe(
      (importTrapFState: ImportTrapFormState) => {
        this.state = importTrapFState;

        if (importTrapFState.closeModal) {
          this.onCloseModal();
        }
      }
    );
  }

  /**
   * Este método executa quando o componentes não é mais ativo
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método fecha uma modal
   */
  public onCloseModal(): void {
    this.closeModal(HIDE_MODAL(), true, false, false);
  }

  /**
   * Este método valida e cadastrar ou atualiza
   */
  public onFormClick(): void {
    if (this.firstFormGroup.invalid) {
      this.checkValidateForms(this.firstFormGroup);
    } else {
      const action = CREATE_IMPORT_TRAP({ ids: this.firstFormGroup.getRawValue().ids });
      this.onSubmit(action);
    }
  }

  /**
   * Este método retorna uma lista de safra ordenada
   */
  public getHarvests(): Array<Harvest> {
    return this.state.harvests
      .sort((a: any, b: any) =>
        a.currentYear.seconds
          .toString()
          .localeCompare(b.currentYear.seconds.toString(), undefined, { numeric: true })
      )
      .reverse();
  }

  /*
   * Este método retorna uma lista de gleba por safra;
   */
  public getTrapsByHarvest(harvestId?: string): Array<Trap> {
    if (this.state.traps) {
      if (harvestId) {
        const harvestTrap = this.state.harvests.filter(
          (harvest: Harvest) => harvest.id === harvestId
        )[0];
        return this.state.traps.filter(
          (trap: Trap) => trap.harvestId === harvestId && !harvestTrap.harvested
        );
      }
    }
    return [];
  }

  /**
   *  Este método verifica se a armadilha ja foi importada
   * @param trapId id da gleba
   */
  public wasImportedTrap(trapId: string): boolean {
    return this.trapsInHarvest.filter((trap: Trap) => trap.id === trapId).length > 0;
  }
}
