<!-- Informação do talhão -->
<mat-card class="mat-elevation-z8" style="margin: 15px">
  <mat-card-header>
    <mat-card-title>{{ mapMonitoringItem.field.name }}</mat-card-title>
    <mat-card-subtitle>
      {{ mapMonitoringItem.field.alias }}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="material-card-content">
    <div fxLayout="column" fxLayoutAlign="space-between none">
      <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
        <label
          *ngIf="mapMonitoringItem.field.glebe.cropId === 'FRUTAS'"
          fxFlex="100%"
          fxFlex.gt-xs="0 1 50%"
        >
          {{ trans.label.sector | translate }}:
          <span>{{ mapMonitoringItem.field.glebe.sector }}</span>
        </label>
        <label
          *ngIf="mapMonitoringItem.field.glebe.cropId === 'FRUTAS'"
          fxFlex="100%"
          fxFlex.gt-xs="0 1 50%"
        >
          {{ trans.label.allotment | translate }}:
          <span>{{ mapMonitoringItem.field.glebe.allotment }}</span>
        </label>
        <label
          *ngIf="mapMonitoringItem.field.glebe.cropId === 'FRUTAS'"
          fxFlex="100%"
          fxFlex.gt-xs="0 1 50%"
        >
          {{ trans.label.productiveUnit | translate }}:
          <span>{{ mapMonitoringItem.field.glebe.productiveUnit }}</span>
        </label>

        <label fxFlex="100%" fxFlex.gt-xs="0 1 50%">
          {{ trans.label.area | translate }}:
          <span>
            {{
              applicationService.getCalculatedArea(mapMonitoringItem.field.area.hectares)
                | number : '.2-2'
            }}
            {{ trans.enums[areaType.hectares] | translate }}
          </span>
        </label>
        <label fxFlex="100%" fxFlex.gt-xs="0 1 50%">
          {{ trans.label.crop | translate }}:
          <span> {{ mapMonitoringItem.field.glebe.cropName | translate }}</span>
        </label>
        <label
          fxFlex="100%"
          fxFlex.gt-xs="0 1 50%"
          *ngIf="mapMonitoringItem.field.glebe?.varieties"
        >
          {{ trans.label.cropVarieties | translate }}:
          <span> {{ mapMonitoringItem.field.glebe.varieties | translate }}</span>
        </label>
        <label fxFlex="100%" fxFlex.gt-xs="0 1 50%">
          {{ trans.label.installedEquipments | translate }}:
          <span> {{ getSortedList(mapMonitoringItem.pointMonitoring).length }} </span>
        </label>
        <label fxFlex="100%" fxFlex.gt-xs="0 1 50%">
          {{ trans.label.infestation | translate }}:
          <span
            >&nbsp;{{
              getFormattedNumber(
                mapMonitoringItem.fieldMonitoring.infestation,
                mapMonitoringItem.fieldMonitoring.unit
              )
            }}</span
          >
        </label>
        <label
          fxFlex="100%"
          fxFlex.gt-xs="0 1 50%"
          *ngIf="mapMonitoringItem.fieldMonitoring.controlLevel"
        >
          {{ trans.label.controlLevel | translate }}:
          <span>
            {{
              getFormattedNumber(
                mapMonitoringItem.fieldMonitoring.controlLevel,
                mapMonitoringItem.fieldMonitoring.unit,
                true
              )
            }}</span
          >
        </label>
        <p fxFlex="100%" style="line-height: 1.6; font-weight: bold; padding: 0px">
          <span class="tm-color-gray" [innerHTML]="mapMonitoringItem.fieldMonitoring.text"></span>
        </p>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- Informação dos pontos de coleta -->
<mat-card class="mat-elevation-z8 warning-material-card" style="margin: 15px">
  <mat-card-header>
    <mat-card-title>
      {{ trans.title.installedEquipments | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content
    class="material-card-content"
    *ngIf="mapMonitoringItem.pointMonitoring.length > 0; else equalZero"
  >
    <mat-accordion multi>
      <mat-expansion-panel
        hideToggle
        *ngFor="let point of getSortedList(mapMonitoringItem.pointMonitoring); let i = index"
        [expanded]="step === point.lng.toString() + i"
        (opened)="setStep(point.lng.toString() + i)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title fxLayout="row" fxLayoutAlign=" center">{{ point.name }}</mat-panel-title>
          <mat-panel-description class="material-panel-description">
            <span
              *ngIf="point.lastUpdate"
              matTooltipPosition="right"
              [matTooltip]="trans.label.lastUpdate | translate"
            >
              {{ moment(point.lastUpdate).format(trans.text.date | translate) }}</span
            >
            <div fxLayout="row" fxLayoutAlign="space-around none">
              <span
                *ngIf="point.fillFactor"
                [matTooltip]="
                  (trans.label.fillFactor | translate) +
                  ': ' +
                  (trans.enums[point.fillFactor] | translate | titlecase)
                "
                matTooltipPosition="left"
                class="material-icon-fill-factor"
              >
                <mat-icon
                  *ngIf="point.fillFactor === fillFactorLevel.low"
                  class="low"
                  aria-hidden="false"
                  >layers</mat-icon
                >
                <mat-icon
                  *ngIf="point.fillFactor === fillFactorLevel.medium"
                  class="medium"
                  aria-hidden="false"
                  >layers</mat-icon
                >
                <mat-icon
                  *ngIf="point.fillFactor === fillFactorLevel.high"
                  class="high"
                  aria-hidden="false"
                  >layers</mat-icon
                >
                <mat-icon
                  *ngIf="point.fillFactor === fillFactorLevel.critical"
                  class="critical-blink-me blink-me"
                  aria-hidden="false"
                  >layers_clear</mat-icon
                >
              </span>

              <span
                *ngIf="point.batteryLevel"
                [matTooltip]="
                  (trans.label.batteryLevel | translate) +
                  ': ' +
                  (trans.enums[point.batteryLevel] | translate | titlecase)
                "
                matTooltipPosition="left"
              >
                <i *ngIf="point.batteryLevel === batteryLevel.high" class="las la-battery-full"></i>
                <i
                  *ngIf="point.batteryLevel === batteryLevel.medium"
                  class="las la-battery-half"
                ></i>
                <i
                  *ngIf="point.batteryLevel === batteryLevel.low"
                  class="las la-battery-quarter"
                ></i>
                <i
                  *ngIf="point.batteryLevel === batteryLevel.critical"
                  class="las la-battery-quarter la-battery-quarter-blink-me blink-me"
                ></i>
              </span>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngIf="step" fxLayout="column" fxLayoutAlign="space-between none" style="margin: 15px">
          <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
            <label fxFlex="100%" fxFlex.gt-xs="0 1 50%">
              {{ trans.label.ticketId | translate }}: <span>{{ point.ticketId }}</span>
            </label>
            <label fxFlex="100%" fxFlex.gt-xs="0 1 50%">
              {{ trans.label.device | translate }}: <span>{{ point.device }}</span>
            </label>
            <label fxFlex="100%" fxFlex.gt-xs="0 1 50%">
              {{ trans.label.pheromone | translate }}:
              <span>{{
                point.pheromone ? point.pheromone : (trans.text.notReported | translate)
              }}</span>
            </label>
            <label fxFlex="100%" fxFlex.gt-xs="0 1 50%">
              {{ trans.label.batteryLevel | translate }}:
              <span>{{
                point.batteryLevel
                  ? (trans.enums[point.batteryLevel] | translate | titlecase)
                  : (trans.text.notReported | translate)
              }}</span>
            </label>
            <label [ngSwitch]="point.fillFactor" fxFlex="100%" fxFlex.gt-xs="0 1 50%">
              <label *ngSwitchCase="'LOW'">
                {{ trans.label.fillFactor | translate }}:
                <span>{{
                  point.fillFactor
                    ? (trans.enums[point.fillFactor] | translate | titlecase)
                    : (trans.text.notReported | translate)
                }}</span>
              </label>
              <label *ngSwitchCase="'MEDIUM'">
                {{ trans.label.fillFactor | translate }}:
                <span>{{
                  point.fillFactor
                    ? (trans.enums[point.fillFactor] | translate | titlecase)
                    : (trans.text.notReported | translate)
                }}</span>
              </label>
              <label *ngSwitchCase="'HIGH'">
                {{ trans.label.fillFactor | translate }}:
                <span>{{
                  point.fillFactor
                    ? (trans.enums[point.fillFactor] | translate | titlecase)
                    : (trans.text.notReported | translate)
                }}</span>
              </label>
              <label *ngSwitchCase="'CRITICAL'">
                {{ trans.label.fillFactor | translate }}:
                <span>{{
                  point.fillFactor
                    ? (trans.enums[point.fillFactor] | translate | titlecase)
                    : (trans.text.notReported | translate)
                }}</span>
              </label>
              <label *ngSwitchDefault>
                {{ trans.label.fillFactor | translate }}:
                <span>{{ trans.text.unmonitored | translate }}</span>
              </label>
            </label>

            <label fxFlex="100%" fxFlex.gt-xs="0 1 50%">
              {{ trans.label.lat | translate }}: <span>{{ point.lat | number : '.6-6' }}</span>
            </label>
            <label fxFlex="100%" fxFlex.gt-xs="0 1 50%">
              {{ trans.label.lng | translate }}: <span>{{ point.lng | number : '.6-6' }}</span>
            </label>
            <mat-divider *ngIf="point.values.length > 0" class="material-devider"></mat-divider>

            <mat-chip-list fxFlex="100%">
              <mat-chip *ngFor="let value of point.values" selected [color]="'primary'">
                <label> {{ value.label }}: {{ getFormattedNumber(value.data, value.type) }} </label>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>

  <ng-template #equalZero>
    <mat-card-content class="material-card-content-warning">
      <mat-icon aria-hidden="false" aria-label="Warning">warning</mat-icon>
      <div class="text-alignment">
        {{ trans.title.noEquipments | translate }}
      </div>
    </mat-card-content>
  </ng-template>
</mat-card>
