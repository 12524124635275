import { Injectable } from '@angular/core';

@Injectable()
export class UsefulService {
  constructor() {}

  /**
   * Este método ordena uma lista
   * @param item lista
   * @param fieldPat item a ser ordenado
   * @param directionStr direção da ordenação
   */
  public orderList<T>(item: Array<T>, fieldPath: string, directionStr?: 'desc' | 'asc'): Array<T> {
    const result = item.sort((a: any, b: any) =>
      a[fieldPath].localeCompare(b[fieldPath], undefined, {
        numeric: true,
      })
    );

    return directionStr === 'desc' ? result.reverse() : result;
  }

  /**
   * Este método converte um enum em lista
   */
  public getListOfEnum(item: any): any {
    return Object.keys(item).map((key) => item[key]);
  }

  /**
   * Este método retorna um numero de uma string
   */
  convertStringToNumber(value: string | undefined): number {
    return value ? parseFloat(value) : 0;
  }
}
