/*
 * Cores de heatmap / legend
 */
export const chartColor = {
  red: '#dd1d21',
  yellow: '#fbce07',
  midGreen: '#5cab2a',
  lightWhite: '#fafafa',
  veryDarkGrey: '#000000',
  lightGrey: '#a6a6a6',
} as any;

export const borderColor = {
  LIGHT: '#fafafa',
  SAFE: '#5cab2a',
  WARNING: '#fbce07',
  DANGER: '#dd1d21',
} as any;
