import { createAction, props } from '@ngrx/store';
import { Field, Glebe, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_HARVESTS = createAction('[Import Field Form Page] GET_HARVESTS');
export const GET_HARVESTS_SUCCESS = createAction(
  '[Import Field Form Page] GET_HARVESTS_SUCCESS',
  props<{ harvests: Array<Harvest> }>()
);
export const GET_HARVESTS_FAIL = createAction(
  '[Import Field Form Page] GET_HARVESTS_FAIL',
  props<{ error: any }>()
);

export const GET_GLEBES = createAction('[Import Field Form Page] GET_GLEBES');
export const GET_GLEBES_SUCCESS = createAction(
  '[Import Field Form Page] GET_GLEBES_SUCCESS',
  props<{ glebes: Array<Glebe> }>()
);
export const GET_GLEBES_FAIL = createAction(
  '[Import Field Form Page] GET_GLEBES_FAIL',
  props<{ error: any }>()
);

export const GET_FIELD = createAction('[Import Field Form Page] GET_FIELD');
export const GET_FIELD_SUCCESS = createAction(
  '[Import Field Form Page] GET_FIELD_SUCCESS',
  props<{ fields: Array<Field> }>()
);
export const GET_FIELD_FAIL = createAction(
  '[Import Field Form Page] GET_FIELD_FAIL',
  props<{ error: any }>()
);

/**
 * Importar
 */
export const CREATE_IMPORT_FIELD = createAction(
  '[Import Field Form Page] CREATE_IMPORT_FIELD',
  props<{ ids: Array<string> }>()
);
export const CREATE_IMPORT_FIELD_SUCCESS = createAction(
  '[Import Field Form Page] CREATE_IMPORT_FIELD_SUCCESS'
);
export const CREATE_IMPORT_FIELD_FAIL = createAction(
  '[Import Field Form Page] CREATE_IMPORT_TRAP_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_MODAL = createAction('[Import Field Form Page] HIDE_MODAL');
