import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CustomersAtions from './customers-route-optimization.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Customer } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
4;
import { MyToastrService } from 'src/app/services/toastr.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CustomersRouteOptimizationEffects {
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastrService: MyToastrService,
    public trans: TranslateTypes
  ) {}

  getClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomersAtions.GET_CUSTUMERS_ROUTE_OPTIMIZATION),
      mergeMap(() =>
        this.httpClient.get<Customer[]>('http://127.0.0.1:5000/clients').pipe(
          map((customers: Customer[]) =>
            CustomersAtions.GET_CUSTOMERS_ROUTE_OPTMIZIATION_SUCCESS({ customers })
          ),
          catchError((error) => {
            this.toastrService.error(this.trans.services.clients.getClientsFail, {
              '%0': error.message,
            });
            return of(CustomersAtions.GET_CUSTOMERS_ROUTE_OPTIMIZATION_FAIL({ error }));
          })
        )
      )
    )
  );
}
