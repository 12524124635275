import { Injectable } from '@angular/core';
import { CollectionReference } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Trap, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import * as importTrapFormAction from './import-trap-form.actions';
import { ApplicationService } from '../../../services/application.service';
import { Config, GenericFirestoreService } from '../../../services/generic-firestore.service';
import { GenericHttpService } from '../../../services/generic-http.service';
import { MyToastrService } from '../../../services/toastr.service';
import { TranslateTypes } from '../../../services/translation.service';

@Injectable()
export class ImportTrapFormEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private genericHttpServiceService: GenericHttpService,
    private applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço para listar todas as glebas colhidas.
   */
  public getHarvest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importTrapFormAction.GET_HARVESTS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Harvest>(`${this.applicationService.getFarmRefDatabase()}/harvests`)
          .pipe(
            map((harvests: Array<Harvest>) =>
              importTrapFormAction.GET_HARVESTS_SUCCESS({
                harvests: harvests.filter(
                  (harvest: Harvest) => harvest.id !== this.applicationService.getHarvestId()
                ),
              })
            ),
            catchError((error) => of(importTrapFormAction.GET_HARVESTS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar todas as glebas colhidas.
   */
  public getTraps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importTrapFormAction.GET_TRAP),
      switchMap(() => {
        return this.genericFirestoreService
          .getAll<Trap>(`${this.applicationService.getFarmRefDatabase()}/traps`, {
            queryFn: (ref: CollectionReference) => ref.where('field', '==', null),
            propertyDate: [['harvestDate']],
          } as Config)
          .pipe(
            map((traps: Array<Trap>) =>
              importTrapFormAction.GET_TRAP_SUCCESS({
                traps: traps.filter(
                  (trap: Trap) => trap.harvestId !== this.applicationService.getHarvestId()
                ),
              })
            ),
            catchError((error) => of(importTrapFormAction.GET_TRAP_FAIL(error)))
          );
      })
    );
  });

  /**
   * Este método chama um serviço para cadastrar gleba.
   */
  public createTrap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importTrapFormAction.CREATE_IMPORT_TRAP),
      mergeMap((action) => {
        const parameters = {
          type: 'traps',
          customerId: this.applicationService.getCustomerId(),
          farmId: this.applicationService.getFarmId(),
          harvestId: this.applicationService.getHarvestId(),
        };

        return this.genericHttpServiceService
          .create<{ ids: Array<string> }>('importToHarvest', { ...parameters, ids: action.ids })
          .toPromise()
          .then(() => {
            this.toastrService.success(this.trans.services.create.importTrapSuccess);
            return importTrapFormAction.CREATE_IMPORT_TRAP_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return importTrapFormAction.CREATE_IMPORT_TRAP_FAIL(error);
          });
      })
    )
  );
}
