import { AgmMap } from '@agm/core';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/services/application.service';
import {
  GeolocationPoint,
  Point,
  Polygon,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

declare const google: any;

@Component({
  selector: 'app-google-maps-draw-polygon',
  templateUrl: './google-maps-draw-polygon.component.html',
  styleUrls: ['./google-maps-draw-polygon.component.scss'],
})
export class GoogleMapsDrawPolygonComponent implements AfterViewInit, OnDestroy {
  @ViewChild('AgmMap', { static: true }) public agmMap!: AgmMap;
  @Input() public height = '500px';
  @Input() public polygon!: Polygon;
  @Input() public enableDrawing = true;
  @Output() public eventDrawEnd: EventEmitter<any> = new EventEmitter<any>(true);
  @Input() public set setPolygons(polygons: Array<Polygon>) {
    if (polygons && polygons[0]) {
      this.polygons = polygons;
      this.setFitBoundsGlebe();
    } else {
      this.polygons = [];
    }
  }

  public readonly mapTypeId: any = 'satellite';

  public polygons: Array<Polygon> = [];
  public coordinates: GeolocationPoint | null;
  public subscriber!: Subscription;
  public map: any = {};
  public zoom = 15;
  public fitBounds = true;
  public agmPolygon = true;

  constructor(private db: AngularFirestore, public applicationService: ApplicationService) {
    const farm = this.applicationService.getFarm();
    this.coordinates = farm ? farm.coordinates : null;
  }

  public ngAfterViewInit(): void {
    this.subscriber = this.agmMap.mapReady.subscribe((map) => {
      this.map = map;

      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

      bounds.extend(new google.maps.LatLng(-10.854120303387177, -45.915253));
      bounds.extend(new google.maps.LatLng(-10.855468267007058, -44.910706));
      bounds.extend(new google.maps.LatLng(-11.848475404292838, -44.9104));
      bounds.extend(new google.maps.LatLng(-11.847000833113952, -45.918427));
      bounds.extend(new google.maps.LatLng(-10.854120303387177, -45.915253));

      const historicalOverlay = new google.maps.GroundOverlay(
        'https://firebasestorage.googleapis.com/v0/b/tarvos-production.appspot.com/o/GroundOverlay%2FTeste%201_Formosa_600dpi.jpeg?alt=media&token=24c8e8a3-4b90-4fd8-85a9-9c3a8f8003d1',
        bounds
      );

      historicalOverlay.setOpacity(0.5);
      historicalOverlay.setMap(this.map);

      map.setOptions({
        tilt: 0,
        scaleControl: true,
        gestureHandling: 'greedy',
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
        },
      });

      if (this.enableDrawing) {
        this.initDrawingManager(map);
      } else {
        this.setFitBounds();
      }
    });
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  /**
   * define uma área para mostrar todos os polígonos
   */
  public setFitBounds(): void {
    setTimeout(() => {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      if (this.polygon) {
        this.polygon.points.forEach((latLng) => {
          bounds.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
        });
      }

      this.map.fitBounds(bounds);
    }, 500);
  }

  public setFitBoundsGlebe(): void {
    setTimeout(() => {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      this.polygons.forEach((p) => {
        p.points.forEach((latLng) => {
          bounds.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
        });
      });

      this.map.fitBounds(bounds);
    }, 500);
  }

  public initDrawingManager(map: any): void {
    let dataEvent: Array<any> = [];
    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    drawingManager.setOptions({
      polygonOptions: { fillColor: '#ECB842', strokeColor: '#ECB842' },
    });
    drawingManager.setMap(map);
    google.maps.event.addListener(drawingManager, 'overlaycomplete', (data: any) => {
      drawingManager.setDrawingMode(null);
      this.agmPolygon = false;
      if (data.type === google.maps.drawing.OverlayType.POLYGON) {
        this.addPolygonChangeEvent(data.overlay);
        google.maps.event.addListener(data.overlay, 'coordinates_changed', () => {
          this.updateDraw(data.overlay);
        });

        if (dataEvent && dataEvent.length > 0) {
          dataEvent[0].overlay.setMap(null);
          dataEvent = [];
        }

        dataEvent.push(data);
        this.updateDraw(data.overlay);
      }
    });
    if (this.polygon && this.polygon.points && this.polygon.points.length > 0) {
      drawingManager.setDrawingMode(null);
      this.setFitBounds();
    }
  }

  public updateDraw(polygon: any): void {
    const meter = google.maps.geometry.spherical.computeArea(polygon.getPath().getArray());
    const polygonMap: Array<Point> = polygon
      .getPath()
      .getArray()
      .map((x: any) => {
        return { id: this.db.createId(), lat: x.lat(), lng: x.lng() };
      });

    this.eventDrawEnd.emit({
      meter,
      polygon: polygonMap,
    });
  }

  public addPolygonChangeEvent(polygon: any): void {
    const me = polygon;
    let isBeingDragged = false;
    const triggerCoordinatesChanged = () => {
      google.maps.event.trigger(me, 'coordinates_changed');
    };

    google.maps.event.addListener(me, 'dragstart', () => {
      isBeingDragged = true;
    });

    google.maps.event.addListener(me, 'dragend', () => {
      triggerCoordinatesChanged();
      isBeingDragged = false;
    });

    me.getPaths().forEach((path: any, i: any) => {
      google.maps.event.addListener(path, 'insert_at', () => {
        triggerCoordinatesChanged();
      });
      google.maps.event.addListener(path, 'set_at', () => {
        if (!isBeingDragged) {
          triggerCoordinatesChanged();
        }
      });
      google.maps.event.addListener(path, 'remove_at', () => {
        triggerCoordinatesChanged();
      });
    });
  }

  public convertToNumber(value: number | null | undefined): number {
    if (value) {
      return parseFloat(value.toString());
    }
    return 0;
  }
}

export const options = {
  drawingControl: true,
  drawingControlOptions: {
    drawingModes: ['polygon'],
  },
  polygonOptions: {
    draggable: false,
    editable: true,
    fillColor: 'rgba(255, 255, 255, 1)',
    zIndex: 2,
  },
  drawingMode: 'polygon',
};
