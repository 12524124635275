import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import {
  FieldFormState,
  fieldFormStateDefault,
} from 'src/app/view/field/field-form/field-form.state';
import * as fieldFormAction from './field-form.actions';

export const fieldReducer = createReducer(
  _.cloneDeep(fieldFormStateDefault),
  /**
   * GET
   */
  on(fieldFormAction.GET_FIELDS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(fieldFormAction.GET_FIELDS_SUCCESS, (state, action) => ({
    ...state,
    fields: action.fields,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(fieldFormAction.GET_FIELDS_FAIL, (state) => ({
    ...state,
    fields: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(fieldFormAction.GET_HIGHEST_NUMBER_REGISTERED, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(fieldFormAction.GET_HIGHEST_NUMBER_REGISTERED_SUCCESS, (state, action) => ({
    ...state,
    lastCount: action.lastCount,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(fieldFormAction.GET_HIGHEST_NUMBER_REGISTERED_FAIL, (state) => ({
    ...state,
    lastCount: 0,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(fieldFormAction.GET_GLEBES, (state) => ({ ...state, loading: state.loading + 1 })),
  on(fieldFormAction.GET_GLEBES_SUCCESS, (state, action) => ({
    ...state,
    glebes: action.glebes,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(fieldFormAction.GET_GLEBES_FAIL, (state) => ({
    ...state,
    glebes: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(fieldFormAction.GET_SYSTEM, (state) => ({ ...state, loading: state.loading + 1 })),
  on(fieldFormAction.GET_SYSTEM_SUCCESS, (state, action) => ({
    ...state,
    system: action.system,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(fieldFormAction.GET_SYSTEM_FAIL, (state) => ({
    ...state,
    system: {} as OccurrenceSettings,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(fieldFormAction.GET_HAS_TRAP_LINKED_FIELD, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(fieldFormAction.GET_HAS_TRAP_LINKED_FIELD_SUCCESS, (state, action) => ({
    ...state,
    hasTrapLinkedField: action.hasTrapLinkedField,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(fieldFormAction.GET_HAS_TRAP_LINKED_FIELD_FAIL, (state) => ({
    ...state,
    hasTrapLinkedField: false,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * CREATE
   */
  on(fieldFormAction.CREATE_FIELD, (state) => ({ ...state, loadingModal: state.loadingModal + 1 })),
  on(fieldFormAction.CREATE_FIELD_SUCCESS, (state) => ({ ...state, closeModal: true })),
  on(fieldFormAction.CREATE_FIELD_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * UPDATE
   */
  on(fieldFormAction.UPDATE_FIELD, (state) => ({ ...state, loadingModal: state.loadingModal + 1 })),
  on(fieldFormAction.UPDATE_FIELD_SUCCESS, (state) => ({ ...state, closeModal: true })),
  on(fieldFormAction.UPDATE_FIELD_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * OTHER
   */
  on(fieldFormAction.HIDE_MODAL, (state) => ({
    ..._.cloneDeep(fieldFormStateDefault),
  }))
);

export function reducer(state: FieldFormState | undefined, action: Action): FieldFormState {
  return fieldReducer(state, action);
}
