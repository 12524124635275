import { Validators } from '@angular/forms';
import { CustomValidators } from '../../utils/custom-validators/custom-validators';
import { KmzPolygon } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const importKmzFormStateDefault = {
  polygons: [],
  loading: 0,
  loadingModal: 0,
  closeModal: false,
} as ImportKmzFormState;

export interface ImportKmzFormState {
  polygons: Array<KmzPolygon>;
  loading: number;
  loadingModal: number;
  closeModal: boolean;
}

export const firstFormGroup = {
  id: [null],
  kmzFile: [null, [Validators.required, CustomValidators.isFileKmz]],
} as any;
