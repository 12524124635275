import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { GET_FARMS, REMOVE_FARM, UPDATE_FARM_EDIT } from 'src/app/view/farm/farm.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { Farm } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Farm';
import { RemoveModalComponent } from '../components/remove-modal/remove-modal.component';
import { CustomTableDirective } from '../utils/custom-table/custom-table';
import { FarmFormComponent } from './farm-form/farm-form.component';
import { FarmState, farmStateDefault } from './farm.state';
import * as _ from 'lodash';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { Operation } from 'src/app/enums/Operation';

@Component({
  selector: 'app-farm',
  templateUrl: './farm.component.html',
  styleUrls: ['./farm.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FarmComponent extends CustomTableDirective implements OnInit, OnDestroy {
  public subscribe!: Subscription;
  public farmState$: Observable<FarmState>;
  public farmSelected!: string | null;
  public subscribeDataSharing!: Subscription;
  public state: FarmState = farmStateDefault;
  public operation = Operation;

  constructor(
    private store: Store<any>,
    private dialogRef: MatDialogRef<FarmComponent>,
    private dialog: MatDialog,
    private labelPipe: LabelPipe,
    public applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {
    super();
    this.farmState$ = this.store.pipe(select('farm'));
  }

  public ngOnInit(): void {
    this.subscribe = this.farmState$.subscribe((farmState: FarmState) => {
      this.state = farmState;
      if (this.state && this.getFarmsByUser()) {
        this.farmSelected = this.applicationService.getFarmId();
        this.setTableSetting();
      }
    });
    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === FarmComponent.name) {
        this.store.dispatch(GET_FARMS());
      }
    });

    this.store.dispatch(GET_FARMS());
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.subscribeDataSharing.unsubscribe();
  }

  public hideModalList(): void {
    this.dialogRef.close();
  }

  /**
   * Este método abre a modal para cadastrar ou editar
   * @param edit verifica se é uma edição
   * @param farm data
   */
  public openDialog(edit: boolean, farm: Farm | null): void {
    if (farm) {
      const { id, city, coordinates, country, district, name, state } =
        this.getFarmsByUser().filter((x: any) => x.id === farm.id)[0];
      this.store.dispatch(
        UPDATE_FARM_EDIT({
          farm: {
            id,
            city: city ? city : null,
            coordinates,
            country: country ? country : null,
            district: district ? district : null,
            name,
            state: state ? state : null,
          },
        })
      );
    }
    this.dialog.open(FarmFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-800'],
      data: { edit },
    });
  }

  /**
   * Este método abre uma modal de confirmação para remover N itens
   * @param itemIds lista de ids
   */
  public removeItems(itemIds: Array<any>): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      disableClose: true,
      width: 'material-dialog-panel-500',
      data: {
        itemIds,
        title:
          itemIds.length > 1
            ? this.labelPipe.transform(this.trans.title.removeFarms)
            : this.labelPipe.transform(this.trans.title.removeFarm),
        message:
          itemIds.length > 1
            ? this.labelPipe.transform(this.trans.text.removeAllFarm)
            : this.labelPipe.transform(this.trans.text.removeFarm),
      },
    });

    const subs = dialogRef.afterClosed().subscribe(async (results) => {
      if (results) {
        const ids = results.map((result: any) => result.id);
        this.store.dispatch(REMOVE_FARM({ ids }));
      }
      subs.unsubscribe();
    });
  }

  /**
   * Este método define as configurações iniciais da tabela
   */
  public setTableSetting(): void {
    const farms = _.cloneDeep(this.getFarmsByUser());
    this.dataSource = new MatTableDataSource<Farm>(farms);
    this.initTable(this.applicationService.filterColumnByPermission(this.state.displayedColumns));
  }

  /**
   * Este método retorna uma lista de fazendas filtradas pelo usuário
   */
  getFarmsByUser(): Array<Farm> {
    const farmIds = this.applicationService.getUser()?.farmIds;
    if (farmIds) {
      //@ts-ignore
      return this.state.farms.filter((farm: Farm) => farmIds?.includes(farm.id));
    }
    return [];
  }

  /**
   * @Override
   * Este método verifica Se o número de elementos selecionados corresponde ao número total de linhas.
   */
  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.filter(
      (x: any) => x.id !== this.farmSelected
    ).length;
    const numRows = this.dataSource.filteredData.filter(
      (x: any) => x.id !== this.farmSelected
    ).length;
    return numSelected === numRows;
  }

  /**
   * @Override
   * Seleciona todas as linhas se elas não estiverem todas selecionadas; Ou seleciona linha por linha
   */
  public masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          if (row.id !== this.farmSelected) {
            this.selection.select(row);
          }
        });
  }
}
