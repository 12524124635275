import { Action, createReducer, on } from '@ngrx/store';
import {
  CustomerState,
  customerStateDefault,
} from 'src/app/view/customer/create-customer/create-customer.state';
import * as customerAction from './create-customer.actions';

export const customerReducer = createReducer(
  customerStateDefault,
  /**
   * Obeter
   */
  on(customerAction.GET_TERMS_OF_USE_URL, (state) => ({ ...state, loading: true })),
  on(customerAction.GET_TERMS_OF_USE_URL_SUCCESS, (state, action) => ({
    ...state,
    loading: false,
    termsOfUseUrl: action.termsOfUseUrl,
  })),
  on(customerAction.GET_TERMS_OF_USE_URL_FAIL, (state) => ({
    ...state,
    user: null,
    loading: false,
  })),

  on(customerAction.GET_PRIVACY_POLICY_URL, (state) => ({ ...state, loading: true })),
  on(customerAction.GET_PRIVACY_POLICY_URL_SUCCESS, (state, action) => ({
    ...state,
    loading: false,
    privacyPolicyUrl: action.privacyPolicyUrl,
  })),
  on(customerAction.GET_PRIVACY_POLICY_URL_FAIL, (state) => ({
    ...state,
    user: null,
    loading: false,
  })),
  on(customerAction.GET_SUPPORT_USERS, (state) => ({ ...state, loading: true })),
  on(customerAction.GET_SUPPORT_USERS_SUCCESS, (state, action) => ({
    ...state,
    loading: false,
    supportUsers: action.supportUsers,
  })),
  on(customerAction.GET_SUPPORT_USERS_FAIL, (state) => ({
    ...state,
    supportUsers: [],
    loading: false,
  })),

  /**
   * Cadastrar uma conta
   */
  on(customerAction.CREATE_CUSTOMER, (state) => ({ ...state, loading: true })),
  on(customerAction.CREATE_CUSTOMER_SUCCESS, (state, action) => ({
    ...state,
    loading: false,
  })),
  on(customerAction.CREATE_CUSTOMER_FAIL, (state) => ({ ...state, user: null, loading: false }))
);

export function reducer(state: CustomerState | undefined, action: Action): CustomerState {
  return customerReducer(state, action);
}
