import { MatDateFormats } from '@angular/material/core';

export const PHONE_MASK = {
  brazil: '(00) 00000-0000',
  paraguay: '000 000 000',
} as {
  brazil: string;
  paraguay: string;
};

export const MATERIAL_DATE_MONTH = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
} as MatDateFormats;

export const MATERIAL_DATE_YEAR = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
} as MatDateFormats;
