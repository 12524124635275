<!-- Loading -->
<app-loading [isOpen]="state.loadingModal"></app-loading>
<app-progress-bar [isOpen]="state.loading"></app-progress-bar>

<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 mat-dialog-title>{{ trans.title.harvestField | label }}</h2>

  <button
    mat-icon-button
    autofocus="false"
    class="custom-button-close"
    (click)="onCloseModal()"
    tabindex="-1"
  >
    <i class="las la-times"></i>
  </button>
</div>

<mat-dialog-content>
  <div
    class="material-check-list-container"
    *ngIf="state.trapLinkedToField >= 1 || state.requestLinkedToField >= 1"
  >
    <div>
      <p>{{ trans.text.harvestDependencies | label }}</p>
    </div>
    <div>
      <ul>
        <li *ngIf="state.trapLinkedToField > 0">
          <span *ngIf="state.trapLinkedToField === 1"
            >{{ state.trapLinkedToField }} {{ trans.text.trapInProgress | translate }}</span
          >
          <span *ngIf="state.trapLinkedToField > 1"
            >{{ state.trapLinkedToField }} {{ trans.text.trapsInProgress | translate }}</span
          >
        </li>
        <li *ngIf="state.reportLinkedToField > 0">
          <span *ngIf="state.reportLinkedToField === 1"
            >{{ state.reportLinkedToField }} {{ trans.text.reportInProgress | translate }}</span
          >
          <span *ngIf="state.reportLinkedToField > 1"
            >{{ state.reportLinkedToField }} {{ trans.text.reportsInProgress | translate }}</span
          >
        </li>
      </ul>
    </div>
  </div>

  <form
    class="custom-form-field"
    [formGroup]="firstFormGroup"
    *ngIf="state.trapLinkedToField === 0 && state.requestLinkedToField === 0"
  >
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <div fxFlex="100%">
        <mat-checkbox
          color="primary"
          id="fallowing"
          formControlName="fallowing"
          class="custom-form-checkbox-line"
          (change)="onChangeFallowing($event)"
        >
          {{ trans.text.enterFallowing | translate }}
          {{ trans.text.fieldUnavailableDuringPeriod | label }}
        </mat-checkbox>
        <mat-form-field>
          <mat-label>{{ trans.label.fallowingUntil | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="startPicker"
            id="fallowingUntil"
            formControlName="fallowingUntil"
            [appDisableControl]="firstFormGroup.get('fallowing')?.value !== true"
            [min]="applicationService.getServerDate().toDate()"
            required
          />
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
          <mat-error>
            {{
              getInputMessageError('fallowingUntil', firstFormGroup.get('fallowingUntil')?.errors)
                | translate
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="space-between">
  <button mat-button (click)="onCloseModal()">{{ trans.button.close | translate }}</button>
  <button
    mat-button
    color="primary"
    [disabled]="state.trapLinkedToField >= 1 || state.requestLinkedToField >= 1"
    (click)="onFormClick()"
  >
    {{ trans.button.yes | translate }}
  </button>
</mat-dialog-actions>
