import { createAction, props } from '@ngrx/store';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import {
  CreateGlebeForm,
  UpdateGlebeForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/glebe';
import { Crop } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_SYSTEM = createAction('[Glebe Form Page] GET_SYSTEM');
export const GET_SYSTEM_SUCCESS = createAction(
  '[Glebe Form Page] GET_SYSTEM_SUCCESS',
  props<{ system: OccurrenceSettings }>()
);
export const GET_SYSTEM_FAIL = createAction(
  '[Glebe Form Page] GET_SYSTEM_FAIL',
  props<{ error: any }>()
);

export const GET_HIGHEST_NUMBER_REGISTERED = createAction(
  '[Glebe Form Page] GET_HIGHEST_NUMBER_REGISTERED'
);
export const GET_HIGHEST_NUMBER_REGISTERED_SUCCESS = createAction(
  '[Glebe Form Page] GET_HIGHEST_NUMBER_REGISTERED_SUCCESS',
  props<{ lastCount: number }>()
);
export const GET_HIGHEST_NUMBER_REGISTERED_FAIL = createAction(
  '[Glebe Form Page] GET_HIGHEST_NUMBER_REGISTERED_FAIL',
  props<{ error: any }>()
);

export const GET_HAS_FIELD_LINKED_GLEBE = createAction(
  '[Glebe Form Page] GET_HAS_FIELD_LINKED_GLEBE',
  props<{ glebeId: string }>()
);
export const GET_HAS_FIELD_LINKED_GLEBE_SUCCESS = createAction(
  '[Glebe Form Page] GET_HAS_FIELD_LINKED_GLEBE_SUCCESS',
  props<{ hasFieldLinkedGlebe: boolean }>()
);
export const GET_HAS_FIELD_LINKED_GLEBE_FAIL = createAction(
  '[Glebe Form Page] GET_HAS_FIELD_LINKED_GLEBE_FAIL',
  props<{ error: any }>()
);

export const GET_CROPS = createAction('[Glebe Form Page] GET_CROPS');
export const GET_CROPS_SUCCESS = createAction(
  '[Glebe Form Page] GET_CROPS_SUCCESS',
  props<{ crops: Array<Crop> }>()
);
export const GET_CROPS_FAIL = createAction(
  '[Glebe Form Page] GET_CROPS_FAIL',
  props<{ error: any }>()
);

/**
 * Cadastrar
 */
export const CREATE_GLEBE = createAction(
  '[Glebe Form Page] CREATE_GLEBE',
  props<{ glebe: CreateGlebeForm }>()
);
export const CREATE_GLEBE_SUCCESS = createAction('[Glebe Form Page] CREATE_GLEBE_SUCCESS');
export const CREATE_GLEBE_FAIL = createAction(
  '[Glebe Form Page] CREATE_GLEBE_FAIL',
  props<{ error: any }>()
);

/**
 * Atualizar
 */
export const UPDATE_GLEBE = createAction(
  '[Glebe Form Page] UPDATE_GLEBE',
  props<{ glebe: UpdateGlebeForm }>()
);
export const UPDATE_GLEBE_SUCCESS = createAction('[Glebe Form Page] UPDATE_GLEBE_SUCCESS');
export const UPDATE_GLEBE_FAIL = createAction(
  '[Glebe Form Page] UPDATE_GLEBE_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_MODAL = createAction('[Glebe Form Page] HIDE_MODAL');
