<app-progress-bar [isOpen]="state.loadingLevelExceededChart"></app-progress-bar>

<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 mat-dialog-title>
    {{ trans.title.controlLevelExceeded | translate }}
    <button
      class="custom-button-help"
      mat-mini-fab
      color="primary"
      matTooltipClass="custom-tooltip-help"
      [matTooltip]="trans.text.helpLevelExceededChart['TRAP'] | translate"
    >
      ?
    </button>
  </h2>

  <button
    mat-icon-button
    autofocus="false"
    class="custom-button-close"
    (click)="closeModal()"
    tabindex="-1"
  >
    <i class="las la-times"></i>
  </button>
</div>

<mat-dialog-content style="height: 75vh; overflow: hidden">
  <app-level-exceeded-chart
    *ngIf="state.levelExceededChart.length > 0"
    [data]="state.levelExceededChart"
  ></app-level-exceeded-chart>
  <app-empty-states-box
    *ngIf="state.loadingLevelExceededChart <= 0 && state.levelExceededChart.length <= 0"
    [iconSize]="'62px'"
    [messageSize]="'22px'"
    [messagePadding]="'45px 20px'"
    [icon]="'la-chart-bar'"
    [message]="trans.text.chartNotFound"
  ></app-empty-states-box>
</mat-dialog-content>
