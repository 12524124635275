import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { TranslateTypes } from 'src/app/services/translation.service';
import { LoginState, loginStateDefault } from '../login/login.state';
import { ApplicationService } from 'src/app/services/application.service';
import { Router } from '@angular/router';
import { BaseForm } from '../utils/base-form/base-form';
import { firstFormGroup } from './recover-password.state';
import { RECOVER_PASSWORD } from '../login/login.actions';
import _ from 'lodash';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class RecoverPasswordComponent extends BaseForm implements OnInit, OnDestroy {
  public loginState$: Observable<LoginState>;
  public state: LoginState = loginStateDefault;
  public subscribe!: Subscription;
  public hide = true;
  public createAccountFocused = false;
  public recoverPasswordFocused = false;
  public hidePasswordFocused = false;

  constructor(
    private router: Router,
    private store: Store<any>,
    public trans: TranslateTypes,
    public applicationService: ApplicationService,
    public formBuilder: FormBuilder
  ) {
    super(store, null, formBuilder, trans);

    this.loginState$ = this.store.pipe(select('login'));
    this.subscribe = this.loginState$.subscribe((state: LoginState) => {
      this.state = state;
      if (state.user) {
        this.router.navigate(['/home/gm']);
      }
    });
  }

  /**
   * Este método é executado quando o componente e inicializado
   */
  ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Método onde retorna uma ação para recuperar senha
   */
  public recoverPassword(): void {
    if (this.firstFormGroup.invalid) {
      this.checkValidateForms(this.firstFormGroup);
    } else {
      const action = RECOVER_PASSWORD({ email: this.firstFormGroup.getRawValue().email });
      this.onSubmit(action);
    }
  }

  /**
   * Este método tenta logar uma conta ao pressionar o 'Enter'
   */
  public keyDownLogin(event: any): void {
    if (
      event.keyCode === 13 &&
      !(this.recoverPasswordFocused || this.createAccountFocused || this.hidePasswordFocused)
    ) {
      this.recoverPassword();
    }
  }
}
