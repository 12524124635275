<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
  <!-- Logo e Taggle Menu -->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-icon-button *ngIf="sideNav" (click)="sideNav.toggle()" [autofocus]="false">
      <i class="las la-bars"></i>
    </button>

    <a routerLink="/home/gm" *ngIf="applicationService.getIsDarkTheme()">
      <img class="material-logo" src="./../../../assets/images/logo_h_mono4.png" alt="logo" />
    </a>
    <a routerLink="/home/gm" *ngIf="!applicationService.getIsDarkTheme()">
      <img class="material-logo" src="./../../../assets/images/logo_h_cor2.png" alt="logo" />
    </a>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <!-- Menu para selecionar Customer -->
    <app-select-customer
      #selectCustomerComponent
      *ngIf="getCustomersByUser().length > 1 && sideNav"
      [customers]="getCustomersByUser()"
      [user]="state.user"
    ></app-select-customer>

    <!-- Menu para selecionar Fazenda e Safra -->
    <app-select-farm-and-crop
      #selectFarmAndCropComponent
      *ngIf="state.harvests.length > 0 && getFarmsByCustomer().length > 0 && sideNav"
      [farms]="getFarmsByCustomer()"
      [harvests]="state.harvests"
      [user]="state.user"
    ></app-select-farm-and-crop>

    <!-- Notificações -->
    <app-notification *ngIf="sideNav" [notifications]="state.notifications"></app-notification>

    <!-- Usuário -->
    <app-user-header *ngIf="sideNav" [user]="state.user"></app-user-header>

    <!-- Botão de logout -->
    <button *ngIf="sideNav" id="logout-btn" mat-icon-button (click)="logout()">
      <mat-icon>logout</mat-icon>
    </button>
  </div>
</div>
