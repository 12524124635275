<agm-map
  #AgmMap
  [latitude]="convertToNumber(coordinates?.lat)"
  [longitude]="convertToNumber(coordinates?.lng)"
  [zoom]="zoom"
  [mapTypeId]="mapTypeId"
  [ngStyle]="{ height: height }"
  [disableDoubleClickZoom]="true"
  [disableDefaultUI]="true"
>
  <agm-polygon
    [zIndex]="1"
    *ngIf="polygon && polygon.points"
    [fillColor]="'#ECB842'"
    [strokeColor]="'#ECB842'"
    [paths]="polygon.points"
    [clickable]="enableDrawing"
    (polyClick)="polyClick($event)"
  >
  </agm-polygon>

  <!-- <div *ngIf="markers?.length > 0">
    <agm-circle
      [zIndex]="2"
      *ngFor="let m of markers"
      [latitude]="m.lat"
      [longitude]="m.lng"
      [radius]="radius"
      [fillOpacity]="0.75"
      [fillColor]="'#f6d445'"
      [circleDraggable]="false"
      [editable]="false"
    >
    </agm-circle>
  </div> -->

  <agm-marker
    #marker
    *ngFor="let m of markers; let i = index"
    [id]="m.id"
    [latitude]="m.lat"
    [longitude]="m.lng"
    [iconUrl]="getIconSvg()"
    (dragEnd)="dragEnd($event, m)"
    [zIndex]="3"
    [markerDraggable]="markerDraggable"
    [markerClickable]="markerClickable"
    [openInfoWindow]="false"
    (markerClick)="openInfoWindow($event, m)"
  >
  </agm-marker>

  <agm-snazzy-info-window
    [wrapperClass]="'material-info-window-marker'"
    #agmSnazzyInfoWindow
    [isOpen]="false"
    [border]="false"
    [shadow]="false"
    [showCloseButton]="false"
    [closeOnMapClick]="false"
  >
    <ng-template>
      <button
        mat-button
        color="primary"
        matTooltipClass="material-actions-tooltip"
        [matTooltip]="trans.button.edit | translate"
        *ngIf="btnEdit"
        (click)="editMarker()"
      >
        <i class="las la-pencil-alt"></i>
      </button>
      <button
        mat-button
        color="warn"
        matTooltipClass="material-actions-tooltip"
        [matTooltip]="trans.button.remove | translate"
        (click)="removeMarker()"
      >
        <i class="las la-trash"></i>
      </button>
    </ng-template>
  </agm-snazzy-info-window>
</agm-map>
