import { SelectionModel } from '@angular/cdk/collections';
import { ViewChild, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { dateColumn } from 'src/app/constants/dateColumn';
import { levelColumn, orderLevel } from 'src/app/constants/levelColumn';

@Directive()
export abstract class CustomTableDirective {
  @ViewChild(MatSort, { static: true }) public sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) public paginator!: MatPaginator;

  public dataSource!: MatTableDataSource<any>;
  public selection = new SelectionModel<any>(true, []);
  public expandedElement: any | null = null;
  public displayedColumns!: Array<string>;

  constructor() {}

  public initTable(displayedColumns: Array<string>): any {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<any>(true, []);
    this.displayedColumns = displayedColumns;
    this.customDataSearch();
    this.customOrderBy();
    this.dataSearch('');
  }

  public customOrderBy(): void {
    this.dataSource.sortData = (data: any[], sort: MatSort): any[] => {
      if (sort.active) {
        if (dateColumn.includes(sort.active)) {
          data.sort((a: any, b: any) => {
            return this.formatStringToDate(a[sort.active], sort.direction) >
              this.formatStringToDate(b[sort.active], sort.direction)
              ? 1
              : -1;
          });
        } else if (levelColumn.includes(sort.active)) {
          data.sort((a: any, b: any) => {
            let indexA = orderLevel.indexOf(a[sort.active]);
            let indexB = orderLevel.indexOf(b[sort.active]);

            if (indexA === -1 && indexB === -1) {
              return 0;
            }
            if (indexA === -1) {
              return sort.direction === 'asc' ? 1 : -1;
            }
            if (indexB === -1) {
              return sort.direction === 'asc' ? -1 : 1;
            }
            return indexA - indexB;
          });
        } else {
          data.sort((a: any, b: any) =>
            a[sort.active]
              ? a[sort.active].localeCompare(b[sort.active], undefined, {
                  numeric: true,
                })
              : null
          );
        }
      }
      return sort.direction === 'desc' ? data.reverse() : data;
    };
  }

  /**
   * Este método formata uma string em um componente date
   * @param date Data no formato de string
   */
  private formatStringToDate(date: string, direction: string): Date {
    if (!date) {
      if (direction === 'asc') {
        return new Date(2080, 2, 1);
      }
      return new Date(0);
    } else {
      return new Date(
        date.split('/')[2] +
          '-' +
          ('0' + date.split('/')[1]).slice(-2) +
          '-' +
          ('0' + date.split('/')[0]).slice(-2)
      );
    }
  }

  public customDataSearch(): void {
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      return this.displayedColumns.some(
        (column) => data[column] && data[column].toString().toLowerCase().includes(filter)
      );
    };
  }

  public dataSearch(event: KeyboardEvent | string, element?: any): void {
    this.selection.clear();
    const value = typeof event === 'object' ? (event.target as HTMLInputElement).value : event;
    if (this.dataSource) {
      this.dataSource.filter = value.trim().toLowerCase();
      if (element) {
        element.value = '';
      }
    }
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  public masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  public checkboxLabel(row?: any): string {
    return row
      ? `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`
      : `${this.isAllSelected() ? 'select' : 'deselect'} all`;
  }
}
