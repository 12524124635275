import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { gmStateDefault } from 'src/app/view/gm/gm.state';
import * as gmAction from './gm.actions';
import { GmState } from './gm.state';

export const gmReducer = createReducer(
  _.cloneDeep(gmStateDefault),
  /**
   * GET
   */
  on(gmAction.GET_GM, (state) => ({ ...state, loading: state.loading + 1 })),
  on(gmAction.GET_GM_SUCCESS, (state, action) => {
    // Check if old gmConfig is equal to new gmConfig
    const isGmConfigEqual = !!action.gm && _.isEqual(state.gmConfig, action.gm);
    const updatedLoading = state.loading > 0 ? state.loading - 1 : 0;
    const gmConfigValue = !!action.gm
      ? { ...action.gm, startDate: state.gmConfig.startDate, endDate: state.gmConfig.endDate }
      : gmStateDefault.gmConfig;

    return {
      ...state,
      gmConfig: isGmConfigEqual ? state.gmConfig : gmConfigValue,
      gmConfigEdit: isGmConfigEqual ? state.gmConfigEdit : gmConfigValue,
      loading: updatedLoading,
      hasGmConfigInfo: !action.gm,
      shouldRenderMap: isGmConfigEqual ? state.shouldRenderMap : !!action.gm,
    };
  }),
  on(gmAction.GET_GM_FAIL, (state) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(gmAction.GET_FIELDS, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(gmAction.GET_FIELDS_SUCCESS, (state, action) => ({
    ...state,
    fields: action.fields,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(gmAction.GET_FIELDS_FAIL, (state) => ({
    ...state,
    fields: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(gmAction.GET_GLEBES, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(gmAction.GET_GLEBES_SUCCESS, (state, action) => ({
    ...state,
    glebes: action.glebes,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(gmAction.GET_GLEBES_FAIL, (state) => ({
    ...state,
    glebes: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(gmAction.GET_OCCURRENCES, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(gmAction.GET_OCCURRENCES_SUCCESS, (state, action) => ({
    ...state,
    occurrences: action.occurrences,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(gmAction.GET_OCCURRENCES_FAIL, (state) => ({
    ...state,
    occurrences: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(gmAction.GET_CROPS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(gmAction.GET_CROPS_SUCCESS, (state, action) => ({
    ...state,
    crops: action.crops,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(gmAction.GET_CROPS_FAIL, (state) => ({
    ...state,
    crops: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(gmAction.GET_TRAPS, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(gmAction.GET_TRAPS_SUCCESS, (state, action) => ({
    ...state,
    traps: action.traps,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(gmAction.GET_TRAPS_FAIL, (state) => ({
    ...state,
    traps: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  /**
   * UPDATE
   */
  on(gmAction.UPDATE_GM_EDIT, (state, action) => ({
    ...state,
    gmConfigEdit: _.cloneDeep(action.gm),
  })),
  on(gmAction.UPDATE_GM, (state) => ({
    ...state,
    shouldRenderMap: false,
    loading: state.loading + 1,
  })),
  on(gmAction.UPDATE_GM_SUCCESS, (state) => ({
    ...state,
    shouldRenderMap: true,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(gmAction.UPDATE_GM_FAIL, (state) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  }))
);

export function reducer(state: GmState | undefined, action: Action): GmState {
  return gmReducer(state, action);
}
