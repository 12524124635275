/**
 * NOTE - Comentários e Refatoração de código 19/05/2021
 */

import * as farmAction from './farm.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ApplicationService } from '../../services/application.service';
import { GenericFirestoreService } from '../../services/generic-firestore.service';
import { TranslateTypes } from '../../services/translation.service';
import { MyToastrService } from '../../services/toastr.service';
import { GenericHttpService } from '../../services/generic-http.service';
import { Farm, WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { UpdateFarmForm } from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';
import { Role, Status } from '@tarvos-ag/tarvos-firestore-models/src/enums';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FarmEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private genericHttpService: GenericHttpService,
    private applicationService: ApplicationService,
    private labelPipe: LabelPipe,

    private translateService: TranslateService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço para listar glebas.
   */
  public getFarms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmAction.GET_FARMS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Farm>(`${this.applicationService.getCustomerRefDatabase()}/farms`)
          .pipe(
            map((farms: Array<Farm>) => farmAction.GET_FARMS_SUCCESS({ farms })),
            catchError((error) => of(farmAction.GET_FARMS_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para listar gestores.
   */
  public getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmAction.GET_USERS),
      switchMap(() => {
        const combineList = [
          {
            ref: `${this.applicationService.getUserRefDatabase()}`,
            config: {
              queryFn: (ref: any) =>
                ref
                  .where('role', '==', Role.admin)
                  .where('customersIds', 'array-contains', this.applicationService.getCustomerId()),
            },
          },
          {
            ref: `${this.applicationService.getUserRefDatabase()}`,
            config: {
              queryFn: (ref: any) =>
                ref
                  .where('role', '==', Role.operator)
                  .where('customersIds', 'array-contains', this.applicationService.getCustomerId()),
            },
          },
        ];

        return this.genericFirestoreService.getCombine<WebUser>(combineList, 'name', 'asc').pipe(
          map((users: Array<WebUser>) => farmAction.GET_USERS_SUCCESS({ users })),
          catchError((error) => of(farmAction.GET_USERS_FAIL(error)))
        );
      })
    )
  );

  /**
   * Este método chama um serviço para cadastrar fazenda.
   */
  public createFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmAction.CREATE_FARM),
      mergeMap(async (action) => {
        return this.genericHttpService
          .create('createFarm', action.farm)
          .toPromise()
          .then(async (farm: any) => {
            this.genericHttpService
              .create('createHarvest', {
                harvestData: { ...action.farm.farmData.harvest, status: Status.created },
                customerId: this.applicationService.getCustomerId(),
                farmId: farm.id,
              })
              .toPromise()
              .then(async (harvest: any) => {
                const user = {
                  farms: [{ farmId: farm.id, harvestId: harvest.id }],
                  customerId: this.applicationService.getCustomerId(),
                  customerName: this.applicationService.getCustomer()?.name,
                  usersIds: [this.applicationService.getUserId()],
                };
                await this.genericHttpService.update('updateUsersCustomers', user).toPromise();
              });
            this.toastrService.success(
              this.labelPipe.transform(this.trans.services.create.farmSuccess)
            );
            return farmAction.CREATE_FARM_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              this.labelPipe.transform(
                this.translateService.instant(
                  `${
                    error.code !== undefined
                      ? error.code
                      : error.error.code !== undefined
                      ? error.error.code
                      : error.error.internalCode
                  }`
                )
              )
            );
            return farmAction.CREATE_FARM_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para atualizar fazendas.
   */
  public updateFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmAction.UPDATE_FARM),
      mergeMap((action) => {
        return this.genericFirestoreService
          .update<UpdateFarmForm>(
            `${this.applicationService.getCustomerRefDatabase()}/farms`,
            action.farm
          )
          .then(() => {
            this.toastrService.success(
              this.labelPipe.transform(this.trans.services.update.farmSuccess)
            );
            return farmAction.UPDATE_FARM_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              this.labelPipe.transform(
                this.translateService.instant(
                  `${
                    error.code !== undefined
                      ? error.code
                      : error.error.code !== undefined
                      ? error.error.code
                      : error.error.internalCode
                  }`
                )
              )
            );
            return farmAction.UPDATE_FARM_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para remover fazendas.
   */
  public removeFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmAction.REMOVE_FARM),
      mergeMap((action) => {
        return this.genericHttpService
          .remove<Farm>(
            `removeFarm?customerId=${this.applicationService.getCustomerId()}&ids=${action.ids}`
          )
          .toPromise()
          .then((resp: Array<{ id: string; code: string; status: string }>) => {
            resp.forEach((data: { id: string; code: string; status: string }) => {
              if (data.code.includes('success')) {
                this.toastrService.success(
                  this.labelPipe.transform(this.translateService.instant(data.code))
                );
              } else {
                this.toastrService.error(
                  this.labelPipe.transform(this.translateService.instant(data.code))
                );
              }
            });
            return farmAction.REMOVE_FARM_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              this.labelPipe.transform(
                this.translateService.instant(
                  `${
                    error.code !== undefined
                      ? error.code
                      : error.error.code !== undefined
                      ? error.error.code
                      : error.error.internalCode
                  }`
                )
              )
            );
            return farmAction.REMOVE_FARM_FAIL(error);
          });
      })
    )
  );
}
