import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ImportKmz } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import * as importKmzAction from './import-kmz.actions';
import { ApplicationService } from '../../services/application.service';
import { GenericFirestoreService } from '../../services/generic-firestore.service';

@Injectable()
export class ImportKmzEffects {
  constructor(
    private actions$: Actions,
    private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService
  ) {}

  /**
   * Este método chama um serviço para listar gleba.
   */
  public getListImportedKmz$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importKmzAction.GET_LIST_KMZ),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<ImportKmz>(`${this.applicationService.getFarmRefDatabase()}/kmz`)
          .pipe(
            map((kmz: Array<ImportKmz>) => importKmzAction.GET_LIST_KMZ_SUCCESS({ kmz })),
            catchError((error) => of(importKmzAction.GET_LIST_KMZ_FAIL(error)))
          )
      )
    );
  });
}
