<div class="page">
  <app-header class="app-header"></app-header>
  <img src="./../../../assets/images/logo_h_cor1.png" alt="Tarvos logo" style="height: 30px" />
  <div class="">
    <h1 class="code-number">404</h1>
    <p class="">{{ trans.label.pageNotFound | translate }}</p>
  </div>

  <button
    class="navigate-button"
    type="button"
    mat-flat-button
    [color]="'primary'"
    (click)="nagivateToHomePage()"
  >
    {{ trans.button.navigateToHomePage | translate }}
  </button>
</div>
