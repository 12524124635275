import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import * as importKmzFormAction from './import-kmz-form.actions';
import { ImportKmzFormService } from '../../../services/import-kmz-form.service';
import { MyToastrService } from '../../../services/toastr.service';
import { TranslateTypes } from '../../../services/translation.service';

@Injectable()
export class ImportKmzFormEffects {
  constructor(
    private actions$: Actions,
    private importKmzFormService: ImportKmzFormService,
    private toastrService: MyToastrService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço para converter um kmz
   */
  public parseKmz$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importKmzFormAction.PARSE_KMZ),
      mergeMap((action) =>
        this.importKmzFormService
          .parseKmz(action.kmzFile)
          .then((polygons) => {
            return importKmzFormAction.PARSE_KMZ_SUCCESS({ polygons });
          })
          .catch((error) => {
            return importKmzFormAction.PARSE_KMZ_FAIL(error);
          })
      )
    );
  });

  /**
   * Este método chama um serviço para cadastrar os polígonos do kmz.
   */
  public createKmzPolygon$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importKmzFormAction.CREATE_KMZ_POLYGON),
      mergeMap((action) =>
        this.importKmzFormService
          .createKmzPolygon(action.kmzPolygons)
          .then(() => {
            this.toastrService.success(this.trans.services.create.kmzImportSuccess);
            return importKmzFormAction.CREATE_KMZ_POLYGON_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return importKmzFormAction.CREATE_KMZ_POLYGON_FAIL(error);
          })
      )
    );
  });
}
