import { Customer, Farm, Point } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export interface RouteOptimizationState {
  customers: Customer[];
  farms: Farm[];
  routeSegments: Array<Array<{ lat: number; lng: number }>> | null;
  optimizedOrder: Point[];
  durations: number[];
  totalDuration: number;
  loadingCustomers: boolean;
  loadingFarms: boolean;
  error: any;
}

export const initialRouteOptimizationState: RouteOptimizationState = {
  customers: [],
  farms: [],
  routeSegments: null,
  optimizedOrder: [],
  durations: [],
  totalDuration: 0,
  loadingCustomers: false,
  loadingFarms: false,
  error: null,
};
