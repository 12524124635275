import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateTypes } from 'src/app/services/translation.service';

@Component({
  selector: 'app-remove-modal',
  templateUrl: './remove-modal.component.html',
  styleUrls: ['./remove-modal.component.scss'],
})
export class RemoveModalComponent {
  constructor(
    public trans: TranslateTypes,
    @Inject(MAT_DIALOG_DATA) public data: { itemIds: Array<string>; title: string; message: string }
  ) {}
}
