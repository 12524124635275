import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UPDATE_USER_FARM_AND_HARVEST_SELECTED } from 'src/app/view/header/header.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { FarmComponent } from '../../farm/farm.component';
import { HarvestComponent } from '../../harvest/harvest.component';
import { Farm, WebUser, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { Subscription } from 'rxjs';
import { LabelPipe } from 'src/app/pipes/label.pipe';

@Component({
  selector: 'app-select-farm-and-crop',
  templateUrl: './select-farm-and-crop.component.html',
  styleUrls: ['./select-farm-and-crop.component.scss'],
})
export class SelectFarmAndCropComponent implements OnDestroy {
  @Input() public farms: Array<Farm> = [];
  @Input() public harvests: Array<Harvest> = [];
  @Input() public user!: WebUser;

  public farmTitle!: string;
  public harvestTitle!: string;
  public subscribe!: Subscription;

  constructor(
    private store: Store<any>,
    private translateService: TranslateService,
    private dialog: MatDialog,
    public applicationService: ApplicationService,
    private labelPipe: LabelPipe,

    public trans: TranslateTypes
  ) {
    this.farmTitle = this.labelPipe.transform(this.trans.label.farm);
    this.harvestTitle = this.labelPipe.transform(this.trans.label.harvest);

    this.subscribe = this.applicationService.getUserObservable().subscribe((user) => {
      this.farmTitle = this.labelPipe.transform(this.trans.label.farm);
      this.harvestTitle = this.labelPipe.transform(this.trans.label.harvest);
    });
  }

  /**
   * Este método retorna o nome da fazenda selecionada
   */
  public getFarmName(): string | null {
    if (this.farms?.length > 0 && this.user.lastSelectedFarmId) {
      const farm = this.farms.filter((item: Farm) => item.id === this.user.lastSelectedFarmId)[0];
      return farm ? farm.name : null;
    }

    return null;
  }

  /**
   * Este método retorna o nome da safra selecionada
   */
  public getHarvestName(): string | null {
    if (this.harvests?.length > 0 && this.user.lastSelectedHarvestId) {
      const harvest = this.harvests.filter(
        (x: Harvest) => x.id === this.user.lastSelectedHarvestId
      )[0];
      if (harvest) {
        return `${harvest.name} - ${this.translateService.instant(
          this.trans.enums[harvest.status]
        )}`;
      }
    }

    return null;
  }

  /**
   * Este método abre a lista de fazendas
   */
  public openModalFarm(): void {
    this.dialog.open(FarmComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-800'],
    });
  }

  /**
   * Este método abre a lista de safras
   */
  public openModalHarvest(): void {
    this.dialog.open(HarvestComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-800'],
    });
  }

  /**
   * Este método filtra as safra por fazenda
   */
  public getHarvestsByFarm(farmId: string): Array<Harvest> {
    const result = this.harvests.filter((harvest: any) => harvest.farmId === farmId);
    result.sort((a: any, b: any) =>
      a.harvestPeriod.toString().localeCompare(b.harvestPeriod, undefined, { numeric: true })
    );
    return result
      .sort((a: any, b: any) =>
        a.currentYear.seconds
          .toString()
          .localeCompare(b.currentYear.seconds.toString(), undefined, { numeric: true })
      )
      .reverse();
  }

  /**
   * Este método recebe os ids da fazenda e safra e atualiza o usuário na aplicação e no banco
   * @param farmId id da fazenda
   * @param harvestId id da safra
   */
  public menuSelectionChange(farmId: string, harvestId: string): void {
    this.user.lastSelectedFarmId = farmId;
    this.user.lastSelectedHarvestId = harvestId;
    const customerId = this.user.lastSelectedCustomerId;

    if (this.user.gmConfig) {
      const userGmConfig = this.user.gmConfig[customerId];

      if (userGmConfig) {
        this.user.gmConfig[customerId].farmId = farmId;
        this.user.gmConfig[customerId].harvestId = harvestId;
      } else {
        this.user.gmConfig[customerId] = {
          farmId: farmId,
          harvestId,
        };
      }
    }
    this.store.dispatch(UPDATE_USER_FARM_AND_HARVEST_SELECTED({ user: this.user }));
  }
  /**
   * Este método é executado quando o componente é destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }
}
