<div class="material-container-system">
  <form class="custom-form-field" [formGroup]="firstFormGroup">
    <mat-tab-group animationDuration="0ms">
      <!-- Unidades de Medidas -->
      <mat-tab [label]="trans.label.controlLevel | translate">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <mat-card class="material-top-card-border">
            <mat-card-content>
              <div fxLayout="row wrap">
                <!-- Área do nível de controle mad -->
                <div fxLayout="column" fxFlex="100%" fxFlex.gt-xs="0 1 100%" *ngIf="this.hasMad()">
                  <span class="titleMad">MAD</span>
                  <div fxLayout="row rap">
                    <mat-form-field fxFlex="50%" fxFlex.gt-xs="0 1 50%">
                      <input
                        matInput
                        type="number"
                        placeholder="{{ trans.label.controlLevel | translate }}"
                        [formControlName]="'controlLevel'"
                      />
                      <span matSuffix> ( {{ trans.enums['UND'] | translate }} ) &nbsp;</span>
                      <mat-error>
                        {{
                          getInputMessageError(
                            'controlLevel',
                            firstFormGroup.get('controlLevel')?.errors
                          ) | translate
                        }}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="50%" fxFlex.gt-xs="0 1 50%">
                      <input
                        matInput
                        type="number"
                        placeholder="{{ trans.label.damageLevel | translate }}"
                        [formControlName]="'damageLevel'"
                      />
                      <span matSuffix> ( {{ trans.enums['UND'] | translate }} ) &nbsp;</span>
                      <mat-error>
                        {{
                          getInputMessageError(
                            'damageLevel',
                            firstFormGroup.get('damageLevel')?.errors
                          ) | translate
                        }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-card-content>
            <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
              <button
                mat-button
                color="primary"
                [disabled]="firstFormGroup.invalid"
                *ngIf="applicationService.hasEditPermission(operation.update)"
                (click)="onFormClick()"
              >
                {{ trans.button.save | translate }}
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>
