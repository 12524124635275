import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { Observable } from 'rxjs/Observable';
import { gmTabsDefault, GmTabsState } from '../../gm-tabs.state';
import { Subscription } from 'rxjs';
import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { select, Store } from '@ngrx/store';
import { GET_MOVING_AVERAGE_CHART } from 'src/app/view/gm-tabs/gm-tabs.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Field } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-moving-average-modal',
  templateUrl: './moving-average-modal.component.html',
  styleUrls: ['./moving-average-modal.component.scss'],
})
export class MovingAverageModalComponent implements OnInit, OnDestroy {
  public reportState$: Observable<GmTabsState>;
  public state: GmTabsState = gmTabsDefault;
  public subscribe!: Subscription;
  public formGroup!: FormGroup;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MovingAverageModalComponent>,
    public translateService: TranslateService,
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private store: Store<any>,

    @Inject(MAT_DIALOG_DATA) public data: { field: Field; gm: GmConfiguration }
  ) {
    this.formGroup = this.formBuilder.group({ period: [3, [Validators.required]] });
    this.reportState$ = this.store.pipe(select('gmTabs'));
  }

  public ngOnInit(): void {
    this.subscribe = this.reportState$.subscribe((state: GmTabsState) => {
      this.state = state;
    });

    this.getMovingAverage();
  }

  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.state.movingAverageChart = null;
  }

  public getMovingAverage(matSelect?: MatSelect): void {
    if (matSelect === undefined || !matSelect.panelOpen) {
      const { field, gm } = this.data;
      const { startDate, endDate, trapOccurrenceId, trapCropId } = gm
        ? gm
        : ({} as GmConfiguration);
      const period = this.formGroup.get('period')?.value;

      this.store.dispatch(
        GET_MOVING_AVERAGE_CHART({
          fieldId: field.id,
          occurrenceId: trapOccurrenceId,
          cropId: trapCropId,
          period: period,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }
  }

  /**
   * This is the method that close modal
   */
  public closeModal() {
    this.dialogRef.close();
  }
}
