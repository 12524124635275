import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { Observable } from 'rxjs/Observable';
import { gmTabsDefault, GmTabsState } from '../../gm-tabs.state';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { GET_GLEBES, GET_TOTAL_BETWEEN_FIELDS_CHART } from 'src/app/view/gm-tabs/gm-tabs.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Field, Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import * as moment from 'moment';

@Component({
  selector: 'app-total-between-fields-modal',
  templateUrl: './total-between-fields-modal.component.html',
  styleUrls: ['./total-between-fields-modal.component.scss'],
})
export class TotalBetweenFieldsModalComponent implements OnInit, OnDestroy {
  public reportState$: Observable<GmTabsState>;
  public state: GmTabsState = gmTabsDefault;
  public subscribe!: Subscription;
  public formGroup!: FormGroup;
  public moment: any = moment;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TotalBetweenFieldsModalComponent>,
    public translateService: TranslateService,
    public trans: TranslateTypes,
    public applicationService: ApplicationService,
    public zone: NgZone,
    private store: Store<any>,
    private formBuilder: FormBuilder,

    @Inject(MAT_DIALOG_DATA) public data: { field: Field; gm: GmConfiguration }
  ) {
    this.formGroup = this.formBuilder.group({
      fieldIds: [[], [Validators.required]],
      glebeIds: [[], [Validators.required]],
    });
    this.store.dispatch(GET_GLEBES());
    this.reportState$ = this.store.pipe(select('gmTabs'));
  }

  public ngOnInit(): void {
    this.subscribe = this.reportState$.subscribe((state: GmTabsState) => {
      this.state = state;
    });

    this.getNearestFields();
    this.getTotalBetween();
  }

  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.state.totalBetweenFieldsChart = null;
  }

  public getFieldIdsByGlebe(): Array<string> {
    return this.state.fields
      .filter((field) => {
        return this.getGlebesByIds()
          .map((glebe) => glebe.id)
          .includes(field.glebe.id);
      })
      .map((field) => field.id);
  }
  public selectAllFields(): void {
    const fieldIds = this.getFieldIdsByGlebe();
    this.formGroup.get('fieldIds')?.setValue(fieldIds);
  }
  public deselectAllFields(): void {
    this.formGroup.get('fieldIds')?.setValue([]);
  }

  public getTotalBetween(matSelect?: MatSelect): void {
    if (matSelect === undefined || !matSelect.panelOpen) {
      const { gm } = this.data;
      const { startDate, endDate, trapOccurrenceId, trapCropId } = gm
        ? gm
        : ({} as GmConfiguration);
      const ids = this.formGroup.get('fieldIds')?.value;

      this.store.dispatch(
        GET_TOTAL_BETWEEN_FIELDS_CHART({
          fieldIds: ids,
          occurrenceId: trapOccurrenceId,
          startDate: startDate,
          endDate: endDate,
          cropId: trapCropId,
        })
      );
    }
  }

  /**
   * Este método retorna uma lista com os 10 talhões mais próximo do ultimo selecionado
   */
  public getNearestFields(): Array<string> {
    const { trapCropId } = this.state.gm ? this.state.gm : ({} as GmConfiguration);

    const fieldCenterBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    this.data.field.polygon.points.forEach((latLng) =>
      fieldCenterBounds.extend(new google.maps.LatLng(latLng.lat, latLng.lng))
    );

    let fields = this.state.fields.filter((field: Field) => field.glebe.cropId === trapCropId);
    fields = fields.length <= 0 ? [this.data.field] : fields;

    const ids = fields.map((field: Field) => {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      field.polygon.points.forEach((latLng) =>
        bounds.extend(new google.maps.LatLng(latLng.lat, latLng.lng))
      );
      return {
        id: field.id,
        meters: google.maps.geometry.spherical.computeDistanceBetween(
          fieldCenterBounds.getCenter(),
          bounds.getCenter()
        ),
      };
    });

    const sliceIds = ids
      .sort((a, b) => (a.meters > b.meters ? 1 : -1))
      .map((element) => element.id)
      .slice(0, 10);

    const glebeIds = this.state.fields
      .filter((field) => sliceIds.some((id) => id === field.id))
      .map((field) => field.glebe.id);
    this.formGroup.get('glebeIds')?.setValue(glebeIds);
    this.formGroup.get('fieldIds')?.setValue(sliceIds);

    return sliceIds;
  }

  extractRegistrationNumber(name: string): string {
    return name.slice(1).padStart(2, '0');
  }

  public selectAllGlebes(): void {
    const glebeIds = this.state.glebes.map((glebe) => glebe.id);
    this.formGroup.get('glebeIds')?.setValue(glebeIds);
  }

  public deselectAllGlebes(): void {
    this.formGroup.get('glebeIds')?.setValue([]);
  }

  /**
   * Este método retorna uma lista de gleba ou a gleba selecionada;
   */
  public getGlebesByIds(): Array<Glebe> {
    const glebes = this.state.glebes;
    const glebeIds = this.formGroup.get('glebeIds')?.value;
    if (glebeIds?.length > 0) {
      return glebes.filter(
        (glebe: Glebe) => glebeIds.filter((id: string) => id === glebe.id).length > 0
      );
    }
    return glebes;
  }

  /**
   * Este método retorna uma lista de field quando selecionar uma gleba;
   */
  public getFieldsByGlebe(glebeId?: string): Array<Field> {
    if (glebeId) {
      return this.getFieldByCrop().filter((field: Field) => field.glebe.id === glebeId);
    }
    return this.getFieldByCrop();
  }

  /**
   * Este método retorna uma lista de talhões filtrado por cultura
   */
  getFieldByCrop(): Array<Field> {
    return this.state.fields.sort((a, b) =>
      this.extractRegistrationNumber(a.glebe.name).localeCompare(
        this.extractRegistrationNumber(b.glebe.name)
      )
    );
  }

  /**
   * Este método recebe um evento ao selecionar a gleba
   * @param glebeIds lista de ids de gleba
   */
  public onChangeGlebe(): void {
    const glebeIds = this.formGroup.get('glebeIds')?.value[0]
      ? this.formGroup.get('glebeIds')?.value
      : this.state.glebes.map((glebe) => glebe.id);
    const fieldIds = this.formGroup.get('fieldIds')?.value;

    if (glebeIds?.length > 0 && fieldIds?.length > 0) {
      const fields = this.state.fields.filter(
        (field: Field) => glebeIds.filter((id: string) => id === field.glebe.id).length > 0
      );
      this.formGroup
        .get('fieldIds')
        ?.setValue(
          fieldIds.filter(
            (id: string) => fields.filter((field: Field) => field.id === id).length > 0
          )
        );
    }
  }

  formatName(prefix: string, name: string): string {
    const registrationNumber = this.extractRegistrationNumber(name);
    return prefix + registrationNumber;
  }

  /**
   * This is the method that close modal
   */
  public closeModal() {
    this.dialogRef.close();
  }
}
