import { Validators } from '@angular/forms';
import { CustomValidators } from '../../utils/custom-validators/custom-validators';
import { Field, Glebe, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const importFieldFormStateDefault = {
  harvests: [],
  glebes: [],
  fields: [],
  loading: 0,
  loadingModal: 0,
  closeModal: false,
} as ImportFieldFormState;

export const firstFormGroup = {
  ids: [[], [Validators.required, CustomValidators.listIsEmpty]],
} as any;

export interface ImportFieldFormState {
  harvests: Array<Harvest>;
  glebes: Array<Glebe>;
  fields: Array<Field>;
  loading: number;
  loadingModal: number;
  closeModal: boolean;
}
