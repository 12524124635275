import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { DESTROY_COMPONENT, GET_GLEBES, REMOVE_GLEBE } from 'src/app/view/glebe/glebe.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { RemoveModalComponent } from '../components/remove-modal/remove-modal.component';
import { CustomTableDirective } from '../utils/custom-table/custom-table';
import { GlebeState, glebeStateDefault } from './glebe.state';
import * as _ from 'lodash';
import * as moment from 'moment';
import { GlebeFormComponent } from './glebe-form/glebe-form.component';
import { ImportGlebeFormComponent } from './import-glebe-form/import-glebe-form.component';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { Operation } from 'src/app/enums/Operation';
@Component({
  selector: 'app-glebe',
  templateUrl: './glebe.component.html',
  styleUrls: ['./glebe.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GlebeComponent extends CustomTableDirective implements OnInit, OnDestroy {
  public glebeState$: Observable<GlebeState>;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public state: GlebeState = glebeStateDefault;
  public operation = Operation;

  constructor(
    private store: Store<any>,
    private translateService: TranslateService,
    private dialog: MatDialog,
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    private labelPipe: LabelPipe
  ) {
    super();
    this.glebeState$ = this.store.pipe(select('glebeState'));

    // deve estar dentro do construtor para ser carregado antes da página estar ativa;
    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === GlebeComponent.name) {
        this.store.dispatch(GET_GLEBES());
      }

      if (key === 'create-glebe') {
        this.openDialog(false, null);
      }

      if (key === 'import-glebe') {
        this.openDialogImporGlebe();
      }
    });
  }

  public ngOnInit(): void {
    this.subscribe = this.glebeState$.subscribe((glebeState: GlebeState) => {
      this.state = glebeState;
      if (this.state.glebes) {
        this.setTableSetting();
      }
    });
    this.store.dispatch(GET_GLEBES());
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.subscribeDataSharing.unsubscribe();
    this.store.dispatch(DESTROY_COMPONENT());
  }

  /**
   * Este método abre a modal para cadastrar ou editar
   * @param edit verifica se é uma edição
   * @param glebe data
   */
  public openDialog(edit: boolean, glebe: Glebe | null): void {
    this.dialog.open(GlebeFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-800'],
      data: {
        edit,
        glebe: edit ? this.state.glebes.filter((x: any) => glebe && glebe.id === x.id)[0] : null,
      },
    });
  }

  /**
   * Este método abre a modal para importar glebas
   */
  public openDialogImporGlebe(): void {
    this.dialog.open(ImportGlebeFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-500'],
      data: this.state.glebes,
    });
  }

  /**
   * Este método abre uma modal de confirmação para remover N itens
   * @param itemIds lista de itemIds
   */
  public removeItems(itemIds: Array<any>): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      disableClose: true,
      width: 'material-dialog-panel-500',
      data: {
        itemIds,
        title:
          itemIds.length > 1
            ? this.labelPipe.transform(this.trans.title.removeGlebes)
            : this.labelPipe.transform(this.trans.title.removeGlebe),
        message:
          itemIds.length > 1
            ? this.labelPipe.transform(this.trans.text.removeAllGlebe)
            : this.labelPipe.transform(this.trans.text.removeGlebe),
      },
    });

    const subs = dialogRef.afterClosed().subscribe((results) => {
      if (results) {
        const ids = results.map((result: any) => result.id);
        this.store.dispatch(REMOVE_GLEBE({ ids }));
      }
      subs.unsubscribe();
    });
  }

  /**
   * Este método define as configurações iniciais da tabela
   * @param state Estado do componente
   */
  //
  public setTableSetting(): void {
    const glebes = _.cloneDeep(this.state.glebes);
    const hasFruitCrop = glebes.some((glebe) => glebe.cropId === 'FRUTAS');

    if (hasFruitCrop) {
      this.state.displayedColumns = [
        'select',
        'name',
        'sector',
        'allotment',
        'cropName',
        'harvestDate',
        'actions',
      ];
      this.state.displayedForms = [
        'name',
        'cropName',
        'sector',
        'allotment',
        'productiveUnit',
        'varieties',
        'harvestDate',
      ];
    }
    this.dataSource = new MatTableDataSource<Glebe>(
      glebes.map((glebe: any) => {
        glebe.name = `${glebe.name} ${glebe.alias ? `(${glebe.alias})` : ''}`;
        glebe.harvestDate = `${
          glebe.finalHarvestDate
            ? moment(glebe.finalHarvestDate.toDate()).format(
                this.translateService.instant(this.trans.text.date)
              )
            : '-'
        }`;

        return glebe;
      })
    );
    this.initTable(this.applicationService.filterColumnByPermission(this.state.displayedColumns));
  }
}
