import * as _ from 'lodash';
import * as moment from 'moment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { DESTROY_COMPONENT, GET_FIELDS, REMOVE_FIELD } from 'src/app/view/field/field.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { RemoveModalComponent } from '../components/remove-modal/remove-modal.component';
import { CustomTableDirective } from '../utils/custom-table/custom-table';
import { FieldFormComponent } from './field-form/field-form.component';
import { FieldState, fieldStateDefault } from './field.state';
import { ReapFormComponent } from './reap-form/reap-form.component';
import { DecimalPipe } from '@angular/common';
import { ImportFieldFormComponent } from './import-field-form/import-field-form.component';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { Router } from '@angular/router';
import { Operation } from 'src/app/enums/Operation';
import { Role } from '@tarvos-ag/tarvos-firestore-models/src/enums';
import { Field } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FieldComponent extends CustomTableDirective implements OnInit, OnDestroy {
  public fieldState$: Observable<FieldState>;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public state: FieldState = fieldStateDefault;
  public operation = Operation;
  public Role = Role;

  constructor(
    private store: Store<any>,
    private translateService: TranslateService,
    private dialog: MatDialog,
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    private decimalPipe: DecimalPipe,
    private router: Router,
    private labelPipe: LabelPipe
  ) {
    super();
    this.fieldState$ = this.store.pipe(select('fieldState'));

    // deve estar dentro do construtor para ser carregado antes da página estar ativa;
    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === FieldComponent.name) {
        this.store.dispatch(GET_FIELDS());
      }

      if (key === 'create-field') {
        this.openDialog(false, null);
      }

      if (key === 'import-field') {
        this.openDialogImporField();
      }
    });
  }

  public ngOnInit(): void {
    this.subscribe = this.fieldState$.subscribe((fieldState: FieldState) => {
      this.state = fieldState;
      if (this.state.fields) {
        this.setTableSetting();
      }
    });
    this.store.dispatch(GET_FIELDS());
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.subscribeDataSharing.unsubscribe();
    this.store.dispatch(DESTROY_COMPONENT());
  }

  /**
   * Este método abre a modal para cadastrar ou editar
   * @param edit verifica se é uma edição
   * @param field data
   */
  public openDialog(edit: boolean, field: Field | null): void {
    this.dialog.open(FieldFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-800'],
      data: {
        edit,
        field: edit ? this.state.fields.filter((x: any) => field && field.id === x.id)[0] : null,
      },
    });
  }

  /**
   * Este método abre a modal para importar talhões
   */
  public openDialogImporField(): void {
    this.dialog.open(ImportFieldFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-500'],
      data: this.state.fields,
    });
  }

  public openComponentImportKmz(): void {
    this.router.navigate(['/home/configuration/import-kmz']);
  }

  /**
   * Este método abre a modal para colher talhão
   */
  public openDialogReap(field: Field | null): void {
    if (field) {
      this.dialog.open(ReapFormComponent, {
        disableClose: true,
        width: 'material-dialog-panel-500',
        data: { fieldId: field.id, fieldName: field.name },
      });
    }
  }

  /**
   * Este método abre uma modal de confirmação para remover N itens
   * @param itemIds lista de itemIds
   */
  public removeItems(itemIds: Array<any>): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      disableClose: true,
      width: '500px',
      data: {
        itemIds,
        title:
          itemIds.length > 1
            ? this.labelPipe.transform(this.trans.title.removeFields)
            : this.labelPipe.transform(this.trans.title.removeField),
        message:
          itemIds.length > 1
            ? this.labelPipe.transform(this.trans.text.removeAllField)
            : this.labelPipe.transform(this.trans.text.removeField),
      },
    });

    const subs = dialogRef.afterClosed().subscribe((results) => {
      if (results) {
        const ids = results.map((result: any) => result.id);
        this.store.dispatch(REMOVE_FIELD({ ids }));
      }
      subs.unsubscribe();
    });
  }

  /**
   * Este método define as configurações iniciais da tabela
   * @param state Estado do componente
   */
  public setTableSetting(): void {
    const fields = _.cloneDeep(this.state.fields);
    const hasCropFruit = fields.some((field) => field.glebe.cropId === 'FRUTAS');

    if (hasCropFruit) {
      this.state.displayedColumns = [
        'select',
        'name',
        'glebeName',
        'allotment',
        'productiveUnit',
        'sector',
        'harvestDate',
        'actions',
      ];
      this.state.displayedForms = [
        'name',
        'glebeName',
        'cropName',
        'allotment',
        'productiveUnit',
        'sector',
        'areaString',
        'harvestDate',
      ];
    }

    this.dataSource = new MatTableDataSource<Field>(
      fields.map((field: any) => {
        if (field.glebe.cropId === 'FRUTAS') {
          field.allotment = field.glebe.allotment;
          field.sector = field.glebe.sector;
          field.productiveUnit = field.glebe.productiveUnit;
        }
        field.name = `${field.name} ${field.alias ? `(${field.alias})` : ''}`;
        field.glebeName = `${field.glebe?.name} ${
          field.glebe?.alias ? `(${field.glebe?.alias})` : ''
        }`;
        field.varietiesName = field.glebe.varieties;
        field.cropName = field.glebe.cropName;
        field.harvestDate = field.harvestDate
          ? moment(field.harvestDate.toDate()).format(
              this.translateService.instant(this.trans.text.date)
            )
          : '-';

        const fieldArea = this.applicationService.getSystemSettings()?.fieldArea;

        if (fieldArea) {
          field.areaString = `${this.decimalPipe.transform(
            this.applicationService.getCalculatedArea(field.area.hectares),
            '.2-2'
          )} ${this.translateService.instant(this.trans.enums[fieldArea])}`;
        }

        return field;
      })
    );
    this.initTable(
      this.applicationService.filterColumnByPermission(this.state.displayedColumns, true)
    );
  }
}
