import { AgmMap } from '@agm/core';
import { AfterViewInit, Component, Input, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { GeolocationPoint, Point } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { Area, Glebe, KmzPolygon } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

declare const google: any;

@Component({
  selector: 'app-google-maps-import-kmz',
  templateUrl: './google-maps-import-kmz.component.html',
  styleUrls: ['./google-maps-import-kmz.component.scss'],
})
export class GoogleMapsImportKmzComponent implements AfterViewInit, OnDestroy {
  @ViewChild('AgmMap', { static: true }) public agmMap!: AgmMap;
  @ViewChild('agmSnazzyInfoWindow', { static: true }) public agmSnazzyInfoWindow: any;
  @Input() public height = '500px';
  @Input() public set setOnPolygon(polygons: any) {
    this.setPolygon(polygons);
  }

  public readonly mapTypeId: any = 'satellite';
  public readonly zoom = 20;
  public readonly fitBounds = true;
  public polygons!: Array<KmzPolygon>;
  public infoWindowMessage: KmzPolygon | null = null;
  public coordinates: GeolocationPoint | null;
  public subscriber!: Subscription;
  public map: any = {};

  constructor(
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    public zone: NgZone
  ) {
    const farm = this.applicationService.getFarm();
    this.coordinates = farm ? farm.coordinates : null;
  }

  /**
   * Este método é executado quando o componente é totalmente carregado
   */
  public ngAfterViewInit(): void {
    this.subscriber = this.agmMap.mapReady.subscribe((map) => {
      this.map = map;

      map.setOptions({
        tilt: 0,
        zoom: this.zoom,
        scaleControl: true,
        gestureHandling: 'greedy',
      });
    });
  }

  /**
   * Este método é executado quando o componente é destruído
   */
  public ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  /**
   * Este método recebe um polígono faz o mapeamento e desenha no mapa
   * @param polygon Polígono
   */
  public setPolygon(polygons: Array<KmzPolygon>): void {
    if (polygons.length > 0) {
      this.polygons = polygons
        .map((polygon: KmzPolygon) => {
          const obj = new google.maps.Polygon({ paths: polygon.points });
          const squareMeters: number = google.maps.geometry.spherical.computeArea(
            obj.getPath().getArray()
          );
          const area: Area = { hectares: parseFloat((squareMeters / 10000).toFixed(8)) };
          return { ...polygon, area };
        })
        .sort((a: any, b: any) =>
          a.area.hectares
            .toString()
            .localeCompare(b.area.hectares.toString(), undefined, { numeric: true })
        )
        .reverse();

      this.setFitBounds();
    } else {
      this.polygons = [];
    }
  }

  /**
   * Este método da um zoom e centraliza os polígonos na tela
   */
  public setFitBounds(): void {
    setTimeout(() => {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      if (this.polygons && this.polygons.length > 0) {
        this.polygons.forEach((polygon: { name: string; points: Array<Point> }) => {
          polygon.points.forEach((point) => {
            bounds.extend(new google.maps.LatLng(point));
          });
        });
      } else {
        bounds.extend(new google.maps.LatLng(this.coordinates?.lat, this.coordinates?.lng));
      }
      this.map.fitBounds(bounds);
    }, 500);
  }

  /**
   * Este método transforma uma string em número
   * @param value valor a ser convertido
   */
  public convertToNumber(value: string | number | null | undefined): number {
    if (value) {
      return parseFloat(value.toString());
    }
    return 0;
  }

  /**
   * Este método abre um popup com as informações do talhão
   * @param position indica a posição em latLng
   * @param infoWindow popup componente
   */
  public openInfoWindow(position: any, polygon: KmzPolygon): void {
    setTimeout(() => {
      if (this.agmSnazzyInfoWindow) {
        this.infoWindowMessage = polygon;
        this.agmSnazzyInfoWindow.latitude = position.latLng.lat();
        this.agmSnazzyInfoWindow.longitude = position.latLng.lng();
        this.agmSnazzyInfoWindow._updatePosition();
        this.agmSnazzyInfoWindow._openInfoWindow();
      } else {
        this.closeInfoWindow();
      }
    }, 100);
  }

  /**
   * Este método fecha um popup com as informações do talhão
   * @param infoWindow popup componente
   */
  public closeInfoWindow(): void {
    setTimeout(() => {
      if (this.agmSnazzyInfoWindow) {
        this.agmSnazzyInfoWindow._closeInfoWindow();
        this.infoWindowMessage = null;
      }
    }, 100);
  }

  /**
   * Este método seleciona um talhão
   */
  public selectPolygon(index: number): void {
    this.polygons[index].selected = !this.polygons[index].selected;
  }

  /**
   * Este método retorna uma lista de polígonos selecionados
   */
  public getPolygonsSelected(): Array<KmzPolygon> {
    return this.polygons.filter((polygon: KmzPolygon) => polygon.selected);
  }

  /*
   * Este método retorna uma lista de polígonos selecionados e com gleba definidas
   */
  public getPolygonsDefinedAndSelected(): Array<KmzPolygon> {
    return this.polygons.filter((polygon: KmzPolygon) => polygon.selected && polygon.defined);
  }

  /**
   * Este método retorna uma lista de polígonos selecionados
   */
  public getPolygonsDefined(): Array<KmzPolygon> {
    return this.polygons.filter((polygon: KmzPolygon) => polygon.defined);
  }

  /**
   * Este método retorna o polígono para o estado normal
   */
  public resetPolygons(): void {
    this.polygons = this.polygons.map((polygon: KmzPolygon) => {
      if (polygon.selected && polygon.defined) {
        return { ...polygon, glebe: null, selected: false, defined: false };
      } else {
        return polygon;
      }
    });
  }

  /**
   * Este método define uma gleba para cada polígono
   * @param item Gleba selecionada
   */
  public setGlebeToPolygons(item: Glebe): void {
    const glebe = { ...item } as unknown as Glebe;
    this.polygons = this.polygons.map((polygon: KmzPolygon) => {
      if (polygon.selected) {
        return { ...polygon, glebe, selected: false, defined: true };
      } else {
        return polygon;
      }
    });
  }
}
