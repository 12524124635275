import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { LoginService } from '../../services/login.service';
import { MyToastrService } from '../../services/toastr.service';
import { TranslateTypes } from '../../services/translation.service';
import * as loginAction from './login.actions';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    public trans: TranslateTypes,
    private toastrService: MyToastrService,
    private router: Router
  ) {}

  /**
   * Este método chama um serviço para logar usuários.
   */
  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginAction.LOGIN),
      mergeMap((action) =>
        this.loginService
          .login(action.user)
          .then((user) => loginAction.LOGIN_SUCCESS({ user }))
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return loginAction.LOGIN_FAIL(error);
          })
      )
    )
  );

  /**
   * Este método chama um serviço para atualizar senha.
   */
  public recoverPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginAction.RECOVER_PASSWORD),
      mergeMap((action) => {
        return this.loginService
          .recoverPassword(action.email)
          .then(() => {
            this.toastrService.success(this.trans.services.update.recoverPasswordSuccess);
            this.router.navigate(['/login']);
            return loginAction.RECOVER_PASSWORD_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return loginAction.RECOVER_PASSWORD_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para verificar o token para alterar a senha
   */
  public checkToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginAction.CHECK_TOKEN),
      mergeMap((action) =>
        this.loginService
          .checkToken(action.token)
          .then((res) =>
            loginAction.CHECK_TOKEN_SUCCESS({ oobCode: res.oobCode, decoded: res.decoded })
          )
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return loginAction.CHECK_TOKEN_FAIL(error);
          })
      )
    )
  );

  public updatePassWord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginAction.UPDATE_PASSWORD),
      mergeMap((action) =>
        this.loginService
          .updatePassword(action.oobCode, action.decoded, action.password)
          .then(() => loginAction.UPDATE_PASSWORD_SUCCESS())
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return loginAction.UPDATE_PASSWORD_FAIL(error);
          })
      )
    )
  );
}
