import { createAction, props } from '@ngrx/store';
import { HeaderWebUser } from 'src/app/interfaces/User';
import { Farm, Harvest, Notification } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_USER = createAction('[Header Component] GET_USER');
export const GET_USER_SUCCESS = createAction(
  '[Header Component] GET_USER_SUCCESS',
  props<{ user: HeaderWebUser }>()
);
export const GET_USER_FAIL = createAction(
  '[Header Component] GET_USER_FAIL',
  props<{ error: any }>()
);

export const GET_FARMS = createAction('[Header Component] GET_FARMS');
export const GET_FARMS_SUCCESS = createAction(
  '[Header Component] GET_FARMS_SUCCESS',
  props<{ farms: Array<Farm> }>()
);
export const GET_FARMS_FAIL = createAction(
  '[Header Component] GET_FARMS_FAIL',
  props<{ error: any }>()
);

export const GET_HARVESTS = createAction(
  '[Header Component] GET_HARVESTS',
  props<{ farms: Array<Farm> }>()
);
export const GET_HARVESTS_SUCCESS = createAction(
  '[Header Component] GET_HARVESTS_SUCCESS',
  props<{ harvests: Array<Harvest> }>()
);
export const GET_HARVESTS_FAIL = createAction(
  '[Header Component] GET_HARVESTS_FAIL',
  props<{ error: any }>()
);

export const GET_NOTIFICATION = createAction('[Header Component] GET_NOTIFICATION');
export const GET_NOTIFICATION_SUCCESS = createAction(
  '[Header Component] GET_NOTIFICATION_SUCCESS',
  props<{ notifications: Array<Notification> }>()
);
export const GET_NOTIFICATION_FAIL = createAction(
  '[Header Component] GET_NOTIFICATION_FAIL',
  props<{ error: any }>()
);

/**
 * Atualizar
 */
export const LOAD_USER_UPDATE = createAction(
  '[Modal Header Component] LOAD_USER_UPDATE',
  props<{ user: HeaderWebUser }>()
);
export const UPDATE_USER = createAction(
  '[Modal Header Component] UPDATE_USER',
  props<{ user: HeaderWebUser }>()
);
export const UPDATE_USER_SUCCESS = createAction('[Modal Header Component] UPDATE_USER_SUCCESS');
export const UPDATE_USER_FAIL = createAction(
  '[Modal Header Component] UPDATE_USER_FAIL',
  props<{ error: any }>()
);

export const UPDATE_THEME = createAction(
  '[Modal Header Component] UPDATE_THEME',
  props<{ user: HeaderWebUser }>()
);
export const UPDATE_THEME_SUCCESS = createAction('[Modal Header Component] UPDATE_THEME_SUCCESS');
export const UPDATE_THEME_FAIL = createAction(
  '[Modal Header Component] UPDATE_THEME_FAIL',
  props<{ error: any }>()
);

export const UPDATE_USER_AND_PROFILE_IMAGE = createAction(
  '[Modal Header Component] UPDATE_USER_AND_PROFILE_IMAGE',
  props<{ user: HeaderWebUser }>()
);
export const UPDATE_USER_AND_PROFILE_IMAGE_SUCCESS = createAction(
  '[Modal Header Component] UPDATE_USER_AND_PROFILE_IMAGE_SUCCESS'
);
export const UPDATE_USER_AND_PROFILE_IMAGE_FAIL = createAction(
  '[Modal Header Component] UPDATE_USER_AND_PROFILE_IMAGE_FAIL',
  props<{ error: any }>()
);

export const UPDATE_USER_CUSTOMER_SELECTED = createAction(
  '[Modal Header Component] UPDATE_USER_CUSTOMER_SELECTED',
  props<{ user: HeaderWebUser }>()
);
export const UPDATE_USER_CUSTOMER_SELECTED_SUCCESS = createAction(
  '[Modal Header Component] UPDATE_USER_CUSTOMER_SELECTED_SUCCESS'
);
export const UPDATE_USER_CUSTOMER_SELECTED_FAIL = createAction(
  '[Modal Header Component] UPDATE_USER_CUSTOMER_SELECTED_FAIL',
  props<{ error: any }>()
);

export const UPDATE_USER_FARM_AND_HARVEST_SELECTED = createAction(
  '[Modal Header Component] UPDATE_USER_FARM_AND_HARVEST_SELECTED',
  props<{ user: HeaderWebUser }>()
);
export const UPDATE_USER_FARM_AND_HARVEST_SELECTED_SUCCESS = createAction(
  '[Modal Header Component] UPDATE_USER_FARM_AND_HARVEST_SELECTED_SUCCESS'
);
export const UPDATE_USER_FARM_AND_HARVEST_SELECTED_FAIL = createAction(
  '[Modal Header Component] UPDATE_USER_FARM_AND_HARVEST_SELECTED_FAIL',
  props<{ error: any }>()
);

export const UPDATE_USER_PASSWORD = createAction(
  '[Modal Header Component] UPDATE_USER_PASSWORD',
  props<{ email: string }>()
);
export const UPDATE_USER_PASSWORD_SUCCESS = createAction(
  '[Modal Header Component] UPDATE_USER_PASSWORD_SUCCESS'
);
export const UPDATE_USER_PASSWORD_FAIL = createAction(
  '[Modal Header Component] UPDATE_USER_PASSWORD_FAIL',
  props<{ error: any }>()
);

export const UPDATE_GM = createAction(
  '[Modal Header Component] UPDATE_GM',
  props<{ notification: Notification }>()
);
export const UPDATE_GM_SUCCESS = createAction('[Modal Header Component] UPDATE_GM_SUCCESS');
export const UPDATE_GM_FAIL = createAction(
  '[Modal Header Component] UPDATE_GM_FAIL',
  props<{ error: any }>()
);

/**
 * Remover
 */
export const CLEAN_NOTIFICATIONS = createAction(
  '[Header Component] CLEAN_NOTIFICATIONS',
  props<{ notificationIds: Array<string> }>()
);
export const CLEAN_NOTIFICATIONS_SUCCESS = createAction(
  '[Header Component] CLEAN_NOTIFICATIONS_SUCCESS'
);
export const CLEAN_NOTIFICATIONS_FAIL = createAction(
  '[Header Component] CLEAN_NOTIFICATIONS_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_MODAL = createAction('[Modal Header Component] HIDE_MODAL');
