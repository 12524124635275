<app-loading [isOpen]="state.loading"></app-loading>
<div fxLayout="column" fxLayoutAlign="center center" style="height: 100vh">
  <img class="material-background-image" src="./../../../assets/images/logo_v_mono3.png" alt="" />
  <div class="material-login-container">
    <img
      *ngIf="applicationService.getIsDarkTheme()"
      src="./../../../assets/images/logo_h_cor2.png"
      alt=""
    />
    <img
      *ngIf="!applicationService.getIsDarkTheme()"
      src="./../../../assets/images/logo_h_cor1.png"
      alt=""
    />
    <h4>{{ trans.title.startSession | translate }}:</h4>

    <form novalidate (keydown)="keyDownLogin($event)" [formGroup]="firstFormGroup">
      <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
        <mat-form-field fxFlex="100%" appearance="outline" [color]="'primary'">
          <mat-label>{{ trans.label.email | translate }}</mat-label>
          <input
            autofocus="true"
            matInput
            type="email"
            id="email"
            name="email"
            formControlName="email"
            placeholder="{{ trans.placeholder.typeTheEmail | translate }}"
          />
        </mat-form-field>
        <mat-form-field fxFlex="100%" appearance="outline" [color]="'primary'">
          <mat-label>{{ trans.label.password | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ trans.placeholder.typeThePassword | translate }}"
            id="password"
            name="password"
            formControlName="password"
            [type]="hide ? 'password' : 'text'"
            autocomplete="on"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            (focus)="hidePasswordFocused = true"
            (blur)="hidePasswordFocused = false"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon fontSet="material-icons-outlined" [color]="'primary'">
              <i *ngIf="!hide" class="las la-eye"></i>
              <i *ngIf="hide" class="las la-eye-slash"></i>
            </mat-icon>
          </button>
        </mat-form-field>
      </div>
    </form>
    <div
      class="material-login-action"
      fxLayout="column"
      fxLayout.gt-xs="row wrap"
      fxLayout.gt-md="row wrap"
    >
      <div fxFlex="100%" fxLayout="row" fxLayoutAlign="end none">
        <a
          mat-button
          [routerLink]="['/recoverPassword']"
          [color]="'primary'"
          (focus)="recoverPasswordFocused = true"
          (blur)="recoverPasswordFocused = false"
        >
          {{ trans.button.forgotPassword | translate }}
        </a>
      </div>
      <div fxFlex="100%">
        <button type="button" mat-flat-button [color]="'primary'" (click)="login()">
          {{ trans.button.signIn | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
