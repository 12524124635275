import { Status } from '@tarvos-ag/tarvos-firestore-models/src/enums';
import { Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const harvestFormStateDefault = {
  harvests: [],
  loading: 0,
  loadingModal: 0,
  closeModal: false,
  harvestEdit: { status: Status.created } as Harvest,
} as HarvestFormState;

export interface HarvestFormState {
  harvests: Array<Harvest>;
  loading: number;
  loadingModal: number;
  closeModal: boolean;
  harvestEdit: Harvest;
}
