<ng-container>
  <mat-toolbar class="no-padding" color="primary">
    <mat-toolbar-row>
      <app-header [sideNav]="sidenav" class="app-header"></app-header>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      [opened]="!mobileQuery.matches"
      #sidenav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      position="start"
      class="menu-sidenav"
    >
      <app-menu
        [sideNav]="sidenav"
        [termsOfUseUrl]="state.termsOfUseUrl"
        [privacyPolicyUrl]="state.privacyPolicyUrl"
      ></app-menu>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
