import { createAction, props } from '@ngrx/store';
import { Farm } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter Farms
 */
export const GET_FARMS_ROUTE_OPTIMIZATION = createAction(
  '[Farms] Get Farms',
  props<{ customerId: string }>()
);
export const GET_FARMS_ROUTE_OPTIMIZATION_SUCCESS = createAction(
  '[Farms] Get Farms Success',
  props<{ farms: Farm[] }>()
);
export const GET_FARMS_ROUTE_OPTIMIZATION_FAIL = createAction(
  '[Farms] Get Farms Fail',
  props<{ error: any }>()
);
