import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Regex, regex } from 'src/app/constants/regex';
import { CREATE_USER, HIDE_MODAL, UPDATE_USER } from 'src/app/view/user/user.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { PHONE_MASK } from '../../../constants/mask';
import { UserState, userStateDefault } from './user.state';
import * as _ from 'lodash';
import {
  CreateUserForm,
  UpdateUserForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';
import { CCC, Role } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('form', { static: true }) public form!: NgForm;

  public userState$: Observable<UserState>;
  public subscribe!: Subscription;
  public state: UserState = userStateDefault;
  public cols!: Observable<number | undefined>;
  public regex: Regex = regex;
  public enumCcc = CCC;
  public phoneMask = PHONE_MASK;

  constructor(
    private store: Store<any>,
    private dialogRef: MatDialogRef<UserFormComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userState$ = this.store.pipe(select('user'));
  }

  /**
   * Este método é executado quando o componente esta sendo atualizado
   */
  public ngOnInit(): void {
    this.subscribe = this.userState$.subscribe((state: UserState) => {
      this.state = state;
      if (state && state.closeModal) {
        this.closeModal();
      }
      if (this.data.edit) {
        this.state.userForm = {
          farmIds: this.state.userEdit.farmIds,
          ccc: this.state.userEdit.ccc,
          email: this.state.userEdit.email,
          name: this.state.userEdit.name,
          phone: this.state.userEdit.phone,
          role: this.state.userEdit.role,
        };
      }
    });
  }

  /**
   * Este método é executado após o detector de mudança padrão concluir a verificação de todo o conteúdo da diretiva
   */
  public ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Método executado quando o componentes não é mais ativo
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método limpa o redux e o formulário, além de fechar a modal
   */
  public closeModal(): void {
    this.form.reset();
    this.store.dispatch(HIDE_MODAL());
    this.dialogRef.close();
  }

  public selectAllFarms(): void {
    const farmIds = this.state.farms.map((farm) => farm.id);
    this.state.userForm.farmIds = farmIds;
  }
  public deselectAllFarms(): void {
    this.state.userForm.farmIds = [];
  }

  /**
   * Este método cadastra ou edita
   */
  public create(): void {
    const userForm = _.cloneDeep(this.state.userForm);

    const createUserForm: CreateUserForm = {
      customerId: this.applicationService.getCustomerId(),
      harvestId: this.applicationService.getHarvestId(),
      userData: userForm,
    };

    if (!this.data.edit) {
      this.store.dispatch(CREATE_USER({ user: createUserForm }));
    } else {
      const userForm = _.cloneDeep(this.state.userForm);
      const UpdateUserForm: UpdateUserForm = {
        ...userForm,
        id: this.state.userEdit.id,
      };

      this.store.dispatch(UPDATE_USER({ user: UpdateUserForm }));
    }
  }

  /**
   * Este método converte um enum em lista
   */
  public getEnumType(): Array<string> {
    return Object.values(CCC);
  }

  /**
   * Este método converte um enum em lista
   */
  public getEnumRole(avoidRoles?: String[]): Array<Role> | null {
    if (avoidRoles) return Object.values(Role).filter((item) => !avoidRoles.includes(item));
    return Object.values(Role);
  }

  /**
   * Este método é executado quando um 'ccc' é selecionado. E limpa o telefone quado a seleção for null
   * @param event valor que foi selecionado
   */
  onCccChange(event: any): void {
    if (!event.value) {
      this.form.form.get('phone')?.setValue('');
    }
  }
}
