import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import * as occurrenceSettingsAction from './occurrence-settings.actions';
import { ApplicationService } from '../../../services/application.service';
import { GenericFirestoreService } from '../../../services/generic-firestore.service';
import { MyToastrService } from '../../../services/toastr.service';
import { TranslateTypes } from '../../../services/translation.service';
import { Crop } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Injectable()
export class OccurrenceSettingsEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço para obter as configurações do sistema.
   */
  public getOccurrenceSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(occurrenceSettingsAction.GET_OCCURRENCE_SETTINGS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<OccurrenceSettings>(
            `${this.applicationService.getHarvestRefDatabase()}/occurrenceSettings`
          )
          .pipe(
            map((occurrenceSettings: Array<OccurrenceSettings>) =>
              occurrenceSettingsAction.GET_OCCURRENCE_SETTINGS_SUCCESS({
                occurrenceSettings: occurrenceSettings[0],
              })
            ),
            catchError((error) => of(occurrenceSettingsAction.GET_OCCURRENCE_SETTINGS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para obter as culturas.
   */
  public getCrops$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(occurrenceSettingsAction.GET_CROPS),
      switchMap(() =>
        this.genericFirestoreService.getAll<Crop>('crops').pipe(
          map((crops: Array<Crop>) => occurrenceSettingsAction.GET_CROPS_SUCCESS({ crops })),
          catchError((error) => of(occurrenceSettingsAction.GET_CROPS_FAIL(error)))
        )
      )
    );
  });

  /**
   * Este método chama um serviço para atualizar configurações do sistema.
   */
  public updateSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(occurrenceSettingsAction.UPDATE_OCCURRENCE_SETTINGS),
      mergeMap((action) => {
        return this.genericFirestoreService
          .update<OccurrenceSettings>(
            `${this.applicationService.getHarvestRefDatabase()}/occurrenceSettings`,
            action.occurrenceSettings
          )
          .then(() => {
            this.toastrService.success(this.trans.services.update.systemSuccess);
            return occurrenceSettingsAction.UPDATE_OCCURRENCE_SETTINGS_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return occurrenceSettingsAction.UPDATE_OCCURRENCE_SETTINGS_FAIL(error);
          });
      })
    )
  );
}
