import { Validators } from '@angular/forms';
import {
  ContractedPlanConsumption,
  Field,
  Point,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Glebe';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import { CustomValidators } from '../../utils/custom-validators/custom-validators';
import { FieldFormData } from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/field';

export const fieldFormStateDefault = {
  system: {} as OccurrenceSettings,
  fields: [],
  glebes: [],
  listOfRandomPoints: [],
  hasTrapLinkedField: false,
  contractedPlanConsumption: null,
  loading: 0,
  loadingModal: 0,
  closeModal: false,
  lastCount: 0,
} as FieldFormState;

export const firstFormGroup: FieldFormData = {
  name: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(64)]],
  alias: [
    null,
    [Validators.minLength(2), Validators.maxLength(64), CustomValidators.whitespaceValidator],
  ],
  glebeId: [null, [Validators.required]],
  fallowing: [false],
  harvested: [false],
  area: [null],
  polygon: [null],
} as any;

export const secondFormGroup = {
  area: [null, [Validators.required]],
  polygon: [null, [Validators.required]],
} as any;

export interface FieldFormState {
  system: OccurrenceSettings;
  fields: Array<Field>;
  glebes: Array<Glebe>;
  listOfRandomPoints: Array<Point>;
  hasTrapLinkedField: boolean;
  contractedPlanConsumption: ContractedPlanConsumption | null;
  loading: number;
  loadingModal: number;
  closeModal: boolean;
  lastCount: number;
}
