import { NameAndId } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const updateUserStateDefault = {
  loading: 0,
  users: [],
  farms: [],
  customers: [],
  selectedCustomerId: '',
  selectedCustomerName: '',
  selectedFarms: [],
} as UpdateUserState;

export interface UpdateUserState {
  loading: number;
  users: Array<NameAndId>;
  farms: Array<NameAndId>;
  customers: Array<NameAndId>;
  selectedCustomerId: string;
  selectedCustomerName: string;
  selectedFarms: Array<string>;
  closeModal?: boolean;
}
