export const title = {
  updateUserCustomers: 'Cadastrar cliente e fazendas em usuários de suporte',
  updateUserCustomersModal: 'Os seguintes usuários serão atualizados: ',
  accumulatedCount: 'Capturas desde a última troca de piso',
  accumulatedChart: 'Capturas acumuladas',
  admin: 'Administrador',
  captions: 'LEGENDAS',
  chooseReportFormat: 'Escolha o formato do seu relatório',
  climate: 'Temperatura e Umidade do Talhão',
  comment: 'Comentário',
  configuration: 'Configurações',
  configureFrequencyReportSendEmailAndWhatsapp:
    'Configurar a Frequência do Envio de Relatórios por Email e WhatsApp.',
  configureSystem: 'Configurações do Sistema',
  configureUnitsOfMeasure: 'Configurar Unidades de Medidas',
  contractedPlan: 'Plano Contratado',
  controlLevelExceeded: 'Ocorrências que Excederam o Nível de Controle',
  createFarm: {
    default: 'Cadastrar Fazenda',
    productiveUnit: 'Cadastrar Unidade Produtiva',
    type: 'farm',
  },
  createHarvest: {
    default: 'Cadastrar Safra',
    year: 'Cadastrar ano',
    cycle: 'Cadastrar ciclo',
    type: 'harvest',
  },
  createNewAccount: 'Cadastrar uma nova conta',
  createNewCustomer: 'Cadastrar um novo cliente',
  createTrap: 'Cadastrar Armadilha',
  createUser: 'Cadastrar Usuário',
  dailyCaptures: 'Capturas diárias',
  deselectAll: 'Desselecionar todos',
  editFarm: {
    default: 'Editar Fazenda',
    productiveUnit: 'Editar Unidade Produtiva',
    type: 'farm',
  },
  editTrap: 'Editar Armadilha',
  editUser: 'Editar Usuário',
  equipments: 'Equipamentos',
  evolutionOfSurveys: 'Acompanhamento de capturas diárias',
  chartMad: 'Acompanhamento MAD',
  Farms: {
    default: 'Fazendas',
    productiveUnit: 'Unidades Produtivas',
    type: 'farm',
  },
  field: {
    default: 'Talhão',
    lot: 'Lote',
    type: 'field',
  },
  filters: 'Filtros',
  forgotPassword: 'Esqueceu sua senha?',
  freePlan: 'Plano Gratuito',
  generalMonitoring: 'Monitoramento Geral',
  routeOptimization: 'Otimização de Rotas',
  glebe: {
    default: 'Gleba',
    productionUnit: 'Unidade de Produção',
    productiveUnit: 'Unidade Produtiva',
    type: 'glebe',
  },
  harvest: {
    default: 'Safra',
    year: 'Ano',
    cycle: 'Ciclo',
    type: 'harvest',
  },
  harvestField: {
    default: 'Colher Talhão',
    lot: 'Colher Lote',
    type: 'field',
  },
  harvests: {
    default: 'Safras',
    year: 'Anos',
    cycle: 'Ciclos',
    type: 'harvest',
  },
  heatMap: 'MAPA DE CALOR',
  imagesCollectedField: 'Imagens Coletadas em Campo',
  importKmz: 'Importar Kmz',
  incidenceOfOccurrence: 'Incidência de Ocorrências',
  infestationEvolution: 'Evolução da Infestação',
  installationDate: 'Data de instalação',
  infoFarm: {
    default: 'Identificação Fazenda',
    productiveUnit: 'Identificação Unidade Produtiva',
    type: 'farm',
  },

  infoField: {
    default: 'Identificação Talhão',
    lot: 'Identificação Lote',
    type: 'field',
  },
  infoGlebe: {
    default: 'Identificação Grupo de Talhões',
    lot: 'Identificação Grupo de Lotes',
    type: 'field',
  },
  installedEquipments: 'Equipamentos instalados',
  kmz: 'Lista de kmz Importados',
  latestOccurrenceMonitoringReport: 'Relatório de Monitoramento de Ocorrência mais Recente',
  listIOfHarvest: {
    default: 'Lista de Safras',
    year: 'Lista de anos',
    cycle: 'Lista de ciclos',
    type: 'harvest',
  },
  listOfFarm: {
    default: 'Lista de Fazendas',
    productiveUnit: 'Lista de Unidades Produtivas',
    type: 'farm',
  },
  listOfField: {
    default: 'Lista de Talhões',
    lot: 'Lista de Lotes',
    type: 'field',
  },
  listOfGlebe: {
    default: 'Lista de Glebas',
    productionUnit: 'Lista de Unidade de Produção',
    productiveUnit: 'Lista de Unidade Produtiva',
    type: 'glebe',
  },
  listOfManager: 'Lista de Gestores',
  listOfTrap: 'Lista de Armadilhas',
  listOfTrapReport: 'Lista de Relatórios de Armadilhas',
  mad: 'MAD',
  managers: 'Gestores',
  movingAverage: 'Média Móvel',
  newPassword: 'Definir uma nova senha',
  noCollectionPoints: 'Não há pontos de coleta',
  noEquipments: 'Não há equipamentos instalados',
  noOccurrencesReport: 'A ocorrência selecionada não foi coletada neste relatório',
  occurrence: 'Ocorrências',
  pdfCompleted: 'Seu relatório está agendado',
  pdfCreated: 'Seu relatório foi agendado',
  interactiveReport: 'RELATÓRIO INTERATIVO',
  pheromone: 'Atrativo',
  pheromoneCaption: 'FEROMÔNIO',
  planExpired: 'Seu plano expirou',
  registerLinerExchange: 'Registrar troca de piso',
  removeFarm: {
    default: 'Deletar Fazenda',
    productiveUnit: 'Deletar Unidade Produtiva',
    type: 'farm',
  },
  removeFarms: {
    default: 'Deletar Fazendas',
    productiveUnit: 'Deletar Unidades Produtivas',
    type: 'farm',
  },
  removeField: {
    default: 'Deletar Talhão',
    lot: 'Deletar Lote',
    type: 'field',
  },
  removeFields: {
    default: 'Deletar Talhões',
    lot: 'Deletar Lotes',
    type: 'field',
  },
  removeGlebe: {
    default: 'Deletar Gleba',
    productionUnit: 'Deletar Unidade de Produção',
    productiveUnit: 'Deletar Unidade Produtiva',
    type: 'glebe',
  },
  removeGlebes: {
    default: 'Deletar Glebas',
    productionUnit: 'Deletar Unidades de Produção',
    productiveUnit: 'Deletar Unidades Produtivas',
    type: 'glebe',
  },
  removeHarvest: {
    default: 'Deletar Safra',
    year: 'Deletar ano',
    cycle: 'Deletar ciclo',
    type: 'harvest',
  },
  removeHarvests: {
    default: 'Deletar Safras',
    year: 'Deletar anos',
    cycle: 'Deletar ciclos',
    type: 'harvest',
  },
  removeReport: 'Deletar Relatório',
  removeReports: 'Deletar Relatórios',
  removeTrap: 'Deletar Armadilha',
  removeTraps: 'Deletar Armadilhas',
  removeUser: 'Deletar Usuário',
  removeUsers: 'Deletar Usuários',
  report: 'Relatório',
  reports: 'Relatórios',
  startSession: 'Entre para iniciar uma sessão',
  selectAll: 'Selecionar todos',
  soil: 'Umidade do solo',
  system: 'Sistema',
  timelinePoint: 'Linha do tempo do ponto de coleta',
  totalBetweenFields: {
    default: 'Total entre talhões',
    lot: 'Total entre lotes',
    type: 'field',
  },
  traps: 'Armadilhas',
  trapsReports: 'Relatórios de Armadilhas',
  users: 'Usuários',
  xlsCompleted: 'Seu relatório está pronto',
  xlsCreated: 'Seu solicitação foi cadastrada',
};
