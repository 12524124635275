export const enums = {
  ADMIN: 'Administrador',
  READER: 'Leitor',
  OPERATOR: 'Operador',
  NOT_POSITIONED: 'Não Posicionada',
  BATTERY_EXCHANGE: 'Troca de Bateria',
  TRAP_REMOVAL: 'Remover Armadilha',
  STICKY_LINER_EXCHANGE: 'Troca de Piso',
  LINER_ROLL_EXCHANGE: 'Troca de rolo de piso',
  PHEROMONE_EXCHANGE: 'Troca de Atrativo',
  CREATED: 'Em Andamento',
  COMPLETED: 'Finalizada',
  NOT_FOUND_DATA: 'Dados não encontrados',
  USER_NOT_SPECIFIED: 'Usuário não especificado',
  HIGH: 'ALTO',
  MEDIUM: 'MÉDIO',
  LOW: 'BAIXO',
  CRITICAL: 'CRÍTICO',
  INTERACTIVE: 'Interativo',
  GEOREFERENCED: 'Georreferenciado',
  MAD: 'MAD',
  TODAY: 'Hoje',
  DAYS: 'Dia',
  WEEK: 'Semana',
  MONTH: 'Mês',
  YEAR: 'Ano',
  PLURAL_DAYS: 'Dias',
  PLURAL_WEEK: 'Semanas',
  PLURAL_MONTH: 'Meses',
  PLURAL_YEAR: 'Anos',
  DISABLED: 'Desabilitada',
  NO_SIGNAL: 'Sem Sinal',
  MONITORING: 'Monitorando',
  TRAP: 'Armadilha',
  FIRST_HARVEST: {
    default: 'Primeira Safra',
    year: 'Primeiro Ano',
    cycle: 'Primeiro Ciclo',
    type: 'harvest',
  },
  SECOND_HARVEST: {
    default: 'Segunda Safra',
    year: 'Segundo Ano',
    cycle: 'Segundo Ciclo',
    type: 'harvest',
  },
  THIRD_HARVEST: {
    default: 'Terceira Safra',
    year: 'Terceiro Ano',
    cycle: 'Terceiro Ciclo',
    type: 'harvest',
  },
  FOLLOWING: 'Vazio Sanitário',
  PLANNING: 'Planejamento',
  NEVER: 'Nunca',
  DATE: 'Definir uma data',
  AMOUNT: 'Quantidade',
  IN_PROGRESS: 'Em Andamento',
  CENTIMETERS: 'cm',
  SQUARE_METERS: 'm²',
  METERS: 'm',
  KILOMETERS: 'km',
  SQUARE_KILOMETERS: 'km²',
  HECTARES: 'ha',
  UND: 'und',
  PERCENTAGE: '%',
  NOT_DEFINED: '',
  REPRODUCTIVE: 'Reprodutivo',
  VEGETATIVE: 'Vegetativo',
  OCCURRENCE_METERS: 'o/m',
  OCCURRENCE_ZERO: 'Ausência',
  OCCURRENCE_MODERATE: 'Baixa',
  OCCURRENCE_MEDIUM: 'Média',
  OCCURRENCE_HIGH: 'Alta',
  LEVEL_HIGH: 'Alto',
  OCCURRENCE_NOT_DEFINED: 'Não Definido',
  INTENSITY: 'Intensidade',
  YES: 'Sim',
  NO: 'Não',

  '7_DAYS': '7 Dias',
  '15_DAYS': '15 Dias',
  '30_DAYS': '30 Dias',
  '60_DAYS': '60 Dias',
  '90_DAYS': '90 Dias',
  '+55': 'Brasil',
  '+54': 'Argentina',
  '+595': 'Paraguai',
};
