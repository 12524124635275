import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { FarmState, farmStateDefault } from 'src/app/view/farm/farm.state';
import * as farmAction from './farm.actions';

export const farmReducer = createReducer(
  farmStateDefault,
  /**
   * GET
   */
  on(farmAction.GET_FARMS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(farmAction.GET_FARMS_SUCCESS, (state, action) => ({
    ...state,
    farms: action.farms,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(farmAction.GET_FARMS_FAIL, (state) => ({
    ...state,
    farms: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(farmAction.GET_USERS, (state) => ({
    ...state,
    loadingProgressBar: state.loadingProgressBar + 1,
  })),
  on(farmAction.GET_USERS_SUCCESS, (state, action) => ({
    ...state,
    users: action.users,
    loadingProgressBar:
      state.loadingProgressBar > 0 ? state.loadingProgressBar - 1 : (state.loadingProgressBar = 0),
  })),
  on(farmAction.GET_USERS_FAIL, (state) => ({
    ...state,
    users: [],
    loadingProgressBar:
      state.loadingProgressBar > 0 ? state.loadingProgressBar - 1 : (state.loadingProgressBar = 0),
  })),

  /**
   * CREATE
   */
  on(farmAction.CREATE_FARM, (state) => ({ ...state, loadingModal: state.loadingModal + 1 })),
  on(farmAction.CREATE_FARM_SUCCESS, (state) => ({ ...state, closeModal: true })),
  on(farmAction.CREATE_FARM_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * UPDATE
   */
  on(farmAction.UPDATE_FARM_EDIT, (state, action) => ({
    ...state,
    farmForm: _.cloneDeep(action.farm),
  })),
  on(farmAction.UPDATE_FARM, (state) => ({ ...state, loadingModal: state.loadingModal + 1 })),
  on(farmAction.UPDATE_FARM_SUCCESS, (state) => ({ ...state, closeModal: true })),
  on(farmAction.UPDATE_FARM_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * REMOVE
   */
  on(farmAction.REMOVE_FARM, (state) => ({ ...state, loadingRemove: state.loadingRemove + 1 })),
  on(farmAction.REMOVE_FARM_SUCCESS, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),
  on(farmAction.REMOVE_FARM_FAIL, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),

  /**
   * OTHER
   */
  on(farmAction.HIDE_MODAL, (state) => ({
    ...state,
    loadingProgressBar:
      state.loadingProgressBar > 0 ? state.loadingProgressBar - 1 : (state.loadingProgressBar = 0),
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
    closeModal: farmStateDefault.closeModal,
    farmForm: farmStateDefault.farmForm,
  }))
);

export function reducer(state: FarmState | undefined, action: Action): FarmState {
  return farmReducer(state, action);
}
