import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { GET_MAD_CHART } from 'src/app/view/gm-tabs/gm-tabs.actions';
import { TranslateTypes } from 'src/app/services/translation.service';
import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { gmTabsDefault, GmTabsState } from '../../gm-tabs.state';
import { Field } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-mad-modal',
  templateUrl: './mad-modal.component.html',
  styleUrls: ['./mad-modal.component.scss'],
})
export class MadModalComponent implements OnInit, OnDestroy {
  public reportState$: Observable<GmTabsState>;
  public state: GmTabsState = gmTabsDefault;
  public subscribe!: Subscription;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MadModalComponent>,
    public translateService: TranslateService,
    public trans: TranslateTypes,
    public zone: NgZone,
    private store: Store<any>,

    @Inject(MAT_DIALOG_DATA) public data: { field: Field; gm: GmConfiguration }
  ) {
    this.reportState$ = this.store.pipe(select('gmTabs'));
  }

  public ngOnInit(): void {
    this.subscribe = this.reportState$.subscribe((state: GmTabsState) => {
      this.state = state;
    });

    const { gm } = this.data;
    const { startDate, endDate } = gm ? gm : ({} as GmConfiguration);

    this.store.dispatch(
      GET_MAD_CHART({
        startDate: startDate,
        endDate: endDate,
        glebeName: this.data.field.glebe.name,
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.state.madChart = [];
  }

  /**
   * This is the method that close modal
   */
  public closeModal() {
    this.dialogRef.close();
  }
}
