import { createAction, props } from '@ngrx/store';
import { CreateHarvestForm } from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';
import { Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_HARVESTS = createAction('[Harvest Form Page] GET_HARVESTS');
export const GET_HARVESTS_SUCCESS = createAction(
  '[Harvest Form Page] GET_HARVESTS_SUCCESS',
  props<{ harvests: Array<Harvest> }>()
);
export const GET_HARVESTS_FAIL = createAction(
  '[Harvest Form Page] GET_HARVESTS_FAIL',
  props<{ error: any }>()
);

/**
 * Cadastrar
 */
export const CREATE_HARVEST = createAction(
  '[Harvest Form Page] CREATE_HARVEST',
  props<{ harvest: CreateHarvestForm }>()
);
export const CREATE_HARVEST_SUCCESS = createAction('[Harvest Form Page] CREATE_HARVEST_SUCCESS');
export const CREATE_HARVEST_FAIL = createAction(
  '[Harvest Form Page] CREATE_HARVEST_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_MODAL = createAction('[Harvest Form Page] HIDE_MODAL');
