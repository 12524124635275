<div class="material-container-system">
  <form class="custom-form-field" [formGroup]="firstFormGroup">
    <mat-tab-group animationDuration="0ms">
      <!-- Nível de Controle -->
      <mat-tab [label]="trans.label.controlLevel | translate">
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <mat-card class="material-top-card-border">
            <mat-card-content class="material-card-content-table">
              <mat-tab-group
                #group
                animationDuration="0ms"
                selectedIndex="0"
                (selectedIndexChange)="selectedTabChangeTab($event)"
              >
                <mat-tab *ngFor="let crop of state.crops; let i = index" [label]="crop.name">
                  <table
                    mat-table
                    [dataSource]="dataSource"
                    matSort
                    matSortDisableClear
                    multiTemplateDataRows
                    matSortDirection="asc"
                  >
                    <!-- Items table -->
                    <div
                      *ngFor="
                        let column of applicationService.filterColumnByPermission(
                          state.displayedColumnsModal
                        )
                      "
                    >
                      <ng-container
                        matColumnDef="{{ column }}"
                        *ngIf="
                          column !== 'select' &&
                          column !== 'actions' &&
                          column !== 'cropIds' &&
                          column === 'name'
                        "
                      >
                        <th mat-header-cell *matHeaderCellDef>
                          {{ trans.label[column] | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <a
                            class="material-table-item-link"
                            (click)="expandedElement = expandedElement === element ? null : element"
                            >{{ element[column] }}</a
                          >
                        </td>
                      </ng-container>
                      <ng-container
                        matColumnDef="{{ column }}"
                        *ngIf="column !== 'select' && column !== 'actions' && column !== 'name'"
                      >
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element"></td>
                      </ng-container>
                    </div>
                    <!-- View item -->
                    <ng-container matColumnDef="expandedDetail">
                      <td
                        mat-cell
                        *matCellDef="let element"
                        [attr.colspan]="
                          applicationService.filterColumnByPermission(state.displayedColumnsModal)
                            .length
                        "
                      >
                        <div
                          *ngIf="expandedElement === element && group.selectedIndex === i"
                          class="material-element-detail"
                          [@detailExpand]="expandedElement === element ? 'expanded' : 'collapsed'"
                          [style.height]="expandedElement === element ? 'unset' : '0 !important'"
                        >
                          <div
                            [formArrayName]="'customControlLevels'"
                            *ngFor="
                              let customControlLevelControls of getControls();
                              let customControlLevelIndex = index
                            "
                          >
                            <div
                              *ngIf="
                                customControlLevelControls.value.occurrence?.id ===
                                element.occurrence?.id
                              "
                              [formGroupName]="customControlLevelIndex"
                            >
                              <div
                                [formArrayName]="'controlLevels'"
                                *ngFor="
                                  let controlLevelsControls of customControlLevelControls[
                                    'controls'
                                  ].controlLevels['controls'];
                                  let controlLevelsIndex = index
                                "
                              >
                                <div [formGroupName]="controlLevelsIndex">
                                  <div
                                    fxLayout="column"
                                    fxLayout.gt-xs="row wrap"
                                    fxLayout.gt-md="row wrap"
                                    [formArrayName]="'values'"
                                    *ngFor="
                                      let valuesControls of controlLevelsControls['controls']
                                        .values['controls'];
                                      let valuesIndex = index
                                    "
                                  >
                                    <mat-form-field
                                      fxFlex="100%"
                                      fxFlex.gt-xs="0 1 50%"
                                      [formGroupName]="valuesIndex"
                                      *ngIf="crop.id === valuesControls.value.cropId"
                                    >
                                      <input
                                        matInput
                                        type="number"
                                        id="{{
                                          element.occurrence.id +
                                            controlLevelsControls.value.controlLevelType +
                                            valuesControls.value.cropId +
                                            valuesIndex
                                        }}"
                                        name="{{
                                          element.occurrence.name +
                                            controlLevelsControls.value.controlLevelType +
                                            valuesControls.value.cropName +
                                            valuesIndex
                                        }}"
                                        placeholder="{{
                                          trans.enums[controlLevelsControls.value.controlLevelType]
                                            | translate
                                        }}"
                                        [formControlName]="'value'"
                                        [errorStateMatcher]="customValidatorsMatcher"
                                      />
                                      <span *ngIf="valuesControls.value.unit" matSuffix>
                                        ( {{ trans.enums[valuesControls.value.unit] | translate }} )
                                        &nbsp;</span
                                      >
                                      <mat-error>
                                        {{
                                          getInputMessageError(
                                            'trapControlLevel',
                                            firstFormGroup
                                              .get('customControlLevels')
                                              ?.get(customControlLevelIndex.toString())
                                              ?.get('controlLevels')
                                              ?.get(controlLevelsIndex.toString())
                                              ?.get('values')
                                              ?.get(valuesIndex.toString())
                                              ?.get('value')?.errors
                                          ) | translate
                                        }}
                                      </mat-error>
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <tr
                      mat-header-row
                      *matHeaderRowDef="
                        applicationService.filterColumnByPermission(state.displayedColumnsModal);
                        sticky: true
                      "
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="
                        let row;
                        columns: applicationService.filterColumnByPermission(
                          state.displayedColumnsModal
                        )
                      "
                      class="material-row-height"
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: ['expandedDetail']"
                      class="material-detail-row"
                    ></tr>
                  </table>
                </mat-tab>
              </mat-tab-group>
            </mat-card-content>
            <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
              <mat-paginator [pageSize]="15" [pageSizeOptions]="[15]"> </mat-paginator>
              <button mat-button color="primary" (click)="onFormClick()">
                {{ trans.button.save | translate }}
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>
