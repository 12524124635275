import { Validators } from '@angular/forms';
import { SystemSettings } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const systemSettingsStateDefault = {
  systemSettings: {} as SystemSettings,
  loading: 0,
} as SystemSettingsState;

export interface SystemSettingsState {
  systemSettings: SystemSettings;
  loading: number;
}

export const firstFormGroup = {
  id: [null],
  fieldArea: [null, [Validators.required]],
  controlLevel: [null, [Validators.required]],
  damageLevel: [null, [Validators.required]],
} as any;
