import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateTypes } from './translation.service';

@Injectable()
export class MyToastrService {
  constructor(
    private toastrService: ToastrService,
    private translateService: TranslateService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método mostra uma mensagem de informação
   * @param message mensagem
   */
  public info(message: string, interpolateParams?: any): void {
    this.toastrService.info(
      this.getText(`${message}`, interpolateParams),
      this.getText(`text.info`)
    );
  }

  /**
   * TEste método mostra uma mensagem de sucesso
   * @param message mensagem
   */
  public success(message: string, interpolateParams?: any): void {
    this.toastrService.success(
      this.getText(`${message}`, interpolateParams),
      this.getText(`text.success`)
    );
  }

  /**
   * Este método mostra uma mensagem de atenção
   * @param message mensagem
   */
  public warning(message: string, interpolateParams?: any): void {
    this.toastrService.warning(
      this.getText(`${message}`, interpolateParams),
      this.getText(`text.warning`)
    );
  }

  /**
   * Este método mostra uma mensagem de erro
   * @param message mensagem
   */
  public error(message: string, interpolateParams?: any): void {
    this.toastrService.error(
      this.getText(`${message}`, interpolateParams),
      this.getText(`text.error`)
    );
  }

  /**
   * Este método retorna o texto traduzido
   */
  private getText(text: string, interpolateParams?: any): string {
    return this.translateService.instant(text, interpolateParams);
  }
}
