<!-- Loading -->
<app-loading [isOpen]="state.loadingModal"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="closeModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span *ngIf="data.edit" class="title">{{ trans.title.editUser | translate }}</span>
  <span *ngIf="!data.edit" class="title">{{ trans.title.createUser | translate }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <form class="custom-form-field" #form="ngForm">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="name"
          name="name"
          [(ngModel)]="state.userForm.name"
          #name="ngModel"
          placeholder="{{ trans.placeholder.name | translate }}"
          minlength="2"
          maxlength="64"
          appWhiteSpaceValidator
          required
          [pattern]="regex.text"
        />
        <mat-error *ngIf="name.invalid && (name.dirty || name.touched)">
          <mat-error *ngIf="name.errors?.required">
            {{ trans.errors?.required.name | translate }}
          </mat-error>
          <mat-error *ngIf="name.errors?.minlength">
            {{ trans.errors?.minlength.name | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              name.errors?.pattern && !name.errors?.minlength && !name.errors?.whitespaceValidator
            "
          >
            {{ trans.errors?.minlength.name | translate }}
          </mat-error>
          <mat-error *ngIf="name.errors?.whitespaceValidator">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="email"
          id="email"
          name="email"
          #email="ngModel"
          [(ngModel)]="state.userForm.email"
          placeholder="{{ trans.placeholder.email | translate }}"
          minlength="8"
          maxlength="64"
          required
          [disabled]="data.edit"
          [pattern]="regex.email"
        />
        <mat-hint *ngIf="!data.edit"
          ><strong>{{ trans.text.emailExample | translate }}</strong>
        </mat-hint>
        <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
          <mat-error *ngIf="email.errors?.required">
            {{ trans.errors?.required.email | translate }}
          </mat-error>
          <mat-error *ngIf="email.errors?.minlength">
            {{ trans.errors?.minlength.email | translate }}
          </mat-error>
          <mat-error *ngIf="email.errors?.pattern && !email.errors?.minlength">
            {{ trans.errors?.pattern.email | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label>{{ trans.label.ccc | translate }}</mat-label>
        <mat-select
          #materialSelect
          id="ccc"
          name="ccc"
          #ccc="ngModel"
          [(ngModel)]="state.userForm.ccc"
          (selectionChange)="onCccChange($event)"
        >
          <mat-option [value]="null">
            {{ trans.text.clean | translate }}
          </mat-option>
          <mat-option *ngFor="let ccc of getEnumType()" [value]="ccc">
            <span
              *ngIf="ccc === enumCcc.brazil"
              class="material-flag-icon flag-icon flag-icon-br"
            ></span>
            <span
              *ngIf="ccc === enumCcc.paraguay"
              class="material-flag-icon flag-icon flag-icon-py"
            ></span>
            {{ trans.enums[ccc] | translate }} <strong>{{ ccc }}</strong>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          matInput
          id="phone"
          name="phone"
          [disabled]="!state.userForm.ccc"
          #phone="ngModel"
          [mask]="
            state.userForm.ccc?.toString() === enumCcc.brazil
              ? phoneMask.brazil
              : phoneMask.paraguay
          "
          [(ngModel)]="state.userForm.phone"
          placeholder="{{ trans.placeholder.phone | translate }}"
          [required]="state.userForm.ccc !== null"
        />
        <mat-hint *ngIf="state.userForm.ccc">
          <strong>{{
            state.userForm.ccc?.toString() === enumCcc.brazil
              ? phoneMask.brazil
              : phoneMask.paraguay
          }}</strong>
        </mat-hint>
        <mat-error *ngIf="phone.invalid && (phone.dirty || phone.touched)">
          <mat-error
            *ngIf="
              state.userForm.ccc?.toString() === enumCcc.brazil &&
              !phone.errors?.Mask &&
              !phone.errors?.required
            "
          >
            {{ trans.errors?.mask.phone.brazil | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              state.userForm.ccc?.toString() === enumCcc.paraguay &&
              !phone.errors?.Mask &&
              !phone.errors?.required
            "
          >
            {{ trans.errors?.mask.phone.paraguay | translate }}
          </mat-error>
          <mat-error *ngIf="phone.errors?.required">
            {{ trans.errors?.required.phone | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label>{{ trans.label.farm | label }}</mat-label>
        <mat-select
          #materialSelect
          id="farmIds"
          id="farmIds"
          name="farmIdsId"
          #farmIds="ngModel"
          [(ngModel)]="state.userForm.farmIds"
          [multiple]="true"
          required
        >
          <mat-option
            class="selectAll"
            [disabled]="state.userEdit.id === applicationService.getUserId()"
            (click)="selectAllFarms()"
            >{{ trans.title.selectAll | translate }}</mat-option
          >
          <mat-option
            class="selectAll"
            [disabled]="state.userEdit.id === applicationService.getUserId()"
            (click)="deselectAllFarms()"
            >{{ trans.title.deselectAll | translate }}</mat-option
          >
          <mat-option
            *ngFor="let farm of state.farms"
            [value]="farm.id"
            [disabled]="state.userEdit.id === applicationService.getUserId()"
          >
            {{ farm.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="farmIds.invalid && (farmIds.dirty || farmIds.touched)">
          <mat-error *ngIf="farmIds.errors?.required">
            {{ trans.errors?.required.farm | label }}
          </mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label>{{ trans.label.role | translate }}</mat-label>
        <mat-select
          #materialSelect
          id="role"
          name="role"
          #role="ngModel"
          [(ngModel)]="state.userForm.role"
          [disabled]="data.edit && applicationService.getUser()?.id === state.userEdit.id"
          required
        >
          <div *ngFor="let role of getEnumRole(['ADMIN'])">
            <mat-option [value]="role">
              {{ trans.enums[role] | translate }}
            </mat-option>
          </div>
        </mat-select>
        <mat-error *ngIf="role.invalid && (role.dirty || role.touched)">
          <mat-error *ngIf="role.errors?.required">
            {{ trans.errors?.required.role | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="closeModal()">
    {{ trans.button.close | translate }}
  </button>
  <button mat-button color="primary" [disabled]="form.invalid" (click)="create()">
    {{ trans.button.save | translate }}
  </button>
</mat-dialog-actions>
