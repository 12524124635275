/**
 * Common configuration data
 */
export const TARVOS = {
  termsOfUse: 'https://tarvos.ag/terms_of_use/',
  privacyPolicy: 'https://tarvos.ag/privacy_policy/',
  support: 'suporte@tarvos.ag',
};

export const env = {
  no_emulators: {
    emulateFirestore: false,
    emulateAuth: false,
    emulateFunctions: false,
  },
  serve: {
    emulateFirestore: false,
    emulateAuth: false,
    emulateFunctions: true,
  },
  emulators: {
    emulateFirestore: true,
    emulateAuth: true,
    emulateFunctions: true,
  },
};

export const project = {
  env: {
    develop: {
      api: 'http://localhost:5001/tarvos-dev/us-central1',
    },
    staging: {
      api: 'http://localhost:5001/tarvos-staging/us-central1',
    },
    production: {
      api: 'http://localhost:5001/tarvos-production/us-central1',
    },
  },
};
