import { Validators } from '@angular/forms';
import { Glebe, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { CustomValidators } from '../../utils/custom-validators/custom-validators';

export const importGlebeFormStateDefault = {
  harvests: [],
  glebes: [],
  loading: 0,
  loadingModal: 0,
  closeModal: false,
} as ImportGlebeFormState;

export const firstFormGroup = {
  ids: [[], [Validators.required, CustomValidators.listIsEmpty]],
} as any;

export interface ImportGlebeFormState {
  harvests: Array<Harvest>;
  glebes: Array<Glebe>;
  loading: number;
  loadingModal: number;
  closeModal: boolean;
}
