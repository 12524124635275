<agm-map
  #AgmMap
  [latitude]="convertToNumber(coordinates?.lat)"
  [longitude]="convertToNumber(coordinates?.lng)"
  [zoom]="zoom"
  [mapTypeId]="mapTypeId"
  [ngStyle]="{ height: height }"
  [disableDoubleClickZoom]="true"
  [disableDefaultUI]="true"
>
  <div *ngIf="polygons && polygons.length > 0">
    <agm-polygon
      *ngFor="let polygon of polygons; let i = index"
      [zIndex]="i"
      [fillColor]="polygon.selected ? '#ECB842' : polygon.defined ? '#4eb862' : '#ffffff'"
      [strokeColor]="polygon.selected ? '#ECB842' : polygon.defined ? '#4eb862' : '#fafafa'"
      [paths]="polygon.points"
      [clickable]="true"
      (polyClick)="selectPolygon(i)"
      (polyMouseOut)="closeInfoWindow()"
      (polyMouseMove)="openInfoWindow($event, polygon)"
    ></agm-polygon>
  </div>

  <agm-snazzy-info-window
    [wrapperClass]="'material-info-window-marker'"
    #agmSnazzyInfoWindow
    [isOpen]="false"
    [border]="false"
    [shadow]="false"
    [showCloseButton]="false"
    [closeOnMapClick]="false"
  >
    <ng-template>
      <div
        fxLayout="column"
        fxLayoutAlign="space-between none"
        style="padding: 5px; color: #000000"
      >
        <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
          <label fxFlex="100%">
            {{ trans.label.alias | translate }}:
            <span *ngIf="infoWindowMessage?.name !== ''">{{ infoWindowMessage?.name }}</span>
            <span *ngIf="infoWindowMessage?.name === ''">
              {{ trans.text.aliasNotDefined | translate }}</span
            >
          </label>
          <label fxFlex="100%" *ngIf="infoWindowMessage?.glebe?.alias">
            {{ trans.label.glebe | label }}: <span> {{ infoWindowMessage?.glebe?.name }}</span>
          </label>
          <label fxFlex="100%" *ngIf="infoWindowMessage?.glebe?.alias">
            {{ trans.label.alias | translate }}: <span> {{ infoWindowMessage?.glebe?.alias }}</span>
          </label>
          <label fxFlex="100%" *ngIf="infoWindowMessage?.glebe?.cropName">
            {{ trans.label.crop | translate }}:
            <span> {{ infoWindowMessage?.glebe?.cropName }}</span>
          </label>
          <label fxFlex="100%" *ngIf="infoWindowMessage?.glebe?.varieties">
            {{ trans.label.varieties | translate }}:
            <span> {{ infoWindowMessage?.glebe?.varieties }}</span>
          </label>
        </div>
      </div>
    </ng-template>
  </agm-snazzy-info-window>
</agm-map>
