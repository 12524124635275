import { Action, createReducer, on } from '@ngrx/store';
import * as systemSettingsAction from './system-settings.actions';
import * as _ from 'lodash';
import {
  SystemSettingsState,
  systemSettingsStateDefault,
} from 'src/app/view/configuration/system-settings/system-settings.state';
import { SystemSettings } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const SystemSettingsReducer = createReducer(
  _.cloneDeep(systemSettingsStateDefault),

  /**
   * GET
   */
  on(systemSettingsAction.GET_SYSTEM_SETTINGS, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(systemSettingsAction.GET_SYSTEM_SETTINGS_SUCCESS, (state, action) => ({
    ...state,
    systemSettings: action.systemSettings,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(systemSettingsAction.GET_SYSTEM_SETTINGS_FAIL, (state) => ({
    ...state,
    systemSettings: {} as SystemSettings,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * UPDATE
   */
  on(systemSettingsAction.UPDATE_SYSTEM_SETTINGS, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(systemSettingsAction.UPDATE_SYSTEM_SETTINGS_SUCCESS, (state) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(systemSettingsAction.UPDATE_SYSTEM_SETTINGS_FAIL, (state) => ({
    ...state,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * OTHER
   */
  on(systemSettingsAction.DESTROY_COMPONENT, (state) => ({
    ..._.cloneDeep(systemSettingsStateDefault),
  }))
);

export function reducer(
  state: SystemSettingsState | undefined,
  action: Action
): SystemSettingsState {
  return SystemSettingsReducer(state, action);
}
