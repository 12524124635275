import { Action, createReducer, on } from '@ngrx/store';
import { ReportState, reportStateDefault } from './report.state';

import * as _ from 'lodash';
import * as reportAction from './report.actions';

export const reportReducer = createReducer(
  _.cloneDeep(reportStateDefault),

  /**
   * GET
   */

  on(reportAction.GET_REPORT, (state) => ({ ...state, loading: state.loading + 1 })),
  on(reportAction.GET_REPORT_SUCCESS, (state, action) => ({
    ...state,
    customerId: action.customerId,
    farmId: action.farmId,
    farm: action.farm,
    harvestId: action.harvestId,
    fields: action.fields,
    occurrences: action.occurrences,
    report: action.report,
    startDate: action.data.startDate,
    endDate: action.data.endDate,
    showModal: action.showModal,
    evolutionChart: action.data.evolutionChart,
    monitoringReport: action.data.monitoringReport,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(reportAction.GET_REPORT_FAIL, (state) => ({
    ...state,
    fields: [],
    occurrences: [],
    report: null,
    monitoringReport: [],
    evolutionChart: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(reportAction.GET_EVOLUTION_CHART, (state) => ({
    ...state,
    loadingEvolutionChartByFields: state.loadingEvolutionChartByFields + 1,
  })),
  on(reportAction.GET_EVOLUTION_CHART_SUCCESS, (state, action) => ({
    ...state,
    evolutionChartByFields: action.evolutionChart,
    loadingEvolutionChartByFields: (state.loadingEvolutionChartByFields = 0),
  })),
  on(reportAction.GET_EVOLUTION_CHART_FAIL, (state) => ({
    ...state,
    evolutionChartByFields: null,
    loadingEvolutionChartByFields: (state.loadingEvolutionChartByFields = 0),
  })),

  // on(reportAction.GET_ACCUMULATED_CHART, (state) => ({
  //   ...state,
  //   loading: state.loadingAccumulatedChart + 1,
  // })),
  // on(reportAction.GET_ACCUMULATED_CHART_SUCCESS, (state, action) => ({
  //   ...state,
  //   reportAccumulated: action.reportAccumulated,
  //   loading: (state.loadingAccumulatedChart = 0),
  // })),
  // on(reportAction.GET_ACCUMULATED_CHART_FAIL, (state) => ({
  //   ...state,
  //   reportAccumulated: null,
  //   loading: (state.loadingAccumulatedChart = 0),
  // })),

  // on(reportAction.GET_MAD_CHART, (state) => ({
  //   ...state,
  //   loading: state.loadingMadChart + 1,
  // })),
  // on(reportAction.GET_MAD_CHART_SUCCESS, (state, action) => ({
  //   ...state,
  //   madChart: action.madChart,
  //   loading: (state.loadingMadChart = 0),
  // })),
  // on(reportAction.GET_MAD_CHART_FAIL, (state) => ({
  //   ...state,
  //   madChart: [],
  //   loading: (state.loadingMadChart = 0),
  // })),

  on(reportAction.GET_PHONE, (state) => ({
    ...state,
  })),
  on(reportAction.GET_PHONE_SUCCESS, (state, action) => ({
    ...state,
    phone: action.phone,
  })),
  on(reportAction.GET_PHONE_FAIL, (state) => ({
    ...state,
    phone: '',
  })),

  /**
   * OTHER
   */
  on(reportAction.DESTROY_COMPONENT, () => ({
    ..._.cloneDeep(reportStateDefault),
  }))
);

export function reducer(state: ReportState | undefined, action: Action): ReportState {
  return reportReducer(state, action);
}
