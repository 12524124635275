import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { HarvestState, harvestStateDefault } from 'src/app/view/harvest/harvest.state';
import * as harvestAction from './harvest.actions';

export const harvestReducer = createReducer(
  _.cloneDeep(harvestStateDefault),
  /**
   * GET
   */
  on(harvestAction.GET_HARVESTS, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(harvestAction.GET_HARVESTS_SUCCESS, (state, action) => ({
    ...state,
    harvests: action.harvests,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(harvestAction.GET_HARVESTS_FAIL, (state) => ({
    ...state,
    harvests: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * REMOVE
   */
  on(harvestAction.REMOVE_HARVEST, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove + 1,
  })),
  on(harvestAction.REMOVE_HARVEST_SUCCESS, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),
  on(harvestAction.REMOVE_HARVEST_FAIL, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),

  /**
   * OTHER
   */
  on(harvestAction.DESTROY_COMPONENT, (state) => ({
    ..._.cloneDeep(harvestStateDefault),
  }))
);

export function reducer(state: HarvestState | undefined, action: Action): HarvestState {
  return harvestReducer(state, action);
}
