import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { UpdatePasswordDecoded } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import firebase from 'firebase/compat/app';
import { UserLogin } from 'src/app/interfaces/UserLogin';
import { ApplicationService } from './application.service';
import { GenericFirestoreService } from './generic-firestore.service';
import { GenericHttpService } from './generic-http.service';

@Injectable()
export class LoginService {
  constructor(
    private genericHttpService: GenericHttpService,
    private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService,
    private fireAuth: AngularFireAuth,
    private router: Router
  ) {}

  public login(user: UserLogin): Promise<any> {
    return this.fireAuth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => this.fireAuth.signInWithEmailAndPassword(user.email, user.password));
  }

  public checkToken(token: string): Promise<any> {
    return this.genericHttpService.getById(`checkToken?token=${token}`).toPromise();
  }

  public recoverPassword(email: string) {
    return this.genericHttpService.getById(`recoverPassword?email=${email}`).toPromise();
  }

  public async updatePassword(
    oobCode: string,
    decoded: UpdatePasswordDecoded,
    password: string
  ): Promise<any> {
    await this.fireAuth.confirmPasswordReset(oobCode, password);
    await this.login({ email: decoded.email, password } as UserLogin);
    await this.genericFirestoreService.update('users', {
      id: decoded.uid,
      lastPasswordUpdate: this.applicationService.getServerDate(),
    });
    return await this.router.navigate(['/home/gm']);
  }

  public signOutFirebase(): Promise<any> {
    return this.fireAuth.signOut().then(() => (window.location.href = '/login'));
  }
}
