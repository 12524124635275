export const IconCustom = {
  url: '',
  scaledSize: { height: 16, width: 16 },
  anchor: { x: 12, y: 12 },
};

export const IconUrl = {
  TRAP: {
    LIGHT: 'trap_light.svg',
    SAFE: 'trap_safe.svg',
    INFO: 'trap_info.svg',
    WARNING: 'trap_warning.svg',
    DANGER: 'trap_danger.svg',
  },
  PLAGUE: {
    LIGHT: 'plague_light.svg',
    SAFE: 'plague_safe.svg',
    INFO: 'plague_info.svg',
    WARNING: 'plague_warning.svg',
    DANGER: 'plague_danger.svg',
    NO_COMMUNICATION: 'plague_no_communication.svg',
  },
  DAMAGE: {
    LIGHT: 'disease_light.svg',
    SAFE: 'disease_safe.svg',
    INFO: 'disease_info.svg',
    WARNING: 'disease_warning.svg',
    DANGER: 'disease_danger.svg',
  },
  DISEASE: {
    LIGHT: 'disease_light.svg',
    SAFE: 'disease_safe.svg',
    INFO: 'disease_info.svg',
    WARNING: 'disease_warning.svg',
    DANGER: 'disease_danger.svg',
  },
  WEED: {
    LIGHT: 'weed_light.svg',
    SAFE: 'weed_safe.svg',
    INFO: 'weed_info.svg',
    WARNING: 'weed_warning.svg',
    DANGER: 'weed_danger.svg',
  },
  WEATHER: {
    LIGHT: 'weather_light.svg',
    SAFE: 'weather_safe.svg',
    INFO: 'weather_info.svg',
    WARNING: 'weather_warning.svg',
    DANGER: 'weather_danger.svg',
  },
  NUTRITION: {
    LIGHT: 'nutrition_light.svg',
    SAFE: 'nutrition_safe.svg',
    INFO: 'nutrition_info.svg',
    WARNING: 'nutrition_warning.svg',
    DANGER: 'nutrition_danger.svg',
  },
  NATURAL_ENEMY: {
    LIGHT: 'natural_enemy_light.svg',
    SAFE: 'natural_enemy_safe.svg',
    INFO: 'natural_enemy_info.svg',
    WARNING: 'natural_enemy_warning.svg',
    DANGER: 'natural_enemy_danger.svg',
  },
} as any;
