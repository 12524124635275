<div
  *ngIf="dataSource.data.length > 0"
  style="margin: 0px 15px"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <h2>{{ trans.title.importKmz | translate }}</h2>
  <div *ngIf="applicationService.hasEditPermission(operation.create, true)">
    <button
      type="button"
      mat-flat-button
      color="primary"
      class="margin-right"
      (click)="openComponentFields()"
    >
      {{ trans.title.listOfField | label }}
    </button>
    <button type="button" mat-flat-button color="primary" (click)="openDialog()">
      {{ trans.button.importKmz | translate }}
    </button>
  </div>
</div>

<div [hidden]="dataSource.data.length <= 0" class="material-content">
  <mat-card class="material-top-card-border">
    <mat-card-header>
      <mat-card-title>{{ trans.title.kmz | translate }}</mat-card-title>
      <mat-form-field class="margin-left-auto">
        <input
          #search
          matInput
          type="search"
          placeholder="{{ trans.placeholder.search | translate }}"
          (keyup)="dataSearch($event)"
        />
        <button
          mat-button
          matSuffix
          mat-icon-button
          aria-label="Search"
          (click)="dataSearch('', search)"
        >
          <mat-icon fontSet="material-icons-outlined">close</mat-icon>
        </button>
      </mat-form-field>
    </mat-card-header>
    <mat-card-content>
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortDisableClear
        multiTemplateDataRows
        matSortActive="name"
        matSortDirection="asc"
      >
        <!-- Items table -->
        <div
          *ngFor="
            let column of applicationService.filterColumnByPermission(state.displayedColumns, true)
          "
        >
          <ng-container
            matColumnDef="{{ column }}"
            *ngIf="column !== 'select' && column !== 'actions' && column === 'name'"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ trans.label[column] | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <a
                class="material-table-item-link"
                (click)="expandedElement = expandedElement === element ? null : element"
                >{{ element[column] }}</a
              >
            </td>
          </ng-container>
          <ng-container
            matColumnDef="{{ column }}"
            *ngIf="column !== 'select' && column !== 'actions' && column !== 'name'"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ trans.label[column] | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
          </ng-container>
        </div>
        <!-- Items actions -->
        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="material-table-column-actions">
            <a
              mat-button
              color="primary"
              matTooltipClass="material-actions-tooltip"
              [matTooltip]="trans.button.download | translate"
              filename="Certificate"
              [href]="element.url"
              [download]="'kmz'"
              ><i class="las la-file-download"></i
            ></a>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="
            applicationService.filterColumnByPermission(state.displayedColumns, true);
            sticky: true
          "
        ></tr>
        <tr
          mat-row
          *matRowDef="
            let row;
            columns: applicationService.filterColumnByPermission(state.displayedColumns, true)
          "
          class="material-row-height"
        ></tr>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <mat-paginator [pageSizeOptions]="state.pageSizeOptions" showFirstLastButtons>
      </mat-paginator>
    </mat-card-actions>
  </mat-card>
</div>

<app-empty-states-box
  *ngIf="!state.loading && dataSource.data.length <= 0"
  [iconSize]="'62px'"
  [messageSize]="'22px'"
  [messagePadding]="'45px 20px'"
  [icon]="'la-file-import'"
  [message]="trans.text.kmzNotFound"
  [buttonOne]="trans.title.listOfField | label"
  [buttonTwo]="trans.button.importKmz"
  [checkHarvestCompleted]="true"
  (buttonOneClick)="openComponentFields()"
  (buttonTwoClick)="openDialog()"
></app-empty-states-box>
