import { Field } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const fieldStateDefault = {
  fields: [],
  loading: 0,
  loadingRemove: 0,

  displayedColumns: ['select', 'name', 'glebeName', 'cropName', 'actions'],
  displayedForms: ['name', 'glebeName', 'cropName'],
  pageSizeOptions: [15],
} as FieldState;

export interface FieldState {
  fields: Array<Field>;
  loading: number;
  loadingRemove: number;

  displayedColumns: Array<string>;
  displayedForms: Array<string>;
  pageSizeOptions: number[];
}
