import { createAction, props } from '@ngrx/store';
import { Crop } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';

/**
 * Obter
 */
export const GET_OCCURRENCE_SETTINGS = createAction(
  '[Occurrence Settings Page] GET_OCCURRENCE_SETTINGS'
);
export const GET_OCCURRENCE_SETTINGS_SUCCESS = createAction(
  '[Occurrence Settings Page] GET_OCCURRENCE_SETTINGS_SUCCESS',
  props<{ occurrenceSettings: OccurrenceSettings }>()
);
export const GET_OCCURRENCE_SETTINGS_FAIL = createAction(
  '[Occurrence Settings Page] GET_OCCURRENCE_SETTINGS_FAIL',
  props<{ error: any }>()
);

export const GET_CROPS = createAction('[Occurrence Settings Page] GET_CROPS');
export const GET_CROPS_SUCCESS = createAction(
  '[Occurrence Settings Page] GET_CROPS_SUCCESS',
  props<{ crops: Array<Crop> }>()
);
export const GET_CROPS_FAIL = createAction(
  '[Occurrence Settings Page] GET_CROPS_FAIL',
  props<{ error: any }>()
);

/**
 * Atualizar
 */
export const UPDATE_OCCURRENCE_SETTINGS = createAction(
  '[Occurrence Settings Page] UPDATE_OCCURRENCE_SETTINGS',
  props<{ occurrenceSettings: OccurrenceSettings }>()
);
export const UPDATE_OCCURRENCE_SETTINGS_SUCCESS = createAction(
  '[Occurrence Settings Page] UPDATE_OCCURRENCE_SETTINGS_SUCCESS'
);
export const UPDATE_OCCURRENCE_SETTINGS_FAIL = createAction(
  '[Occurrence Settings Page] UPDATE_OCCURRENCE_SETTINGS_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const DESTROY_COMPONENT = createAction('[Occurrence Settings Page] DESTROY_COMPONENT');
