import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { TranslateTypes } from 'src/app/services/translation.service';
import {
  firstFormGroup,
  ImportKmzFormState,
  importKmzFormStateDefault,
} from './import-kmz-form.state';
import { BaseForm } from '../../utils/base-form/base-form';
import { ApplicationService } from 'src/app/services/application.service';
import { DefineFieldFormComponent } from '../define-field-form/define-field-form.component';
import { GoogleMapsImportKmzComponent } from '../google-maps-import-kmz/google-maps-import-kmz.component';
import { CREATE_KMZ_POLYGON, HIDE_MODAL, PARSE_KMZ } from './import-kmz-form.actions';
import * as _ from 'lodash';
import { Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-import-kmz-form',
  templateUrl: './import-kmz-form.component.html',
  styleUrls: ['./import-kmz-form.component.scss'],
})
export class ImportKmzFormComponent extends BaseForm implements OnInit, OnDestroy {
  @ViewChild('mapsImportKmz', { static: false }) mapsImportKmz!: GoogleMapsImportKmzComponent;
  public importKmzFormState$: Observable<ImportKmzFormState>;
  public state: ImportKmzFormState = importKmzFormStateDefault;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;

  constructor(
    public formBuilder: FormBuilder,
    public store: Store<any>,
    public dialogRef: MatDialogRef<ImportKmzFormComponent>,
    public applicationService: ApplicationService,
    public dialog: MatDialog,
    public trans: TranslateTypes
  ) {
    super(store, dialogRef, formBuilder, trans);
    this.importKmzFormState$ = this.store.pipe(select('importKmzFormState'));
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));
    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.subscribe = this.importKmzFormState$.subscribe(
      (importKmzFormState: ImportKmzFormState) => {
        this.state = importKmzFormState;
        if (importKmzFormState.closeModal) {
          this.onCloseModal();
        }
      }
    );
  }

  /**
   * Este método executa quando o componentes não é mais ativo
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método fecha uma modal
   */
  public onCloseModal(): void {
    this.closeModal(HIDE_MODAL(), true, false, false);
  }

  /**
   * Este método valida e cadastrar ou atualiza
   */
  public onFormClick(): void {
    if (this.firstFormGroup.invalid) {
      this.checkValidateForms(this.firstFormGroup);
    } else {
      const data = this.mapsImportKmz.getPolygonsDefined();

      const action = CREATE_KMZ_POLYGON({
        kmzPolygons: _.cloneDeep(data),
      });
      this.onSubmit(action);
    }
  }

  /**
   * Este método dispara uma ação para parcear um kmz
   */
  public onChangeKmzFile(): void {
    if (this.firstFormGroup.get('kmzFile')?.value) {
      const action = PARSE_KMZ({ kmzFile: this.firstFormGroup.get('kmzFile')?.value });
      this.store.dispatch(action);
    } else {
      this.state.polygons = [];
    }
  }

  /**
   * Este método abre a modal para definir um talhão
   */
  public openDialog(): void {
    const dialogRef = this.dialog.open(DefineFieldFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-500'],
    });

    dialogRef.afterClosed().subscribe((glebe: Glebe) => {
      if (glebe) {
        this.mapsImportKmz.setGlebeToPolygons(glebe);
      }
    });
  }
}
