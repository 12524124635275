import { AgmMap } from '@agm/core';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { IconCustom } from 'src/app/constants/mapIconCustom';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import {
  GeolocationPoint,
  Point,
  Polygon,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

declare const google: any;

@Component({
  selector: 'app-google-maps-draw-marker',
  templateUrl: './google-maps-draw-marker.component.html',
  styleUrls: ['./google-maps-draw-marker.component.scss'],
})
export class GoogleMapsDrawMarkerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('AgmMap', { static: true }) public agmMap!: AgmMap;
  @ViewChild('agmSnazzyInfoWindow', { static: true }) public agmSnazzyInfoWindow: any;

  @Input() public height = '500px';
  @Input() public iconUrl = 'scouting_light.svg';
  @Input() public multiple = true;
  @Input() public radius = 0;
  @Input() public enableDrawing = true;
  @Input() public btnEdit = false;
  @Input() public markerClickable = true;
  @Input() public markerDraggable = true;
  @Output() public eventDrawEnd: EventEmitter<any> = new EventEmitter<any>(true);
  @Input() public set setInputMarkers(
    markers: Array<{
      id: string | null;
      lat: number | null;
      lng: number | null;
    }>
  ) {
    this.setMarkers(markers as Array<Point>);
  }
  @Input() public set setInputPolygons(polygon: Polygon | undefined) {
    if (polygon) {
      this.setPolygons(polygon);
    }
  }

  public readonly mapTypeId: any = 'satellite';

  public markers: Array<Point> | null = [];
  public polygon: Polygon | null = null;
  public coordinates: GeolocationPoint | null;
  public subscriber!: Subscription;
  public selectedMarker: Point | null = null;
  public map: any = {};
  public zoom = 15;
  public fitBounds = true;

  constructor(
    private db: AngularFirestore,
    public applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {
    const farm = this.applicationService.getFarm();
    this.coordinates = farm ? farm.coordinates : null;
  }

  /**
   * Este método é executado quando o componente é totalmente carregado
   */
  public ngAfterViewInit(): void {
    this.subscriber = this.agmMap.mapReady.subscribe((map) => {
      this.map = map;

      map.setOptions({
        tilt: 0,
        scaleControl: true,
        gestureHandling: 'greedy',
      });

      if (this.polygon && google.maps.geometry) {
        this.setFitBounds();
      }
    });
  }

  /**
   * Este método é executado quando o componente é destruído
   */
  public ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  /**
   * Este método recebe um polígono faz o mapeamento e desenha no mapa
   * @param polygon Polígono
   */
  public setPolygons(polygon: Polygon): void {
    if (polygon) {
      this.polygon = polygon;
      this.setFitBounds();
    } else {
      this.polygon = null;
    }
  }

  /**
   * Este método recebe uma lista de pontos faz o mapeamento e desenha no mapa
   * @param markers lista de pontos de um polígono
   */
  public setMarkers(markers: Array<Point>): void {
    if (markers && markers.length > 0 && markers[0] && markers[0].lat && markers[0].lng) {
      this.markers = _.cloneDeep(
        markers.map((marker) => {
          return {
            id: marker.id,
            lat: this.convertToNumber(marker.lat),
            lng: this.convertToNumber(marker.lng),
          };
        }) as unknown as Array<Point>
      );
    } else {
      this.markers = null;
    }
  }

  /**
   * Este método da um zoom e centraliza os polígonos na tela
   */
  public setFitBounds(): void {
    setTimeout(() => {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      this.polygon?.points.forEach((latLng) => {
        bounds.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
      });
      this.map.fitBounds(bounds);
    }, 500);
  }

  /**
   * Este método é executado quando o evento de click sobre o polígono é acionado
   * @param event lat e lng do click
   */
  public polyClick(event: any): void {
    if (this.enableDrawing) {
      const marker = {
        id: this.db.createId(),
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      this.eventDrawEnd.emit({
        disabled: false,
        action: 'create',
        marker,
      });
    }
  }

  /**
   * Este método abre um popup com as informações do talhão
   * @param position indica a posição em latLng
   * @param infoWindow popup componente
   */
  public openInfoWindow(position: any, marker: Point): void {
    if (
      this.agmSnazzyInfoWindow &&
      (!this.selectedMarker || this.selectedMarker.id !== marker.id)
    ) {
      this.selectedMarker = marker;
      this.agmSnazzyInfoWindow.latitude = position.latitude;
      this.agmSnazzyInfoWindow.longitude = position.longitude;
      this.agmSnazzyInfoWindow._updatePosition();
      this.agmSnazzyInfoWindow._openInfoWindow();
    } else {
      this.closeInfoWindow();
    }
  }

  /**
   * Este método fecha um popup com as informações do talhão
   * @param infoWindow popup componente
   */
  public closeInfoWindow(): void {
    setTimeout(() => {
      if (this.agmSnazzyInfoWindow) {
        this.agmSnazzyInfoWindow._closeInfoWindow();
        this.selectedMarker = null;
      }
    }, 200);
  }

  /**
   * Este método é executado quando um evento de edição é acionado
   */
  public editMarker(): void {
    this.eventDrawEnd.emit({
      disabled: false,
      action: 'update',
      marker: this.selectedMarker,
    });

    this.closeInfoWindow();
  }

  /**
   *  Este método é executado quando um evento de arrastar é acionado
   * @param event lat e long com as novas coordenadas
   * @param marker ponto que foi arrastado
   */
  public dragEnd(event: any, marker: Point): void {
    if (this.enableDrawing) {
      if (this.markerInField(event.latLng, this.polygon)) {
        marker.lat = event.latLng.lat();
        marker.lng = event.latLng.lng();
        this.eventDrawEnd.emit({
          disabled: false,
          action: 'drag',
          hasDraggedOffField: false,
          marker,
        });
      } else {
        this.eventDrawEnd.emit({
          disabled: false,
          action: 'drag',
          hasDraggedOffField: true,
          marker,
        });
      }
    }
  }

  /**
   * Este método é executado quando um evento para remover ponto é acionado
   */
  public removeMarker(): void {
    setTimeout(() => {
      if (this.markers && this.selectedMarker) {
        const index = this.markers.indexOf(this.selectedMarker);
        this.markers?.splice(index, 1);
        this.eventDrawEnd.emit({
          disabled: false,
          action: 'remove',
          marker: this.selectedMarker,
        });
      }
    }, 200);

    this.closeInfoWindow();
  }

  /**
   * Este método verifica se o ponto está dentro de um talhão
   * @param marker ponto que está desenhado no map
   * @param polygon polígono que está desenhado no map
   */
  public markerInField(marker: any, polygon: any): boolean {
    return google.maps.geometry.poly.containsLocation(
      marker,
      new google.maps.Polygon({ paths: polygon.points })
    );
  }

  /**
   * Este método transforma uma string em número
   * @param value valor a ser convertido
   */
  public convertToNumber(value: string | number | null | undefined): number {
    if (value) {
      return parseFloat(value.toString());
    }
    return 0;
  }

  /**
   * Este método retorna a url do ícone para scouting
   */
  public getIconSvg(): google.maps.Icon {
    const iconCustom: any = IconCustom;

    iconCustom.url = `./../assets/icons/${this.iconUrl}`;

    return iconCustom as google.maps.Icon;
  }
}
