import { Validators } from '@angular/forms';
import { Trap, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { CustomValidators } from '../../utils/custom-validators/custom-validators';

export const importTrapFormStateDefault = {
  harvests: [],
  traps: [],
  loading: 0,
  loadingModal: 0,
  closeModal: false,
} as ImportTrapFormState;

export const firstFormGroup = {
  ids: [[], [Validators.required, CustomValidators.listIsEmpty]],
} as any;

export interface ImportTrapFormState {
  harvests: Array<Harvest>;
  traps: Array<Trap>;
  loading: number;
  loadingModal: number;
  closeModal: boolean;
}
