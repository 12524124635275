import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { GET_LEVEL_EXCEEDED_CHART } from 'src/app/view/gm-tabs/gm-tabs.actions';
import { TranslateTypes } from 'src/app/services/translation.service';
import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { gmTabsDefault, GmTabsState } from '../../gm-tabs.state';
import { Field } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-level-exceeded-modal',
  templateUrl: './level-exceeded-modal.component.html',
  styleUrls: ['./level-exceeded-modal.component.scss'],
})
export class LevelExceededModalComponent implements OnInit, OnDestroy {
  public reportState$: Observable<GmTabsState>;
  public state: GmTabsState = gmTabsDefault;
  public subscribe!: Subscription;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LevelExceededModalComponent>,
    public translateService: TranslateService,
    public trans: TranslateTypes,
    private store: Store<any>,

    @Inject(MAT_DIALOG_DATA) public data: { field: Field; gm: GmConfiguration }
  ) {
    this.reportState$ = this.store.pipe(select('gmTabs'));
  }

  public ngOnInit(): void {
    this.subscribe = this.reportState$.subscribe((state: GmTabsState) => {
      this.state = state;
    });

    const { field, gm } = this.data;
    const { startDate, endDate, trapCropId } = gm ? gm : ({} as GmConfiguration);

    this.store.dispatch(
      GET_LEVEL_EXCEEDED_CHART({
        startDate: startDate,
        endDate: endDate,
        fieldId: field.id,
        cropId: trapCropId,
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.state.levelExceededChart = [];
  }

  /**
   * This is the method that close modal
   */
  public closeModal() {
    this.dialogRef.close();
  }
}
