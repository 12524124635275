export const cardFarmLabel = {
  // country: 'País',
  // state: 'Estado',
  // city: 'Cidade',
  // district: 'Distrito',
} as any;

export const cardGlebeLabel = {
  harvestName: 'Safra',
  cropName: 'Cultura',
  varieties: 'Variedade',
} as any;

export const cardFieldLabel = {
  area: 'Área (ha)',
  glebe: 'Gleba',
} as any;
