import { Validators } from '@angular/forms';
import { regex } from 'src/app/constants/regex';

export const firstFormGroup = {
  email: [
    null,
    [
      Validators.required,
      Validators.pattern(regex.email),
      Validators.minLength(8),
      Validators.maxLength(64),
    ],
  ],
} as any;
