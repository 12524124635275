import { Action, createReducer, on } from '@ngrx/store';
import { UpdateUserState, updateUserStateDefault } from './update-user.state';
import * as updateUserAction from './update-user.action';

export const updateUserReducer = createReducer(
  updateUserStateDefault,
  on(updateUserAction.GET_CUSTOMERS, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(updateUserAction.GET_CUSTOMERS_SUCCESS, (state, action) => ({
    ...state,
    customers: action.customers,
    loading: state.loading - 1,
  })),
  on(updateUserAction.GET_CUSTOMERS_FAIL, (state) => ({
    ...state,
    customers: [],
    loading: state.loading - 1,
  })),
  on(updateUserAction.GET_CUSTOMER_FARMS, (state, action) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(updateUserAction.GET_CUSTOMERS_FARMS_SUCCESS, (state, action) => ({
    ...state,
    farms: action.farms,
    loading: state.loading - 1,
  })),
  on(updateUserAction.GET_CUSTOMERS_FARMS_FAIL, (state) => ({
    ...state,
    farms: [],
    loading: state.loading - 1,
  })),
  on(updateUserAction.GET_SUPPORT_USERS, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(updateUserAction.GET_SUPPORT_USERS_SUCCESS, (state, action) => ({
    ...state,
    users: action.users,
    loading: state.loading - 1,
  })),
  on(updateUserAction.GET_SUPPORT_USERS_FAIL, (state) => ({
    ...state,
    users: [],
    loading: state.loading - 1,
  })),
  on(updateUserAction.POST_UPDATE_USERS_CUSTOMERS, (state) => ({
    ...state,
    closeModal: false,
    loading: state.loading + 1,
  })),
  on(updateUserAction.UPDATE_USERS_CUSTOMERS_SUCCESS, (state) => ({
    ...state,
    closeModal: true,
    loading: state.loading - 1,
  })),
  on(updateUserAction.POST_UPDATE_USERS_CUSTOMERS_FAIL, (state) => ({
    ...state,
    closeModal: false,
    loading: state.loading - 1,
  }))
);

export function reducer(state: UpdateUserState | undefined, action: Action): UpdateUserState {
  return updateUserReducer(state, action);
}
