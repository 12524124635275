<!-- Loading -->
<app-loading [isOpen]="state.loadingModal || state.loading"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="onCloseModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span class="title">{{ trans.button.importTrap | translate }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <form class="custom-form-field" [formGroup]="firstFormGroup">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <!-- field -->
      <mat-form-field fxFlex="100%">
        <mat-label>{{ trans.label.trap | translate }}</mat-label>
        <mat-select #materialSelect id="ids" name="ids" formControlName="ids" multiple>
          <mat-option
            class="material-select-option-message"
            *ngIf="getHarvests().length <= 0"
            [disabled]="true"
          >
            {{ trans.text.noTrapsFound | translate }}</mat-option
          >
          <mat-optgroup *ngFor="let item of getHarvests(); let i = index" [label]="item.name">
            <mat-option
              class="material-select-option-message"
              *ngIf="getTrapsByHarvest(item.id).length <= 0"
              [disabled]="true"
            >
              {{ trans.text.noTrapsFound | translate }}</mat-option
            >
            <mat-option
              *ngFor="let trap of getTrapsByHarvest(item.id)"
              [value]="trap.id"
              [disabled]="wasImportedTrap(trap.id)"
            >
              {{ trap.name }}
              <span *ngIf="trap.alias && trap.alias !== ''">( {{ trap.alias }} )</span>
              <span style="float: right" *ngIf="wasImportedTrap(trap.id)">{{
                trans.text.imported | translate
              }}</span>
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="onCloseModal()">{{ trans.button.close | translate }}</button>
  <div>
    <button mat-button color="primary" (click)="onFormClick()">
      {{ trans.button.save | translate }}
    </button>
  </div>
</mat-dialog-actions>
