import { Injectable } from '@angular/core';
import pt from '../../assets/i18n/pt';

export function GenericClass<Props>(): new () => Props {
  return class {} as any;
}

function concatIfExistsPath(path: string, suffix: string): string {
  return path ? `${path}.${suffix}` : suffix;
}

export function transformObjectToPath<T extends object | string>(
  suffix: string,
  objectToTransformOrEndOfPath: T,
  path = ''
): T {
  return typeof objectToTransformOrEndOfPath === 'object'
    ? Object.entries(objectToTransformOrEndOfPath).reduce((objectToTransform, [key, value]) => {
        (objectToTransform as any)[key] = transformObjectToPath(
          key,
          value,
          concatIfExistsPath(path, suffix)
        );

        return objectToTransform;
      }, {} as T)
    : (concatIfExistsPath(path, suffix) as T);
}

@Injectable()
export class TranslateTypes extends GenericClass<typeof pt>() {
  constructor() {
    super();
    Object.assign(this, transformObjectToPath('', pt));
  }
}
