import { Farm } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export interface FarmsRouteOptimizationState {
  farms: Farm[];
  loading: boolean;
  error: any;
}

export const initialFarmsRouteOptimizationState: FarmsRouteOptimizationState = {
  farms: [],
  loading: false,
  error: null,
};
