import * as _ from 'lodash';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseForm } from '../../utils/base-form/base-form';
import { FormBuilder } from '@angular/forms';
import { ApplicationService } from 'src/app/services/application.service';
import { DESTROY_COMPONENT } from 'src/app/view/configuration/occurrence-settings/occurrence-settings.actions';
import {
  firstFormGroup,
  SystemSettingsState,
  systemSettingsStateDefault,
} from './system-settings.state';
import { UsefulService } from '../../utils/useful-methods/useful.service';
import { Operation } from 'src/app/enums/Operation';

import {
  GET_SYSTEM_SETTINGS,
  UPDATE_SYSTEM_SETTINGS,
} from 'src/app/view/configuration/system-settings/system-settings.actions';
import { AreaType } from '@tarvos-ag/tarvos-firestore-models/src/enums';
import { SystemSettings } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SystemSettingsComponent extends BaseForm implements OnInit, OnDestroy {
  public glebeState$: Observable<SystemSettingsState>;
  public state: SystemSettingsState = systemSettingsStateDefault;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public areaType = AreaType;
  public operation = Operation;

  constructor(
    public formBuilder: FormBuilder,
    public store: Store<any>,

    public trans: TranslateTypes,
    public applicationService: ApplicationService,
    public usefulService: UsefulService
  ) {
    super(store, null, formBuilder, trans);
    this.glebeState$ = this.store.pipe(select('systemSettingsState'));

    // deve estar dentro do construtor para ser carregado antes da página estar ativa;
    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === SystemSettingsComponent.name) {
        this.store.dispatch(GET_SYSTEM_SETTINGS());
        this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));
      }
    });
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    this.store.dispatch(GET_SYSTEM_SETTINGS());
    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));

    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.subscribe = this.glebeState$.subscribe((state: SystemSettingsState) => {
      this.state = state;

      if (_.has(this.state.systemSettings, 'fieldArea')) {
        this.firstFormGroup.patchValue({
          id: this.state.systemSettings.id,
          fieldArea: this.state.systemSettings.fieldArea,
        });
        if (this.state.systemSettings.mad) {
          this.firstFormGroup.patchValue({
            controlLevel: this.state.systemSettings.mad.controlLevels.controlLevel,
            damageLevel: this.state.systemSettings.mad.controlLevels.damageLevel,
          });
        }
      }
    });
  }

  /**
   * Este método executa quando o componentes não é mais ativo
   */
  public ngOnDestroy(): void {
    this.store.dispatch(DESTROY_COMPONENT());
    this.subscribe.unsubscribe();
  }

  /**
   * Este método valida e cadastrar ou atualiza
   */
  public onFormClick(): void {
    if (this.firstFormGroup.invalid) {
      this.checkValidateForms(this.firstFormGroup);
    } else {
      const systemSettings: SystemSettings = {
        id: this.firstFormGroup.getRawValue().id,
        fieldArea: this.firstFormGroup.getRawValue().fieldArea,
        mad: {
          controlLevels: {
            controlLevel: this.firstFormGroup.getRawValue().controlLevel,
            damageLevel: this.firstFormGroup.getRawValue().damageLevel,
          },
        },
      };
      const action = UPDATE_SYSTEM_SETTINGS({ systemSettings });
      this.onSubmit(action);
    }
  }

  /**
   * Este método filtra os dados da tabela
   * @Override
   */
  public customDataSearch(): void {
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      return this.displayedColumns.some(
        (column) =>
          column !== 'name' &&
          data[column] &&
          data[column].toString().toLowerCase().includes(filter)
      );
    };
  }

  public hasMad(): boolean {
    return _.has(this.state.systemSettings, 'mad');
  }
}
