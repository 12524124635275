/**
 * NOTE - Comentários e Refatoração de código 19/05/2021
 */

import * as gmAction from './gm.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { GmState, gmStateDefault } from 'src/app/view/gm/gm.state';
import { ApplicationService } from '../../services/application.service';
import { GenericFirestoreService } from '../../services/generic-firestore.service';
import { Timestamp } from 'src/app/interfaces/Types';
import { Crop, Field, Trap, Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Injectable()
export class GmEffects {
  public gmState$: Observable<GmState>;
  public state: GmState = gmStateDefault;

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService
  ) {
    this.gmState$ = this.store.pipe(select('gm'));
    this.gmState$.subscribe((state: GmState) => {
      this.state = state;
    });
  }

  /**
   * Este método chama um serviço para monitoramento geral.
   */
  public getGm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmAction.GET_GM),
      switchMap(() =>
        this.genericFirestoreService
          .getById<GmConfiguration>(
            `${this.applicationService.getHarvestRefDatabase()}/generalMonitoringConfigurations/${this.applicationService.getUserId()}`
          )
          .pipe(
            map((gm: GmConfiguration) => gmAction.GET_GM_SUCCESS({ gm })),
            catchError((error) => of(gmAction.GET_GM_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para listar talhões.
   */
  public getFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmAction.GET_FIELDS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Field>(`${this.applicationService.getHarvestRefDatabase()}/fields`, {
            fieldPath: 'name',
            directionStr: 'asc',
          })
          .pipe(
            map((fields: Array<Field>) => gmAction.GET_FIELDS_SUCCESS({ fields })),
            catchError((error) => of(gmAction.GET_FIELDS_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para listar glebas.
   */
  public getGlebes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmAction.GET_GLEBES),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Glebe>(`${this.applicationService.getHarvestRefDatabase()}/glebes`, {
            fieldPath: 'name',
            directionStr: 'asc',
          })
          .pipe(
            map((glebes: Array<Glebe>) => gmAction.GET_GLEBES_SUCCESS({ glebes })),
            catchError((error) => of(gmAction.GET_GLEBES_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para listar ocorrências.
   */
  public getOccurrences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmAction.GET_OCCURRENCES),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<any>(`${this.applicationService.getHarvestRefDatabase()}/filters`)
          .pipe(
            map((occurrences: Array<any>) => gmAction.GET_OCCURRENCES_SUCCESS({ occurrences })),
            catchError((error) => of(gmAction.GET_OCCURRENCES_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para listar culturas.
   */
  public getCrops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmAction.GET_CROPS),
      switchMap(() =>
        this.genericFirestoreService.getAll<any>('crops').pipe(
          map((crops: Array<Crop>) => gmAction.GET_CROPS_SUCCESS({ crops })),
          catchError((error) => of(gmAction.GET_CROPS_FAIL(error)))
        )
      )
    )
  );

  /**
   * Este método chama um serviço para listar armadilhas da safra em uso.
   */
  public getTraps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(gmAction.GET_TRAPS),
      switchMap(() => {
        return this.genericFirestoreService
          .getAll<Trap>(`${this.applicationService.getFarmRefDatabase()}/traps`, {
            queryFn: (ref: any) =>
              ref.where('harvestId', '==', this.applicationService.getHarvestId()),
          })
          .pipe(
            map((traps: Array<Trap>) => gmAction.GET_TRAPS_SUCCESS({ traps })),
            catchError((error) => of(gmAction.GET_TRAPS_FAIL(error)))
          );
      })
    );
  });

  /**
   * Este método chama um serviço para atualizar monitoramento geral.
   */
  public updateGm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gmAction.UPDATE_GM),
      switchMap((action) => {
        const user = this.applicationService.getUser();
        action.gm.userId = user ? user.id : '';
        action.gm.id = user ? user.id : action.gm.id;

        action.gm.startDate = Timestamp.fromDate(action.gm.startDate.toDate());
        action.gm.endDate = Timestamp.fromDate(action.gm.endDate.toDate());

        return this.genericFirestoreService
          .update<GmConfiguration>(
            `${this.applicationService.getHarvestRefDatabase()}/generalMonitoringConfigurations`,
            action.gm
          )
          .then(() => gmAction.UPDATE_GM_SUCCESS())
          .catch((error) => {
            if (error.code === 'not-found') {
              return this.genericFirestoreService
                .create<GmConfiguration>(
                  `${this.applicationService.getHarvestRefDatabase()}/generalMonitoringConfigurations`,
                  action.gm
                )
                .then(() => gmAction.UPDATE_GM_SUCCESS())
                .catch((err) => {
                  return gmAction.UPDATE_GM_FAIL(err);
                });
            } else {
              return gmAction.UPDATE_GM_FAIL(error);
            }
          });
      })
    )
  );
}
