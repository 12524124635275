export const IconCustom = {
  url: '',
  scaledSize: { height: 16, width: 16 },
  anchor: { x: 12, y: 12 },
};

export const OccurrenceUrl = {
  OCCURRENCE: {
    ARMIGERA: 'insect_one.png',
    FRUGIPERDA: 'insect_two.png',
    SACCHARALIS: 'insect_one.png',
    TRACA_TOMATEIRO: 'insect_three.png',
    BROCA_PEQUENA_FRUTO: 'insect_four.png',
    CIGARRINHA_DO_MILHO_ADULTO: 'insect_three.png',
    CAPITATA: 'insect_one.png',
    ANASTREPHA: 'insect_two.png',
    VIRESCENS: 'insect_one.png',
  },
} as any;
