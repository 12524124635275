import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { first } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private fireAuth: AngularFireAuth,
    private db: AngularFirestore
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const user = await this.getAuthUser();

    if (state.url === '/login' && user) {
      this.router.navigate(['/home/gm']);
      return false;
    } else if (state.url === '/recoverPassword' && !user) {
      return true;
    } else if (state.url === '/login' && !user) {
      return true;
      // eslint-disable-next-line prettier/prettier
    } else if (state.url === '/home/admin/trap' && user && user[0].role !== 'ADMIN') {
      this.router.navigate(['/home/gm']);
      return false;
    } else if (state.url === '/home/routeOptimization' && user && user[0].role !== 'ADMIN') {
      this.router.navigate(['/home/gm']);
      return false;
    } else if (state.url === '/createCustomer' && user && user[0].role !== 'ADMIN') {
      this.router.navigate(['/home/gm']);
      return false;
    } else if (user) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  private async getAuthUser(): Promise<any> {
    return await this.fireAuth.user
      .pipe(first())
      .toPromise()
      .then((data: any) => {
        if (data) {
          return this.db
            .collection<WebUser>('users', (ref) => ref.where('id', '==', data.uid))
            .valueChanges()
            .pipe(first())
            .toPromise()
            .then((user: any) => {
              return user;
            })
            .catch((error: any) => {});
        } else {
          return null;
        }
      })
      .catch((error: any) => {});
  }
}
