import { RouteData } from './route-route-optimization.actions';

export interface RoutesState {
  route: RouteData | null;
  loading: boolean;
  error: any;
}

export const initialRoutesState: RoutesState = {
  route: null,
  loading: false,
  error: null,
};
