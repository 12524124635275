<app-progress-bar [isOpen]="state.loadingTotalBetweenFieldsChart"></app-progress-bar>

<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 mat-dialog-title>
    {{ trans.title.totalBetweenFields | label }}
    {{ state.totalBetweenFieldsChart ? '(' + state.totalBetweenFieldsChart.name + ')' : '' }}
    <button
      class="custom-button-help"
      mat-mini-fab
      color="primary"
      matTooltipClass="custom-tooltip-help"
      [matTooltip]="trans.text.helpTotalBetweenChart['TRAP'] | label"
    >
      ?
    </button>
  </h2>

  <button
    mat-icon-button
    autofocus="false"
    class="custom-button-close"
    (click)="closeModal()"
    tabindex="-1"
  >
    <i class="las la-times"></i>
  </button>
</div>

<mat-dialog-content style="height: 75vh; overflow: hidden">
  <form class="custom-form-field" [formGroup]="formGroup">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <mat-form-field fxFlex="100%">
        <mat-label>{{ trans.label.glebe | label }}</mat-label>
        <mat-select
          #materialSelect
          id="glebeIds"
          formControlName="glebeIds"
          (selectionChange)="onChangeGlebe()"
          multiple
          required
        >
          <mat-option class="selectAll" (click)="selectAllGlebes()">{{
            trans.title.selectAll | translate
          }}</mat-option>
          <mat-option class="selectAll" (click)="deselectAllGlebes()">{{
            trans.title.deselectAll | translate
          }}</mat-option>
          <mat-option *ngFor="let glebe of this.state.glebes" [value]="glebe.id">
            <span>
              {{ glebe.name }}
              <span *ngIf="glebe.alias && glebe.alias !== ''">( {{ glebe.alias }} )</span></span
            >
            <span
              style="float: right"
              matTooltipClass="material-tooltip-class"
              [matTooltip]="trans.text.harvestedDate | translate"
              [matTooltipPosition]="'left'"
              *ngIf="glebe.harvested"
            >
              {{ moment(glebe.finalHarvestDate.toDate()).format(trans.text.date | translate) }}
            </span>
          </mat-option>
        </mat-select>
        <mat-error>
          {{ trans.errors.required['field'] | label }}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="100%">
        <mat-label>{{ trans.label.field | label }}</mat-label>
        <mat-select
          #materialSelect
          id="fieldIds"
          formControlName="fieldIds"
          (openedChange)="getTotalBetween(materialSelect)"
          multiple
          required
        >
          <mat-option class="selectAll" (click)="selectAllFields()">{{
            trans.title.selectAll | translate
          }}</mat-option>
          <mat-option class="selectAll" (click)="deselectAllFields()">{{
            trans.title.deselectAll | translate
          }}</mat-option>
          <mat-optgroup *ngFor="let item of getGlebesByIds(); let i = index" [label]="item.name">
            <mat-option
              class="material-select-option-message"
              *ngIf="getFieldsByGlebe(item.id).length <= 0"
              [disabled]="true"
            >
              {{ trans.text.noRegisteredFields | label }}</mat-option
            >
            <mat-option *ngFor="let field of getFieldsByGlebe(item.id)" [value]="field.id">
              {{ formatName('T', field.name) }} ({{ field.alias }})
              <span
                style="float: right"
                matTooltipClass="material-tooltip-class"
                [matTooltip]="trans.text.harvestedDate | translate"
                [matTooltipPosition]="'left'"
                *ngIf="field.harvested"
              >
                {{ moment(field.harvestDate.toDate()).format(trans.text.date | translate) }}
              </span>
            </mat-option>
          </mat-optgroup>
        </mat-select>
        <mat-error>
          {{ trans.errors.required['field'] | label }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
  <div style="height: 55vh">
    <app-total-between-fields-chart
      *ngIf="state.totalBetweenFieldsChart"
      [data]="state.totalBetweenFieldsChart"
    ></app-total-between-fields-chart>
  </div>
  <app-empty-states-box
    *ngIf="state.loadingTotalBetweenFieldsChart <= 0 && state.totalBetweenFieldsChart === null"
    [iconSize]="'62px'"
    [messageSize]="'22px'"
    [messagePadding]="'45px 20px'"
    [icon]="'la-chart-line'"
    [message]="trans.text.chartNotFound"
  ></app-empty-states-box>
</mat-dialog-content>
