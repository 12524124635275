import * as _ from 'lodash';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseForm } from '../../utils/base-form/base-form';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ControlLevel } from 'src/app/interfaces/ControlLevel';
import { ApplicationService } from 'src/app/services/application.service';
import { MatTableDataSource } from '@angular/material/table';
import { CustomControlLevel } from 'src/app/interfaces/CustomControlLevel';
import { ControlLevelType } from 'src/app/enums/ControlLevelType';
import {
  CustomValidators,
  CustomValidatorsMatcher,
} from '../../utils/custom-validators/custom-validators';
import { regex, Regex } from 'src/app/constants/regex';
import {
  firstFormGroup,
  OccurrenceSettingsState,
  occurrenceSettingsStateDefault,
} from './occurrence-settings.state';
import {
  DESTROY_COMPONENT,
  GET_CROPS,
  GET_OCCURRENCE_SETTINGS,
  UPDATE_OCCURRENCE_SETTINGS,
} from 'src/app/view/configuration/occurrence-settings/occurrence-settings.actions';
import { UnitOfMeasureOfOccurrence } from '@tarvos-ag/tarvos-firestore-models/src/enums';
import { ControlLevelValue } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-occurrence-settings',
  templateUrl: './occurrence-settings.component.html',
  styleUrls: ['./occurrence-settings.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OccurrenceSettingsComponent extends BaseForm implements OnInit, OnDestroy {
  public occurrenceSettingsState$: Observable<OccurrenceSettingsState>;
  public state: OccurrenceSettingsState = occurrenceSettingsStateDefault;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public controlLevelType: any = ControlLevelType;
  public regex: Regex = regex;
  public customValidators = CustomValidators;
  public customValidatorsMatcher = new CustomValidatorsMatcher();

  constructor(
    public formBuilder: FormBuilder,
    public store: Store<any>,
    public trans: TranslateTypes,
    public applicationService: ApplicationService
  ) {
    super(store, null, formBuilder, trans);
    this.occurrenceSettingsState$ = this.store.pipe(select('occurrenceSettingsState'));

    // deve estar dentro do construtor para ser carregado antes da página estar ativa;
    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === OccurrenceSettingsComponent.name) {
        this.store.dispatch(GET_OCCURRENCE_SETTINGS());
        this.store.dispatch(GET_CROPS());
        this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));
      }
    });
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    this.store.dispatch(GET_OCCURRENCE_SETTINGS());
    this.store.dispatch(GET_CROPS());
    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));

    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.subscribe = this.occurrenceSettingsState$.subscribe((state: OccurrenceSettingsState) => {
      this.state = state;

      if (
        this.state.occurrenceSettings.customControlLevels?.length > 0 &&
        this.state.crops?.length > 0
      ) {
        this.addFormGroupAtArray(_.cloneDeep(this.state.occurrenceSettings.customControlLevels));
        this.setTableSetting(_.cloneDeep(this.state.occurrenceSettings.customControlLevels));
        this.firstFormGroup.patchValue({
          id: this.state.occurrenceSettings.id,
        });
      }
    });
  }

  /**
   * Este método executa quando o componentes não é mais ativo
   */
  public ngOnDestroy(): void {
    this.store.dispatch(DESTROY_COMPONENT());
    this.subscribe.unsubscribe();
  }

  /**
   * Este método valida e cadastrar ou atualiza
   */
  public onFormClick(): void {
    if (this.firstFormGroup.invalid) {
      this.checkValidateForms(this.firstFormGroup);
    } else {
      const action = UPDATE_OCCURRENCE_SETTINGS({
        occurrenceSettings: this.firstFormGroup.getRawValue(),
      });
      this.onSubmit(action);
    }
  }

  /**
   * Este método adiciona controle de formulário para o nível de controle customizado
   */
  private addFormGroupAtArray(customControlLevels: Array<CustomControlLevel>): void {
    this.firstFormGroup.removeControl('customControlLevels');
    this.firstFormGroup.addControl(
      'customControlLevels',
      new FormArray([], [Validators.required, CustomValidators.listIsEmpty])
    );

    customControlLevels.forEach((customControlLevel: CustomControlLevel) => {
      (this.firstFormGroup.controls.customControlLevels as FormArray).push(
        new FormBuilder().group({
          controlLevels: new FormArray(
            customControlLevel.controlLevels.map((controlLevel: ControlLevel) => {
              if (controlLevel === null) {
                return new FormBuilder().group(controlLevel, { validators: [] });
              } else {
                return new FormBuilder().group(
                  {
                    controlLevelType: [[], Validators.required],
                    values: new FormBuilder().array(
                      controlLevel.values.map((value: ControlLevelValue) => {
                        const valueForm = new FormBuilder().group(value);
                        if (
                          valueForm.get('value')?.value != null &&
                          value.unit === UnitOfMeasureOfOccurrence.percentage
                        ) {
                          valueForm
                            .get('value')
                            ?.setValidators([Validators.required, CustomValidators.isPercentage]);
                          return valueForm;
                        } else if (
                          valueForm.get('value')?.value != null &&
                          value.unit === UnitOfMeasureOfOccurrence.und
                        ) {
                          valueForm
                            .get('value')
                            ?.setValidators([Validators.required, CustomValidators.isUnd]);
                          return valueForm;
                        } else if (
                          valueForm.get('value')?.value != null &&
                          value.unit === UnitOfMeasureOfOccurrence.occurrenceByMeters
                        ) {
                          valueForm
                            .get('value')
                            ?.setValidators([
                              Validators.required,
                              CustomValidators.isOccurrenceByMeters,
                            ]);
                          return valueForm;
                        } else {
                          return valueForm;
                        }
                      })
                    ),
                  },
                  { validators: [Validators.required] }
                );
              }
            })
          ),
          occurrence: [[], Validators.required],
        })
      );
    });

    this.firstFormGroup.patchValue({
      customControlLevels,
    });
  }

  /**
   * Este método define as configurações iniciais da tabela 'nível de controle customizado'
   * @param cropId id da cultura
   */
  public setTableSetting(customControlLevels: Array<CustomControlLevel>): void {
    this.dataSource = new MatTableDataSource<CustomControlLevel>(
      customControlLevels
        .map((customControlLevel: any) => {
          customControlLevel.name = customControlLevel.occurrence.name;
          customControlLevel.cropIds = customControlLevel.occurrence.cropsIds.toString();
          return customControlLevel;
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    );

    this.initTable(
      this.applicationService.filterColumnByPermission(this.state.displayedColumnsModal)
    );
    this.selectedTabChangeTab(0);
  }

  /**
   * Este método filtra as ocorrências por cultura
   */
  public selectedTabChangeTab(index: number): void {
    this.expandedElement = null;
    const id = this.state.crops[index].id;
    this.dataSearch(id);
  }

  /**
   * Este método filtra os dados da tabela
   * @Override
   */
  public customDataSearch(): void {
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      return this.displayedColumns.some(
        (column) =>
          column !== 'name' &&
          data[column] &&
          data[column].toString().toLowerCase().includes(filter)
      );
    };
  }

  /**
   * Este método retorna os controles dos formulários de ocorrência
   */
  public getControls(): any {
    const controls: any = this.firstFormGroup.get('customControlLevels');
    return controls.controls;
  }
}
