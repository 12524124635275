import { createAction, props } from '@ngrx/store';
import { Trap, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_HARVESTS = createAction('[Import Trap Form Page] GET_HARVESTS');
export const GET_HARVESTS_SUCCESS = createAction(
  '[Import Trap Form Page] GET_HARVESTS_SUCCESS',
  props<{ harvests: Array<Harvest> }>()
);
export const GET_HARVESTS_FAIL = createAction(
  '[Import Trap Form Page] GET_HARVEST_FAIL',
  props<{ error: any }>()
);

export const GET_TRAP = createAction('[Import Trap Form Page] GET_TRAP');
export const GET_TRAP_SUCCESS = createAction(
  '[Import Trap Form Page] GET_TRAP_SUCCESS',
  props<{ traps: Array<Trap> }>()
);
export const GET_TRAP_FAIL = createAction(
  '[Import Trap Form Page] GET_TRAP_FAIL',
  props<{ error: any }>()
);

/**
 * Importar
 */
export const CREATE_IMPORT_TRAP = createAction(
  '[Import Trap Form Page] CREATE_IMPORT_TRAP',
  props<{ ids: Array<string> }>()
);
export const CREATE_IMPORT_TRAP_SUCCESS = createAction(
  '[Import Trap Form Page] CREATE_IMPORT_TRAP_SUCCESS'
);
export const CREATE_IMPORT_TRAP_FAIL = createAction(
  '[Import Trap Form Page] CREATE_IMPORT_TRAP_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_MODAL = createAction('[Import Trap Form Page] HIDE_MODAL');
