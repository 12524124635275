import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { GET_FARMS, GET_NOTIFICATION, GET_USER } from 'src/app/view/header/header.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { HeaderState, headerStateDefault } from './header.state';
import { SelectFarmAndCropComponent } from './select-farm-and-crop/select-farm-and-crop.component';
import { CustomerAndFarms } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/User';
import { LoginService } from 'src/app/services/login.service';
import { Farm } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChildren('selectFarmAndCropComponent')
  public selectFarmAndCropComponent!: QueryList<SelectFarmAndCropComponent>;
  @Input() public sideNav?: MatSidenav;

  public headerState$: Observable<HeaderState>;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public state: HeaderState = headerStateDefault;

  public overlayRef!: OverlayRef;

  constructor(
    private store: Store<any>,
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    private loginService: LoginService
  ) {
    this.headerState$ = this.store.pipe(select('header'));
    this.subscribe = this.headerState$.subscribe((state: HeaderState) => {
      this.state = state;

      if (
        state.farms?.length > 0 &&
        state.harvests?.length > 0 &&
        state.user &&
        !state.user.lastSelectedFarmId &&
        !state.user.lastSelectedHarvestId &&
        this.selectFarmAndCropComponent.last
      ) {
        const farm = this.state.farms[0];
        const harvest = this.selectFarmAndCropComponent.last.getHarvestsByFarm(farm.id)[0];
        this.selectFarmAndCropComponent.last.menuSelectionChange(farm.id, harvest.id);
      }
    });
  }

  public ngOnInit(): void {
    this.dispatch();
    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === HeaderComponent.name) {
        this.dispatch();
      }
    });
  }

  public dispatch() {
    this.store.dispatch(GET_USER());
    this.store.dispatch(GET_FARMS());
    this.store.dispatch(GET_NOTIFICATION());
  }

  /**
   * Este método retorna uma lista de clientes filtrados pelo usuário
   */
  getCustomersByUser(): Array<CustomerAndFarms> {
    const customersAndFarms = this.applicationService.getUser()?.customersAndFarms;
    if (customersAndFarms) {
      return Object.values(customersAndFarms).sort((a, b) =>
        a.customerName.localeCompare(b.customerName)
      );
    }
    return [];
  }

  /**
   * Este método retorna uma lista de fazendas filtradas pelo usuário
   */
  getFarmsByCustomer(): Array<Farm> {
    const customerId = this.applicationService.getCustomerId();
    if (this.applicationService.getUser()?.customersAndFarms) {
      const farmIds = this.applicationService.getUser()?.customersAndFarms![customerId]?.farmIds;
      if (farmIds) {
        return this.state.farms.filter((farm: Farm) => farmIds?.includes(farm.id));
      }
    }
    const farmIds = this.applicationService.getUser()?.farmIds;
    if (farmIds) {
      //@ts-ignore
      return this.state.farms.filter((farm: Farm) => farmIds?.includes(farm.id));
    }
    return [];
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.subscribeDataSharing.unsubscribe();
    this.overlayRef.dispose();
  }

  /**
   * Este método finaliza a sessão do usuário
   */
  public logout(): void {
    this.loginService.signOutFirebase();
  }
}
