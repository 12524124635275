import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import {
  GET_TRAPS,
  GET_REGISTRATION_NUMBER,
  REMOVE_TRAP,
  UPDATE_TRAP_EDIT,
} from 'src/app/view/trap/trap.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { Trap } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { RemoveModalComponent } from '../components/remove-modal/remove-modal.component';
import { CustomTableDirective } from '../utils/custom-table/custom-table';
import { ImportTrapFormComponent } from './import-trap-form/import-trap-form.component';
import { TrapFormComponent } from './trap-form/trap-form.component';
import { TrapState, trapStateDefault } from './trap.state';
import { UpdateLinerModalComponent } from './update-liner-modal/update-liner-modal.component';
import { Operation } from 'src/app/enums/Operation';
import { TimestampType } from 'src/app/interfaces/Types';

@Component({
  selector: 'app-trap',
  templateUrl: './trap.component.html',
  styleUrls: ['./trap.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TrapComponent extends CustomTableDirective implements OnInit, OnDestroy {
  @ViewChild('#appRemoveModal', { static: true })
  appRemoveModal!: RemoveModalComponent;
  @ViewChild('#appRemoveMultipleModal', { static: true })
  public appRemoveMultipleModal!: RemoveModalComponent;
  @ViewChild('#appUpdateLinner', { static: true })
  appUpdateModal!: UpdateLinerModalComponent;
  @ViewChild('#appMultipleUpdateLinner', { static: true })
  public appMultipleUpdateModal!: UpdateLinerModalComponent;

  public trapState$: Observable<TrapState>;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public state: TrapState = trapStateDefault;
  public operation = Operation;

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private translateService: TranslateService,
    public applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {
    super();
    this.trapState$ = this.store.pipe(select('trap'));
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    /**
     * Escuta as alterações de estado do redux
     */
    this.subscribe = this.trapState$.subscribe((trapState: TrapState) => {
      this.state = trapState;
      if (this.state && this.state.traps) {
        this.setTableSetting();
      }
    });

    /**
     * Escuta as alterações da fazenda
     */
    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === TrapComponent.name) {
        this.store.dispatch(GET_TRAPS());
        this.store.dispatch(GET_REGISTRATION_NUMBER());
      }
    });

    this.store.dispatch(GET_TRAPS());
    this.store.dispatch(GET_REGISTRATION_NUMBER());
  }

  /**
   * Este método é executado quando o componente é destruído
   */
  public ngOnDestroy(): void {
    this.state.loading = 0; // Remover quando o redux for separado por lista e formulário
    this.subscribe.unsubscribe();
    this.subscribeDataSharing.unsubscribe();
  }

  /**
   * Este método abre a modal para cadastrar ou editar
   * @param edit verifica se é uma edição
   * @param trap data
   */
  public openDialog(edit: boolean, trap: Trap | null): void {
    if (trap) {
      this.store.dispatch(
        UPDATE_TRAP_EDIT({
          trap: this.state.traps.filter((x: any) => x.id === trap.id)[0],
        })
      );
    }

    this.dialog.open(TrapFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-800'],
      data: { edit },
    });
  }

  /**
   * Este método abre a modal para importar armadilha
   */
  public openDialogImporTrap(): void {
    this.dialog.open(ImportTrapFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-500'],
      data: this.state.fields,
    });
  }

  /**
   * Este método abre uma modal de confirmação para remover N itens
   * @param itemIds lista de itemIds
   */
  public removeItems(items: Array<any>): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      disableClose: true,
      width: 'material-dialog-panel-500',
      data: {
        itemIds: items,
        title: items.length > 1 ? this.trans.title.removeTraps : this.trans.title.removeTrap,
        message: items.length > 1 ? this.trans.text.removeAllTrap : this.trans.text.removeTrap,
      },
    });
    const subs = dialogRef.afterClosed().subscribe((results) => {
      if (results) {
        const ids = results.map((result: any) => result.id);
        this.store.dispatch(REMOVE_TRAP({ ids }));
      }
      subs.unsubscribe();
    });
  }
  public openDialogUpdateLiner(items: any): void {
    this.dialog.open(UpdateLinerModalComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-500'],
      data: { selectedItems: this.selection.selected, items: items },
    });
  }

  public filterRemovableTraps(traps: Array<Trap>) {
    // return traps.filter((trap) => trap.status !== 'MONITORING');
    return traps;
  }

  public canBeDeleted(trap: Trap) {
    return this.translateService.instant(this.trans.button.remove);
    // return trap.status === 'MONITORING'
    //   ? this.translateService.instant(this.trans.button.cantRemove)
    //   : this.translateService.instant(this.trans.button.remove);
  }

  formatSelectedTraps(n: number) {
    const text = `${n} selecionada`;
    return n < 2 ? text : text + 's';
  }

  getLastLinerExchange(linerExchangeHistory: Array<TimestampType>): String {
    if (linerExchangeHistory && linerExchangeHistory.length > 0)
      return linerExchangeHistory.slice(-1)[0].toDate().toLocaleDateString('pt-br');
    return '';
  }

  /**
   * Este método define as configurações iniciais da tabela
   */
  public setTableSetting(): void {
    const traps = _.cloneDeep(this.state.traps);
    this.dataSource = new MatTableDataSource<Trap>(
      traps.map((trap: any) => {
        if (trap.lastUpdate) {
          trap.lastUpdate = moment(trap.lastUpdate.toDate()).format(
            this.translateService.instant(this.trans.text.date)
          );
        }

        if (trap.batteryLevel) {
          trap.batteryLevel = this.translateService.instant(this.trans.enums[trap.batteryLevel]);
        }

        switch (trap.fillFactor) {
          case 'LOW':
          case 'MEDIUM':
          case 'HIGH':
          case 'CRITICAL':
            trap.fillFactor = this.translateService.instant(this.trans.enums[trap.fillFactor]);
            break;
          default:
            break;
        }
        trap.name = `${trap.name} ${trap.alias ? `(${trap.alias})` : ''}`;
        trap.glebeName = trap.field ? trap.field.glebe.name : '';
        trap.fieldName = trap.field
          ? `${trap.field.name} ${trap.field.alias ? `(${trap.field.alias})` : ''}`
          : '';
        trap.deviceName = trap.device.name;
        trap.officialTrap = `${trap.officialTrap ? trap.officialTrap : ''}`;
        trap.trapCode = `${trap.trapCode ? trap.trapCode : ''}`;
        trap.trapType = `${trap.trapType ? trap.trapType : ''}`;
        trap.lastLinerExchange = `${this.getLastLinerExchange(trap.linerExchangeHistory)}`;
        return trap;
      })
    );

    this.initTable(
      this.applicationService.filterColumnByPermission(this.state.displayedColumns, true)
    );
  }
}
