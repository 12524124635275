<app-loading [isOpen]="state.loading" [excitedTime]="'0s'"></app-loading>
<div fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
  <img class="material-background-image" src="./../../../assets/images/logo_v_mono3.png" alt="" />
  <div class="material-login-container">
    <img
      *ngIf="applicationService.getIsDarkTheme()"
      src="./../../../assets/images/logo_h_cor2.png"
      alt=""
    />
    <img
      *ngIf="!applicationService.getIsDarkTheme()"
      src="./../../../assets/images/logo_h_cor1.png"
      alt=""
    />
    <h4>{{ trans.title.newPassword | translate }}:</h4>

    <form #form="ngForm" [formGroup]="firstFormGroup">
      <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
        <mat-form-field fxFlex="100%" appearance="outline" [color]="'primary'">
          <mat-label>{{ trans.label.password | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ trans.placeholder.typeThePassword | translate }}"
            formControlName="password"
            autocomplete="on"
            name="password"
            id="password"
            [type]="hide ? 'password' : 'text'"
            required
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon fontSet="material-icons-outlined" [color]="'primary'">
              <i *ngIf="!hide" class="las la-eye"></i>
              <i *ngIf="hide" class="las la-eye-slash"></i>
            </mat-icon>
          </button>
          <mat-error>
            {{
              getInputMessageError('password', firstFormGroup.get('password')?.errors) | translate
            }}
          </mat-error>
        </mat-form-field>

        <!-- <mat-form-field fxFlex="100%" appearance="outline" [color]="'primary'">
          <mat-label>{{ trans.label.confirmPassword | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ trans.placeholder.typeThePassword | translate }}"
            formControlName="confirmPassword"
            autocomplete="on"
            name="confirmPassword"
            id="confirmPassword"
            type="password"
            required
          />
          <mat-error>
            {{ getInputMessageError('password', firstFormGroup.get('confirmPassword')?.errors) | translate }}
          </mat-error>
        </mat-form-field> -->
      </div>
    </form>
    <div
      class="material-login-action"
      fxLayout="column"
      fxLayout.gt-xs="row wrap"
      fxLayout.gt-md="row wrap"
    >
      <div fxFlex="100%">
        <button
          type="button"
          mat-flat-button
          [color]="'primary'"
          [disabled]="form.invalid"
          (click)="updatePassword()"
        >
          {{ trans.button.changePassword | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
