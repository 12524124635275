<app-loading [isOpen]="state.loading"></app-loading>
<div fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
  <img class="material-background-image" src="./../../../assets/images/logo_v_mono3.png" alt="" />
  <div class="material-login-container">
    <img
      *ngIf="applicationService.getIsDarkTheme()"
      src="./../../../assets/images/logo_h_cor2.png"
      alt=""
    />
    <img
      *ngIf="!applicationService.getIsDarkTheme()"
      src="./../../../assets/images/logo_h_cor1.png"
      alt=""
    />
    <h4>{{ trans.title.startSession | translate }}:</h4>

    <form novalidate (keydown)="keyDownLogin($event)" [formGroup]="firstFormGroup">
      <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
        <mat-form-field fxFlex="100%" appearance="outline" [color]="'primary'">
          <mat-label>{{ trans.label.email | translate }}</mat-label>
          <input
            matInput
            type="email"
            id="email"
            name="email"
            formControlName="email"
            placeholder="{{ trans.placeholder.typeTheEmail | translate }}"
            required
          />
          <mat-error>
            {{ getInputMessageError('email', firstFormGroup.get('email')?.errors) | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <div
      class="material-login-action"
      fxLayout="column"
      fxLayout.gt-xs="row wrap"
      fxLayout.gt-md="row wrap"
    >
      <div fxFlex="100%">
        <button type="button" mat-flat-button [color]="'primary'" (click)="recoverPassword()">
          {{ trans.button.sendEmail | translate }}
        </button>
      </div>
      <div fxFlex="100%">
        <button type="button" mat-stroked-button [color]="'primary'" [routerLink]="['/login']">
          {{ trans.button.backToLogin | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
