import { createAction, props } from '@ngrx/store';
import {
  Field,
  TrapReport,
  Glebe,
  Occurrence,
  Trap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { RemoveReport } from 'src/app/interfaces/RemoveReport';

/**
 * GET
 */
export const GET_TRAP_REPORTS = createAction('[Trap Report Page] GET_TRAP_REPORTS');
export const GET_TRAP_REPORTS_SUCCESS = createAction(
  '[Trap Report Page] GET_TRAP_REPORTS_SUCCESS',
  props<{ trapReports: Array<TrapReport> }>()
);
export const GET_TRAP_REPORTS_FAIL = createAction(
  '[Trap Report Page] GET_TRAP_REPORTS_FAIL',
  props<{ error: any }>()
);

export const GET_FIELDS = createAction('[Trap Report Page] GET_FIELDS');
export const GET_FIELDS_SUCCESS = createAction(
  '[Trap Report Page] GET_FIELDS_SUCCESS',
  props<{ fields: Array<Field> }>()
);
export const GET_FIELDS_FAIL = createAction(
  '[Trap Report Page] GET_FIELDS_FAIL',
  props<{ error: any }>()
);

export const GET_GLEBES = createAction('[Trap Report Page] GET_GLEBES');
export const GET_GLEBES_SUCCESS = createAction(
  '[Trap Report Page] GET_GLEBES_SUCCESS',
  props<{ glebes: Array<Glebe> }>()
);
export const GET_GLEBES_FAIL = createAction(
  '[Trap Report Page] GET_GLEBES_FAIL',
  props<{ error: any }>()
);

export const GET_TRAPS = createAction('[Trap Report Page] GET_TRAPS');
export const GET_TRAPS_SUCCESS = createAction(
  '[Trap Report Page] GET_TRAPS_SUCCESS',
  props<{ traps: Array<Trap> }>()
);
export const GET_TRAPS_FAIL = createAction(
  '[Trap Report Page] GET_TRAPS_FAIL',
  props<{ error: any }>()
);

export const GET_OCCURRENCES = createAction('[Trap Report Page] GET_OCCURRENCES');
export const GET_OCCURRENCES_SUCCESS = createAction(
  '[Trap Report Page] GET_OCCURRENCES_SUCCESS',
  props<{ occurrences: Array<Occurrence> }>()
);
export const GET_OCCURRENCES_FAIL = createAction(
  '[Trap Report Page] GET_OCCURRENCES_FAIL',
  props<{ error: any }>()
);

/**
 * CREATE
 */
export const CREATE_REPORT_XLS = createAction(
  '[Trap Report Page] CREATE_REPORT_XLS',
  props<{ trapReportXls: TrapReport }>()
);
export const CREATE_REPORT_XLS_SUCCESS = createAction(
  '[Trap Report Page] CREATE_REPORT_XLS_SUCCESS'
);
export const CREATE_REPORT_XLS_FAIL = createAction(
  '[Trap Report Page] CREATE_REPORT_XLS_FAIL',
  props<{ error: any }>()
);

/**
 * REMOVE
 */
export const REMOVE_TRAP_REPORTS = createAction(
  '[Trap Report Page] REMOVE_TRAP_REPORTS',
  props<{ reports: Array<RemoveReport> }>()
);
export const REMOVE_TRAP_REPORTS_SUCCESS = createAction(
  '[Trap Report Page] REMOVE_TRAP_REPORTS_SUCCESS'
);
export const REMOVE_TRAP_REPORTS_FAIL = createAction(
  '[Trap Report Page] REMOVE_TRAP_REPORTS_FAIL',
  props<{ error: any }>()
);

/**
 * ARCHIVE
 */
export const ARCHIVE_TRAP_REPORT = createAction(
  '[Trap Report Page] ARCHIVE_TRAP_REPORT',
  props<{ reports: Array<RemoveReport> }>()
);
export const ARCHIVE_TRAP_REPORT_SUCCESS = createAction(
  '[Trap Report Page] ARCHIVE_TRAP_REPORT_SUCCESS'
);
export const ARCHIVE_TRAP_REPORT_FAIL = createAction(
  '[Trap Report Page] ARCHIVE_TRAP_REPORT_FAIL',
  props<{ error: any }>()
);

/**
 * OTHER
 */
export const DESTROY_COMPONENT = createAction('[Trap Report Page] DESTROY_COMPONENT');
