import { createAction, props } from '@ngrx/store';
import { Farm, WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import {
  CreateFarmForm,
  UpdateFarmForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';

/**
 * Obter
 */
export const GET_FARMS = createAction('[Farm Page] GET_FARMS');
export const GET_FARMS_SUCCESS = createAction(
  '[Farm Page] GET_FARMS_SUCCESS',
  props<{ farms: Array<Farm> }>()
);
export const GET_FARMS_FAIL = createAction('[Farm Page] GET_FARMS_FAIL', props<{ error: any }>());

export const GET_USERS = createAction('[Farm Page] GET_USERS');
export const GET_USERS_SUCCESS = createAction(
  '[Farm Page] GET_USERS_SUCCESS',
  props<{ users: Array<WebUser> }>()
);
export const GET_USERS_FAIL = createAction('[Farm Page] GET_USERS_FAIL', props<{ error: any }>());

/**
 * Cadastrar
 */
export const CREATE_FARM = createAction(
  '[Modal Farm Page] CREATE_FARM',
  props<{ farm: CreateFarmForm }>()
);
export const CREATE_FARM_SUCCESS = createAction('[Modal Farm Page] CREATE_FARM_SUCCESS');
export const CREATE_FARM_FAIL = createAction(
  '[Modal Farm Page] CREATE_FARM_FAIL',
  props<{ error: any }>()
);

/**
 * Atualizar
 */
export const UPDATE_FARM_EDIT = createAction(
  '[Modal Farm Page] UPDATE_FARM_EDIT',
  props<{ farm: UpdateFarmForm }>()
);
export const UPDATE_FARM = createAction(
  '[Modal Farm Page] UPDATE_FARM',
  props<{ farm: UpdateFarmForm }>()
);
export const UPDATE_FARM_SUCCESS = createAction('[Modal Farm Page] UPDATE_FARM_SUCCESS');
export const UPDATE_FARM_FAIL = createAction(
  '[Modal Farm Page] UPDATE_FARM_FAIL',
  props<{ error: any }>()
);

/**
 * Remover
 */
export const REMOVE_FARM = createAction('[Farm Page] REMOVE_FARM', props<{ ids: Array<string> }>());
export const REMOVE_FARM_SUCCESS = createAction('[Farm Page] REMOVE_FARM_SUCCESS');
export const REMOVE_FARM_FAIL = createAction(
  '[Farm Page] REMOVE_FARM_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_MODAL = createAction('[Modal Farm Page] HIDE_MODAL');
