import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as FarmsActions from './farms-route-optimization.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Farm } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { MyToastrService } from 'src/app/services/toastr.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FarmsRouteOptimizationEffects {
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private toastrService: MyToastrService,
    public trans: TranslateTypes
  ) {}

  getFarms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FarmsActions.GET_FARMS_ROUTE_OPTIMIZATION),
      mergeMap((action) =>
        this.httpClient
          .post<Farm[]>('http://127.0.0.1:5000/farms', { customer_id: action.customerId })
          .pipe(
            map((farms: Farm[]) => FarmsActions.GET_FARMS_ROUTE_OPTIMIZATION_SUCCESS({ farms })),
            catchError((error) => {
              this.toastrService.error(this.trans.services.farms.getFarmsFail, {
                '%0': error.message,
              });
              return of(FarmsActions.GET_FARMS_ROUTE_OPTIMIZATION_FAIL({ error }));
            })
          )
      )
    )
  );
}
