import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateTypes } from 'src/app/services/translation.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  constructor(private router: Router, public trans: TranslateTypes) {}

  nagivateToHomePage(): void {
    this.router.navigate(['/']);
  }
}
