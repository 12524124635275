import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { UserState, userStateDefault } from 'src/app/view/user/user-form/user.state';
import * as userAction from './user.actions';

export const userReducer = createReducer(
  userStateDefault,
  /**
   * GET
   */
  on(userAction.GET_USERS, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(userAction.GET_USERS_SUCCESS, (state, action) => ({
    ...state,
    users: action.users,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(userAction.GET_USERS_FAIL, (state) => ({
    ...state,
    users: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(userAction.GET_FARMS, (state) => ({
    ...state,
    loading: state.loading + 1,
  })),
  on(userAction.GET_FARMS_SUCCESS, (state, action) => ({
    ...state,
    farms: action.farms,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(userAction.GET_FARMS_FAIL, (state) => ({
    ...state,
    farms: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * CREATE
   */
  on(userAction.CREATE_USER, (state) => ({
    ...state,
    loadingModal: state.loadingModal + 1,
  })),
  on(userAction.CREATE_USER_SUCCESS, (state) => ({
    ...state,
    closeModal: true,
  })),
  on(userAction.CREATE_USER_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * UPDATE
   */
  on(userAction.LOAD_USER_UPDATE, (state, action) => ({
    ...state,
    userEdit: _.cloneDeep(action.user),
  })),
  on(userAction.UPDATE_USER, (state) => ({
    ...state,
  })),
  on(userAction.UPDATE_USER_SUCCESS, (state) => ({
    ...state,
    closeModal: true,
  })),
  on(userAction.UPDATE_USER_FAIL, (state) => ({
    ...state,
  })),

  /**
   * REMOVE
   */
  on(userAction.REMOVE_USER, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove + 1,
  })),
  on(userAction.REMOVE_USER_SUCCESS, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),
  on(userAction.REMOVE_USER_FAIL, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),

  /**
   * OTHER
   */
  on(userAction.HIDE_MODAL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
    closeModal: userStateDefault.closeModal,
    userEdit: userStateDefault.userEdit,
  }))
);

export function reducer(state: UserState | undefined, action: Action): UserState {
  return userReducer(state, action);
}
