import { AbstractControl, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class CustomValidators {
  public static listIsEmpty(control: AbstractControl): { listIsEmpty: true } | null {
    const array = control.value as Array<any>;
    if (array && array.length > 0 && array.filter((item) => item != null).length > 0) {
      return null;
    } else {
      return { listIsEmpty: true };
    }
  }

  public static whitespaceValidator(control: FormControl): { whitespaceValidator: true } | null {
    if (control.value && control.value.startsWith(' ')) {
      return { whitespaceValidator: true };
    }
    if (control.value && control.value.endsWith(' ')) {
      return { whitespaceValidator: true };
    }
    return null;
  }

  public static isPercentage(control: FormControl): { isNotPercentage: true } | null {
    if (control && control.value > 0 && control.value <= 100) {
      return null;
    } else {
      return { isNotPercentage: true };
    }
  }

  public static isUnd(control: FormControl): { isNotUnd: boolean } | null {
    if (control && control.value > 0 && Number.isInteger(control.value) && control.value <= 500) {
      return null;
    } else {
      return { isNotUnd: true };
    }
  }

  public static isOccurrenceByMeters(
    control: FormControl
  ): { isNotOccurrenceByMeters: boolean } | null {
    if (control && control.value > 0 && control.value < 500) {
      return null;
    } else {
      return { isNotOccurrenceByMeters: true };
    }
  }

  public static isFileKmz(control: FormControl): { isNotFileKmz: boolean } | null {
    if (control && control.value && control.value.fileNames.endsWith('.kmz')) {
      return null;
    } else {
      return { isNotFileKmz: true };
    }
  }
}

export class CustomValidatorsMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl): boolean {
    return control.parent != null && control.parent.invalid && control.touched;
  }
}
