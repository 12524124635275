import { UserLogin } from 'src/app/interfaces/UserLogin';
import { UpdatePasswordDecoded } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const loginStateDefault = {
  user: null,
  userEdit: {} as UserLogin,
  loading: 0,
  oobCode: '',
  decoded: {} as UpdatePasswordDecoded,
} as LoginState;

export interface LoginState {
  user: UserLogin | null;
  userEdit: UserLogin;
  loading: number;
  oobCode: string;
  decoded: UpdatePasswordDecoded;
}

export const firstFormGroup = {
  email: [null],
  password: [null],
} as any;
