import { Injectable } from '@angular/core';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor() {}

  /**
   * This method compare all fields between the forms and select the field updated
   */
  public getDifferences(form1: any, form2: any) {
    let diff: any = {};

    for (let key in form1) {
      if (form1.hasOwnProperty(key) && form2.hasOwnProperty(key)) {
        if (!_.isEqual(form1[key], form2[key])) {
          diff[key] = form2[key];
        }
      }
    }

    return diff;
  }
}
