import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as importFieldFormAction from './import-field-form.actions';
import { ApplicationService } from '../../../services/application.service';
import { Config, GenericFirestoreService } from '../../../services/generic-firestore.service';
import { GenericHttpService } from '../../../services/generic-http.service';
import { MyToastrService } from '../../../services/toastr.service';
import { TranslateTypes } from '../../../services/translation.service';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { Field, Glebe, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Injectable()
export class ImportFieldFormEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private genericHttpServiceService: GenericHttpService,
    private applicationService: ApplicationService,
    private labelPipe: LabelPipe,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço para listar todas as safras.
   */
  public getHarvest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importFieldFormAction.GET_HARVESTS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Harvest>(`${this.applicationService.getFarmRefDatabase()}/harvests`)
          .pipe(
            map((harvests: Array<Harvest>) =>
              importFieldFormAction.GET_HARVESTS_SUCCESS({
                harvests: harvests.filter(
                  (harvest: Harvest) => harvest.id !== this.applicationService.getHarvestId()
                ),
              })
            ),
            catchError((error) => of(importFieldFormAction.GET_HARVESTS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar todas as glebas.
   */
  public getGlebes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importFieldFormAction.GET_GLEBES),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Glebe>(`${this.applicationService.getHarvestRefDatabase()}/glebes`)
          .pipe(
            map((glebes: Array<Glebe>) => importFieldFormAction.GET_GLEBES_SUCCESS({ glebes })),
            catchError((error) => of(importFieldFormAction.GET_GLEBES_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar todas as glebas colhidas.
   */
  public getFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importFieldFormAction.GET_FIELD),
      switchMap(() => {
        return this.genericFirestoreService
          .getAll<Field>(`${this.applicationService.getFarmRefDatabase()}/fields`, {
            queryFn: (ref) => ref.where('harvested', '==', true),
            // Don't know why typing doesn't work here
            // queryFn: (ref: CollectionReference) => ref.where('harvested', '==', true),
            propertyDate: [['harvestDate'], ['fallowingUntil']],
          } as Config)
          .pipe(
            map((fields: Array<Field>) =>
              importFieldFormAction.GET_FIELD_SUCCESS({
                fields: fields.filter(
                  (harvest: Field) => harvest.id !== this.applicationService.getHarvestId()
                ),
              })
            ),
            catchError((error) => of(importFieldFormAction.GET_FIELD_FAIL(error)))
          );
      })
    );
  });

  /**
   * Este método chama um serviço para cadastrar gleba.
   */
  public createImportField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importFieldFormAction.CREATE_IMPORT_FIELD),
      mergeMap((action) => {
        const parameters = {
          type: 'fields',
          customerId: this.applicationService.getCustomerId(),
          farmId: this.applicationService.getFarmId(),
          harvestId: this.applicationService.getHarvestId(),
        };

        return this.genericHttpServiceService
          .create<{ ids: Array<string> }>('importToHarvest', { ...parameters, ids: action.ids })
          .toPromise()
          .then(() => {
            this.toastrService.success(
              this.labelPipe.transform(this.trans.services.create.importFieldSuccess)
            );
            return importFieldFormAction.CREATE_IMPORT_FIELD_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return importFieldFormAction.CREATE_IMPORT_FIELD_FAIL(error);
          });
      })
    )
  );
}
