import { Validators } from '@angular/forms';
import { Reap } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const reapFormStateDefault = {
  reapEdit: {} as Reap,
  trapLinkedToField: 0,
  requestLinkedToField: 0,
  reportLinkedToField: 0,
  loadingModal: 0,
  loading: 0,
  closeModal: false,
} as ReapFormState;

export const firstFormGroup = {
  id: [null],
  fallowing: [false],
  harvested: [true, [Validators.required]],
  harvestDate: [null, [Validators.required]],
  fallowingUntil: [null, [Validators.required]],
} as any;

export interface ReapFormState {
  reapEdit: Reap;
  trapLinkedToField: number;
  requestLinkedToField: number;
  reportLinkedToField: number;
  loadingModal: number;
  loading: number;
  closeModal: boolean;
}
