import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { Regex, regex } from 'src/app/constants/regex';
import { BaseForm } from '../../utils/base-form/base-form';
import {
  EnableField,
  firstFormGroup,
  infestationOptions,
  reportFormatOptions,
  secondFormGroup,
  TrapReportFormState,
  trapReportFormStateDefault,
} from './trap-report-form.state';
import { MatRadioChange, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import {
  Crop,
  Field,
  TrapReport,
  Glebe,
  // WebUser,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import {
  CREATE_TRAP_REPORT,
  CREATE_TRAP_REPORT_XLS,
  GET_CROPS,
  GET_FIELDS,
  GET_GLEBES,
  GET_HIGHEST_NUMBER_REGISTERED,
  GET_OCCURRENCES,
  GET_TRAPS,
  // GET_TRAP_REPORTS,
  GET_USERS,
  HIDE_MODAL,
  UPDATE_TRAP_REPORT,
} from 'src/app/view/trap-report/trap-report-form/trap-report-form.actions';

import * as _ from 'lodash';
import * as moment from 'moment';
import { GenerateReportType } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Component({
  selector: 'app-trap-report-form',
  templateUrl: './trap-report-form.component.html',
  styleUrls: ['./trap-report-form.component.scss'],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TrapReportFormComponent extends BaseForm implements OnInit, OnDestroy {
  @ViewChild('stepper', { static: true }) public stepper!: MatStepper;

  public trapReportState$: Observable<TrapReportFormState>;
  public state: TrapReportFormState = trapReportFormStateDefault;
  public infestationOptions: Array<string> = infestationOptions;
  public reportFormatOptions: Array<string> = reportFormatOptions;
  public enableField: EnableField = trapReportFormStateDefault.enableField;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public regex: Regex = regex;
  public generateReportType = GenerateReportType;
  public moment: any = moment;

  constructor(
    public formBuilder: FormBuilder,
    public store: Store<any>,
    public dialogRef: MatDialogRef<TrapReportFormComponent>,
    public dialog: MatDialog,
    public trans: TranslateTypes,
    public applicationService: ApplicationService,

    @Inject(MAT_DIALOG_DATA) public data: { edit: boolean; trapReport: TrapReport }
  ) {
    super(store, dialogRef, formBuilder, trans);
    this.trapReportState$ = this.store.pipe(select('trapReportFormState'));
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    // this.store.dispatch(GET_TRAP_REPORTS());
    this.store.dispatch(GET_USERS());
    this.store.dispatch(GET_GLEBES());
    this.store.dispatch(GET_CROPS());
    this.store.dispatch(GET_FIELDS());
    this.store.dispatch(GET_TRAPS());
    this.store.dispatch(GET_OCCURRENCES());
    this.store.dispatch(GET_HIGHEST_NUMBER_REGISTERED());

    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));
    this.secondFormGroup = this.formBuilder.group(_.cloneDeep(secondFormGroup));

    if (this.data.edit) {
      this.firstFormGroup.patchValue({ type: this.data.trapReport.type });
      const infestation = this.data.trapReport.infestationLevel
        ? this.data.trapReport.infestationLevel
        : 'ALL';
      const reportFormat = this.data.trapReport.reportFormat
        ? this.data.trapReport.reportFormat
        : 'INTERACTIVE';
      this.secondFormGroup.patchValue({
        ...this.data.trapReport,
        infestationLevel: infestation,
        reportFormat,
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.subscribe = this.trapReportState$.subscribe((state: TrapReportFormState) => {
      this.state = state;
      this.enableField = state.enableField;

      if (state.response) {
        this.stepper.next();
      }

      if (this.data.edit) {
        if (this.stepper.selectedIndex === 0 && this.firstFormGroup.get('type')?.status === 'VALID')
          this.stepper.selectedIndex = 1;
        this.chooseReportFormat();
      }

      if (state.closeModal) {
        this.onCloseModal();
      }

      if (this.state.lastCount >= 0 && !this.data.edit) {
        this.generateUniqueName();
      }
    });
  }

  /**
   * Este método muda o formulário para o passo 2;
   * @param stepper Referência do component stepper
   */
  public onNext(stepper: MatStepper): void {
    const stepControl = stepper.selected?.stepControl;

    if (stepControl?.invalid && stepper.selectedIndex === 1) {
      this.checkValidateForms(this.secondFormGroup);
    }

    if (stepControl?.invalid && stepper.selectedIndex === 2) {
      this.checkValidateForms(this.thirdFormGroup);
    }

    stepper.next();
  }

  /**
   * Este método executa quando o componentes não é mais ativo
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método fecha uma modal
   */
  public onCloseModal(): void {
    this.closeModal(HIDE_MODAL(), true, false, false);
  }

  /**
   * Este método valida e cadastrar ou atualiza
   */
  public onFormClick(): void {
    if (this.secondFormGroup.invalid) {
      this.checkValidateForms(this.secondFormGroup);
    } else {
      this.secondFormGroup.get('type')?.setValue(this.firstFormGroup.get('type')?.value);
      const data = this.resetFormGroup(this.secondFormGroup.getRawValue());
      // delete data.cropId;
      const action = this.data.edit
        ? UPDATE_TRAP_REPORT({ trapReport: data })
        : CREATE_TRAP_REPORT({ trapReport: data });
      this.onSubmit(action);
    }
  }

  /**
   * Este método gera o nome do relatório
   */
  public generateUniqueName(): void {
    this.secondFormGroup
      .get('name')
      ?.setValue(`R-${String(this.state.lastCount + 1).padStart(2, '0')}`);
  }

  /**
   * Este método retorna uma lista de field quando selecionar uma gleba;
   */
  public getFieldsByCropEndGlebe(glebeId?: string): Array<Field> {
    if (glebeId) {
      return this.state.fields.filter((field: Field) => field.glebe.id === glebeId);
    }
    return this.state.fields;
  }

  /**
   * Este método retorna uma lista de cropsIds dependendo das culturas registradas pelo cliente;
   */
  public getCropsByGlebes(): Array<Crop> {
    const glebes = this.state.glebes;
    return this.state.crops.filter(
      (crop) => glebes.filter((glebe) => glebe.cropId === crop.id).length > 0
    );
  }

  /**
   * Este método retorna uma lista de cropsIds e o cropId selecionado;
   */
  public getCropByIds(): Array<Crop> {
    const { cropId } = this.secondFormGroup.getRawValue();
    const glebes = this.state.glebes;

    if (cropId?.length > 0) {
      if (cropId !== 'ALL') {
        return this.state.crops.filter(
          (crop) =>
            cropId === crop.id && glebes.filter((glebe) => glebe.cropId === crop.id).length > 0
        );
      }
      return this.state.crops.filter(
        (crop) => glebes.filter((glebe) => glebe.cropId === crop.id).length > 0
      );
    }

    return this.state.crops;
  }

  /**
   * Este método recebe um evento ao selecionar a cultura
   * @param cropId id da cultura
   */
  public onChangeCrop(): void {
    const { cropId, glebeIds } = this.secondFormGroup.getRawValue();
    if (cropId?.length > 0 && glebeIds?.length > 0) {
      if (cropId === 'ALL') {
        return this.secondFormGroup
          .get('glebeIds')
          ?.setValue(
            glebeIds.filter(
              (id: string) => this.state.glebes.filter((glebe: Glebe) => glebe.id === id).length > 0
            )
          );
      }
      const glebes = this.state.glebes.filter(
        (glebe) => cropId.filter((id: string) => id === glebe.cropId).length > 0
      );
      this.secondFormGroup
        .get('glebeIds')
        ?.setValue(
          glebeIds.filter(
            (id: string) => glebes.filter((glebe: Glebe) => glebe.id === id).length > 0
          )
        );
    }
  }

  /**
   * Este método retorna uma lista de gleba ou a gleba selecionada;
   */
  public getGlebesByIds(): Array<Glebe> {
    const glebes = this.state.glebes;
    const { glebeIds } = this.secondFormGroup.getRawValue();

    if (glebeIds?.length > 0) {
      return glebes.filter(
        (glebe: Glebe) => glebeIds.filter((id: string) => id === glebe.id).length > 0
      );
    }

    return glebes;
  }

  /**
   * Este método retorna uma lista de gleba com o cropId selecionado;
   */
  public getGlebesByCrop(cropId?: string): Array<Glebe> {
    if (cropId) {
      return this.state.glebes.filter((glebe: Glebe) => glebe.cropId === cropId);
    }

    return this.state.glebes;
  }

  /**
   * Este método verifica se já existe um relatório com está gleba;
   */
  public registeredGlebe(glebeId?: string): boolean {
    return this.state.trapReports.some((report: any) => {
      if (report.glebeId) return report.glebeId === glebeId;
      return false;
    });
  }

  /**
   * Este método retorna uma lista de field quando selecionar uma gleba;
   */
  public getFieldsByGlebe(glebeId?: string): Array<Field> {
    if (glebeId) {
      return this.state.fields.filter((field: Field) => field.glebe.id === glebeId);
    }
    return this.state.fields;
  }

  /**
   * Este método recebe um evento ao selecionar a gleba
   * @param glebeIds lista de ids de gleba
   */
  public onChangeGlebe(): void {
    const { glebeIds, fieldIds } = this.secondFormGroup.getRawValue();
    if (glebeIds?.length > 0 && fieldIds?.length > 0) {
      const fields = this.state.fields.filter(
        (field: Field) => glebeIds.filter((id: string) => id === field.glebe.id).length > 0
      );
      this.secondFormGroup
        .get('fieldIds')
        ?.setValue(
          fieldIds.filter(
            (id: string) => fields.filter((field: Field) => field.id === id).length > 0
          )
        );
    }
  }

  /**
   * Este método retorna o link do pdf
   */
  getLinkToReportPdf() {
    const id = this.state.response?.id;
    return this.state.trapReports.filter((report: TrapReport) => report.id === id)[0]?.link;
  }

  /**
   * Este método baixa o pdf
   * @param url link
   */
  openPdf() {
    window.open(this.getLinkToReportPdf(), '_blank');
  }

  /**
   * Este método dispara uma ação para exportar um relatório em xls
   */
  public exportXls(): void {
    const id = this.state.response?.id;
    if (id) {
      this.store.dispatch(CREATE_TRAP_REPORT_XLS({ id }));
    }
  }

  /**
   * Este método formata o formulário quando uma seleção é feita
   * @param event radio change
   */
  public onChangeRadio(event: MatRadioChange) {
    if (event.value === GenerateReportType.pdf) {
      this.secondFormGroup.get('startDate')?.setValue(null);
      this.secondFormGroup.get('endDate')?.setValue(null);
    } else {
      this.secondFormGroup.get('period')?.setValue(null);
      this.secondFormGroup.get('reportFormat')?.setValue('XLS');
      this.chooseReportFormat();
    }
  }

  /**
   * Este método filtras as ocorrências por cultura
   */
  public getOccurrenceByCrop(): any {
    const cropId = this.secondFormGroup.get('cropId')?.value;
    if (cropId !== 'ALL' && cropId) {
      return this.state.occurrences.filter(
        (occurrence: any) => occurrence.cropsIds.includes(cropId) === true
      );
    }
    return this.state.occurrences;
  }

  public toUpperCase(level: string): string {
    return level.toUpperCase();
  }

  public selectAllGlebes(): void {
    const glebeIds = this.getGlebeIdsByCrop();
    this.secondFormGroup.get('glebeIds')?.setValue(glebeIds);
  }
  public deselectAllGlebes(): void {
    this.secondFormGroup.get('glebeIds')?.setValue([]);
  }

  public getGlebeIdsByCrop(): Array<string> {
    return this.state.glebes
      .filter((glebe) => {
        return this.getCropByIds()
          .map((crop) => crop.id)
          .includes(glebe.cropId);
      })
      .map((glebe) => glebe.id);
  }
  public selectAllFields(): void {
    const fieldIds = this.getFieldIdsByGlebe();
    this.secondFormGroup.get('fieldIds')?.setValue(fieldIds);
  }
  public deselectAllFields(): void {
    this.secondFormGroup.get('fieldIds')?.setValue([]);
  }

  public getFieldIdsByGlebe(): Array<string> {
    return this.state.fields
      .filter((field) => {
        return this.getGlebesByIds()
          .map((glebe) => glebe.id)
          .includes(field.glebe.id);
      })
      .map((field) => field.id);
  }

  public chooseReportFormat(): void {
    if (this.secondFormGroup.get('reportFormat')?.value === 'MAD') {
      this.secondFormGroup.get('cropId')?.setValue('FRUTAS');
    }
    this.enableField.period = this.fieldPermission([
      { type: this.generateReportType.pdf, format: ['interactive', 'georeferenced'] },
    ]);
    this.enableField.startDate = this.fieldPermission([{ type: this.generateReportType.xls }]);
    this.enableField.endDate = this.fieldPermission([{ type: this.generateReportType.xls }]);
    this.enableField.crop = this.fieldPermission([
      { type: this.generateReportType.xls },
      { type: this.generateReportType.pdf, format: ['interactive', 'georeferenced'] },
    ]);
    this.enableField.glebes = this.fieldPermission([
      { type: this.generateReportType.xls },
      { type: this.generateReportType.pdf, format: ['interactive', 'georeferenced'] },
    ]);
    this.enableField.glebe = this.fieldPermission([
      { type: this.generateReportType.pdf, format: ['mad'] },
    ]);
    this.enableField.field = this.fieldPermission([
      { type: this.generateReportType.xls },
      { type: this.generateReportType.pdf, format: ['interactive', 'georeferenced'] },
    ]);
    this.enableField.occurrence = this.fieldPermission([
      { type: this.generateReportType.xls },
      { type: this.generateReportType.pdf, format: ['interactive', 'georeferenced'] },
    ]);
    this.enableField.infestation = this.fieldPermission([
      { type: this.generateReportType.pdf, format: ['interactive', 'georeferenced'] },
    ]);
    this.enableField.sendData = this.fieldPermission([
      { type: this.generateReportType.pdf, format: ['interactive'] },
    ]);
    this.enableField.users = this.fieldPermission([
      { type: this.generateReportType.pdf, format: ['interactive', 'georeferenced', 'mad'] },
    ]);
    this.enableField.alias = this.fieldPermission([
      { type: this.generateReportType.xls },
      { type: this.generateReportType.pdf, format: ['interactive', 'georeferenced', 'mad'] },
    ]);
  }
  public fieldPermission(permisions: Array<{ type: string; format?: Array<string> }>): boolean {
    return permisions.some((permission: { type: string; format?: Array<string> }) => {
      if (permission.type == this.firstFormGroup.get('type')?.value) {
        if (permission.format && permission.format?.length > 0) {
          return !!permission.format?.includes(
            this.secondFormGroup.get('reportFormat')?.value.toLowerCase()
          );
        }
        return true;
      }
      return false;
    });
  }

  public resetFormGroup(data: any): any {
    if (data.reportFormat === 'MAD') {
      return {
        alias: data.alias,
        glebeId: data.glebeId,
        id: this.data.edit ? data.id : data.glebeId,
        name: data.name,
        reportFormat: data.reportFormat,
        type: data.type,
        usersIds: data.usersIds,
      };
    }
    return data;
  }
}
