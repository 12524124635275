import { TrapReport } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/TrapReport';
import { Reap, Trap } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { Injectable } from '@angular/core';
import { CollectionReference } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as reapFormAction from './reap-form.actions';
import { ApplicationService } from '../../../services/application.service';
import { GenericFirestoreService } from '../../../services/generic-firestore.service';
import { MyToastrService } from '../../../services/toastr.service';
import { TranslateTypes } from '../../../services/translation.service';
import { LabelPipe } from 'src/app/pipes/label.pipe';

@Injectable()
export class ReapFormEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService,
    public trans: TranslateTypes,
    private labelPipe: LabelPipe
  ) {}

  /**
   * Este método chama um serviço para verificar a quantidade armadilhas veiculadas ao talhão
   */
  public getTrapLinkedToField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reapFormAction.GET_TRAP_LINKED_TO_FIELD),
      switchMap((action) =>
        this.genericFirestoreService
          .getAll<Trap>(`${this.applicationService.getFarmRefDatabase()}/traps`, {
            queryFn: (ref: CollectionReference) =>
              ref
                .where('harvestId', '==', this.applicationService.getHarvestId())
                .where('field.id', '==', action.id),
          })
          .pipe(
            map((traps: Array<Trap>) => {
              return reapFormAction.GET_TRAP_LINKED_TO_FIELD_SUCCESS({
                trapLinkedToField: traps ? traps.length : 0,
              });
            }),
            catchError((error) => of(reapFormAction.GET_TRAP_LINKED_TO_FIELD_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para verificar a quantidade de relatórios em aberto do talhão
   */
  public getReportLinkedToField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reapFormAction.GET_REPORT_LINKED_TO_FIELD),
      switchMap((action) =>
        this.genericFirestoreService
          .getCombine<TrapReport>([
            {
              ref: `${this.applicationService.getHarvestRefDatabase()}/reportPdfTraps`,
              config: {
                queryFn: (ref: CollectionReference) =>
                  ref.where('fieldIds', 'array-contains', action.id),
              },
            },
          ])
          .pipe(
            map((reports: Array<TrapReport>) => {
              let value = 0;
              if (reports.length > 0) {
                const result = reports.filter((report: TrapReport) => report.type === 'PDF');
                value = result.length === 0 ? 0 : result.length;
              }

              return reapFormAction.GET_REPORT_LINKED_TO_FIELD_SUCCESS({
                reportLinkedToField: value,
              });
            }),
            catchError((error) => of(reapFormAction.GET_REPORT_LINKED_TO_FIELD_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para atualizar talhão para colhido.
   */
  public updateHarvestField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reapFormAction.UPDATE_HARVEST_FIELD),
      mergeMap((action) => {
        return this.genericFirestoreService
          .update<Reap>(`${this.applicationService.getHarvestRefDatabase()}/fields`, action.reap)
          .then(() => {
            this.toastrService.success(
              this.labelPipe.transform(this.trans.services.update.reapSuccess)
            );
            return reapFormAction.UPDATE_HARVEST_FIELD_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return reapFormAction.UPDATE_HARVEST_FIELD_FAIL(error);
          });
      })
    )
  );
}
