<!-- Loading -->
<app-loading [isOpen]="state.loadingModal || state.loadingProgressBar"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="closeModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span *ngIf="data.edit" class="title">{{ trans.title.editFarm | label }}</span>
  <span *ngIf="!data.edit" class="title">{{ trans.title.createFarm | label }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <form class="custom-form-field" #form="ngForm">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="name"
          name="name"
          [(ngModel)]="state.farmForm.name"
          #name="ngModel"
          placeholder="{{ trans.placeholder.name | translate }}"
          minlength="2"
          maxlength="64"
          required
          appWhiteSpaceValidator
          [pattern]="regex.text"
        />
        <mat-error *ngIf="name.invalid && (name.dirty || name.touched)">
          <mat-error *ngIf="name.errors?.required" class="control-label">
            {{ trans.errors?.required.name | translate }}
          </mat-error>
          <mat-error *ngIf="name.errors?.minlength" class="control-label">
            {{ trans.errors?.minlength.name | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              name.errors?.pattern && !name.errors?.minlength && !name.errors?.whitespaceValidator
            "
            class="control-label"
          >
            {{ trans.errors?.pattern.name | translate }}
          </mat-error>
          <mat-error *ngIf="name.errors?.whitespaceValidator" class="control-label">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="lat"
          name="lat"
          [(ngModel)]="state.farmForm.coordinates.lat"
          placeholder="{{ trans.placeholder.lat | translate }}"
          #lat="ngModel"
          appWhiteSpaceValidator
          [pattern]="regex.lat"
          required
        />
        <mat-hint *ngIf="!data.edit">
          <strong>{{ trans.text.latLng | translate }}</strong>
        </mat-hint>
        <mat-error *ngIf="lat.invalid && (lat.dirty || lat.touched)">
          <mat-error *ngIf="lat.errors?.required">
            {{ trans.errors?.required.lat | translate }}
          </mat-error>
          <mat-error *ngIf="lat.errors?.pattern && !lat.errors?.whitespaceValidator">
            {{ trans.errors?.pattern.lat | translate }}
          </mat-error>
          <mat-error *ngIf="lat.errors?.whitespaceValidator">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="lng"
          name="lng"
          [(ngModel)]="state.farmForm.coordinates.lng"
          #lng="ngModel"
          placeholder="{{ trans.placeholder.lng | translate }}"
          appWhiteSpaceValidator
          [pattern]="regex.lng"
          required
        />
        <mat-hint *ngIf="!data.edit">
          <strong>{{ trans.text.latLng | translate }}</strong>
        </mat-hint>
        <mat-error *ngIf="lng.invalid && (lng.dirty || lng.touched)">
          <mat-error *ngIf="lng.errors?.required">
            {{ trans.errors?.required.lng | translate }}
          </mat-error>
          <mat-error *ngIf="lng.errors?.pattern && !lng.errors?.whitespaceValidator">
            {{ trans.errors?.pattern.lng | translate }}
          </mat-error>
          <mat-error *ngIf="lng.errors?.whitespaceValidator">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="country"
          name="country"
          [(ngModel)]="state.farmForm.country"
          #country="ngModel"
          placeholder="{{ trans.placeholder.country | translate }}"
          minlength="2"
          maxlength="30"
          appWhiteSpaceValidator
          [pattern]="regex.text"
        />
        <mat-error *ngIf="country.invalid && (country.dirty || country.touched)">
          <mat-error *ngIf="country.errors?.minlength" class="control-label">
            {{ trans.errors?.minlength.country | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              country.errors?.pattern &&
              !country.errors?.minlength &&
              !country.errors?.whitespaceValidator
            "
            class="control-label"
          >
            {{ trans.errors?.pattern.country | translate }}
          </mat-error>
          <mat-error *ngIf="country.errors?.whitespaceValidator" class="control-label">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="stateInput"
          name="stateInput"
          [(ngModel)]="state.farmForm.state"
          #stateInput="ngModel"
          placeholder="{{ trans.placeholder.state | translate }}"
          minlength="2"
          maxlength="30"
          appWhiteSpaceValidator
          [pattern]="regex.text"
        />
        <mat-error *ngIf="stateInput.invalid && (stateInput.dirty || stateInput.touched)">
          <mat-error *ngIf="stateInput.errors?.minlength" class="control-label">
            {{ trans.errors?.minlength.state | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              stateInput.errors?.pattern &&
              !stateInput.errors?.minlength &&
              !stateInput.errors?.whitespaceValidator
            "
            class="control-label"
          >
            {{ trans.errors?.minlength.state | translate }}
          </mat-error>
          <mat-error *ngIf="stateInput.errors?.whitespaceValidator" class="control-label">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="city"
          name="city"
          [(ngModel)]="state.farmForm.city"
          #city="ngModel"
          placeholder="{{ trans.placeholder.city | translate }} ({{
            trans.label.optional | translate
          }})"
          minlength="2"
          maxlength="30"
          appWhiteSpaceValidator
          [pattern]="regex.text"
        />
        <mat-error *ngIf="city.invalid && (city.dirty || city.touched)">
          <mat-error *ngIf="city.errors?.minlength" class="control-label">
            {{ trans.errors?.minlength.city | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              city.errors?.pattern && !city.errors?.minlength && !city.errors?.whitespaceValidator
            "
            class="control-label"
          >
            {{ trans.errors?.minlength.city | translate }}
          </mat-error>
          <mat-error *ngIf="city.errors?.whitespaceValidator" class="control-label">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <input
          matInput
          type="text"
          id="district"
          name="district"
          [(ngModel)]="state.farmForm.district"
          #district="ngModel"
          placeholder="{{ trans.placeholder.district | translate }} ({{
            trans.label.optional | translate
          }})"
          minlength="2"
          maxlength="30"
          appWhiteSpaceValidator
          [pattern]="regex.text"
        />
        <mat-error *ngIf="district.invalid && (district.dirty || district.touched)">
          <mat-error *ngIf="district.errors?.minlength" class="control-label">
            {{ trans.errors?.minlength.district | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              district.errors?.pattern &&
              !district.errors?.minlength &&
              !district.errors?.whitespaceValidator
            "
            class="control-label"
          >
            {{ trans.errors?.minlength.district | translate }}
          </mat-error>
          <mat-error *ngIf="district.errors?.whitespaceValidator" class="control-label">
            {{ trans.errors?.whiteSpace | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>

      <div *ngIf="!data.edit" fxFlex="100%" class="material-title-harvest">
        <h4>
          {{ trans.title.createHarvest | label }}
        </h4>
      </div>

      <mat-form-field *ngIf="!data.edit" fxFlex="100%" fxFlex.gt-xs="0 1 50%">
        <mat-label>{{ trans.label.currentYear | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="startPicker"
          id="currentYear"
          name="currentYear"
          [(ngModel)]="state.farmForm.harvest!.currentYear"
          (dateInput)="chosenYearHandler($event.value, startPicker)"
          [min]="moment(applicationService.getServerDate().toDate().getFullYear(), 'YYYY')"
          #currentYear="ngModel"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker
          #startPicker
          startView="multi-year"
          (yearSelected)="chosenYearHandler($event, startPicker)"
          panelClass="material-year-picker"
        >
        </mat-datepicker>
        <mat-error *ngIf="currentYear.invalid && (currentYear.dirty || currentYear.touched)">
          <mat-error *ngIf="currentYear.errors?.required">
            {{ trans.errors?.required.currentYear | translate }}
          </mat-error>
          <mat-error *ngIf="currentYear.errors?.matDatepickerMin">
            {{ trans.errors?.matDatepickerMin.currentYear | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="100%">
        <mat-label>{{ trans.label.harvestName | label }}</mat-label>
        <input
          matInput
          name="name"
          placeholder="Ex. Primeira Safra de 2024"
          [(ngModel)]="state.farmForm.harvest!.name"
        />
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="closeModal()">
    {{ trans.button.close | translate }}
  </button>
  <button mat-button color="primary" [disabled]="form.invalid" (click)="create()">
    {{ trans.button.save | translate }}
  </button>
</mat-dialog-actions>
