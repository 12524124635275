import { Farm, WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { FarmForm } from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/farm';

export const farmStateDefault = {
  farms: [],
  users: [],
  farmForm: {
    id: '',
    city: null,
    coordinates: { lat: null, lng: null },
    country: null,
    district: null,
    harvest: {
      currentYear: '',
      harvestPeriod: '',
      name: '',
    },
    name: '',
    state: null,
  },
  loading: 0,
  loadingProgressBar: 0,
  loadingModal: 0,
  loadingRemove: 0,
  closeModal: false,
  displayedColumns: ['select', 'name', 'actions'],
  displayedForms: ['name', 'country', 'state', 'city', 'district'],
  pageSizeOptions: [15],
} as FarmState;

export interface FarmState {
  farms: Array<Farm>;
  users: Array<WebUser>;
  farmForm: FarmFormFront;
  loading: number;
  loadingProgressBar: number;
  loadingModal: number;
  loadingRemove: number;
  closeModal: boolean;

  displayedColumns: string[];
  displayedForms: string[];
  pageSizeOptions: number[];
}

export interface FarmFormFront extends Omit<FarmForm, 'harvest' | 'coordinates'> {
  id?: string;
  harvest?: {
    currentYear: string;
    harvestPeriod: string;
    name: string;
  };
  coordinates: {
    lat: number | null;
    lng: number | null;
  };
}

export interface CreateFarmData extends FarmForm {}
export interface UpdateFarmData extends FarmForm {
  id: string;
}
