export const pheromoneIconCustom = {
  url: '',
  scaledSize: { height: 16, width: 16 },
  anchor: { x: 12, y: 12 },
};

export const PheromoneUrl = {
  NORMAL: {
    'Helicoverpa Armigera': 'pheromone_type_1.svg',
    'Spodoptera Frugiperda': 'pheromone_type_2.svg',
    'Spodoptera frugiperda (lagarta-do-cartucho)': 'pheromone_type_2.svg',
    'Diatraea Saccharalis': 'pheromone_type_3.svg',
    'Heliothis Virescens': 'pheromone_type_3.svg',
    'Tuta absoluta': 'pheromone_type_4.svg',
    'Tuta absoluta (traça-do-tomateiro)': 'pheromone_type_4.svg',
    'Neoleucinodes elegantalis': 'pheromone_type_5.svg',
    'Neoleucinodes elegantalis (broca-pequena-do-fruto)': 'pheromone_type_5.svg',
    'Dalbulus maidis': 'pheromone_type_6.svg',
    'Dalbulus maidis (cigarrinha-do-milho)': 'pheromone_type_6.svg',
    'Ceratitis capitata': 'pheromone_type_7.svg',
    'Anastrepha obliqua': 'pheromone_type_8.svg',
    NO_COMMUNICATION: 'plague_no_communication.svg',
  },
  FOCO: {
    'Helicoverpa Armigera': 'pheromone_type_1_foco.svg',
    'Spodoptera Frugiperda': 'pheromone_type_2_foco.svg',
    'Spodoptera frugiperda (lagarta-do-cartucho)': 'pheromone_type_2_foco.svg',
    'Diatraea Saccharalis': 'pheromone_type_3_foco.svg',
    'Heliothis Virescens': 'pheromone_type_3_foco.svg',
    'Tuta absoluta': 'pheromone_type_4_foco.svg',
    'Tuta absoluta (traça-do-tomateiro)': 'pheromone_type_4_foco.svg',
    'Neoleucinodes elegantalis': 'pheromone_type_5_foco.svg',
    'Neoleucinodes elegantalis (broca-pequena-do-fruto)': 'pheromone_type_5_foco.svg',
    'Dalbulus maidis': 'pheromone_type_6_foco.svg',
    'Dalbulus maidis (cigarrinha-do-milho)': 'pheromone_type_6_foco.svg',
    'Ceratitis capitata': 'pheromone_type_7_foco.svg',
    'Anastrepha obliqua': 'pheromone_type_8_foco.svg',
    NO_COMMUNICATION: 'plague_no_communication_foco.svg',
  },
} as any;
