import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import {
  ImportFieldFormState,
  importFieldFormStateDefault,
} from 'src/app/view/field/import-field-form/import-field-form.state';
import * as importFieldFormAction from './import-field-form.actions';

export const importFieldFormReducer = createReducer(
  _.cloneDeep(importFieldFormStateDefault),

  /**
   * OBTER
   */
  on(importFieldFormAction.GET_HARVESTS, (state) => ({ ...state, loading: state.loading + 1 })),
  on(importFieldFormAction.GET_HARVESTS_SUCCESS, (state, action) => ({
    ...state,
    harvests: action.harvests,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(importFieldFormAction.GET_HARVESTS_FAIL, (state) => ({
    ...state,
    harvests: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(importFieldFormAction.GET_GLEBES, (state) => ({ ...state, loading: state.loading + 1 })),
  on(importFieldFormAction.GET_GLEBES_SUCCESS, (state, action) => ({
    ...state,
    glebes: action.glebes,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(importFieldFormAction.GET_GLEBES_FAIL, (state) => ({
    ...state,
    glebes: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(importFieldFormAction.GET_FIELD, (state) => ({ ...state, loading: state.loading + 1 })),
  on(importFieldFormAction.GET_FIELD_SUCCESS, (state, action) => ({
    ...state,
    fields: action.fields,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(importFieldFormAction.GET_FIELD_FAIL, (state) => ({
    ...state,
    fields: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * CADASTRAR
   */
  on(importFieldFormAction.CREATE_IMPORT_FIELD, (state) => ({
    ...state,
    loadingModal: state.loadingModal + 1,
  })),
  on(importFieldFormAction.CREATE_IMPORT_FIELD_SUCCESS, (state) => ({
    ...state,
    closeModal: true,
  })),
  on(importFieldFormAction.CREATE_IMPORT_FIELD_FAIL, (state) => ({
    ...state,
    loadingModal: state.loadingModal > 0 ? state.loadingModal - 1 : (state.loadingModal = 0),
  })),

  /**
   * OUTROS
   */
  on(importFieldFormAction.HIDE_MODAL, (state) => ({
    ..._.cloneDeep(importFieldFormStateDefault),
  }))
);

export function reducer(
  state: ImportFieldFormState | undefined,
  action: Action
): ImportFieldFormState {
  return importFieldFormReducer(state, action);
}
