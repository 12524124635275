import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { homeStateDefault } from 'src/app/view/home/home.state';
import * as homeAction from './home.actions';

export const homeReducer = createReducer(
  _.cloneDeep(homeStateDefault),

  /**
   * Obeter
   */
  on(homeAction.GET_TERMS_OF_USE_URL, (state) => ({ ...state, loading: true })),
  on(homeAction.GET_TERMS_OF_USE_URL_SUCCESS, (state, action) => ({
    ...state,
    loading: false,
    termsOfUseUrl: action.termsOfUseUrl,
  })),
  on(homeAction.GET_TERMS_OF_USE_URL_FAIL, (state) => ({
    ...state,
    user: null,
    loading: false,
  })),

  on(homeAction.GET_PRIVACY_POLICY_URL, (state) => ({
    ...state,
    loading: true,
  })),
  on(homeAction.GET_PRIVACY_POLICY_URL_SUCCESS, (state, action) => ({
    ...state,
    loading: false,
    privacyPolicyUrl: action.privacyPolicyUrl,
  })),
  on(homeAction.GET_PRIVACY_POLICY_URL_FAIL, (state) => ({
    ...state,
    user: null,
    loading: false,
  })),

  /**
   * OTHER
   */
  on(homeAction.DESTROY_COMPONENT, (state) => ({
    ..._.cloneDeep(homeStateDefault),
  }))
);

export function reducer(state: any | undefined, action: Action): any {
  return homeReducer(state, action);
}
