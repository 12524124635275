import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { UPDATE_USER_FARM_AND_HARVEST_SELECTED } from 'src/app/view/header/header.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { CustomerAndFarms } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/User';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss'],
})
export class SelectCustomerComponent {
  @Input() public customers: Array<CustomerAndFarms> = [];
  @Input() public user!: WebUser;

  constructor(
    private store: Store<any>,
    public applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método retorna o nome da fazenda selecionada
   */
  public getCustomerName(): string | null {
    if (this.user.lastSelectedCustomerId) {
      const customer = this.customers.filter(
        (item) => item.customerId === this.user.lastSelectedCustomerId
      )[0];
      return customer ? customer.customerName : null;
    }

    return null;
  }

  /**
   * Este método retorna o nome da fazenda selecionada
   */
  public getFarmsByCustomer(): Array<string> {
    const customersAndFarms = this.user.customersAndFarms;
    if (customersAndFarms) {
      const farmIds = customersAndFarms[this.user.lastSelectedCustomerId]?.farmIds;

      if (farmIds) return farmIds;
    }
    return [];
  }

  /**
   *
   */
  public getCustomersAndFarms(): Array<CustomerAndFarms> {
    const customersAndFarms = this.user.customersAndFarms;
    return customersAndFarms
      ? Object.values(customersAndFarms).sort((a, b) =>
          a.customerName.localeCompare(b.customerName)
        )
      : [];
  }
  /**
   * Este método recebe o id do customer e atualiza o usuário na aplicação e no banco
   * @param customerId id do customer
   */
  public menuSelectionChange(customerId: string): void {
    if (this.user.gmConfig) {
      const userGmConfig = this.user.gmConfig[customerId];

      if (userGmConfig) {
        this.user.lastSelectedCustomerId = customerId;
        this.user.lastSelectedFarmId = userGmConfig.farmId;
        this.user.lastSelectedHarvestId = userGmConfig.harvestId;
        this.store.dispatch(UPDATE_USER_FARM_AND_HARVEST_SELECTED({ user: this.user }));
      }
    }
  }
}
