import { Action, createReducer, on } from '@ngrx/store';
import { GlebeState, glebeStateDefault } from 'src/app/view/glebe/glebe.state';
import * as glebeAction from './glebe.actions';
import * as _ from 'lodash';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';

export const glebeReducer = createReducer(
  _.cloneDeep(glebeStateDefault),

  /**
   * GET
   */
  on(glebeAction.GET_GLEBES, (state) => ({ ...state, loading: state.loading + 1 })),
  on(glebeAction.GET_GLEBES_SUCCESS, (state, action) => ({
    ...state,
    glebes: action.glebes,
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(glebeAction.GET_GLEBES_FAIL, (state) => ({
    ...state,
    glebes: [],
    loading: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  on(glebeAction.GET_SYSTEM, (state) => ({ ...state, loadingModal: state.loading + 1 })),
  on(glebeAction.GET_SYSTEM_SUCCESS, (state, action) => ({
    ...state,
    system: action.system,
    loadingModal: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),
  on(glebeAction.GET_SYSTEM_FAIL, (state) => ({
    ...state,
    system: {} as OccurrenceSettings,
    loadingModal: state.loading > 0 ? state.loading - 1 : (state.loading = 0),
  })),

  /**
   * REMOVE
   */
  on(glebeAction.REMOVE_GLEBE, (state) => ({ ...state, loadingRemove: state.loadingRemove + 1 })),
  on(glebeAction.REMOVE_GLEBE_SUCCESS, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),
  on(glebeAction.REMOVE_GLEBE_FAIL, (state) => ({
    ...state,
    loadingRemove: state.loadingRemove > 0 ? state.loadingRemove - 1 : (state.loadingRemove = 0),
  })),

  /**
   * OTHER
   */
  on(glebeAction.DESTROY_COMPONENT, (state) => ({
    ..._.cloneDeep(glebeStateDefault),
  }))
);

export function reducer(state: GlebeState | undefined, action: Action): GlebeState {
  return glebeReducer(state, action);
}
