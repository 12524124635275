/**
 * NOTE - Comentários e Refatoração de código 19/05/2021
 */

import * as fieldAction from './field.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ApplicationService } from '../../services/application.service';
import { GenericFirestoreService } from '../../services/generic-firestore.service';
import { GenericHttpService } from '../../services/generic-http.service';
import { MyToastrService } from '../../services/toastr.service';
import { TranslateTypes } from '../../services/translation.service';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { TranslateService } from '@ngx-translate/core';
import { Field } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Injectable()
export class FieldEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private genericHttpService: GenericHttpService,
    private applicationService: ApplicationService,
    private labelPipe: LabelPipe,
    public trans: TranslateTypes,
    private translateService: TranslateService
  ) {}

  /**
   * Este método chama um serviço para listar talhões.
   */
  public getFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fieldAction.GET_FIELDS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Field>(`${this.applicationService.getHarvestRefDatabase()}/fields`)
          .pipe(
            map((fields: Array<Field>) => fieldAction.GET_FIELDS_SUCCESS({ fields })),
            catchError((error) => of(fieldAction.GET_FIELDS_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço para remover talhões.
   */
  public removeField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fieldAction.REMOVE_FIELD),
      mergeMap((action) => {
        return this.genericHttpService
          .remove<Field>(
            `removeField?customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&harvestId=${this.applicationService.getHarvestId()}&ids=${
              action.ids
            }`
          )
          .toPromise()
          .then((resp: Array<{ id: string; code: string; status: string }>) => {
            resp.forEach((data: { id: string; code: string; status: string }) => {
              if (data.code.includes('success')) {
                this.toastrService.success(
                  this.labelPipe.transform(this.translateService.instant(data.code))
                );
              } else {
                this.toastrService.error(
                  this.labelPipe.transform(this.translateService.instant(data.code))
                );
              }
            });
            return fieldAction.REMOVE_FIELD_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              this.labelPipe.transform(
                this.translateService.instant(
                  `${
                    error.code !== undefined
                      ? error.code
                      : error.error.code !== undefined
                      ? error.error.code
                      : error.error.internalCode
                  }`
                )
              )
            );
            return fieldAction.REMOVE_FIELD_FAIL(error);
          });
      })
    )
  );
}
