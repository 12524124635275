// route-optimization.reducer.ts
import { Action, createReducer, on } from '@ngrx/store';
import * as RouteOptimizationActions from './route-optimization.actions';
import { initialRouteOptimizationState, RouteOptimizationState } from './route-optimization.state';

export const routeOptimizationReducer = createReducer(
  initialRouteOptimizationState,

  // GET_CUSTOMERS Actions
  on(RouteOptimizationActions.GET_CUSTOMERS, (state) => ({
    ...state,
    loadingCustomers: true,
    error: null,
  })),
  on(RouteOptimizationActions.GET_CUSTOMERS_SUCCESS, (state, { clients }) => ({
    ...state,
    customers: clients,
    loadingCustomers: false,
    error: null,
  })),
  on(RouteOptimizationActions.GET_CUSTOMERS_FAIL, (state, { error }) => ({
    ...state,
    loadingCustomers: false,
    error,
  })),

  // GET_FARMS Actions
  on(RouteOptimizationActions.GET_FARMS, (state) => ({
    ...state,
    loadingFarms: true,
    error: null,
  })),
  on(RouteOptimizationActions.GET_FARMS_SUCCESS, (state, { farms }) => ({
    ...state,
    farms,
    loadingFarms: false,
    error: null,
  })),
  on(RouteOptimizationActions.GET_FARMS_FAIL, (state, { error }) => ({
    ...state,
    loadingFarms: false,
    error,
  })),

  // CALCULATE_ROUTE Actions
  on(RouteOptimizationActions.CALCULATE_ROUTE, (state) => ({
    ...state,
    loading: true, // Mantém o loading geral se necessário
    error: null,
  })),
  on(
    RouteOptimizationActions.CALCULATE_ROUTE_SUCCESS,
    (state, { routeSegments, optimizedOrder, durations }) => ({
      ...state,
      routeSegments,
      optimizedOrder,
      durations,
      totalDuration: durations.reduce((acc, curr) => acc + curr, 0),
      loading: false,
      error: null,
    })
  ),
  on(RouteOptimizationActions.CALCULATE_ROUTE_FAIL, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);

export function reducer(
  state: RouteOptimizationState | undefined,
  action: Action
): RouteOptimizationState {
  return routeOptimizationReducer(state, action);
}
