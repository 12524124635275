import * as common from './environment.common';
/**
 * Configurações do cloud function
 */
export const environment = {
  name: 'prod',
  production: true,
  ngrxDebug: false,
  api: 'https://us-central1-tarvos-production.cloudfunctions.net',
  domain: 'app.tarvos.ag',
  ...common.env.no_emulators,
};

/**
 * Configurações do banco firebase
 */
export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyAYCy2DK7PBPOCu8aBGuWY035avaNwlNTs',
  authDomain: 'tarvos-production.firebaseapp.com',
  databaseURL: 'https://tarvos-production.firebaseio.com',
  projectId: 'tarvos-production',
  storageBucket: 'tarvos-production.appspot.com',
  messagingSenderId: '580006920103',
  appId: '1:580006920103:web:2359d8f940fce40f0d0082',
  measurementId: 'G-HT5MKX23P4',
  domain: 'app.tarvos.ag',
};

/**
 * Configurações do angular google maps
 */
export const AGEM_GOOGLE_MAPS = {
  apiKey: 'AIzaSyAYCy2DK7PBPOCu8aBGuWY035avaNwlNTs',
  libraries: ['drawing'],
  language: 'pt-br',
};

/**
 * Informações Tarvos
 */
export const TARVOS = {
  ...common.TARVOS,
};
