<mat-dialog-content
  class="material-dialog-content"
  fxLayout="column"
  fxLayoutAlign="space-around center"
>
  <h2>
    {{ trans.title.planExpired | translate }}
  </h2>
  <img class="material-image-blocked" src="../../../../assets/images/blocked_image.png" />
  <p class="material-dialog-text" fxLayout="column" fxLayoutAlign="space-around center">
    <strong>{{ trans.text.planExpired.text1 | translate }}</strong>
    {{ trans.text.planExpired.text2 | translate }}
  </p>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between stretch">
  <button mat-button (click)="logout()">{{ trans.button.logout | translate }}</button>
  <a
    mat-button
    color="primary"
    [href]="'mailto:' + tarvos.support"
    target="_blank"
    cdkFocusInitial
    >{{ trans.button.sendEmail | translate }}</a
  >
</mat-dialog-actions>
