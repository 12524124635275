import * as _ from 'lodash';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, Portal } from '@angular/cdk/portal';
import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UPDATE_THEME, LOAD_USER_UPDATE } from 'src/app/view/header/header.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { AccountFormComponent } from '../account-form/account-form.component';
import { WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent {
  @ViewChildren(CdkPortal) public templatePortals?: QueryList<Portal<any>>;
  @Input() public user: WebUser | null = null;

  public overlayRef!: OverlayRef;

  constructor(
    private store: Store<any>,
    private overlay: Overlay,
    private dialog: MatDialog,
    // private media: MediaMatcher,
    public applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método configura e abre um panel pelo index indicado
   * @param index Indica qual panel
   */
  public openPanelByIndex(index: number): void {
    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'material-backdrop-class',
      positionStrategy: this.overlay.position().global().centerHorizontally(),
    });

    const overlayRef = this.overlay.create(config);
    // @TODO: Does this line have side effects? Delete if not
    // const mobileQuery = this.media.matchMedia('(max-width: 599px)');

    overlayRef.updatePositionStrategy(this.overlay.position().global().right('15px').top('50px'));
    overlayRef.attach(this.templatePortals?.toArray()[index]);
    overlayRef.backdropClick().subscribe(() => overlayRef.detach());

    this.overlayRef = overlayRef;
  }

  /**
   * Este método dispara uma ação para atualizar o tema
   */
  public onSelectTheme(): void {
    const user = _.cloneDeep(this.user);
    if (user) {
      user.isDarkTheme = !user.isDarkTheme;
      this.store.dispatch(UPDATE_THEME({ user }));
    }
  }

  /**
   * Este método abre a modal para editar informações de um usuário logada
   */
  public editAccount(): void {
    this.dialog.open(AccountFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-800'],
      data: { edit: true },
    });

    if (this.user) {
      this.store.dispatch(LOAD_USER_UPDATE({ user: this.user }));
    }

    this.overlayRef.detach();
  }
}
