/**
 * NOTE - Comentários e Refatoração de código 19/05/2021
 */

import * as defineFieldFormAction from './define-field-form.actions';
import { Injectable } from '@angular/core';
import { CollectionReference } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ApplicationService } from '../../../services/application.service';
import { GenericFirestoreService } from '../../../services/generic-firestore.service';
import { GenericHttpService } from '../../../services/generic-http.service';
import { MyToastrService } from '../../../services/toastr.service';
import { TranslateTypes } from '../../../services/translation.service';
import { CalculateService } from 'src/app/services/calculate.service';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { TranslateService } from '@ngx-translate/core';
import { Crop, Glebe, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Injectable()
export class DefineFieldFormEffects {
  constructor(
    private actions$: Actions,
    private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService,
    private calculateService: CalculateService,
    private genericHttpService: GenericHttpService,
    private toastrService: MyToastrService,
    private labelPipe: LabelPipe,
    public trans: TranslateTypes,
    private translateService: TranslateService
  ) {}

  /**
   * Este método chama um serviço para listar culturas
   */
  public getCrops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(defineFieldFormAction.GET_CROPS),
      switchMap(() =>
        this.genericFirestoreService.getAll<Crop>('crops').pipe(
          map((crops: Array<Crop>) => defineFieldFormAction.GET_CROPS_SUCCESS({ crops })),
          catchError((error) => of(defineFieldFormAction.GET_CROPS_FAIL(error)))
        )
      )
    )
  );

  /**
   * Este método chama um serviço para listar glebas
   */
  public getGlebes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(defineFieldFormAction.GET_GLEBES),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Glebe>(`${this.applicationService.getHarvestRefDatabase()}/glebes`)
          .pipe(
            map((glebes: Array<Glebe>) => defineFieldFormAction.GET_GLEBES_SUCCESS({ glebes })),
            catchError((error) => of(defineFieldFormAction.GET_GLEBES_FAIL(error)))
          )
      )
    )
  );

  /**
   * Este método chama um serviço obter o maior número de registro.
   */
  public getLastCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(defineFieldFormAction.GET_HIGHEST_NUMBER_REGISTERED),
      switchMap(() => {
        let lastCount;
        if (!this.applicationService.getFarm()?.collectionSettings?.glebe?.lastCount) {
          const harvests = this.applicationService.getHarvestList();
          const combineList = harvests.map((harvest: Harvest) => {
            return {
              ref: `${this.applicationService.getFarmRefDatabase()}/harvests/${harvest.id}/glebes`,
              config: {
                queryFn: (ref: CollectionReference) =>
                  ref.orderBy('registrationNumber', 'desc').limit(1),
              },
            };
          });

          return this.genericFirestoreService.getCombine<Glebe>(combineList).pipe(
            map(
              (glebes: Array<Glebe>) => {
                const registrationNumberMap = glebes.map(
                  (glebe: Glebe) => glebe.registrationNumber
                ) as Array<number>;
                lastCount = this.calculateService.calculateLastCount(registrationNumberMap);
                return defineFieldFormAction.GET_HIGHEST_NUMBER_REGISTERED_SUCCESS({
                  lastCount,
                });
              },
              catchError((error) =>
                of(defineFieldFormAction.GET_HIGHEST_NUMBER_REGISTERED_FAIL(error))
              )
            )
          );
        } else {
          lastCount = this.applicationService.getFarm()?.collectionSettings?.glebe?.lastCount
            ? this.applicationService.getFarm()!.collectionSettings!.glebe!.lastCount
            : 0;
          return of(
            defineFieldFormAction.GET_HIGHEST_NUMBER_REGISTERED_SUCCESS({
              lastCount,
            })
          );
        }
      })
    )
  );

  /**
   * Este método chama um serviço para cadastrar gleba.
   */
  public createGlebe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(defineFieldFormAction.CREATE_GLEBE),
      mergeMap((action) => {
        return this.genericHttpService
          .create('createGlebe', action.glebe)
          .toPromise()
          .then((resp: any) => {
            this.toastrService.success(
              this.labelPipe.transform(this.trans.services.create.glebeSuccess)
            );
            const glebeId = resp.glebe;
            return this.genericFirestoreService
              .getById<Glebe>(
                `${this.applicationService.getHarvestRefDatabase()}/glebes/${glebeId}`
              )
              .first()
              .toPromise()
              .then((glebe) => {
                return defineFieldFormAction.CREATE_GLEBE_SUCCESS({ glebe });
              });
          })
          .catch((error) => {
            this.toastrService.error(
              this.labelPipe.transform(
                this.translateService.instant(
                  `${
                    error.code !== undefined
                      ? error.code
                      : error.error.code !== undefined
                      ? error.error.code
                      : error.error.internalCode
                  }`
                )
              )
            );
            return defineFieldFormAction.CREATE_GLEBE_FAIL(error);
          });
      })
    )
  );
}
