<!-- Loading -->
<app-loading [isOpen]="state.loadingModal || state.loading"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="closeModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span *ngIf="data.edit" class="title">{{ trans.title.createHarvest | label }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <form class="custom-form-field" #form="ngForm">
    <mat-form-field fxFlex="100%">
      <mat-label>{{ trans.label.currentYear | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="startPicker"
        id="currentYear"
        name="currentYear"
        [(ngModel)]="state.harvestEdit.currentYear"
        (dateInput)="chosenYearHandler($event.value, startPicker)"
        [min]="moment(this.applicationService.getServerDate().toDate().getFullYear(), 'YYYY')"
        [disabled]="data.edit"
        #currentYear="ngModel"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker
        #startPicker
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event, startPicker)"
        panelClass="material-year-picker"
      >
      </mat-datepicker>
      <mat-error *ngIf="currentYear.invalid && (currentYear.dirty || currentYear.touched)">
        <mat-error *ngIf="currentYear.errors?.required">
          {{ trans.errors?.required.currentYear | translate }}
        </mat-error>
        <mat-error *ngIf="currentYear.errors?.matDatepickerMin">
          {{ trans.errors?.matDatepickerMin.currentYear | translate }}
        </mat-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="100%">
      <mat-label>{{ trans.label.harvestName | label }}</mat-label>
      <input
        matInput
        name="name"
        placeholder="Ex. Primeira Safra de 2024"
        [(ngModel)]="state.harvestEdit.name"
      />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="closeModal()">
    {{ trans.button.close | translate }}
  </button>
  <button mat-button color="primary" [disabled]="form.invalid" (click)="create()">
    {{ trans.button.save | translate }}
  </button>
</mat-dialog-actions>
