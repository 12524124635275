import { createAction, props } from '@ngrx/store';
import { WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { CustomerData } from './create-customer.state';

/**
 * Obter
 */
export const GET_TERMS_OF_USE_URL = createAction('[Customer Page] GET_TERMS_OF_USE_URL');
export const GET_TERMS_OF_USE_URL_SUCCESS = createAction(
  '[Customer Page] GET_TERMS_OF_USE_URL_SUCCESS',
  props<{ termsOfUseUrl: string }>()
);
export const GET_TERMS_OF_USE_URL_FAIL = createAction(
  '[Customer Page] GET_TERMS_OF_USE_URL_FAIL',
  props<{ error: any }>()
);

export const GET_SUPPORT_USERS = createAction('[Customer Page] GET_SUPPORT_USERS');
export const GET_SUPPORT_USERS_SUCCESS = createAction(
  '[Customer Page] GET_SUPPORT_USERS_SUCCESS',
  props<{ supportUsers: Array<WebUser> }>()
);
export const GET_SUPPORT_USERS_FAIL = createAction(
  '[Customer Page] GET_SUPPORT_USERS_FAIL',
  props<{ error: any }>()
);

export const GET_PRIVACY_POLICY_URL = createAction('[Customer Page] GET_PRIVACY_POLICY_URL');
export const GET_PRIVACY_POLICY_URL_SUCCESS = createAction(
  '[Customer Page] GET_PRIVACY_POLICY_URL_SUCCESS',
  props<{ privacyPolicyUrl: string }>()
);
export const GET_PRIVACY_POLICY_URL_FAIL = createAction(
  '[Customer Page] GET_PRIVACY_POLICY_URL_FAIL',
  props<{ error: any }>()
);

/**
 * Cadastrar
 */
export const CREATE_CUSTOMER = createAction(
  '[Customer Page] CREATE_CUSTOMER',
  props<{ customer: CustomerData }>()
);
export const CREATE_CUSTOMER_SUCCESS = createAction('[Customer Page] CREATE_CUSTOMER_SUCCESS');
export const CREATE_CUSTOMER_FAIL = createAction(
  '[Customer Page] CREATE_CUSTOMER_FAIL',
  props<{ error: any }>()
);
