<button
  mat-flat-button
  color="primary"
  class="material-button-open-menu"
  [matMenuTriggerFor]="menuCustomer"
>
  <p class="material-title">
    {{ getCustomerName() }}
  </p>
  <p class="material-subtitle">
    {{ getFarmsByCustomer().length + ' Fazendas' }}
  </p>
  <mat-icon>keyboard_arrow_down</mat-icon>
</button>

<mat-menu #menuCustomer="matMenu">
  <span *ngFor="let customer of getCustomersAndFarms()">
    <button
      mat-menu-item
      [ngClass]="user.lastSelectedCustomerId === customer.customerId ? 'material-item-select' : ''"
      (click)="menuSelectionChange(customer.customerId)"
    >
      {{ customer.customerName }}
    </button>
  </span>
</mat-menu>
