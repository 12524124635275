import { createAction, props } from '@ngrx/store';
import {
  Device,
  Field,
  Glebe,
  Trap,
  ManufacturedTrap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { UpdateLinerForm } from './trap.state';
import {
  CreateTrapForm,
  UpdateTrapForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/trap';

/**
 * Obter
 */
export const GET_TRAPS = createAction('[Trap Page] GET_TRAPS');
export const GET_TRAPS_SUCCESS = createAction(
  '[Trap Page] GET_TRAPS_SUCCESS',
  props<{ traps: Array<Trap> }>()
);
export const GET_TRAPS_FAIL = createAction('[Trap Page] GET_TRAPS_FAIL', props<{ error: any }>());

export const GET_REGISTRATION_NUMBER = createAction('[Trap Page] GET_REGISTRATION_NUMBER');
export const GET_REGISTRATION_NUMBER_SUCCESS = createAction(
  '[Trap Page] GET_REGISTRATION_NUMBER_SUCCESS',
  props<{ lastCount: number }>()
);
export const GET_REGISTRATION_NUMBER_FAIL = createAction(
  '[Trap Page] GET_REGISTRATION_NUMBER_FAIL',
  props<{ error: any }>()
);

export const GET_FIELDS = createAction('[Trap Page] GET_FIELDS');
export const GET_FIELDS_SUCCESS = createAction(
  '[Trap Page] GET_FIELDS_SUCCESS',
  props<{ fields: Array<Field> }>()
);
export const GET_FIELDS_FAIL = createAction('[Trap Page] GET_FIELDS_FAIL', props<{ error: any }>());

export const GET_GLEBES = createAction('[Trap Page] GET_GLEBES');
export const GET_GLEBES_SUCCESS = createAction(
  '[Trap Page] GET_GLEBES_SUCCESS',
  props<{ glebes: Array<Glebe> }>()
);
export const GET_GLEBES_FAIL = createAction('[Trap Page] GET_GLEBES_FAIL', props<{ error: any }>());

export const GET_DEVICES = createAction('[Trap Page] GET_DEVICES');
export const GET_DEVICES_SUCCESS = createAction(
  '[Trap Page] GET_DEVICES_SUCCESS',
  props<{ devices: Array<Device> }>()
);
export const GET_DEVICES_FAIL = createAction(
  '[Trap Page] GET_DEVICES_FAIL',
  props<{ error: any }>()
);

export const GET_MANUFACTURED_TRAPS = createAction('[Trap Page] GET_MANUFACTURED_TRAPS');
export const GET_MANUFACTURED_TRAPS_SUCCESS = createAction(
  '[Trap Page] GET_MANUFACTURED_TRAPS_SUCCESS',
  props<{ manufacturedTraps: Array<ManufacturedTrap> }>()
);
export const GET_MANUFACTURED_TRAPS_FAIL = createAction(
  '[Trap Page] GET_MANUFACTURED_TRAPS_FAIL',
  props<{ error: any }>()
);

/**
 * Cadastrar
 */
export const CREATE_TRAP = createAction(
  '[Modal Trap Page] CREATE_TRAP',
  props<{ trap: CreateTrapForm }>()
);
export const CREATE_TRAP_SUCCESS = createAction('[Modal Trap Page] CREATE_TRAP_SUCCESS');
export const CREATE_TRAP_FAIL = createAction(
  '[Modal Trap Page] CREATE_TRAP_FAIL',
  props<{ error: any }>()
);

/**
 * Atualizar
 */
export const UPDATE_TRAP_EDIT = createAction(
  '[Modal Trap Page] UPDATE_TRAP_EDIT',
  props<{ trap: Trap }>()
);
export const UPDATE_TRAP = createAction(
  '[Modal Trap Page] UPDATE_TRAP',
  props<{ trap: UpdateTrapForm }>()
);
export const UPDATE_TRAP_SUCCESS = createAction('[Modal Trap Page] UPDATE_TRAP_SUCCESS');
export const UPDATE_TRAP_FAIL = createAction(
  '[Modal Trap Page] UPDATE_TRAP_FAIL',
  props<{ error: any }>()
);
export const PATCH_TRAP = createAction(
  '[Modal Trap Page] PATCH_TRAP',
  props<{ trapForm: UpdateLinerForm }>()
);
export const PATCH_TRAP_SUCCESS = createAction('[Modal Trap Page] PATCH_TRAP_SUCCESS');
export const PATCH_TRAP_FAIL = createAction(
  '[Modal Trap Page] PATCH_TRAP_FAIL',
  props<{ error: any }>()
);
/**
 * Remover
 */
export const REMOVE_TRAP = createAction('[Trap Page] REMOVE_TRAP', props<{ ids: Array<string> }>());
export const REMOVE_TRAP_SUCCESS = createAction('[Trap Page] REMOVE_TRAP_SUCCESS');
export const REMOVE_TRAP_FAIL = createAction(
  '[Trap Page] REMOVE_TRAP_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_MODAL = createAction('[Modal Trap Page] HIDE_MODAL');
