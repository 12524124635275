export const text = {
  error: 'Erro !',
  warning: 'Atenção !',
  success: 'Sucesso !',
  itemsPerPage: 'Itens por página',
  firstPage: 'Primeira página',
  nextPage: 'Próxima página',
  previousPage: 'Página anterior',
  lastPage: 'Última página',
  of: 'de',
  dateHoursMinute: 'DD/MM/YYYY hh mm',
  date: 'DD/MM/YYYY',
  dateMonth: 'MM/YYYY',
  dateYear: 'YYYY',
  youHaveNotifications: 'Você tem {{%0}} notificações',
  acronymsMonth: 'm',
  acronymsWeek: 'sem',
  acronymsDay: 'd',
  acronymsHour: 'h',
  acronymsMinute: 'm',
  acronymsSecond: 's',
  removeUser: 'Você realmente quer remover este usuário?',
  removeAllUser: 'Você realmente quer remover estes Usuários?',
  usersNotFound: 'Não há usuários cadastrados.',
  commentNotExists: 'Comentário não existente',
  removeField: {
    default: 'Você realmente quer remover este talhão?',
    lot: 'Você realmente quer remover este lote?',
    type: 'field',
  },
  removeAllField: {
    default: 'Você realmente quer remover estes talhões?',
    lot: 'Você realmente quer remover estes lotes?',
    type: 'field',
  },
  stickyLinerExchange: 'Você deseja realizar a troca de piso?',
  pheromoneExchange: 'Você deseja realizar a troca de atrativo?',
  pheromone: 'Essa armadilha contém atrativo de:',
  monitoringTooltip: 'Para acessar os gráficos, clique em um talhão ou lote',
  evolutionOfSurveysNotFound: 'Não há evolução dos levantamentos.',
  iconPheromone: 'Esse é o ícone que representa armadilhas com atrativo para {{%0}} no mapa.',
  trapNoCommunication: 'Armadilha não comunicou.',
  fieldNotFound: {
    default: 'Não há talhões cadastrados.',
    lot: 'Não há lotes cadastrados.',
    type: 'field',
  },
  removeGlebe: {
    default: 'Você realmente quer remover esta gleba?',
    productionUnit: 'Você realmente quer remover esta Unidade de Produção?',
    productiveUnit: 'Você realmente quer remover esta Unidade Produtiva?',
    type: 'glebe',
  },
  removeReport: 'Você realmente quer remover este relatório?',
  removeAllGlebe: {
    default: 'Você realmente quer remover estas glebas?',
    productionUnit: 'Você realmente quer remover estas Unidades de Produção?',
    productiveUnit: 'Você realmente quer remover estas Unidades Produtivas?',
    type: 'glebe',
  },
  removeAllReport: 'Você realmente quer remover estes relatórios?',
  enableCustomControlLevelsByGlebe: {
    default: 'habilitar níveis de controle customizados por gleba',
    productionUnit: 'habilitar níveis de controle customizados por Unidade de Produção',
    productiveUnit: 'habilitar níveis de controle customizados por Unidade Produtiva',
    type: 'glebe',
  },
  glebeNotFound: {
    default: 'Não há glebas cadastradas.',
    productionUnit: 'Não há Unidades de Produção cadastradas.',
    productiveUnit: 'Não há Unidades Produtivas cadastradas.',
    type: 'glebe',
  },
  trapReportNotFound: 'Não há relatórios de armadilhas cadastradas.',
  removeTrap: 'Você realmente quer remover esta armadilha?',
  removeAllTrap: 'Você realmente quer remover estas armadilhas?',
  updateLinner: 'Você realmente quer atualizar a troca de piso desta armadilha?',
  updateLinners: 'Você realmente quer atualizar a troca de piso destas armadilhas?',
  trapNotFound: 'Não há armadilhas cadastradas.',
  requestNotFound: 'Não há solicitações cadastradas.',
  removeFarm: {
    default: 'Você realmente quer remover esta fazenda?',
    productiveUnit: 'Você realmente quer remover esta Unidade Produtiva?',
    type: 'farm',
  },
  removeAllFarm: {
    default: 'Você realmente quer remover estas fazendas?',
    productiveUnit: 'Você realmente quer remover estas Unidades Produtivas?',
    type: 'farm',
  },
  dailyCapturesNotFound: 'Não há capturas diárias.',
  evolutionInfestationNotFound: 'Não há evolução das infestações.',
  kmzNotFound: 'Não há kmz importados',
  version: 'Versão',
  incidenceOfOccurrenceNotFound: 'Não há incidência de ocorrência.',
  updateLinneExchange: 'Para qual dia você deseja registrar a troca de piso para essas armadilhas?',
  high: 'Alto',
  medium: 'Médio',
  low: 'Baixo',
  all: 'Todos os níveis',
  interactive: 'Interativo',
  georeferenced: 'Georreferenciado',
  mad: 'MAD',
  critical: 'Muito Alto',
  veryLow: 'Muito Baixo',
  unmonitored: 'Não Monitorado',
  notFound: 'Não Encontrado',
  controlLevelUndefined: 'Não definido',
  controlLevelExceededNotFound: 'Não há ocorrências que excederam o nível de controle.',
  requestMonitoringNotFound: 'Não há monitoramento.',
  imagesNotFound: 'Não há imagens coletadas.',
  notificationsNotFound: 'Não há notificações.',
  farmNotFound: {
    default: 'Não há fazendas cadastradas.',
    productiveUnit: 'Não há unidades produtivas cadastradas.',
    type: 'farm',
  },
  gmGlebeNotFound: {
    default:
      'Não há monitoramento. Para começar a realizar monitoramentos é necessário cadastrar ou importar uma gleba e pelo menos um talhão.',
    productionUnit:
      'Não há monitoramento. Para começar a realizar monitoramentos é necessário cadastrar ou importar uma Unidade de Produção e pelo menos um talhão.',
    productiveUnit:
      'Não há monitoramento. Para começar a realizar monitoramentos é necessário cadastrar ou importar uma Unidade Produtiva e pelo menos um talhão.',
    type: 'glebe',
  },
  gmFieldNotFound: {
    default:
      'Não há monitoramento. Para começar a monitorar é necessário cadastrar ou importar um talhão.',
    lot: 'Não há monitoramento. Para começar a monitorar é necessário cadastrar ou importar um lote.',
    type: 'field',
  },
  userNotFound: 'Não há usuários cadastrados.',
  termsAndPolicy: {
    text1: 'Li e concordo com os',
    linkTerm: 'Termos de uso',
    text2: 'e as',
    linkPolicy: 'Políticas de privacidade',
  },
  planExpired: {
    text1: 'Notamos alguns problemas na sua conta.',
    text2:
      'Peça ao seu gestor para entrar em contato com o Suporte Tarvos para melhores informações.',
  },
  and: 'e',
  allRightsReserved: 'Todos os direitos reservados.',
  tarvosTm: 'Tarvos™ 2021.',
  harvest: {
    default: 'Safra',
    year: 'Ano',
    cycle: 'Ciclo',
    type: 'harvest',
  },
  following: 'Vazio Sanitário',
  harvestsNotFound: {
    default: 'Não há safras cadastradas.',
    year: 'Não há anos cadastrados.',
    cycle: 'Não há ciclos cadastrados.',
    type: 'harvest',
  },
  removeHarvest: {
    default: 'Você realmente quer remover esta safra?',
    year: 'Você realmente quer remover este ano?',
    cycle: 'Você realmente quer remover este ciclo?',
    type: 'harvest',
  },
  harvestDependencies: {
    default: 'Para colher este talhão é necessário finalizar os seguintes itens:',
    lot: 'Para colher este lote é necessário finalizar os seguintes itens:',
    type: 'field',
  },
  removeAllHarvest: {
    default: 'Você realmente quer remover estas safras?',
    year: 'Você realmente quer remover estes anos?',
    cycle: 'Você realmente quer remover estes ciclos?',
    type: 'harvest',
  },
  reportData: 'Informar dados',
  drawField: 'Desenhar polígono',
  configureControlLevel: 'Configurar nível de controle',
  setCollectionPoints: 'Definir pontos de coleta',
  defineOccurrences: 'Definir ocorrências',
  trapDraggedOffField: {
    default: 'A armadilha não pode ser arrastada para fora do talhão.',
    lot: 'A armadilha não pode ser arrastada para fora do lote.',
    type: 'field',
  },
  noFieldsChanged: 'Não há campos a serem alterados',
  trapsInProgress: 'Armadilhas veiculadas.',
  trapInProgress: 'Armadilha veiculada.',
  reportInProgress: 'Relatório de armadilha/missão em andamento',
  reportsInProgress: 'Relatórios de armadilha/missão em andamento',
  changeProfilePhoto: 'Clique para alterar sua foto do perfil',
  noNickname: 'sem apelido',
  setSchedule: 'Definir cronograma',
  used: 'Utilizados',
  available: 'disponíveis',
  day: 'dia',
  days: 'dias',
  accountReadFileNotSupported: 'O formato do arquivo não é suportado.',
  enterFallowing: 'entrar em pousio',
  fieldUnavailableDuringPeriod: {
    default: ' (esse talhão ficará indisponível durante o período especificado abaixo)',
    lot: ' (esse lote ficará indisponível durante o período especificado abaixo)',
    type: 'field',
  },
  hasScheduling: 'Deseja definir um cronograma para gerar as missões por período',
  emailExample: 'exemplo@exemplo.com',
  latLng: '00.000000',
  phoneExample: '(00) 0 0000-0000',
  clean: 'Limpar',
  noResultsFound: 'Nenhum resultado encontrado',
  wantToDisableTrap: 'Deseja desativar a armadilha.',
  percentage: '%',
  unity: 'und',
  centerField: {
    default: 'Centralizar talhões',
    lot: 'Centralizar lotes',
    type: 'field',
  },
  zoomIn: 'Aumentar o zoom',
  zoomOut: 'Reduzir o zoom',
  filters: 'Filtros',
  noGlebesHarvested: {
    default: 'Não há glebas colhidas',
    productionUnit: 'Não há Unidade de Produção colhidas',
    productiveUnit: 'Não há Unidade Produtiva colhidas',
    type: 'glebe',
  },
  noFieldsHarvested: {
    default: 'Não há talhões colhidos',
    lot: 'Não há lotes colhidos',
    type: 'field',
  },
  noRegisteredGlebes: {
    default: 'Não há glebas cadastradas',
    productionUnit: 'Não há unidades de produção cadastradas',
    productiveUnit: 'Não há unidades produtivas cadastradas',
    type: 'glebe',
  },
  noRegisteredCrops: 'Não há culturas cadastradas',
  noRegisteredSupportUsers: 'Não há usuários de suporte cadastrados',
  noRegisteredFields: {
    default: 'Não há talhões cadastrados',
    lot: 'Não há lotes cadastrados',
    type: 'field',
  },
  noRegisteredTraps: 'Não há armadilhas cadastradas',
  noRegisteredOccurrences: 'Não há ocorrência cadastradas',
  noRegisteredManufacturedTraps: 'Não há armadilhas disponíveis',
  ticketIdInUse: ' - dispositivo cadastrado',
  loading: 'Carregando...',
  stickyExchange: 'Trocou o piso',
  hasRemovedTrap: 'Armadilha foi removida do sistema',
  unselectedFilter: 'Filtro não definido',
  humidity: 'Umidade',
  rain: 'Chuva',
  feelsLike: 'Sensação térmica',
  windSpeed: 'Velocidade do vento',
  pressure: 'Pressão atmosférica',
  trapExistsInSomeRequest: 'Solicitação em andamento',
  chartNotFound: 'Não há informações para este gráfico',
  notReported: 'Não reportado',
  helpEvolutionChart: {
    TRAP: 'Gráfico de linhas que apresenta a contagem diária das ocorrências capturadas pela armadilha.',
  },
  helpAccumulatedChart: {
    TRAP: 'Gráfico de linhas que apresenta capturas desde a última troca de piso da armadilha.',
  },
  helpInfestationEvolutionChart: {
    SCOUTING:
      'Gráfico de linha que compara o nível de infestação e o nível de controle diários para a ocorrência selecionada.',
  },
  helpLevelExceededChart: {
    TRAP: 'Gráfico de barras que apresenta somente as ocorrências com nível de infestação superior ao nível de controle.',
  },
  helpStatusChart: {
    TRAP: 'Gráfico de pizza que apresenta a quantidade de solicitações abertas, finalizadas e atrasadas.',
  },
  helpStatusMadChart: {
    TRAP: 'Gráfico de colunas que apresenta o índice MAD (mosca por armadilha por dia) das áreas selecionadas.',
  },
  helpMadChart: {
    TRAP: 'Gráfico de colunas e linhas que apresenta os índices semanais do MAD.',
  },
  helpStatusClimateChart: {
    TRAP: 'Gráfico que apresenta os dados climáticos (temperatura e umidade)',
  },
  helpClimateChart: {
    TRAP: 'Gráfico que apresenta os dados climáticos',
  },
  helpStatusSoilChart: {
    TRAP: 'Gráfico que apresenta os dados de umidade do solo',
  },
  helpSoilChart: {
    TRAP: 'Gráfico que apresenta os dados de umidade do solo',
  },
  helpTotalBetweenChart: {
    TRAP: {
      default:
        'Gráfico de linhas que apresenta um comparativo entre talhões do total diário da ocorrência selecionada.',
      lot: 'Gráfico de linhas que apresenta um comparativo entre lotes do total diário da ocorrência selecionada.',
      type: 'field',
    },
  },
  helpMovingAverageChart: {
    TRAP: 'Gráfico de linhas que apresenta a média móvel diária das ocorrências capturadas pela armadilha.',
  },
  schedule: {
    never: 'A cada {{frequency}} {{period}} uma missão será programada.',
    date: 'A cada {{frequency}} {{period}} uma missão será programada até {{endByDate}}.',
    amount:
      'A cada {{frequency}} {{period}} uma missão será programada até atingir a quantidade de {{endByQuantity}} missão.',
    pluralAmount:
      'A cada {{frequency}} {{period}} uma missão será programada até atingir a quantidade de {{endByQuantity}} missões.',
    quantity: {
      text1: 'Um total de {{value}} missão gerada até o momento.',
      pluralText1: 'Um total de {{value}} missões geradas até o momento.',
      text2: 'Um total de {{value}} missão gerada.',
      pluralText2: 'Um total de {{value}} missões geradas.',
    },
  },
  scheduleCompleted: 'Todas as missões foram geradas para esta programação.',
  scheduleCanceled: 'Programação foi cancelada.',
  responsibleManager: '( Gestor Responsável )',
  harvestedDate: 'Data da Colheita',
  noHarvestedGlebe: {
    default: 'nenhuma gleba colhida',
    productionUnit: 'nenhuma unidade de produção colhida',
    productiveUnit: 'nenhuma unidade produtiva colhida',
    type: 'glebe',
  },
  noHarvestedField: {
    default: 'nenhum talhão colhido',
    lot: 'nenhum lote colhido',
    type: 'field',
  },
  noTrapsFound: 'nenhuma armadilha encontrada',
  noRegisteredUsers: 'nenhum usuário encontrado',
  periodFallowing: 'Período de pousio',
  imported: 'importado',
  inProjection: 'em projeção',
  noImportedGlebe: {
    default: 'nenhuma gleba foi importada',
    productionUnit: 'nenhuma unidade de produção foi importada',
    productiveUnit: 'nenhuma unidade produtiva foi importada',
    type: 'glebe',
  },
  aliasNotDefined: 'não definido',
  xls: 'Excel',
  xlsMessage: 'Proporciona baixar e visualizar dados de qualquer período.',
  pdf: 'Pdf',
  xlsCompleted:
    'Este relatório já foi criado. Basta clicar no botão para fazer o download do arquivo.',
  pdfCompleted:
    'Este relatório já foi agendado. Verifique seu e-mail ou faça o download do pdf quando disponível',
  pdfMessage:
    'Será enviado para o seu e-mail ou WhatsApp periodicamente, com os principais dados da sua fazenda através do agendamento do relatório.',
  pdfCreated:
    'Não esqueça de checar o seu e-mail cadastrado, no período escolhido para analisar o relatório da sua fazenda.',
  xlsCreated: 'Estamos gerando seu relatório, vamos notificar-lo quando estiver tudo pronto.',
};
