import { Injectable } from '@angular/core';
import { CollectionReference } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { HeaderState } from 'src/app/view/header/header.state';
import { ApplicationService } from '../../services/application.service';
import { GenericFireStorageService } from '../../services/generic-firestorage.service';
import { GenericFirestoreService } from '../../services/generic-firestore.service';
import { LoginService } from '../../services/login.service';
import { MyToastrService } from '../../services/toastr.service';
import { TranslateTypes } from '../../services/translation.service';
import {
  Farm,
  WebUser,
  Harvest,
  Notification,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { Timestamp } from '../../interfaces/Types';

import { of } from 'rxjs';

import * as headerAction from './header.actions';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Period } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Injectable()
export class HeaderEffects {
  constructor(
    private actions$: Actions,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private translateService: TranslateService,
    private toastrService: MyToastrService,
    private applicationService: ApplicationService,
    private genericFirestoreService: GenericFirestoreService,
    private genericFireStorageService: GenericFireStorageService,
    private store: Store<HeaderState>,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço para listar notificações.
   */
  public getNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(headerAction.GET_NOTIFICATION),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Notification>(`${this.applicationService.getFarmRefDatabase()}/alerts`, {
            queryFn: (ref: CollectionReference) =>
              ref.where('wasViewedByUser', '==', false).orderBy('date', 'desc'),
          })
          .pipe(
            map((notifications: Array<Notification>) => {
              notifications.map((notification: any) => {
                const notificationDate = moment(notification.date.toDate());
                const serverDate = moment(this.applicationService.getServerDate().toDate());

                if (moment(serverDate).diff(notificationDate, 'month') > 0) {
                  moment(serverDate).diff(notificationDate, 'month') > 2
                    ? (notification.period = Period.ninety_days)
                    : moment(serverDate).diff(notificationDate, 'month') > 1
                    ? (notification.period = Period.sixty_days)
                    : (notification.period = Period.ninety_days);
                  notification.date = `${moment(serverDate).diff(
                    notificationDate,
                    'month'
                  )} ${this.translateService.instant(this.trans.text.acronymsMonth)}`;
                } else if (moment(serverDate).diff(notificationDate, 'week') > 0) {
                  moment(serverDate).diff(notificationDate, 'week') > 2
                    ? (notification.period = Period.thirty_days)
                    : moment(serverDate).diff(notificationDate, 'week') > 1
                    ? (notification.period = Period.fifteen_days)
                    : (notification.period = Period.thirty_days);
                  notification.date = `${moment(serverDate).diff(
                    notificationDate,
                    'week'
                  )} ${this.translateService.instant(this.trans.text.acronymsWeek)}`;
                } else if (moment(serverDate).diff(notificationDate, 'day') > 0) {
                  notification.period = Period.seven_days;
                  notification.date = `${moment(serverDate).diff(
                    notificationDate,
                    'day'
                  )} ${this.translateService.instant(this.trans.text.acronymsDay)}`;
                } else if (moment(serverDate).diff(notificationDate, 'hour') > 0) {
                  notification.period = Period.today;
                  notification.date = `${moment(serverDate).diff(
                    notificationDate,
                    'hour'
                  )} ${this.translateService.instant(this.trans.text.acronymsHour)}`;
                } else if (moment(serverDate).diff(notificationDate, 'minute') > 0) {
                  notification.period = Period.today;
                  notification.date = `${moment(serverDate).diff(
                    notificationDate,
                    'minute'
                  )} ${this.translateService.instant(this.trans.text.acronymsMinute)}`;
                } else if (moment(serverDate).diff(notificationDate, 'second') > 0) {
                  notification.period = Period.today;
                  notification.date = `${moment(serverDate).diff(
                    notificationDate,
                    'second'
                  )} ${this.translateService.instant(this.trans.text.acronymsSecond)}`;
                } else {
                  notification.date = `0 ${this.translateService.instant(
                    this.trans.text.acronymsSecond
                  )}`;
                }
              });

              return headerAction.GET_NOTIFICATION_SUCCESS({
                notifications,
              });
            }),
            catchError((error) => of(headerAction.GET_NOTIFICATION_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar usuário.
   */
  public getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(headerAction.GET_USER),
      switchMap(() =>
        this.genericFirestoreService
          .getById<WebUser>(
            `${this.applicationService.getUserRefDatabase()}/${this.applicationService.getUserId()}`
          )
          .pipe(
            map((user: WebUser) => headerAction.GET_USER_SUCCESS({ user: _.cloneDeep(user) })),
            catchError((error) => of(headerAction.GET_USER_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar fazenda.
   */
  public getFarms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(headerAction.GET_FARMS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Farm>(`${this.applicationService.getCustomerRefDatabase()}/farms`)
          .pipe(
            map((farms: Array<Farm>) => {
              this.store.dispatch(headerAction.GET_HARVESTS({ farms }));
              return headerAction.GET_FARMS_SUCCESS({ farms });
            }),
            catchError((error) => of(headerAction.GET_FARMS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar safras.
   */
  public getHarvests$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(headerAction.GET_HARVESTS),
      switchMap((action) => {
        const combineList = action.farms.map((farm) => {
          return {
            ref: `${this.applicationService.getCustomerRefDatabase()}/farms/${farm.id}/harvests`,
            propertyDate: [['currentYear']],
          };
        });
        return this.genericFirestoreService.getCombine<Harvest>(combineList, 'name', 'asc').pipe(
          map((harvests: Array<Harvest>) => headerAction.GET_HARVESTS_SUCCESS({ harvests })),
          catchError((error) => of(headerAction.GET_HARVESTS_FAIL(error)))
        );
      })
    );
  });

  /**
   * Este método chama um serviço para atualizar a imagem do usuário.
   */
  public updateUserAndProfileImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerAction.UPDATE_USER_AND_PROFILE_IMAGE),
      mergeMap((action) =>
        this.genericFireStorageService
          .upload<any>(
            `${this.applicationService.getCustomerRefDatabase()}/users/profile-${action.user.id}`,
            action.user.image?.file
          )
          .then((data: any) => {
            return data.ref.getDownloadURL().then((url: any) => {
              action.user.imageUrl = url;
              this.store.dispatch(headerAction.UPDATE_USER({ user: action.user }));
              return headerAction.UPDATE_USER_AND_PROFILE_IMAGE_SUCCESS();
            });
          })
          .catch((error) => of(headerAction.UPDATE_USER_AND_PROFILE_IMAGE_FAIL(error)))
      )
    )
  );

  /**
   * Este método chama um serviço para atualizar usuário.
   */
  public updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerAction.UPDATE_USER),
      mergeMap((action) => {
        delete action.user['image'];

        return this.genericFirestoreService
          .update<WebUser>(`${this.applicationService.getUserRefDatabase()}`, action.user)
          .then(() => {
            this.toastrService.success(this.trans.services.update.headerSuccess, {
              '%0': action.user.name,
            });
            return headerAction.UPDATE_USER_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return headerAction.UPDATE_USER_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para atualizar o tema do usuário.
   */
  public updateTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerAction.UPDATE_THEME),
      mergeMap((action) => {
        action.user['image'];

        return this.genericFirestoreService
          .update<WebUser>(`${this.applicationService.getUserRefDatabase()}`, action.user)
          .then(() => headerAction.UPDATE_THEME_SUCCESS())
          .catch((error) => headerAction.UPDATE_THEME_FAIL(error));
      })
    )
  );

  /**
   * Este método chama um serviço para atualizar o usuário com o cliente selecionado
   */
  public updateUserCustomerSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerAction.UPDATE_USER_CUSTOMER_SELECTED),
      mergeMap((action) => {
        return this.genericFirestoreService
          .update<WebUser>(`${this.applicationService.getUserRefDatabase()}`, action.user)
          .then(() => {
            const whichRouteIsActive = this.activeRoute.children[0].children[0].component as any;
            this.applicationService.updateComponentData.next(whichRouteIsActive.name);
            this.applicationService.updateComponentData.next(null);
            this.store.dispatch(headerAction.GET_NOTIFICATION());
            return headerAction.UPDATE_USER_CUSTOMER_SELECTED_SUCCESS();
          })
          .catch((error) => {
            return headerAction.UPDATE_USER_CUSTOMER_SELECTED_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para atualizar o usuário com a fazenda e safra selecionada
   */
  public updateUserFarmAndHarvestSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerAction.UPDATE_USER_FARM_AND_HARVEST_SELECTED),
      mergeMap((action) => {
        return this.genericFirestoreService
          .update<WebUser>(`${this.applicationService.getUserRefDatabase()}`, action.user)
          .then(() => {
            const whichRouteIsActive = this.activeRoute.children[0].children[0].component as any;
            this.applicationService.updateComponentData.next(whichRouteIsActive.name);
            this.applicationService.updateComponentData.next(null);
            this.store.dispatch(headerAction.GET_NOTIFICATION());
            this.store.dispatch(headerAction.GET_FARMS());
            return headerAction.UPDATE_USER_FARM_AND_HARVEST_SELECTED_SUCCESS();
          })
          .catch((error) => {
            return headerAction.UPDATE_USER_FARM_AND_HARVEST_SELECTED_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para atualizar monitoramento geral.
   */
  public updateGm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerAction.UPDATE_GM),
      mergeMap((action) => {
        return this.genericFirestoreService
          .getById<GmConfiguration>(
            `${this.applicationService.getHarvestRefDatabase()}/generalMonitoringConfigurations/${this.applicationService.getUserId()}`
          )
          .first()
          .toPromise()
          .then((gmConfiguration: GmConfiguration) => {
            const gm = gmConfiguration
              ? gmConfiguration
              : ({
                  startDate: Timestamp.fromDate(new Date()),
                  endDate: Timestamp.fromDate(new Date()),
                } as GmConfiguration);
            gm.glebeIds = [];
            gm.fieldIds = [action.notification.fieldId];
            gm.startDate = action.notification as any;
            gm.endDate = this.applicationService.getServerDate();

            return this.genericFirestoreService
              .update<GmConfiguration>(
                `${this.applicationService.getHarvestRefDatabase()}/generalMonitoringConfigurations`,
                gm
              )
              .then(() => {
                this.router.navigate(['/home/gm']);
                return headerAction.UPDATE_GM_SUCCESS();
              })
              .catch((error) => headerAction.UPDATE_GM_FAIL(error));
          });
      })
    )
  );

  /**
   * Este método chama um serviço para limpar as notificações.
   */
  public cleanNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerAction.CLEAN_NOTIFICATIONS),
      mergeMap((action: any) => {
        return this.genericFirestoreService
          .batchRemove<Notification>(
            `${this.applicationService.getFarmRefDatabase()}/alerts`,
            action.notificationIds
          )
          .then(() => {
            return headerAction.CLEAN_NOTIFICATIONS_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return headerAction.CLEAN_NOTIFICATIONS_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para atualizar senha.
   */
  public updateUserPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(headerAction.UPDATE_USER_PASSWORD),
      mergeMap((action) => {
        return this.loginService
          .recoverPassword(action.email)
          .then(() => {
            this.toastrService.success(this.trans.services.update.recoverPasswordSuccess);
            return headerAction.UPDATE_USER_PASSWORD_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return headerAction.UPDATE_USER_PASSWORD_FAIL(error);
          });
      })
    )
  );
}
