import { createAction, props } from '@ngrx/store';
import { Glebe, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_HARVESTS = createAction('[Import Glebe Form Page] GET_HARVESTS');
export const GET_HARVESTS_SUCCESS = createAction(
  '[Import Glebe Form Page] GET_HARVESTS_SUCCESS',
  props<{ harvests: Array<Harvest> }>()
);
export const GET_HARVESTS_FAIL = createAction(
  '[Import Glebe Form Page] GET_HARVEST_FAIL',
  props<{ error: any }>()
);

export const GET_GLEBES = createAction('[Import Glebe Form Page] GET_GLEBES');
export const GET_GLEBES_SUCCESS = createAction(
  '[Import Glebe Form Page] GET_GLEBES_SUCCESS',
  props<{ glebes: Array<Glebe> }>()
);
export const GET_GLEBES_FAIL = createAction(
  '[Import Glebe Form Page] GET_GLEBES_FAIL',
  props<{ error: any }>()
);

/**
 * Importar
 */
export const CREATE_IMPORT_GLEBE = createAction(
  '[Import Glebe Form Page] CREATE_IMPORT_GLEBE',
  props<{ ids: Array<string> }>()
);
export const CREATE_IMPORT_GLEBE_SUCCESS = createAction(
  '[Import Glebe Form Page] CREATE_IMPORT_GLEBE_SUCCESS'
);
export const CREATE_IMPORT_GLEBE_FAIL = createAction(
  '[Import Glebe Form Page] CREATE_IMPORT_GLEBE_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_MODAL = createAction('[Import Glebe Form Page] HIDE_MODAL');
