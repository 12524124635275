<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="closeModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span class="title">{{ trans.title.filters | translate }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <form class="custom-form-field" #form="ngForm">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <!-- Gleba -->
      <mat-form-field fxFlex="100%">
        <mat-label>{{ trans.label.glebe | label }}</mat-label>
        <mat-select
          #materialSelect
          [(ngModel)]="state.gmConfigEdit.glebeIds"
          id="glebeIds"
          name="glebeIds"
          multiple
          #glebeIds="ngModel"
          (selectionChange)="onChangeGlebe()"
        >
          <mat-option class="selectAll" (click)="selectAllGlebes()">{{
            trans.title.selectAll | translate
          }}</mat-option>
          <mat-option class="selectAll" (click)="deselectAllGlebes()">{{
            trans.title.deselectAll | translate
          }}</mat-option>
          <mat-option *ngFor="let glebe of getGlebesByCrop()" [value]="glebe.id">
            {{ glebe.name }}
            <span *ngIf="glebe.alias && glebe.alias !== ''">( {{ glebe.alias }} )</span>
            <span
              style="float: right"
              matTooltipClass="material-tooltip-class"
              [matTooltip]="trans.text.harvestedDate | translate"
              [matTooltipPosition]="'left'"
              *ngIf="glebe.harvested"
            >
              {{ moment(glebe.finalHarvestDate.toDate()).format(trans.text.date | translate) }}
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Talhão -->
      <mat-form-field fxFlex="100%">
        <mat-label>{{ trans.label.field | label }}</mat-label>
        <mat-select
          #materialSelect
          id="field"
          name="fieldIds"
          #field="ngModel"
          [(ngModel)]="state.gmConfigEdit.fieldIds"
          multiple
        >
          <mat-option class="selectAll" (click)="selectAllFields()">{{
            trans.title.selectAll | translate
          }}</mat-option>
          <mat-option class="selectAll" (click)="deselectAllFields()">{{
            trans.title.deselectAll | translate
          }}</mat-option>
          <mat-optgroup *ngFor="let item of getGlebesByIds(); let i = index" [label]="item.name">
            <mat-option *ngFor="let field of getFieldsByGlebe(item.id)" [value]="field.id">
              {{ field.name }}
              <span *ngIf="field.alias && field.alias !== ''">( {{ field.alias }} )</span>
              <span
                style="float: right"
                matTooltipClass="material-tooltip-class"
                [matTooltip]="trans.text.harvestedDate | translate"
                [matTooltipPosition]="'left'"
                *ngIf="field.harvested"
              >
                {{ moment(field.harvestDate.toDate()).format(trans.text.date | translate) }}
              </span>
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

      <!-- Ocultar armadilhas -->
      <div
        fxFlex="100%"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        style="height: 46px; padding: 0px 5px; padding-bottom: 26px"
      >
        <mat-slide-toggle
          [color]="'primary'"
          name="hideCollectionPoint"
          [checked]="state.gmConfigEdit.hideCollectionPoint"
          [(ngModel)]="state.gmConfigEdit.hideCollectionPoint"
        >
          {{ trans.button.hideCollectionPoint | translate }}
        </mat-slide-toggle>
      </div>

      <!-- Mostrar somente talhão com nível de controle elevado -->
      <div
        fxFlex="100%"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        style="height: 46px; padding: 0px 5px; padding-bottom: 26px"
      >
        <mat-slide-toggle
          [color]="'primary'"
          name="showFieldWithHighControlLevel"
          [checked]="state.gmConfigEdit.showFieldWithHighControlLevel"
          [(ngModel)]="state.gmConfigEdit.showFieldWithHighControlLevel"
        >
          {{ trans.button.showFieldWithHighControlLevel | label }}
        </mat-slide-toggle>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="closeModal()">
    {{ trans.button.close | translate }}
  </button>
  <div>
    <button mat-button color="primary" [mat-dialog-close]="true" (click)="clearFilter()">
      {{ trans.button.clearFilter | translate }}
    </button>
    <button mat-button color="primary" [mat-dialog-close]="true" (click)="apply()">
      {{ trans.button.apply | translate }}
    </button>
  </div>
</mat-dialog-actions>
