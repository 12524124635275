import { XYChart } from '@amcharts/amcharts4/.internal/charts/types/XYChart';
import { Component, Input, NgZone, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { BaseGraph } from '../../../utils/base-graph/base-graph';
import { LineSeries } from '@amcharts/amcharts4/charts';
import { chartColor } from 'src/app/constants/color';
import {
  AccumulatedChart,
  Data,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces/AccumulatedChart';
import { ApplicationService } from 'src/app/services/application.service';
import { UnitOfMeasureOfOccurrence } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Component({
  selector: 'app-accumulated-chart',
  templateUrl: './accumulated-chart.component.html',
  styleUrls: ['./accumulated-chart.component.scss'],
})
export class AccumulatedChartComponent extends BaseGraph implements OnDestroy {
  @Input() public set data(data: AccumulatedChart) {
    this.accumulatedChart = data;
    this.initChart();
  }

  public accumulatedChart!: AccumulatedChart;

  private chart!: XYChart;

  constructor(
    public anotherTranslateService: TranslateService,
    public trans: TranslateTypes,
    public zone: NgZone,
    public applicationService: ApplicationService
  ) {
    super(anotherTranslateService, applicationService);
  }

  public ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  public initChart(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }

      // Criar gráfico
      this.createXYChart('accumulatedChart', this.am4charts.XYChart);

      // Criar DateAxis no eixo x
      this.createBasicXYChartDateAxis();

      // Criar ValueAxis no eixo y
      const valueAxis = this.createBasicXYChartValueAxis();
      valueAxis.maxPrecision = 0;

      if (this.accumulatedChart?.unit === UnitOfMeasureOfOccurrence.boolean) {
        valueAxis.min = -2;
        valueAxis.max = 2;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.disabled = true;

        const range1 = this.addBasicXYChartAxisRanges(
          valueAxis,
          0,
          2,
          this.trans.label.presence,
          chartColor.red,
          chartColor.red
        );
        const range2 = this.addBasicXYChartAxisRanges(
          valueAxis,
          0,
          -2,
          this.trans.label.absence,
          chartColor.midGreen,
          chartColor.midGreen
        );

        if (this.applicationService.getIsDarkTheme()) {
          range1.axisFill.fillOpacity = 0.1;
          range2.axisFill.fillOpacity = 0.1;
        } else {
          range1.axisFill.fillOpacity = 0.2;
          range2.axisFill.fillOpacity = 0.2;
        }
      } else if (this.accumulatedChart?.unit === UnitOfMeasureOfOccurrence.intensity) {
        valueAxis.min = -4;
        valueAxis.max = 4;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.disabled = true;

        // ausência  baixa media ala

        const range1 = this.addBasicXYChartAxisRanges(
          valueAxis,
          2,
          4,
          this.trans.label.high,
          chartColor.red,
          chartColor.red
        );
        const range2 = this.addBasicXYChartAxisRanges(
          valueAxis,
          0,
          2,
          this.trans.label.average,
          chartColor.yellow,
          chartColor.yellow
        );
        const range3 = this.addBasicXYChartAxisRanges(
          valueAxis,
          0,
          -2,
          this.trans.label.low,
          chartColor.midGreen,
          chartColor.midGreen
        );
        const range4 = this.addBasicXYChartAxisRanges(
          valueAxis,
          -2,
          -4,
          this.trans.label.absence,
          chartColor.lightGrey,
          chartColor.lightGrey
        );

        if (this.applicationService.getIsDarkTheme()) {
          range1.axisFill.fillOpacity = 0.1;
          range2.axisFill.fillOpacity = 0.1;
          range3.axisFill.fillOpacity = 0.1;
          range4.axisFill.fillOpacity = 0.1;
        } else {
          range1.axisFill.fillOpacity = 0.2;
          range2.axisFill.fillOpacity = 0.2;
          range3.axisFill.fillOpacity = 0.2;
          range4.axisFill.fillOpacity = 0.2;
        }
      } else {
        valueAxis.min = 0;
        valueAxis.renderer.labels.template.adapter.add('text', (text) => text);
      }

      // Habilitar scrollbar
      this.addBasicXYChartScrollbarX();

      // Adicionar legenda
      this.addBasicXYChartLegend();

      if (this.accumulatedChart) {
        // Total #000000
        if (this.accumulatedChart.total.length > 0) {
          this.createTrendLine(this.accumulatedChart.total, false, false, '#000000', 0.1);
        }

        // Adiciona pontos ---- 0 -> ausência, 0.1 -> baixo, 0.5 -> médio, 1 -> auto
        if (this.accumulatedChart.data?.length > 0) {
          const data: Array<Array<Data>> = this.accumulatedChart.data.map(
            (element: Array<Data>) => {
              if (this.accumulatedChart?.unit === UnitOfMeasureOfOccurrence.intensity) {
                element.map((element1: Data) => {
                  element1.value =
                    element1.value === 0
                      ? -3
                      : element1.value === 0.1
                      ? -1
                      : element1.value === 0.5
                      ? 1
                      : element1.value === 1
                      ? 3
                      : element1.value;
                });
              } else if (this.accumulatedChart?.unit === UnitOfMeasureOfOccurrence.boolean) {
                element.map((element1: Data) => {
                  element1.value =
                    element1.value === 1 ? 1 : element1.value === 0 ? -1 : element1.value;
                });
              }

              return element;
            }
          );

          data.forEach((element: Array<Data>) =>
            this.createTrendLine(element, true, true, null, null)
          );
        }
      }

      this.chart = this.xYChart;
    });
  }

  /**
   * Este método gera as linhas do gráfico
   * @param data conteúdo do gráfico
   * @param randomColor gerar cor automático
   */
  public createTrendLine(
    data: Array<Data>,
    randomColor: boolean,
    hasCircleBullet: boolean,
    color: string | null,
    fillOpacity: number | null
  ): LineSeries {
    const trend = this.xYChart.series.push(new this.am4charts.LineSeries());

    if (
      this.accumulatedChart?.unit === UnitOfMeasureOfOccurrence.boolean ||
      this.accumulatedChart?.unit === UnitOfMeasureOfOccurrence.intensity
    ) {
      trend.tooltipText = `[bold font-size: 13px]{name}`;
    } else {
      trend.tooltipText = `[bold font-size: 13px]{name}: {valueY}`;
    }

    trend.dataFields.valueY = 'value';
    trend.dataFields.dateX = 'date';
    trend.name = data[0].name;
    trend.strokeWidth = 2;
    trend.fillOpacity = fillOpacity != null ? fillOpacity : 0;
    trend.propertyFields.strokeOpacity = 'notHadStickyExchange';

    if (!randomColor) {
      if (color) {
        trend.stroke = trend.fill = this.am4core.color(color.toString());
      } else if (this.xYChart.series.values.length > 1) {
        const lastColor =
          this.xYChart.series.values[this.xYChart.series.values.length - 1].stroke.toString();
        trend.stroke = trend.fill = this.am4core.color(lastColor);
      }
    }

    if (hasCircleBullet) {
      const bullet = trend.bullets.push(new this.am4charts.CircleBullet());
      bullet.strokeWidth = 0.5;
      // bullet.stroke = this.am4core.color('#fff');
      bullet.circle.fill = trend.stroke;

      const hoverState = bullet.states.create('hover');
      hoverState.properties.scale = 1.7;
    }

    trend.data = data;

    trend.tooltip?.adapter.add('tooltipText', (text) => {
      return text + '1';
    });
    return trend;
  }
}
