import { createAction, props } from '@ngrx/store';

export interface RouteData {
  polyline: string;
}

/**
 * Calcular Rota
 */
export const CALCULATE_ROUTE = createAction(
  '[Routes] Calculate Route',
  props<{
    start_point: { lat: number; lng: number };
    home_point: { lat: number; lng: number };
    farms_points: Array<{ lat: number; lng: number }>;
  }>()
);
export const CALCULATE_ROUTE_SUCCESS = createAction(
  '[Routes] Calculate Route Success',
  props<{ route: RouteData }>()
);
export const CALCULATE_ROUTE_FAIL = createAction(
  '[Routes] Calculate Route Fail',
  props<{ error: any }>()
);
