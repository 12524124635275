import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Regex, regex } from 'src/app/constants/regex';
import {
  CREATE_TRAP,
  GET_DEVICES,
  GET_FIELDS,
  GET_GLEBES,
  GET_MANUFACTURED_TRAPS,
  GET_REGISTRATION_NUMBER,
  HIDE_MODAL,
  UPDATE_TRAP,
} from 'src/app/view/trap/trap.actions';
import { TranslateTypes } from 'src/app/services/translation.service';
import {
  Device,
  Field,
  Glebe,
  Point,
  ManufacturedTrap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { GoogleMapsDrawMarkerComponent } from '../../components/maps/google-maps-draw-marker/google-maps-draw-marker.component';
import { TrapState, trapStateDefault } from '../trap.state';
import { MyToastrService } from 'src/app/services/toastr.service';
import * as Moment from 'moment';
import * as _ from 'lodash';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ApplicationService } from 'src/app/services/application.service';
import {
  CreateTrapForm,
  UpdateTrapForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/trap';
import { FormService } from 'src/app/services/form.service';
import { TrapIndex } from '@tarvos-ag/tarvos-firestore-models/src/utils/interfaces';
import { LabelPipe } from 'src/app/pipes/label.pipe';

@Component({
  selector: 'app-trap-form',
  templateUrl: './trap-form.component.html',
  styleUrls: ['./trap-form.component.scss'],
})
export class TrapFormComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) public form!: NgForm;
  @ViewChild('drawMarker', { static: true })
  public drawMarker!: GoogleMapsDrawMarkerComponent;

  public cols!: Observable<number | undefined>;
  public trapState$: Observable<TrapState>;
  public subscribe!: Subscription;
  public subscribeDataSharing!: Subscription;
  public state: TrapState = trapStateDefault;
  public markerCopie: Point | null = null;
  public outOfArea = false;
  public regex: Regex = regex;
  public moment: any = Moment;
  public pheromones: String[] = [];

  constructor(
    private store: Store<any>,
    private dialogRef: MatDialogRef<TrapFormComponent>,
    private toastrService: MyToastrService,
    public trans: TranslateTypes,
    private labelPipe: LabelPipe,
    private db: AngularFirestore,
    private applicationService: ApplicationService,
    private formService: FormService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.trapState$ = this.store.pipe(select('trap'));

    this.store.dispatch(GET_GLEBES());
    this.store.dispatch(GET_FIELDS());
    this.store.dispatch(GET_DEVICES());
    this.store.dispatch(GET_MANUFACTURED_TRAPS());
    this.store.dispatch(GET_REGISTRATION_NUMBER());
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    this.form.reset();
    this.state.trapForm = _.cloneDeep(trapStateDefault.trapForm);

    /**
     * Escuta as alterações de estado do redux
     */
    this.subscribe = this.trapState$.subscribe((state: TrapState) => {
      this.state = state;

      if (state.closeModal) {
        this.closeModal();
      }

      if (state.setMarker) {
        state.setMarker = false;
      }

      if (state.lastCount >= 0 && !this.data.edit) {
        this.generateUniqueName();
      }
    });
    if (this.data.edit) {
      this.state.trapForm.alias = this.state.trapEdit.alias;
      this.state.trapForm.deviceId = this.state.trapEdit.deviceId || '';
      this.state.trapForm.fieldId = this.state.trapEdit.fieldId;
      this.state.trapForm.marker = this.state.trapEdit.marker;
      this.state.trapForm.name = this.state.trapEdit.name;
      this.state.trapForm.pheromone = this.state.trapEdit.pheromone;
      this.state.trapForm.ticketId = this.state.trapEdit.ticketId;
      this.state.trapForm.glebeId = this.state.trapEdit.glebeId || '';
      this.state.trapForm.officialTrap = this.state.trapEdit.officialTrap;
      this.state.trapForm.trapType = this.state.trapEdit.trapType;
      this.state.trapForm.trapCode = this.state.trapEdit.trapCode;
      this.deviceSelection(this.state.trapForm.deviceId);
      this.getPheromoneByDevice();
    }
  }

  /**
   * Este método recebe um evento ao selecionar a gleba
   * @param glebeId id da gleba
   */
  public glebeSelection(glebeId: string): void {
    if (glebeId && this.state.trapForm.fieldId) {
      const list = this.getFieldsByGlebe(glebeId);
      if (list.filter((field) => field.id === this.state.trapForm.fieldId).length <= 0) {
        this.state.trapForm.fieldId = null;
        this.state.trapEdit.field = null;
        this.state.trapForm.marker = {} as Point;
      }
    } else {
      this.state.trapForm.fieldId = null;
      this.state.trapEdit.field = null;
      this.state.trapForm.marker = {} as Point;
    }
  }

  /**
   * Este método retorna uma lista de field quando selecionar uma gleba;
   */
  public getFieldsByGlebe(glebeId?: string): Array<Field> {
    const result = this.state.trapForm.glebeId
      ? this.state.fields.filter((field: Field) => field.glebe.id === this.state.trapForm.glebeId)
      : this.state.fields.filter((field: Field) => field.glebe.id === glebeId);

    return result.sort((a: any, b: any) =>
      a.name.toString().localeCompare(b.name, undefined, {
        numeric: true,
      })
    );
  }

  /**
   * Este método retorna uma lista de gleba ou a gleba selecionada;
   */
  public filterGlebe(): Array<Glebe> {
    if (this.state.trapForm.glebeId) {
      return this.state.glebes.filter((glebe: Glebe) => glebe.id === this.state.trapForm.glebeId);
    }
    return this.state.glebes;
  }

  /**
   * Este método recebe um evento ao selecionar a talhão
   * @param fieldsId id do talhão
   */
  public fieldSelection(fieldsId: string): void {
    if (this.state.fields) {
      if (this.state.trapEdit.field && this.state.trapEdit.field.id !== fieldsId) {
        this.state.trapForm.marker = {} as Point;
      }
      this.state.trapEdit.field = this.state.fields.filter((x: any) => x.id === fieldsId)[0];
    }
  }

  /**
   * Este método recebe um evento ao selecionar a dispositivo
   * @param devicesId id do dispositivo
   */
  public deviceSelection(devicesId: string): void {
    if (this.state.devices.length > 0) {
      this.state.trapEdit.device = this.state.devices.filter((x: any) => x.id === devicesId)[0];
      this.getPheromoneByDevice();
    }
  }

  /**
   * Este método é executado quando o componente é destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método cadastra ou edita
   */
  public create(): void {
    if (!this.data.edit) {
      const trap = this.generatesCreateTrapForm();
      this.store.dispatch(CREATE_TRAP({ trap }));
    } else {
      const trap = this.generatesUpdateTrapForm();
      if (Object.values(trap.trapData).length > 0) {
        return this.store.dispatch(UPDATE_TRAP({ trap }));
      }
      return this.toastrService.error(this.labelPipe.transform(this.trans.text.noFieldsChanged));
    }
  }

  /**
   * Este método limpa o redux e o formulário, além de fechar a modal
   */
  public closeModal(): void {
    this.form.reset();
    this.store.dispatch(HIDE_MODAL());
    this.dialogRef.close();
    this.pheromones = [];
  }

  /**
   * Este método atualiza o google maps com as novas coordenadas
   */
  public changeCoordinates(event: any, model: any): void {
    if (event && model.valid) {
      const marker = _.cloneDeep(this.state.trapForm.marker);

      if (marker) {
        if (model.name === 'lat') {
          marker.lat = event;
        } else if (model.name === 'lng') {
          marker.lng = event;
        }
        if (
          !this.drawMarker.markerInField(
            new google.maps.LatLng(
              this.drawMarker.convertToNumber(marker.lat),
              this.drawMarker.convertToNumber(marker.lng)
            ),
            this.state.trapEdit.field?.polygon
          )
        ) {
          this.toastrService.warning(this.labelPipe.transform(this.trans.text.trapDraggedOffField));
          this.outOfArea = true;
        } else {
          this.outOfArea = false;
        }

        this.state.trapForm.marker = marker;
      }
    }
  }

  /**
   * Este método posiciona uma armadilha a salva seu lat lng;
   * @param markers lista de armadilha
   */
  public onDrawEndMarker(data: any): void {
    this.outOfArea = data.disabled;
    if (data.action === 'drag' || (data.action === 'create' && data.marker)) {
      const marker: Point = {
        id: data.marker.id,
        // @ts-ignore  // GAMBETA! THIS SHOULD NOT BE A Point
        lat: parseFloat(data.marker.lat).toFixed(6),
        // @ts-ignore  // GAMBETA! THIS SHOULD NOT BE A Point
        lng: parseFloat(data.marker.lng).toFixed(6),
      };

      this.state.trapForm.marker = marker;

      if (data.hasDraggedOffField && data.action === 'drag') {
        this.toastrService.warning(this.labelPipe.transform(this.trans.text.trapDraggedOffField));
      }
    }

    if (data.action === 'remove') {
      this.state.trapForm.marker = {} as Point;
    }
  }

  /**
   * Este método gera um nome para solicitação a cada cadastro
   */
  public generateUniqueName(): void {
    `AR${String(this.state.lastCount).padStart(2, '0')}`;
    this.state.trapForm.name = `AR${String(this.state.lastCount).padStart(2, '0')}`;
  }

  /**
   * Este método define o id de comunicação com a armadilha
   * @param ticketId Etiqueta da armadilha
   */
  public selectionTicketId(ticketId: string): void {
    const manufacturedTrap = this.state.manufacturedTraps.filter(
      (item) => item.ticketId === ticketId
    )[0];
    if (manufacturedTrap.communicationId) {
      this.state.trapForm.manufacturedTrapId = manufacturedTrap.id;
    }
  }

  /**
   * Este método retorna uma lista de armadilhas filtrado por device
   */
  getManufacturedTrapsByDevice(): Array<ManufacturedTrap> {
    if (this.state.trapForm.deviceId) {
      const device = this.state.devices.filter(
        (element: Device) => element.id === this.state.trapForm.deviceId
      )[0];
      return this.state.manufacturedTraps
        .filter((manufacturedTrap: ManufacturedTrap) => manufacturedTrap.model === device.model)
        .sort(
          (a: ManufacturedTrap, b: ManufacturedTrap) =>
            Number(a.hasRegisteredTrap) - Number(b.hasRegisteredTrap) ||
            (a.ticketId < b.ticketId ? -1 : 1)
        );
    }
    return [];
  }

  getPheromoneByDevice(): void {
    if (this.state.trapEdit.device) {
      let occurrences = this.state.trapEdit.device.occurrences;
      const occurrencesArray = Object.keys(occurrences).map((occurrence: any) => {
        return occurrences[occurrence];
      });
      const allPheromones = occurrencesArray.map((occurrence) => {
        return occurrence.pheromone;
      });
      this.pheromones = [...new Set(allPheromones)].sort();
    }
  }

  private generateMarkerId(): void {
    if (this.state.trapForm.marker && !this.state.trapForm.marker.id) {
      this.state.trapForm.marker.id = this.db.createId();
    }
  }

  private generatesCreateTrapForm(): CreateTrapForm {
    this.generateMarkerId();
    const trapForm = _.cloneDeep(this.state.trapForm);
    const createTrapForm: CreateTrapForm = {
      customerId: this.applicationService.getCustomerId(),
      farmId: this.applicationService.getFarmId(),
      trapData: {
        alias: trapForm.alias,
        deviceId: trapForm.deviceId,
        harvestId: this.applicationService.getHarvestId(),
        manufacturedTrapId: trapForm.manufacturedTrapId,
        name: trapForm.name,
        pheromone: trapForm.pheromone,
        officialTrap: trapForm.officialTrap || null,
        trapCode: trapForm.trapCode || null,
        trapType: trapForm.trapType || null,
      },
    };

    if (!!trapForm.fieldId) {
      createTrapForm.trapData.fieldId = trapForm.fieldId;
    }
    if (!!trapForm?.marker?.lat) {
      // @ts-ignore  // GAMBETA! THIS SHOULD NOT BE A Point. Check trap-form.component.ts
      createTrapForm.trapData.marker = {
        id: trapForm.marker.id,
        lat: Number(trapForm.marker.lat),
        lng: Number(trapForm.marker.lng),
      };
    }

    return createTrapForm;
  }

  private generatesUpdateTrapForm(): UpdateTrapForm {
    const trapForm = _.cloneDeep(this.state.trapForm);
    if (this.state.trapEdit.fieldId != this.state.trapForm.fieldId) {
      this.generateMarkerId();
    }

    const trapIndex: TrapIndex = {
      customerId: this.applicationService.getCustomerId(),
      farmId: this.applicationService.getFarmId(),
      harvestId: this.applicationService.getHarvestId(),
      trapId: this.state.trapEdit.id,
    };
    const trapFormAfterEdit: UpdateTrapForm['trapData'] = {
      alias: trapForm.alias,
      pheromone: trapForm.pheromone,
      officialTrap: trapForm.officialTrap || null,
      trapCode: trapForm.trapCode || null,
      trapType: trapForm.trapType || null,
      fieldId: trapForm.fieldId || null,
      marker: trapForm.marker,
    };
    trapFormAfterEdit.marker = {
      id: trapForm.marker?.id || null,
      lat: trapForm.marker.lat,
      lng: trapForm.marker.lng,
    };

    let trapFormBeforeEdit = {
      alias: this.state.trapEdit.alias,
      fieldId: this.state.trapEdit.fieldId,
      marker: this.state.trapEdit.marker,
      pheromone: this.state.trapEdit.pheromone,
      officialTrap: this.state.trapEdit.officialTrap,
      trapType: this.state.trapEdit.trapType,
      trapCode: this.state.trapEdit.trapCode,
    };

    const trapDataEdit = this.formService.getDifferences(trapFormBeforeEdit, trapFormAfterEdit);
    return { ...trapIndex, trapData: trapDataEdit };
  }
}
