import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as userAction from './user.actions';
import { ApplicationService } from '../../services/application.service';
import { GenericFirestoreService } from '../../services/generic-firestore.service';
import { GenericHttpService } from '../../services/generic-http.service';
import { MyToastrService } from '../../services/toastr.service';
import { TranslateTypes } from '../../services/translation.service';
import { Farm, WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { UpdateUserForm } from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions';
import { Role } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private genericHttpService: GenericHttpService,
    private applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço para listar usuários.
   */
  public getUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userAction.GET_USERS),
      switchMap(() =>
        this.genericFirestoreService
          .getCombine<WebUser>(
            [
              {
                ref: `${this.applicationService.getUserRefDatabase()}`,
                config: {
                  queryFn: (ref: any) =>
                    ref
                      .where('role', '==', Role.admin)
                      // .where('customerId', '==', this.applicationService.getCustomerId())
                      .where('farmIds', 'array-contains', this.applicationService.getFarmId()),
                },
              },
              {
                ref: `${this.applicationService.getUserRefDatabase()}`,
                config: {
                  queryFn: (ref: any) =>
                    ref
                      .where('role', '==', Role.operator)
                      // .where('customerId', '==', this.applicationService.getCustomerId())
                      .where('farmIds', 'array-contains', this.applicationService.getFarmId()),
                },
              },
              {
                ref: `${this.applicationService.getUserRefDatabase()}`,
                config: {
                  queryFn: (ref: any) =>
                    ref
                      .where('role', '==', Role.reader)
                      // .where('customerId', '==', this.applicationService.getCustomerId())
                      .where('farmIds', 'array-contains', this.applicationService.getFarmId()),
                },
              },
            ],
            'name',
            'asc'
          )
          .pipe(
            map((users: Array<WebUser>) => userAction.GET_USERS_SUCCESS({ users })),
            catchError((error) => of(userAction.GET_USERS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar fazenda.
   */
  public getFarms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userAction.GET_FARMS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Farm>(`${this.applicationService.getCustomerRefDatabase()}/farms`)
          .pipe(
            map((farms: Array<Farm>) => {
              const farmIds = this.applicationService.getUser()?.farmIds;
              return userAction.GET_FARMS_SUCCESS({
                //@ts-ignore
                farms: farms.filter((farm: Farm) => farmIds?.includes(farm.id)),
              });
            }),
            catchError((error) => of(userAction.GET_FARMS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para cadastrar usuários.
   */
  public createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAction.CREATE_USER),
      mergeMap((action) => {
        return this.genericHttpService
          .create<WebUser>('createUser', action.user)
          .toPromise()
          .then(() => {
            this.toastrService.success(this.trans.services.create.userSuccess, {
              '%0': action.user.userData.name,
            });
            return userAction.CREATE_USER_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return userAction.CREATE_USER_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para atualizar usuários.
   */
  public updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAction.UPDATE_USER),
      mergeMap((action) => {
        return this.genericFirestoreService
          .update<UpdateUserForm>(`${this.applicationService.getUserRefDatabase()}`, action.user)
          .then(() => {
            this.toastrService.success(this.trans.services.update.userSuccess, {
              '%0': action.user.name,
            });
            return userAction.UPDATE_USER_SUCCESS();
          })
          .catch((error) => {
            console.log('error: ', error);
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return userAction.UPDATE_USER_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para remover usuários.
   */
  public removeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAction.REMOVE_USER),
      mergeMap((action) => {
        return this.genericHttpService
          .remove<WebUser>(
            `removeUser?ids=${action.ids}&userId=${this.applicationService.getUserId()}`
          )
          .toPromise()
          .then((resp: Array<{ id: string; code: string; status: string }>) => {
            resp.forEach((data: { id: string; code: string; status: string }) => {
              if (data.code.includes('success')) {
                this.toastrService.success(data.code);
              } else {
                this.toastrService.error(data.code);
              }
            });
            return userAction.REMOVE_USER_SUCCESS();
          })
          .catch((error: any) => {
            this.toastrService.error(this.trans.services.remove.userError);
            return userAction.REMOVE_USER_FAIL(error);
          });
      })
    )
  );
}
