import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { PHONE_MASK } from 'src/app/constants/mask';
import { Regex, regex } from 'src/app/constants/regex';
import {
  HIDE_MODAL,
  UPDATE_USER,
  UPDATE_USER_AND_PROFILE_IMAGE,
  UPDATE_USER_PASSWORD,
} from 'src/app/view/header/header.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { UserImage } from 'src/app/interfaces/User';
import { HeaderState, headerStateDefault } from '../header.state';
import { MyToastrService } from 'src/app/services/toastr.service';
import { CCC, Role } from '@tarvos-ag/tarvos-firestore-models/src/enums';

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss'],
})
export class AccountFormComponent implements OnInit {
  @ViewChild('form', { static: true }) public form!: NgForm;

  public headerState$!: Observable<HeaderState>;
  public state: HeaderState = headerStateDefault;
  public subscribe!: Subscription;
  public edit = false;
  public cols!: Observable<number | undefined>;
  public regex: Regex = regex;
  public enumCcc: any = CCC;
  public phoneMask: any = PHONE_MASK;

  constructor(
    private store: Store<any>,
    private toastrService: MyToastrService,
    private dialogRef: MatDialogRef<AccountFormComponent>,
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ngOnInit(): void {
    this.headerState$ = this.store.pipe(select('header'));
    this.subscribe = this.headerState$.subscribe((state: HeaderState) => {
      this.state = state;

      if (state && state.closeModal) {
        this.closeModal();
      }
    });
  }

  /**
   * Este método limpa o redux e o formulário, além de fechar a modal
   */
  public closeModal(): void {
    this.form.reset();
    this.store.dispatch(HIDE_MODAL());
    this.dialogRef.close();
  }

  /**
   * Este método cadastra ou edita
   */
  public update(): void {
    if (this.state.userEdit.image && this.state.userEdit.image.file) {
      this.store.dispatch(
        UPDATE_USER_AND_PROFILE_IMAGE({
          user: _.cloneDeep(this.state.userEdit),
        })
      );
    } else {
      this.store.dispatch(UPDATE_USER({ user: _.cloneDeep(this.state.userEdit) }));
    }
  }

  /**
   * ação para recuperar senha
   */
  public changePassword(): void {
    this.store.dispatch(UPDATE_USER_PASSWORD({ email: this.state.userEdit.email }));
  }

  /**
   * Este método executa a ação de click para abrir o modal para selecionar a imagem
   */
  public changeImage(): void {
    document.getElementById('userImage')?.click();
  }

  /**
   * Este método renderiza as imagens na tela
   * @param event evento com as imagens
   */
  public readerImage(event: any): void {
    const file = event?.target.files.item(0);
    if (file) {
      if (file.type.split('/')[0] !== 'image') {
        this.toastrService.warning(this.trans.text.accountReadFileNotSupported);
      } else {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          let image = {} as UserImage;
          image = {
            file,
            src: e.target.result,
          };
          this.state.userEdit.image = image;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  /**
   * Este método converte um enum em lista
   */
  public getEnumType(): Array<CCC> {
    return Object.values(CCC);
  }

  /**
   * Este método converte um enum em lista
   */
  public getEnumRole(avoidRoles?: String[]): Array<Role> | null {
    if (avoidRoles) return Object.values(Role).filter((item) => !avoidRoles.includes(item));
    return Object.values(Role);
  }
}
