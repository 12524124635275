import { Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const harvestStateDefault = {
  harvests: [],
  loading: 0,
  loadingRemove: 0,

  displayedColumns: ['select', 'name', 'status', 'actions'],
  displayedForms: ['name', 'currentYear', 'harvestFieldPeriodDate', 'harvestPercentage', 'status'],
  pageSizeOptions: [15],
} as HarvestState;

export interface HarvestState {
  harvests: Array<Harvest>;
  loading: number;
  loadingRemove: number;

  displayedColumns: Array<string>;
  displayedForms: Array<string>;
  pageSizeOptions: number[];
}
