import { Injectable } from '@angular/core';
import { CollectionReference } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  Device,
  Field,
  Glebe,
  Trap,
  ManufacturedTrap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import * as trapAction from './trap.actions';
import { ApplicationService } from '../../services/application.service';
import { GenericFirestoreService } from '../../services/generic-firestore.service';
import { GenericHttpService } from '../../services/generic-http.service';
import { MyToastrService } from '../../services/toastr.service';
import { TranslateTypes } from '../../services/translation.service';
import {
  UpdateTrapForm,
  CreateTrapForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/trap';

@Injectable()
export class TrapEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private genericHttpService: GenericHttpService,
    private applicationService: ApplicationService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço para listar armadilhas da safra em uso.
   */
  public getTraps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapAction.GET_TRAPS),
      switchMap(() => {
        return this.genericFirestoreService
          .getAll<Trap>(`${this.applicationService.getFarmRefDatabase()}/traps`, {
            queryFn: (ref: any) =>
              ref.where('harvestId', '==', this.applicationService.getHarvestId()),
          })
          .pipe(
            map((traps: Array<Trap>) => {
              const visibleTraps = traps.filter((trap: Trap) => trap.track?.visible !== false);
              return trapAction.GET_TRAPS_SUCCESS({ traps: visibleTraps });
            }),
            catchError((error) => of(trapAction.GET_TRAPS_FAIL(error)))
          );
      })
    );
  });

  /**
   * Este método chama um serviço para gerar o número de registro da nova armadilha.
   */
  public getLastCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapAction.GET_REGISTRATION_NUMBER),
      switchMap(() => {
        let lastCount;
        if (!this.applicationService.getFarm()?.collectionSettings?.trap?.lastCount) {
          return this.genericFirestoreService
            .getAll<number>(`${this.applicationService.getFarmRefDatabase()}/traps`)
            .pipe(
              map((traps: Array<any>) => {
                const registrationNumber =
                  traps
                    .filter((trap) => trap.registrationNumber)
                    .map((el) => el.registrationNumber)
                    .reduce((max, cur) => Math.max(max, cur), 0) + 1;
                return trapAction.GET_REGISTRATION_NUMBER_SUCCESS({
                  lastCount: registrationNumber,
                });
              }),
              catchError((error) => of(trapAction.GET_REGISTRATION_NUMBER_FAIL(error)))
            );
        } else {
          lastCount = this.applicationService.getFarm()?.collectionSettings?.trap?.lastCount
            ? this.applicationService.getFarm()!.collectionSettings!.trap!.lastCount + 1
            : 1;
          return of(
            trapAction.GET_REGISTRATION_NUMBER_SUCCESS({
              lastCount,
            })
          );
        }
      })
    );
  });

  /**
   * Este método chama um serviço para listar talhões.
   */
  public getFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapAction.GET_FIELDS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Field>(`${this.applicationService.getHarvestRefDatabase()}/fields`)
          .pipe(
            map((fields: Array<Field>) => trapAction.GET_FIELDS_SUCCESS({ fields })),
            catchError((error) => of(trapAction.GET_FIELDS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar glebas.
   */
  public getGlebe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapAction.GET_GLEBES),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Glebe>(`${this.applicationService.getHarvestRefDatabase()}/glebes`, {
            queryFn: (ref: CollectionReference) => ref.orderBy('name', 'asc'),
          })
          .pipe(
            map((glebes: Array<Glebe>) => trapAction.GET_GLEBES_SUCCESS({ glebes })),
            catchError((error) => of(trapAction.GET_GLEBES_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar modelos de armadilhas.
   */
  public getDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapAction.GET_DEVICES),
      switchMap(() =>
        this.genericFirestoreService.getAll<Device>('devices').pipe(
          map((devices: Array<Device>) => trapAction.GET_DEVICES_SUCCESS({ devices })),
          catchError((error) => {
            return of(trapAction.GET_DEVICES_FAIL(error));
          })
        )
      )
    );
  });

  /**
   * Este método chama um serviço para listar o ticket id das armadilhas do cliente.
   */
  public getManufacturedTraps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapAction.GET_MANUFACTURED_TRAPS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<ManufacturedTrap>(`manufacturedTraps`, {
            fieldPath: 'ticketId',
            queryFn: (ref: CollectionReference) =>
              ref.where('customerId', '==', this.applicationService.getCustomerId()),
          })
          .pipe(
            map((manufacturedTraps: Array<ManufacturedTrap>) => {
              return trapAction.GET_MANUFACTURED_TRAPS_SUCCESS({ manufacturedTraps });
            }),
            catchError((error) => {
              return of(trapAction.GET_MANUFACTURED_TRAPS_FAIL(error));
            })
          )
      )
    );
  });

  /**
   * Este método chama um serviço para cadastrar armadilha.
   */
  public createTrap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trapAction.CREATE_TRAP),
      mergeMap((action) => {
        return this.genericHttpService
          .create<CreateTrapForm>('createTrap', action.trap)
          .toPromise()
          .then(() => {
            this.toastrService.success(this.trans.services.create.trapSuccess, {
              '%0': action.trap.trapData.name,
            });
            return trapAction.CREATE_TRAP_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return trapAction.CREATE_TRAP_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para atualizar as trocas de piso da armadilha.
   */
  public patchTrap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trapAction.PATCH_TRAP),
      mergeMap((action) => {
        const updateTrapData = { ...action.trapForm };
        const traps = this.applicationService.getTraps();

        const actualTrap = traps.find((trap) => trap.id === action.trapForm.trapId);
        return this.genericHttpService
          .update<Trap>('updateTrap', { ...updateTrapData })
          .toPromise()
          .then(() => {
            const trapName = actualTrap?.name;
            this.toastrService.success(this.trans.services.update.trapSuccess, {
              '%0': trapName,
            });
            return trapAction.PATCH_TRAP_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return trapAction.PATCH_TRAP_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para atualizar armadilha.
   */
  public updateTrap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trapAction.UPDATE_TRAP),
      mergeMap((action) => {
        return this.genericHttpService
          .update<UpdateTrapForm>('updateTrap', action.trap)
          .toPromise()
          .then(() => {
            this.toastrService.success(this.trans.services.update.trapSuccess);
            return trapAction.UPDATE_TRAP_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return trapAction.UPDATE_TRAP_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para remover armadilha.
   */
  public removeTrap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trapAction.REMOVE_TRAP),
      mergeMap((action) => {
        return this.genericHttpService
          .remove(
            `removeTrap?customerId=${this.applicationService.getCustomerId()}&farmId=${this.applicationService.getFarmId()}&harvestId=${this.applicationService.getHarvestId()}&ids=${
              action.ids
            }`
          )
          .toPromise()
          .then((resp: Array<{ id: string; code: string; status: string }>) => {
            resp.forEach((data: { id: string; code: string; status: string }) => {
              if (data.code.includes('success')) {
                this.toastrService.success(data.code);
              } else {
                this.toastrService.error(data.code);
              }
            });
            return trapAction.REMOVE_TRAP_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return trapAction.REMOVE_TRAP_FAIL(error);
          });
      })
    )
  );
}
