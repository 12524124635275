import { animate, state, style, transition, trigger } from '@angular/animations';
import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import {
  DESTROY_COMPONENT,
  GET_HARVESTS,
  REMOVE_HARVEST,
} from 'src/app/view/harvest/harvest.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { RemoveModalComponent } from '../components/remove-modal/remove-modal.component';
import { CustomTableDirective } from '../utils/custom-table/custom-table';
import { HarvestFormComponent } from './harvest-form/harvest-form.component';
import { HarvestState, harvestStateDefault } from './harvest.state';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { Operation } from 'src/app/enums/Operation';
import { Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Component({
  selector: 'app-harvest',
  templateUrl: './harvest.component.html',
  styleUrls: ['./harvest.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class HarvestComponent extends CustomTableDirective implements OnInit, OnDestroy {
  public harvestState$: Observable<HarvestState>;
  public subscribe!: Subscription;
  public harvestSelected!: string | null;
  public subscribeDataSharing!: Subscription;
  public state: HarvestState = harvestStateDefault;
  public operation = Operation;

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<HarvestComponent>,
    public applicationService: ApplicationService,
    private decimalPipe: DecimalPipe,
    public trans: TranslateTypes,
    private labelPipe: LabelPipe
  ) {
    super();
    this.harvestState$ = this.store.pipe(select('harvestState'));
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    this.subscribe = this.harvestState$.subscribe((harvestState: HarvestState) => {
      this.state = harvestState;
      if (this.state && this.state.harvests) {
        this.harvestSelected = this.applicationService.getHarvestId();
        this.setTableSetting();
      }
    });

    this.subscribeDataSharing = this.applicationService.updateComponentData.subscribe((key) => {
      if (key === HarvestComponent.name) {
        this.store.dispatch(GET_HARVESTS());
      }

      if (key === 'create-harvest') {
        this.openDialog(false, null);
      }
    });

    this.store.dispatch(GET_HARVESTS());
  }

  /**
   * Este método é executado quando o componente é destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.subscribeDataSharing.unsubscribe();
    this.store.dispatch(DESTROY_COMPONENT());
  }

  /**
   * Este método abre a modal para cadastrar ou editar
   * @param edit verifica se é uma edição
   * @param harvest data
   */
  public openDialog(edit: boolean, harvest: Harvest | null): void {
    this.dialog.open(HarvestFormComponent, {
      disableClose: true,
      panelClass: ['material-dialog-panel', 'material-dialog-panel-800'],
      data: { edit, harvestId: edit ? harvest?.id : null },
    });
  }

  /**
   * Este método fecha uma modal
   */
  public hideModalList(): void {
    this.dialogRef.close();
  }

  /**
   * Este método abre uma modal de confirmação para remover N itens
   * @param ids lista de ids
   */
  public removeItems(itemIds: Array<any>): void {
    const dialogRef = this.dialog.open(RemoveModalComponent, {
      disableClose: true,
      width: 'material-dialog-panel-500',
      data: {
        itemIds,
        title:
          itemIds.length > 1
            ? this.labelPipe.transform(this.trans.title.removeHarvests)
            : this.labelPipe.transform(this.trans.title.removeHarvest),
        message:
          itemIds.length > 1
            ? this.labelPipe.transform(this.trans.text.removeAllHarvest)
            : this.labelPipe.transform(this.trans.text.removeHarvest),
      },
    });

    const subs = dialogRef.afterClosed().subscribe((results) => {
      if (results) {
        const ids = results.map((result: any) => result.id);
        this.store.dispatch(REMOVE_HARVEST({ ids }));
      }
      subs.unsubscribe();
    });
  }

  /**
   * Este método define as configurações iniciais da tabela
   * @param state Estado do componente
   */
  public setTableSetting(): void {
    const harvests = _.cloneDeep(this.state.harvests);
    this.dataSource = new MatTableDataSource<Harvest>(
      harvests.map((harvest: any) => {
        harvest.currentYear = moment(harvest.currentYear.toDate()).format(
          this.translateService.instant(this.trans.text.dateYear)
        );
        harvest.harvestPercentage = harvest.harvestPercentage
          ? `${this.decimalPipe.transform(harvest.harvestPercentage, '.2-2')}%`
          : '';
        harvest.status = this.translateService.instant(this.trans.enums[harvest.status]);
        harvest.harvestFieldPeriodDate = `${
          harvest.initialHarvestDate
            ? moment(harvest.initialHarvestDate.toDate()).format(
                this.translateService.instant(this.trans.text.date)
              )
            : ''
        }${
          harvest.finalHarvestDate
            ? ` - ${moment(harvest.finalHarvestDate.toDate()).format(
                this.translateService.instant(this.trans.text.date)
              )}`
            : ''
        }`;
        return harvest;
      })
    );
    this.initTable(this.applicationService.filterColumnByPermission(this.state.displayedColumns));
  }

  /**
   * @Override
   * Este método verifica Se o número de elementos selecionados corresponde ao número total de linhas.
   */
  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.filter(
      (x: any) => x.id !== this.harvestSelected
    ).length;
    const numRows = this.dataSource.filteredData.filter(
      (x: any) => x.id !== this.harvestSelected
    ).length;
    return numSelected === numRows;
  }

  /**
   * @Override
   * Seleciona todas as linhas se elas não estiverem todas selecionadas; Ou seleciona linha por linha
   */
  public masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          if (row.id !== this.harvestSelected) {
            this.selection.select(row);
          }
        });
  }
}
