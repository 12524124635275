export const placeholder = {
  alias: 'Apelido',
  allotment: 'Lote',
  area: 'Área',
  city: 'Cidade',
  country: 'País',
  cropName: 'Nome da cultura',
  cropVarieties: 'Variedade da cultura',
  deviceId: 'Id do dispositivo',
  district: 'Perímetro irrigado',
  email: 'E-mail',
  finishAfter: 'Terminar após',
  glebeAlias: {
    default: 'Apelido da Gleba',
    productionUnit: 'Apelido da Unidade de Produção',
    productiveUnit: 'Apelido da Unidade Produtiva',
    type: 'glebe',
  },
  govCode: 'Propriedade',
  installationDate: 'Data de instalação',
  kmzFile: 'Kmz',
  lat: 'Latitude',
  linesSpacing: 'Distância entre linhas',
  lng: 'Longitude',
  name: 'Nome',
  numberOfPoints: 'Número de Pontos de Coleta',
  officialTrap: 'Código oficial',
  optional: 'Opcional',
  pheromone: 'Atrativo',
  phone: 'Telefone',
  pointSpacing: 'Distância entre pontos',
  productiveUnit: 'Unidade produtiva',
  program: 'Programa',
  radius: 'Raio',
  repeatEvery: 'Repetir a cada',
  search: 'Pesquisar...',
  sector: 'Setor',
  selectTheFarm: {
    default: 'Selecionar fazenda',
    productiveUnit: 'Selecionar unidade produtiva',
    type: 'farm',
  },
  state: 'Estado',
  trapCode: 'Código Tarvos',
  trapType: 'Tipo da armadilha',
  typeTheCompany: 'Digite o nome da empresa',
  typeTheEmail: 'Digite o e-mail',
  typeTheEndDate: 'Digite a data final',
  typeTheFarmName: {
    default: 'Digite o nome da fazenda',
    productiveUnit: 'Digite o nome da unidade produtiva',
    type: 'farm',
  },
  typeTheLat: 'Digite a latitude',
  typeTheLng: 'Digite a longitude',
  typeTheName: 'Digite o nome',
  typeThePassword: 'Digite a senha',
  typeTheStartDate: 'Digite a data inicial',
  workRoute: 'Rota de trabalho',
};
