import { createAction, props } from '@ngrx/store';

/**
 * Obter
 */
export const GET_TERMS_OF_USE_URL = createAction('[Home Page] GET_TERMS_OF_USE_URL');
export const GET_TERMS_OF_USE_URL_SUCCESS = createAction(
  '[Home Page] GET_TERMS_OF_USE_URL_SUCCESS',
  props<{ termsOfUseUrl: string }>()
);
export const GET_TERMS_OF_USE_URL_FAIL = createAction(
  '[Home Page] GET_TERMS_OF_USE_URL_FAIL',
  props<{ error: any }>()
);

export const GET_PRIVACY_POLICY_URL = createAction('[Home Page] GET_PRIVACY_POLICY_URL');
export const GET_PRIVACY_POLICY_URL_SUCCESS = createAction(
  '[Home Page] GET_PRIVACY_POLICY_URL_SUCCESS',
  props<{ privacyPolicyUrl: string }>()
);
export const GET_PRIVACY_POLICY_URL_FAIL = createAction(
  '[Home Page] GET_PRIVACY_POLICY_URL_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const DESTROY_COMPONENT = createAction('[Home Page] DESTROY_COMPONENT');
