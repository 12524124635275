export const others = {
  backend: {
    'farm.create.exists': {
      default: 'Já existe uma fazenda com este nome.',
      productiveUnit: 'Já existe uma unidade produtiva com este nome.',
      type: 'farm',
    },
    'farm.remove.success': {
      default: 'A fazenda foi removida com sucesso.',
      productiveUnit: 'A unidade produtiva foi removida com sucesso.',
      type: 'farm',
    },
    'farm.remove.users': {
      default: 'A fazenda está vinculada a um ou mais usuários e não pode ser removida no momento.',
      productiveUnit:
        'A unidade produtiva está vinculada a um ou mais usuários e não pode ser removida no momento.',
      type: 'farm',
    },
    'field.remove.success': {
      default: 'O talhão foi removido com sucesso.',
      lot: 'O lote foi removido com sucesso.',
      type: 'field',
    },
    'field.remove.generic': {
      default: 'Erro ao remover o talhão.',
      lot: 'Erro ao remover o lote.',
      type: 'field',
    },
    'field.remove.traps': {
      default: 'O talhão está vinculado a uma armadilha e não pode ser removido no momento.',
      lot: 'O lote está vinculado a uma armadilha e não pode ser removido no momento.',
      type: 'field',
    },
    'field.remove.trap_reports': {
      default:
        'O talhão está vinculado a um relatório de armadilha e não pode ser removido no momento.',
      lot: 'O lote está vinculado a um relatório de armadilha e não pode ser removido no momento.',
      type: 'field',
    },

    'field.create.exists': {
      default: 'Já existe um talhão com este nome.',
      lot: 'Já existe um lote com este nome.',
      type: 'field',
    },
    'field.create.generic': {
      default: 'Erro ao cadastrar o talhão.',
      lot: 'Erro ao cadastrar o lote.',
      type: 'field',
    },
    'field.create.success': {
      default: 'Erro ao cadastrar o talhão.',
      lot: 'Erro ao cadastrar o lote.',
      type: 'field',
    },
    'field.create.area': {
      default: 'O talhão não pode ser cadastrado pois a área limite foi atingida.',
      lot: 'O lote não pode ser cadastrado pois a área limite foi atingida.',
      type: 'field',
    },
    'field.update.exists': {
      default: 'Já existe um talhão com este apelido.',
      lot: 'Já existe um talhão com este apelido.',
      type: 'field',
    },
    'glebe.create.exists': {
      default: 'Já existe uma gleba com este nome.',
      productionUnit: 'Já existe uma unidade de produção com este nome.',
      productiveUnit: 'Já existe uma unidade produtiva com este nome.',
      type: 'glebe',
    },
    'glebe.update.exists': {
      default: 'Já existe uma gleba com este apelido.',
      productionUnit: 'Já existe uma unidade de produção com este apelido.',
      productiveUnit: 'Já existe uma unidade produtiva com este apelido.',
      type: 'glebe',
    },
    'glebe.create.generic': {
      default: 'Erro ao cadastrar a gleba.',
      productionUnit: 'Erro ao cadastrar a unidade de produção.',
      productiveUnit: 'Erro ao cadastrar a unidade produtiva.',
      type: 'glebe',
    },
    'glebe.create.success': {
      default: 'A gleba foi cadastrado com sucesso.',
      productionUnit: 'A unidade de produção foi cadastrado com sucesso.',
      productiveUnit: 'A unidade produtiva foi cadastrado com sucesso.',
      type: 'glebe',
    },
    'glebe.remove.success': {
      default: 'A gleba foi removida com sucesso.',
      productionUnit: 'A unidade de produção foi removida com sucesso.',
      productiveUnit: 'A unidade produtiva foi removida com sucesso.',
      type: 'glebe',
    },
    'glebe.remove.generic': {
      default: 'Erro ao remover a gleba.',
      productionUnit: 'Erro ao remover a unidade de produção.',
      productiveUnit: 'Erro ao remover a unidade produtiva.',
      type: 'glebe',
    },
    'glebe.remove.fields': {
      default: 'A gleba está vinculada a um talhão e não pode ser excluída no momento.',
      productionUnit:
        'A unidade de produção está vinculada a um talhão e não pode ser excluída no momento.',
      productiveUnit:
        'A unidade produtiva está vinculada a um talhão e não pode ser excluída no momento.',
      type: 'glebe',
    },
    'trap.remove.success': 'A armadilha foi removida com sucesso.',
    'trap.remove.generic': 'Erro ao remover a armadilha.',
    'trap.remove.traps': 'A armadilha está vinculada a uma solicitação e não pode ser removida.',
    'trap.create.success': 'A armadilha foi cadastrada com sucesso.',
    'trap.create.generic': 'Erro ao cadastrar a armadilha.',
    'trap.create.identifier': 'Já existe uma armadilha com este identificador',
    'trap.create.name': 'Já existe uma armadilha com este nome {{%0}}.',
    'trap.create.owntrap': 'A armadilha não está vinculada à sua fazenda.',
    'trap.create.exists': 'Já existe uma armadilha com este id cadastrado.',
    'request.remove.generic': 'Erro ao cadastrar as solicitações.',
    'request.remove.success': 'A solicitação foi removida com sucesso.',
    'request.create.success': 'A solicitação foi cadastrada com sucesso.',
    'report.create.generic': 'O token deste relatório está inválido.',
    'user.remove.success': 'O usuário foi removido com sucesso.',
    'user.remove.generic': 'Erro ao remover o usuário.',
    'userservice.removeuser.ownuser': 'Erro ao remover o usuário.',
    'userservice.removeuser.nopermission': 'Seu usuário não tem permissão para remover.',
    'userservice.validation.user.alreadyexist': 'Já existe um usuário com este e-mail.',
    'userservice.validation.customer.notfound': 'Cliente não existe na base de dados.',
    'userservice.validation.user.farms':
      'É necessário enviar a lista de fazendas a ser atreladas ao usuário.',
    'kmzhelper.import.generic': 'Falha ao importar arquivo KMZ.',
    'harvest.create.generic': {
      default: 'Erro ao cadastrar a safra.',
      year: 'Erro ao cadastrar o ano.',
      cycle: 'Erro ao cadastrar o ciclo.',
      type: 'harvest',
    },
    'harvest.create.success': {
      default: 'A safra foi cadastrada com sucesso.',
      year: 'O ano foi cadastrada com sucesso.',
      cycle: 'O ciclo foi cadastrada com sucesso.',
      type: 'harvest',
    },
    'harvest.remove.success': {
      default: 'A safra foi removida com sucesso.',
      year: 'O ano foi removida com sucesso.',
      cycle: 'O ciclo foi removida com sucesso.',
      type: 'harvest',
    },
    'harvest.remove.generic': {
      default: 'Erro ao remover a safra.',
      year: 'Erro ao remover o ano.',
      cycle: 'Erro ao remover o ciclo.',
      type: 'harvest',
    },
    'harvest.remove.fields': {
      default: 'A safra está vinculada a um talhão e não pode ser excluída no momento.',
      year: 'O ano está vinculada a um talhão e não pode ser excluída no momento.',
      cycle: 'O ciclo está vinculada a um talhão e não pode ser excluída no momento.',
      type: 'harvest',
    },
    'harvest.remove.glebes': {
      default: 'A safra está vinculada a uma gleba e não pode ser excluída no momento.',
      year: 'O ano está vinculada a uma gleba e não pode ser excluída no momento.',
      cycle: 'O ciclo está vinculada a uma gleba e não pode ser excluída no momento.',
      type: 'harvest',
    },
    'updatePasswordService.token.invalid': 'Este token não é válido.',
    'updatePasswordService.token.expired': 'Este token expirou.',
    'updatePasswordService.token.exceed_limit': 'Este token excedeu o limite de tentativas.',
    'updatePasswordService.token.token_already_used': 'O token já foi usado.',
    'recoverPasswordService.user.notfound': 'Cliente não existe na base de dados.',

    'title-infestation-detected': 'Infestação detectada',
    'title-wrong-trap-detected': 'Existe outra armadilha com o mesmo identificador já ativada',
    'title-report-completed': 'Relatório concluído',
    'title-report-not-found-data': 'Erro ao gerar relatório',
    'title-request-schedule': 'Solicitação programada',
    'title-wrong-phenological-status': 'Estádio fenológico',
    'title-trap-not-monitoring': 'Armadilha não posicionada',
    'title-fill-factor-is-high': 'Fator de enchimento',
    'title-report-not-found-date': 'Relatório sem monitoramento',
    'title-report-date': 'Relatório periódico',
    'message-fill-factor-is-high': 'A armadilha {{ %0 }} está com fator de enchimento ALTO',
    'title-wrong-phenological-desc':
      'A relatório da missão %0 reportou um estádio fenológico abaixo do esperado',
    'message-trap-not-monitoring':
      'A armadilha {{ %0 }} não posicionada no sistema tentou comunicar',
    'message-request-schedule': 'A solicitação {{ %0 }} foi gerada a partir de uma programação.',
    'message-wrong-trap-detected': 'A armadilha {{ %0 }} não pode ser atualizada.',
    'message-infestation-detected':
      'A missão {{%0}} contém níveis de infestação acima dos níveis de controle. Ocorrências: {{%1occurrences}}.',
    'message-report-traps-completed': 'O relatório de armadilhas {{%0}} foi concluído.',
    'message-report-traps-not-found-data':
      'O relatório de armadilhas {{%0}} não apresenta dados para os filtros selecionados.',
    'message-report-not-found-date':
      'O relatório {{%0}} não possui monitoramento para o período entre {{%1period}}.',
    'message-report-date': 'O relatório {{%0}} foi gerado para o período entre {{%1period}}.',
    'harvest.create.exists': 'Já existe uma safra com o mesmo período da safra {{%0}}.',
  },

  'tarvos.unauthorized': 'Usuário não autorizado.',
  'tarvos.forbidden': 'Usuário não tem a permissão necessária.',
};
