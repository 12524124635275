import { GmConfiguration } from 'src/app/interfaces/GmConfiguration';
import { LevelExceededChart } from 'src/app/interfaces/LevelExceededChart';
import {
  EvolutionChart,
  InfestationEvolutionChart,
  MovingAverageChart,
  Field,
  TotalBetweenFieldsChart,
  Glebe,
  Trap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { MadChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Mad';
import { ClimateChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/ClimateChart';
import { AccumulatedChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/AccumulatedChart';
import { SoilChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const gmTabsDefault = {
  initComponent: false,

  gm: {} as GmConfiguration,
  fields: [],
  glebes: [],
  traps: [],
  accumulatedChart: null,
  climateChart: [],
  evolutionChart: null,
  infestationEvolutionChart: null,
  levelExceededChart: [],
  madChart: [],
  movingAverageChart: null,
  soilChart: [],
  totalBetweenFieldsChart: null,

  updateClimateChart: false,
  updateEvolutionChart: false,
  updateInfestationEvolutionChart: false,
  updateLevelExceededChart: false,
  updateMadChart: false,
  updateSoilChart: false,
  updateTotalBetweenFieldsChart: false,

  loadingAccumulatedChart: 0,
  loadingClimateChart: 0,
  loadingEvolutionChart: 0,
  loadingInfestationEvolutionChart: 0,
  loadingLevelExceededChart: 0,
  loadingMadChart: 0,
  loadingMovingAverageChart: 0,
  loadingSoilChart: 0,
  loadingTotalBetweenFieldsChart: 0,
} as GmTabsState;

export interface GmTabsState {
  initComponent: boolean;

  gm: GmConfiguration;
  fields: Array<Field>;
  glebes: Array<Glebe>;
  traps: Array<Trap>;

  accumulatedChart: AccumulatedChart | null;
  climateChart: Array<ClimateChart>;
  evolutionChart: EvolutionChart | null;
  infestationEvolutionChart: InfestationEvolutionChart | null;
  levelExceededChart: Array<LevelExceededChart>;
  madChart: Array<MadChart>;
  movingAverageChart: MovingAverageChart | null;
  soilChart: Array<SoilChart>;
  totalBetweenFieldsChart: TotalBetweenFieldsChart | null;

  updateClimateChart: boolean;
  updateEvolutionChart: boolean;
  updateInfestationEvolutionChart: boolean;
  updateLevelExceededChart: boolean;
  updateMadChart: boolean;
  updateSoilChart: boolean;
  updateTotalBetweenFieldsChart: boolean;

  loadingAccumulatedChart: number;
  loadingClimateChart: number;
  loadingEvolutionChart: number;
  loadingInfestationEvolutionChart: number;
  loadingLevelExceededChart: number;
  loadingMadChart: number;
  loadingMovingAverageChart: number;
  loadingSoilChart: number;
  loadingTotalBetweenFieldsChart: number;
}
