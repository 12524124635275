import { button } from './button';
import { errors } from './errors';
import { label } from './label';
import { placeholder } from './placeholder';

export const components = {
  button,
  label,
  placeholder,
  errors,
};
