import { createAction, props } from '@ngrx/store';
import { Reap } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

/**
 * Obter
 */
export const GET_TRAP_LINKED_TO_FIELD = createAction(
  '[Modal Reap Page] GET_TRAP_LINKED_TO_FIELD',
  props<{ id: string }>()
);
export const GET_TRAP_LINKED_TO_FIELD_SUCCESS = createAction(
  '[Modal Reap Page] GET_TRAP_LINKED_TO_FIELD_SUCCESS',
  props<{ trapLinkedToField: number }>()
);
export const GET_TRAP_LINKED_TO_FIELD_FAIL = createAction(
  '[Modal Reap Page] GET_TRAP_LINKED_TO_FIELD_FAIL',
  props<{ error: any }>()
);

export const GET_REPORT_LINKED_TO_FIELD = createAction(
  '[Modal Reap Page] GET_REPORT_LINKED_TO_FIELD',
  props<{ id: string }>()
);
export const GET_REPORT_LINKED_TO_FIELD_SUCCESS = createAction(
  '[Modal Reap Page] GET_REPORT_LINKED_TO_FIELD_SUCCESS',
  props<{ reportLinkedToField: number }>()
);
export const GET_REPORT_LINKED_TO_FIELD_FAIL = createAction(
  '[Modal Reap Page] GET_REPORT_LINKED_TO_FIELD_FAIL',
  props<{ error: any }>()
);

export const UPDATE_HARVEST_FIELD = createAction(
  '[Modal Reap Page] UPDATE_HARVEST_FIELD',
  props<{ reap: Reap; fieldName: string }>()
);
export const UPDATE_HARVEST_FIELD_SUCCESS = createAction(
  '[Modal Reap Page] UPDATE_HARVEST_FIELD_SUCCESS'
);
export const UPDATE_HARVEST_FIELD_FAIL = createAction(
  '[Modal Reap Page] UPDATE_HARVEST_FIELD_FAIL',
  props<{ error: any }>()
);

/**
 * Outros
 */
export const HIDE_MODAL = createAction('[Modal Reap Page] HIDE_MODAL');
