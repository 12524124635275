export const errors = {
  required: {
    alias: 'O campo apelido é obrigatório',
    area: 'O campo área é obrigatório',
    ccc: 'O campo ccc é obrigatório',
    column: 'O campo colunas é obrigatório',
    communicationId: 'O campo identificador é obrigatório',
    contractedPeriod: 'O campo período do contrato é obrigatório',
    crop: 'O campo cultura é obrigatório',
    currentYear: 'O campo ano é obrigatório',
    customFieldReport: 'O campo relatório de visita é obrigatório',
    device: 'O campo dispositivo é obrigatório',
    email: 'O campo e-mail é obrigatório',
    endByDate: 'O campo "terminar após" é obrigatório',
    endByQuantity: 'O campo "terminar "após" é obrigatório',
    endDate: 'O campo data final é obrigatório',
    fallowingUntil: 'O campo período de pousio é obrigatório',
    farm: {
      default: 'O campo fazenda é obrigatório',
      productiveUnit: 'O campo Unidade Produtiva é obrigatório',
      type: 'farm',
    },
    field: {
      default: 'O campo talhão é obrigatório',
      lot: 'O campo lote é obrigatório',
      type: 'field',
    },
    frequency: 'O campo frequência é obrigatório',
    glebe: {
      default: 'O campo gleba é obrigatório',
      productionUnit: 'O campo Unidade de Produção é obrigatório',
      productiveUnit: 'O campo Unidade Produtiva é obrigatório',
      type: 'glebe',
    },
    harvest: {
      default: 'O campo safra é obrigatório',
      year: 'O campo ano é obrigatório',
      cycle: 'O campo ciclo é obrigatório',
      type: 'harvest',
    },
    harvestPeriod: {
      default: 'O campo período da safra é obrigatório',
      year: 'O campo período do ano é obrigatório',
      cycle: 'O campo período do ciclo é obrigatório',
      type: 'harvest',
    },
    infestationLevel: 'O campo com o nível de infestação é obrigatório',
    reportFormat: 'O campo com o formato do relatório é obrigatório',
    customerName: 'O campo nome do cliente é obrigatório',
    supportIds: '  O campo usuários de suporte é obrigatório',
    kmzFile: 'O campo kmz é obrigatório',
    lat: 'O campo latitude é obrigatório',
    lng: 'O campo longitude é obrigatório',
    manager: 'O campo gestor responsável é obrigatório',
    name: 'O campo nome é obrigatório',
    numberOfPoints: 'O campo número de pontos de coleta é obrigatório',
    occurrence: 'O campo ocorrência é obrigatório',
    occurrenceIds: 'O campo ocorrências é obrigatório',
    offset: 'O campo distância entre pontos é obrigatório',
    password: 'O campo senha é obrigatório',
    period: 'O campo período é obrigatório',
    pheromone: 'O campo atrativo é obrigatório',
    phone: 'O campo telefone é obrigatório',
    polygon: 'Desenhar um polígono é obrigatório',
    radius: 'O campo raio é obrigatório',
    repeat: 'O campo repetir é obrigatório',
    role: 'O campo cargo é obrigatório',
    startDate: 'O campo data inicial é obrigatório',
    status: 'O campo status é obrigatório',
    ticketId: 'O campo id do dispositivo é obrigatório',
    trap: 'O campo armadilha é obrigatório',
    trapControlLevel: 'O campo é obrigatório',
    type: 'O campo tipo é obrigatório',
    users: 'O campo "enviar relatório para" é obrigatório',
    varieties: 'O campo variedades da cultura é obrigatório',
  },

  minlength: {
    alias: 'O apelido deve ter pelo menos 2 caracteres',
    controlLevel: 'A cultura deve ter pelo menos 2 caracteres',
    country: 'O campo país deve ter pelo menos 2 caracteres',
    email: 'O e-mail deve ter pelo menos 8 caracteres',
    farm: {
      default: 'O campo fazenda deve ter pelo menos 2 caracteres',
      productiveUnit: 'O campo unidade produtiva deve ter pelo menos 2 caracteres',
      type: 'farm',
    },
    name: 'O nome deve ter pelo menos 2 caracteres',
    password: 'A senha deve ter pelo menos 8 caracteres',
    state: 'O campo estado deve ter pelo menos 2 caracteres',
    varieties: 'A variedade da cultura deve ter pelo menos 2 caracteres',
    customerName: 'O campo nome do cliente deve ter pelo menos 2 caracteres',
  },

  pattern: {
    alias: 'O formato do apelido é inválido',
    area: 'O formato da área é invalido',
    country: 'O formato do país é invalido',
    email: 'O formato do e-mail é inválido. Ex: exemplo@exemplo.com',
    endByQuantity: 'O campo quantidade suporta somente valores inteiros',
    farm: {
      default: 'O formato do fazenda é invalido',
      productiveUnit: 'O formato do unidade produtiva é invalido',
      type: 'farm',
    },
    frequency: 'O campo frequência suporta somente valores inteiros',
    lat: 'O formato da latitude é inválido. Ex: 00.000000',
    lng: 'O formato da longitude é inválido. Ex: 00.000000',
    name: 'O formato do nome é inválido',
    numberOfPoints: 'O campo número de pontos de coleta suporta somente valores inteiros',
    offset: 'O formato da distância entre pontos é invalido',
    password: 'Sua senha está fraca',
    radius: 'O formato da raio é inválido',
    state: 'O formato do estado é invalido',
    trapControlLevel: 'O campo suporta somente valores em porcentagem',
    varieties: 'O formato da variedade da cultura é inválido',
  },

  mask: {
    phone: {
      brazil: 'O formato do telefone é inválido. Ex: (00) 00000-0000',
      paraguay: 'O formato do telefone é inválido. Ex: 000 000 000',
    },
  },

  matDatepickerMin: {
    currentYear: 'O ano não pode ser anterior ao ano atual',
    endByDate: 'A data final é menor que a data inicial',
    endDate: 'A data final é menor que a data inicial',
    startDate: 'A data de início não pode ser anterior à data atual',
  },

  max: {
    endByQuantity: 'O campo suporta valores de 1 à 100',
    frequency: 'O campo suporta valores de 1 à 100',
    numberOfPoints: 'O campo suporta valores de 1 à 100',
  },

  min: {
    endByQuantity: 'O campo suporta valores de {{value}} à 100',
    frequency: 'O campo suporta valores de 1 à 100',
    numberOfPoints: 'O campo suporta valores de 1 à 100',
  },

  listIsEmpty: {
    occurrences: 'Pelo menos uma ocorrência deve ser selecionada',
  },

  whiteSpace: 'O campo possui espaço em branco, remova',

  isNotPercentage: {
    trapControlLevel: 'O campo suporta valores entre 1 e 100',
  },

  isNotUnd: {
    trapControlLevel: 'O campo suporta valores inteiros entre 0 e 500',
  },

  isNotOccurrenceByMeters: {
    trapControlLevel: 'O campo suporta valores entre 0 e 500',
  },

  isNotFileKmz: {
    kmzFile: 'O arquivo selecionado não é do tipo kmz',
  },
};
