import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NameAndId } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EffectsServices } from 'src/app/services/effects.service';
import { GenericHttpService } from '../../services/generic-http.service';
import { TranslateTypes } from '../../services/translation.service';
import * as updateUserAction from './update-user.action';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UpdateUserEffects {
  constructor(
    private effectServices: EffectsServices,
    private actions$: Actions,
    private genericHttpService: GenericHttpService,
    private translateService: TranslateService,
    public trans: TranslateTypes
  ) {}

  public getCustomers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateUserAction.GET_CUSTOMERS),
      switchMap(() => {
        return this.genericHttpService
          .getById<{ data: { customers: NameAndId[] } }>('graphql?query={customers{name,id}}')
          .pipe(
            map((res: { data: { customers: NameAndId[] } }) =>
              updateUserAction.GET_CUSTOMERS_SUCCESS({ customers: res.data.customers })
            ),
            catchError((error) =>
              this.effectServices.handleError(
                this.translateService.instant(this.trans.services.get.getCustomersFarmsError),
                updateUserAction.GET_CUSTOMERS_FAIL(error)
              )
            )
          );
      })
    );
  });
  public getCustomerFarms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateUserAction.GET_CUSTOMER_FARMS),
      switchMap((action) => {
        return this.genericHttpService
          .getById<{ data: { customerFarms: NameAndId[] } }>(
            `graphql?query={customerFarms(customerId:"${action.customerId}"){id, name}}`
          )
          .pipe(
            map((res: { data: { customerFarms: NameAndId[] } }) => {
              return updateUserAction.GET_CUSTOMERS_FARMS_SUCCESS({
                farms: res.data.customerFarms,
              });
            }),
            catchError((error) => {
              return this.effectServices.handleError(
                this.translateService.instant(this.trans.services.get.getCustomersFarmsError),
                updateUserAction.GET_CUSTOMERS_FARMS_FAIL(error)
              );
            })
          );
      })
    );
  });
  public getSupportUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateUserAction.GET_SUPPORT_USERS),
      switchMap(() => {
        return this.genericHttpService
          .getById<{ data: { supportUsers: NameAndId[] } }>('graphql?query={supportUsers{name,id}}')
          .pipe(
            map((res: { data: { supportUsers: NameAndId[] } }) => {
              return updateUserAction.GET_SUPPORT_USERS_SUCCESS({ users: res.data.supportUsers });
            }),
            catchError((error) => {
              return this.effectServices.handleError(
                this.translateService.instant(this.trans.services.get.supportUsersError),
                updateUserAction.GET_SUPPORT_USERS_FAIL(error)
              );
            })
          );
      })
    );
  });
  public postUpdateUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateUserAction.POST_UPDATE_USERS_CUSTOMERS),
      switchMap((action) => {
        return this.genericHttpService
          .update('updateUsersCustomers', {
            usersIds: action.usersIds,
            customerId: action.customerId,
            customerName: action.customerName,
            farmsIds: action.farmsIds.map((farmId) => farmId),
          })
          .pipe(
            map(() => {
              return this.effectServices.handleSuccess(
                this.translateService.instant(this.trans.services.update.usersSuccess),
                updateUserAction.UPDATE_USERS_CUSTOMERS_SUCCESS()
              );
            }),
            catchError((error) => {
              return this.effectServices.handleError(
                this.translateService.instant(this.trans.services.update.usersError),
                updateUserAction.POST_UPDATE_USERS_CUSTOMERS_FAIL()
              );
            })
          );
      })
    );
  });

  // this.toastrService
}
