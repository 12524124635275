<agm-map
  [latitude]="latitude"
  [longitude]="longitude"
  [zoom]="zoom"
  [style.height]="height"
  [style.width]="width"
>
  <ng-container *ngIf="routeSegments && routeSegments.length > 0">
    <ng-container *ngFor="let segment of routeSegments; let i = index; trackBy: trackByIndex">
      <agm-polyline [strokeColor]="getColor(i)" [strokeOpacity]="1.0" [strokeWeight]="4">
        <agm-polyline-point
          *ngFor="let point of segment; trackBy: trackByIndex"
          [latitude]="point.lat"
          [longitude]="point.lng"
        >
        </agm-polyline-point>
      </agm-polyline>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="markers && markers.length > 0">
    <agm-marker
      *ngFor="let marker of markers; trackBy: trackByMarker"
      [latitude]="marker.lat"
      [longitude]="marker.lng"
      [label]="marker.label || ''"
      [iconUrl]="marker.iconUrl || ''"
    >
    </agm-marker>
  </ng-container>
</agm-map>
