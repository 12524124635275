import { FormArray, Validators } from '@angular/forms';
import { OccurrenceSettings } from 'src/app/interfaces/OccurrenceSettings';
import { CustomValidators } from '../../utils/custom-validators/custom-validators';
import { Crop } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const occurrenceSettingsStateDefault = {
  occurrenceSettings: {} as OccurrenceSettings,
  crops: [],
  loading: 0,
  displayedColumnsModal: ['name', 'cropIds'],
} as OccurrenceSettingsState;

export interface OccurrenceSettingsState {
  occurrenceSettings: OccurrenceSettings;
  crops: Array<Crop>;
  loading: number;
  displayedColumnsModal: Array<string>;
}

export const firstFormGroup = {
  id: [null],
  customControlLevels: new FormArray([], [Validators.required, CustomValidators.listIsEmpty]),
} as any;
