<app-progress-bar [isOpen]="state.loadingSoilChart"></app-progress-bar>

<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 mat-dialog-title>
    {{ trans.title.soil | translate }}
    <button
      class="custom-button-help"
      mat-mini-fab
      color="primary"
      matTooltipClass="custom-tooltip-help"
      [matTooltip]="trans.text.helpStatusSoilChart['TRAP'] | translate"
    >
      ?
    </button>
  </h2>

  <button
    mat-icon-button
    autofocus="false"
    class="custom-button-close"
    (click)="closeModal()"
    tabindex="-1"
  >
    <i class="las la-times"></i>
  </button>
</div>

<mat-dialog-content style="height: 75vh; overflow: hidden">
  <form class="custom-form-field" [formGroup]="formGroup">
    <mat-form-field fxFlex="20%">
      <mat-label>{{ trans.label.startDate | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="startPicker"
        id="startDate"
        formControlName="startDate"
        [max]="applicationService.getServerDate().toDate()"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field fxFlex="20%">
      <mat-label>{{ trans.label.endDate | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="endPicker"
        id="endDate"
        formControlName="endDate"
        [appDisableControl]="!this.formGroup.get('startDate')?.value"
        [min]="this.formGroup.get('startDate')?.value"
        [max]="applicationService.getServerDate().toDate()"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field fxFlex="30%">
      <mat-label>{{ trans.label.trap | translate }}</mat-label>
      <mat-select
        #materialSelect
        id="ticketId"
        formControlName="ticketId"
        [appDisableControl]="
          !(this.formGroup.get('startDate')?.value && this.formGroup.get('endDate')?.value)
        "
        required
      >
        <mat-option *ngFor="let trap of this.state.traps" [value]="trap.ticketId">
          <span>
            {{ trap.name }} - {{ trap.ticketId }}
            <span *ngIf="trap.alias && trap.alias !== ''">( {{ trap.alias }} )</span>
            <span *ngIf="!trap.alias || trap.alias === ''">( {{ trap.name }} )</span>
          </span>
        </mat-option>
      </mat-select>
      <mat-error>
        {{ trans.errors.required['trap'] | label }}
      </mat-error>
    </mat-form-field>
    <button
      type="button"
      mat-flat-button
      color="primary"
      [disabled]="
        !(
          this.formGroup.get('startDate')?.value &&
          this.formGroup.get('endDate')?.value &&
          this.formGroup.get('ticketId')?.value
        )
      "
      (click)="createChart()"
    >
      {{ trans.button.createChart | translate }}
    </button>
  </form>

  <div style="height: 55vh">
    <app-soil-chart *ngIf="state.soilChart" [data]="state.soilChart"></app-soil-chart>
  </div>

  <app-empty-states-box
    *ngIf="state.loadingSoilChart <= 0 && state.soilChart.length <= 0"
    [iconSize]="'62px'"
    [messageSize]="'22px'"
    [messagePadding]="'45px 20px'"
    [icon]="'la-chart-pie'"
    [message]="trans.text.chartNotFound"
  ></app-empty-states-box>
</mat-dialog-content>
