import { UserData } from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/user';
import { Farm, WebUser } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

export const userStateDefault = {
  users: [],
  userEdit: {} as WebUser,
  userForm: {} as UserData,
  farms: [],
  loading: 0,
  loadingModal: 0,
  loadingRemove: 0,
  closeModal: false,
  displayedColumns: ['select', 'name', 'email', 'phoneNumber', 'actions'],
  displayedForms: ['name', 'email', 'phoneNumber', 'farmIds'],
  pageSizeOptions: [15],
} as UserState;

export interface UserState {
  users: Array<WebUser>;
  farms: Array<Farm>;
  userEdit: WebUser;
  userForm: UserData;
  loading: number;
  loadingModal: number;
  loadingRemove: number;
  closeModal: boolean;
  displayedColumns: string[];
  displayedForms: string[];
  pageSizeOptions: number[];
}
