import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { TranslateTypes } from 'src/app/services/translation.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  animations: [
    trigger('openClose', [
      state('open', style({})),
      state(
        'closed',
        style({
          display: 'none',
        })
      ),
      transition('open => closed', [animate('{{time}}')]),
      transition('closed => open', [animate('0s')]),
    ]),
  ],
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  constructor(public trans: TranslateTypes) {}

  @Input() public isOpen = 0;
  @Input() public excitedTime = '3s';
}
