import { createSelector, createFeatureSelector } from '@ngrx/store';
import { RouteOptimizationState } from './route-optimization.state';

export const selectRouteOptimizationState =
  createFeatureSelector<RouteOptimizationState>('routeOptimizationState');

export const selectAllClients = createSelector(
  selectRouteOptimizationState,
  (state: RouteOptimizationState) => state.customers
);

export const selectAllFarms = createSelector(
  selectRouteOptimizationState,
  (state: RouteOptimizationState) => state.farms
);

export const selectCurrentRouteSegments = createSelector(
  selectRouteOptimizationState,
  (state: RouteOptimizationState) => state.routeSegments
);

export const selectOptimizedOrder = createSelector(
  selectRouteOptimizationState,
  (state: RouteOptimizationState) => state.optimizedOrder
);

export const selectDurations = createSelector(
  selectRouteOptimizationState,
  (state: RouteOptimizationState) => state.durations
);

export const selectTotalDuration = createSelector(
  selectRouteOptimizationState,
  (state: RouteOptimizationState) => state.totalDuration
);

// Novos Selectors para Carregamento
export const selectLoadingCustomers = createSelector(
  selectRouteOptimizationState,
  (state: RouteOptimizationState) => state.loadingCustomers
);

export const selectLoadingFarms = createSelector(
  selectRouteOptimizationState,
  (state: RouteOptimizationState) => state.loadingFarms
);
