<app-progress-bar [isOpen]="state.loadingMovingAverageChart"></app-progress-bar>

<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 mat-dialog-title>
    {{ trans.title.movingAverage | translate }}
    {{ state.movingAverageChart ? '(' + state.movingAverageChart?.name + ')' : '' }}
    <button
      class="custom-button-help"
      mat-mini-fab
      color="primary"
      matTooltipClass="custom-tooltip-help"
      [matTooltip]="trans.text.helpMovingAverageChart['TRAP'] | translate"
    >
      ?
    </button>
  </h2>

  <button
    mat-icon-button
    autofocus="false"
    class="custom-button-close"
    (click)="closeModal()"
    tabindex="-1"
  >
    <i class="las la-times"></i>
  </button>
</div>

<mat-dialog-content style="height: 75vh; overflow: hidden">
  <form class="custom-form-field" [formGroup]="formGroup">
    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
      <mat-form-field fxFlex="100%">
        <mat-label>{{ trans.label.period | translate }}</mat-label>
        <mat-select
          #materialSelect
          id="period"
          formControlName="period"
          (openedChange)="getMovingAverage(materialSelect)"
          required
        >
          <mat-option *ngFor="let period of [1, 3, 7, 15]" [value]="period">
            {{ period }} {{ (period === 1 ? trans.text.day : trans.text.days) | translate }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ trans.errors.required['field'] | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
  <app-moving-average-chart
    *ngIf="state.movingAverageChart !== null"
    [data]="state.movingAverageChart"
  ></app-moving-average-chart>
  <app-empty-states-box
    *ngIf="state.loadingMovingAverageChart <= 0 && state.movingAverageChart === null"
    [iconSize]="'62px'"
    [messageSize]="'22px'"
    [messagePadding]="'45px 20px'"
    [icon]="'la-chart-line'"
    [message]="trans.text.chartNotFound"
  ></app-empty-states-box>
</mat-dialog-content>
