import {
  Farm,
  WebUser,
  Harvest,
  Notification,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { HeaderWebUser, UserImage } from 'src/app/interfaces/User';

export const headerStateDefault = {
  notifications: [],
  user: {} as WebUser,
  farms: [],
  harvests: [],
  userEdit: { image: { src: null } as UserImage } as HeaderWebUser,
  loading: false,
  loadingModal: false,
  closeModal: false,
  recoverPassword: true,
} as HeaderState;

export interface HeaderState {
  notifications: Array<Notification>;
  farms: Array<Farm>;
  harvests: Array<Harvest>;
  user: HeaderWebUser;
  userEdit: HeaderWebUser;
  loading: boolean;
  loadingModal: boolean;
  closeModal: boolean;
  recoverPassword: boolean;
}
